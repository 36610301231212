import React from 'react';
import { Collapse, Button, CardBody, Card } from 'reactstrap';

export default class Opciones extends React.Component {
    render(){
        return(
            <Card>
                <CardBody>
                    <Modulo nombre="Catalogos" opciones={["Insumos","SubProductos","Productos","Proveedores","Centros de trabajo","Recursos"]} opcionSeleccionada={this.props.opcionSeleccionada}/>
                    <Modulo nombre="Agricola" opciones={["Formulas","Proyectos"]} opcionSeleccionada={this.props.opcionSeleccionada}/>
                    <Modulo nombre="Movimientos" opciones={["Entradas","Salidas","Traspasos"]} opcionSeleccionada={this.props.opcionSeleccionada}/>
                    <Modulo nombre="Informes" opciones={["Inventario","Produccion","Compras"]} opcionSeleccionada={this.props.opcionSeleccionada}/>
                </CardBody>
            </Card>            
        )
    }
}
class Modulo extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = { collapse: false };
    }    
    toggle = () => {
        this.setState(state => ({ collapse: !state.collapse }));
    }
    onOpcionClick = (e) => {
        this.props.opcionSeleccionada(e);
    }
    render() {
        return (
            <div>
                <span class="text-black" style={{fontWeight:'bold'}} onClick={this.toggle}>{this.props.nombre}</span>
                <Collapse isOpen={this.state.collapse}>
                    <Card>
                        <CardBody>
                            {this.props.opciones.map((e,i) => {
                            return (
                                <React.Fragment>
                                <span class="text-black" onClick={() => this.onOpcionClick(e)}>{e}</span>
                                <br/>
                                </React.Fragment>
                                )
                            }
                            )}
                        </CardBody>
                    </Card>
                </Collapse>
            </div>
        )
    }
}