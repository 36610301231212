import React from 'react';
import {  Table, Form, FormGroup, Col, Input, Button, Popover, Spinner, PopoverHeader } from 'reactstrap';
import {  Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import Buscador from './../Buscador';
import withReactContent from 'sweetalert2-react-content'
import { async } from 'q';

export default class Facturas extends React.Component{
    constructor(props){
        super(props);
        //let fec = new Date().toISOString().substring(0,10);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');
        this.state = {
            formaPagoPA:'',
            clientePago:0,
            conceptoNC:'',
            tipoRelNC:'',
            formaNC:'',
            todas:false,
            importeNC:0,
            mostrarNC:false,
            editando:false,
            editandoPago:false,
            cargando:false,
            emp : 1,
            tipo:'',
            clienteFiltro:0,
            fechaIni:fecIni,
            fechaFin:fecFin,
            tipos:[],
            facturas:[],
            metodos:[],
            formas:[],
            usos:[],
            relaciones:[],
            clientes:[],
            codigos:[],
            empresas:[],
            monedas:[],
            facturaActual:{}
        };        
    }
    componentDidMount = () =>{
        this.cargarTipos();
        this.cargarCatalogos();
    }
    componentWillUnmount(){
        //const estado = JSON.stringify(this.state);
        //localStorage.setItem("facturas", estado);
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let res = await fetch(this.props.urlws + "/factura/listaMetodos");
        const metodos = await res.json();
        res = await fetch(this.props.urlws + "/factura/listaRelacion");
        const relaciones = await res.json();
        res = await fetch(this.props.urlws + "/factura/listaFormas");
        const formas = await res.json();
        res = await fetch(this.props.urlws + "/factura/listaUsos");
        const usos = await res.json();
        res = await fetch(this.props.urlws + "/cliente/lista?filtro=");
        const clientes = await res.json();
        res = await fetch(this.props.urlws + "/articulo/articulosFact?filtro=");
        const codigos = await res.json();
        res = await fetch(this.props.urlws + "/empresa/empresas");
        const empresas = await res.json();
        res = await fetch(this.props.urlws + "/factura/listaMonedas");
        const monedas = await res.json();
        this.setState({ formas, metodos, usos, clientes, relaciones, codigos, empresas, monedas, cargando:false });
    }
    cargarClientes = async() =>{
        this.setState({cargando:true});
        let res = await fetch(this.props.urlws + "/cliente/lista?filtro=");
        const clientes = await res.json();
        this.setState({ clientes, cargando:false });
    }
    cargarTipos = async() =>{
        this.setState({cargando:true});
        const url = this.props.urlws + "/factura/listaTipos/" + this.state.emp;
        const res = await fetch(url);
        const tipos = await res.json();
        this.setState({tipos,cargando:false});
    }
    cargarFacturas = async() =>{
        //2019-08-10
        const tipo = this.state.tipo;
        if(tipo === ""){
            Swal.fire('Atencion','Falta indicar el tipo de documento','success');
            return;
        }
        let todas = this.state.todas?"S":"N";
        let f1 = this.state.fechaIni;
        let f2 = this.state.fechaFin;
        let fecIni = f1.substring(0,4) + f1.substring(5,7) + f1.substring(8);
        let fecFin = f2.substring(0,4) + f2.substring(5,7) + f2.substring(8);
        let cte = this.state.clienteFiltro;
        this.setState({cargando:true});
        const url = `${this.props.urlws}/factura/wslista?cte=${cte}&emp=${this.state.emp}&clave=${tipo}&fecIni=${fecIni}&fecFin=${fecFin}&todas=${todas}`;
        const res = await fetch(url);
        const facturas = await res.json();
        this.setState({facturas, cargando:false});
    }
    imprimirFacturas = () =>{
        const tipo = this.state.tipo;
        if(tipo === ""){
            Swal.fire('Atencion','Falta indicar el tipo de documento','success');
            return;
        }
        let todas = this.state.todas?"S":"N";
        let f1 = this.state.fechaIni;
        let f2 = this.state.fechaFin;
        let fecIni = f1.substring(0,4) + f1.substring(5,7) + f1.substring(8);
        let fecFin = f2.substring(0,4) + f2.substring(5,7) + f2.substring(8);
        let cte = this.state.clienteFiltro;
        const url = `${this.props.urlws}/factura/wslistado?cte=${cte}&emp=${this.state.emp}&clave=${tipo}&fecIni=${fecIni}&fecFin=${fecFin}&todas=${todas}`;
        window.open(url, '', '');
    }
    descargarFacturas = () =>{
        const tipo = this.state.tipo;
        if(tipo === ""){
            Swal.fire('Atencion','Falta indicar el tipo de documento','success');
            return;
        }
        let todas = this.state.todas?"S":"N";
        let f1 = this.state.fechaIni;
        let f2 = this.state.fechaFin;
        let fecIni = f1.substring(0,4) + f1.substring(5,7) + f1.substring(8);
        let fecFin = f2.substring(0,4) + f2.substring(5,7) + f2.substring(8);
        let cte = this.state.clienteFiltro;
        const url = `${this.props.urlws}/factura/descargar?cte=${cte}&emp=${this.state.emp}&clave=${tipo}&fecIni=${fecIni}&fecFin=${fecFin}&todas=${todas}`;
        window.open(url, '', '');
    }

    nueva = async() =>{
        this.setState({cargando:true});
        let res = await fetch(this.props.urlws + "/cliente/lista?filtro=");
        const clientes = await res.json();
        this.setState({ clientes, cargando:false, editando:true });
        //this.setState({editando:true});
    }
    nuevoPago = () =>{
        this.setState({editandoPago:true});
    }
    onChange = (e) =>{
        const campo = e.target.name;
        this.setState({
            [campo]: e.target.value
        });
    }
    onChangeCh = (e) => {
        const campo = e.target.name;
        this.setState({
            [campo]: e.target.checked
        });
    }
    guardarNC = () =>{
        const impNC = this.state.importeNC;
        if(impNC > this.state.facturaActual.Saldo){
            Swal.fire('Atencion','El importe es mayor al saldo de la factura','error');
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar la Nota de Credito ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.guardarNCB();
            }
        });

    }
    guardarNCB = async() => { //async() => {
        const url = this.props.urlws + "/factura/wscrearNC";
        const data = {
            Empresa :this.state.emp,
            Id: this.state.facturaActual.UUID,
            Forma: this.state.formaNC,
            Relacion: this.state.tipoRelNC,
            Concepto: this.state.conceptoNC,
            Importe: this.state.importeNC
        };

        const req = await fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
          })
        const res = await req.text();
        if(res.substring(0,5) === "Error"){
            Swal.fire('Atencion',res,'error');
            return res;
        }
        this.setState({editando: false,mostrarNC:false},()=> this.cargarFacturas());
        Swal.fire('Atencion','Datos guardados.','success');
        let params = '/' + this.state.emp + '-' + res;
        const urlImp = this.props.urlws + '/factura/wsimprimir' + params;
        window.open(urlImp, '', '');
        return "ok";
    }
    guardar = async(data) => { //async() => {
        const url = this.props.urlws + "/factura/wsguardar";
        //const url = "http://localhost:29674/factura/wsguardar";
        this.setState({cargando:true});
        const req = await fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
          });

        const res = await req.text();

        if(res.substring(0,5) === "Error"){
            this.setState({cargando:false});
            Swal.fire('Atencion',res,'error');
            return res;
        }
        this.setState({cargando:false, editando: false},()=> this.cargarFacturas());
        Swal.fire('Atencion','Datos guardados.','success');
        let params = '/' + this.state.emp + '-' + res;
        const urlImp = this.props.urlws  + '/factura/wsimprimir' + params;
        window.open(urlImp, '', '');
        return "ok";
    }
    cancelarCfdi = (cve,num) =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea cancelar este CFDI " + cve + " - " + num + " ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.cancelarCfdiB(cve,num);
            }
        });
    }
    cancelarCfdiB = (cve,num) =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea cancelar este CFDI " + cve + " - " + num + " ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.cancelarCfdiC(cve,num);
            }
        });
    }
    cancelarCfdiC = async(cve,num) =>{
        const url = this.props.urlws + "/factura/wscancelar?emp=" + this.state.emp + '&clave=' + cve + '&numero=' + num;
        //const url = "http://localhost:29674/factura/wscancelar?emp=" + this.state.emp + '&clave=' + cve + '&numero=' + num;
        const req = await fetch(url, {
            method: 'POST'
          })
        const res = await req.text();
        if(res.substring(0,5) === "Error"){
            Swal.fire('Atencion',res,'error');
            return res;
        }
        Swal.fire('Atencion','Se cancelo el CFDI','success');
        return "ok";
    }
    enviarEmailB = async(cve,num,email) => { //async() => {
        const url = this.props.urlws + "/factura/wsenviarEmailFactura/" + this.state.emp + '-' + cve + '-' + num + '-' + email;
        //const url = "http://localhost:29674/factura/wsguardar";
        this.setState({cargando:true});
        const req = await fetch(url, {
            method: 'POST' // or 'PUT'
          })
        const res = await req.text();
        this.setState({cargando:false});
        if(res.substring(0,5) === "Error"){
            Swal.fire('Atencion',res,'error');
            return res;
        }
        Swal.fire('Atencion','Se envio el email.','success');
        return "ok";
    }

    cancelar = () => {
        this.setState({editando: false, editandoPago:false});
    }
    cerrarPA = () => {
        this.setState({editandoPago: false});
    }
    cerrarNC = () => {
        this.setState({mostrarNC: false});
    }
    crearNC = (fact) =>{
        console.log(fact);
        this.setState({mostrarNC: true, facturaActual:fact, importeNC : 0});
    }
    enviarEmail = async(cve,num,email) =>{
        const {value: emailCap} = await Swal.fire({
            title: 'Enviar archivos por email',
            input: 'text',
            inputValue: email,
            inputPlaceholder: 'Direccion de email'
          })
          
          if (emailCap) {
            this.enviarEmailB(cve,num,emailCap);
          }
    }


    imprimir = (cve,num) =>{
        let params = '/' + this.state.emp + '-' + cve + '-' + num;
        let url = this.props.urlws + '/factura/wsimprimir' + params;
        window.open(url, '', '');
    }
    render(){
        const estilo1 = { display: this.state.editando ? 'inline' : 'none' };
        const estilo2 = { padding:2, display: this.state.editando ? 'none' : 'inline' };
        return(
            <div className="container">
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo1}>
                    <Captura guardar={this.guardar} cancelar={this.cancelar} metodos={this.state.metodos} formas={this.state.formas} usos={this.state.usos} clientes={this.state.clientes} codigos={this.state.codigos} monedas={this.state.monedas} emp={this.state.emp} urlws={this.props.urlws} />
                </div>
                <div style={estilo2}>
                    <div className="row">                        
                        <div className="col">
                            <h2>CFDi</h2>
                        </div>
                        <FormGroup as={Col-3}>
                            <Input size="sm" type="select" name="emp" value={this.state.emp} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                {this.state.empresas.map((i, k) =>
                                    <option key={i.I} value={i.I}>{i.N}</option>
                                )}
                            </Input>
                        </FormGroup>
                        &nbsp;&nbsp;
                        <FormGroup as={Col}>
                            <Input size="sm" type="select" name="clienteFiltro" value={this.state.clienteFiltro} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                <option key="0" value="0">Todos</option>
                                {this.state.clientes.map((i, k) =>
                                    <option key={i.I} value={i.I}>{i.N}</option>
                                )}
                            </Input>
                        </FormGroup>
                        <FormGroup as={Col}>
                            <Button color='secondary' size="sm" onClick={this.imprimirFacturas}>Listado</Button>
                            {'  '}
                            <Button color='secondary' size="sm" onClick={this.descargarFacturas}>Descargar</Button>
                        </FormGroup>
                    </div>
                    <div className="row">
                        <FormGroup as={Col} md="2">
                            <span>Tipo</span><br />
                            <Input size="sm" type="select" name="tipo" value={this.state.tipo} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                <option key="0" value="">Seleccionar</option>
                                {this.state.tipos.map((i, k) =>
                                    <option key={i.Id} value={i.Id}>{i.Dato1}</option>
                                )}
                            </Input>
                        </FormGroup>
                        &nbsp;
                        <FormGroup as={Col} md="2">
                            <span>Del dia</span><br />
                            <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                        </FormGroup>
                        &nbsp;
                        <FormGroup as={Col} md="2">
                            <span>al dia</span><br />
                            <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                        </FormGroup>
                        &nbsp;
                        &nbsp;
                        <FormGroup as={Col} md="1">
                            <span>Todas</span><br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input type="checkbox" checked={this.state.todas} name="todas" onChange={this.onChangeCh} />
                        </FormGroup>
                        &nbsp;
                        &nbsp;
                        <FormGroup as={Col}>
                            <span></span><br />
                            <Button size="sm" color='success' onClick={this.cargarFacturas}>Cargar</Button>
                            {'  '}
                            <Button size="sm" color='success' onClick={this.nueva}>+ factura</Button>
                            {'  '}
                            <Button size="sm" color='success' onClick={this.nuevoPago}>+ pago</Button>
                        </FormGroup>
                    </div>
                    <div className="row">
                        <ListaFacturas facturas={this.state.facturas} imprimir={this.imprimir} enviarEmail={this.enviarEmail} cancelar={this.cancelarCfdi} nc={this.crearNC} />
                    </div>
                </div>
                <div className="row">
                    <Modal id="dlgPeso" name="dlgPeso" isOpen={this.state.mostrarNC} toggle={this.cerrarNC} size="sm" tabIndex={1}>
                        <ModalHeader closeButton>
                            <span>Nota de credito</span>
                        </ModalHeader>
                        <ModalBody>
                            <div className="row">
                                <FormGroup as={Col}>
                                    <span>Tipo de relacion</span><br />
                                    <Input size="sm" type="select" name="tipoRelNC" value={this.state.tipoRelNC} onChange={this.onChange}>
                                        <option key="0" value="">Seleccionar</option>
                                        {this.state.relaciones.map((i, k) =>
                                            <option key={i.Id} value={i.Id}>{i.Dato1}</option>
                                        )}
                                    </Input>
                                </FormGroup>
                            </div>
                            <div className="row">
                                <FormGroup as={Col}>
                                    <span>Forma de pago</span><br />
                                    <Input size="sm" type="select" name="formaNC" value={this.state.formaNC} onChange={this.onChange}>
                                        <option key="0" value="">Seleccionar</option>
                                        {this.state.formas.map((i, k) =>
                                            <option key={i.Id} value={i.Id}>{i.Dato1}</option>
                                        )}
                                    </Input>
                                </FormGroup>
                            </div>
                            <div className="row">
                                <FormGroup as={Col}>
                                    <span>Factura</span><br />
                                    <Input size="sm" type="text" className="form-control" value={this.state.facturaActual.Numero} />
                                </FormGroup>
                                <FormGroup as={Col}>
                                    <span>Pagos</span><br />
                                    <Input size="sm" type="text" className="form-control" value={this.state.facturaActual.Pagos} />
                                </FormGroup>
                                <FormGroup as={Col}>
                                    <span>NC Ant.</span><br />
                                    <Input size="sm" type="text" className="form-control" value={this.state.facturaActual.Nc} />
                                </FormGroup>
                                <FormGroup as={Col}>
                                    <span>Saldo</span><br />
                                    <Input size="sm" type="text" className="form-control" value={this.state.facturaActual.Saldo} />
                                </FormGroup>
                                <FormGroup as={Col}>
                                    <span>Importe</span><br />
                                    <Input size="sm" type="text" className="form-control" name="importeNC" value={this.state.importeNC} onChange={this.onChange} />
                                </FormGroup>
                            </div>
                            <div className="row">
                                <FormGroup as={Col}>
                                    <span>Concepto</span><br />
                                    <Input size="sm" type="text" className="form-control" name="conceptoNC" value={this.state.conceptoNC} onChange={this.onChange} />
                                </FormGroup>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="success" onClick={this.guardarNC}>Guardar</Button>
                            <Button color="secondary" onClick={this.cerrarNC}>Cancelar</Button>
                        </ModalFooter>
                    </Modal>
                    <div className="col-10">
                        <FormPago show={this.state.editandoPago} clientes={this.state.clientes} formas={this.state.formas} monedas={this.state.monedas} emp={this.state.emp} onHide={this.cerrarPA} urlws={this.props.urlws}/>
                    </div>
                </div>
            </div>
        )
    }
}

function ListaFacturas({ facturas,imprimir,enviarEmail,cancelar,nc }) {
    return (
        <div>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{ width: 55 }}>Serie</th>
                        <th style={{ width: 75 }}>Numero</th>
                        <th style={{ width: 85 }}>Fecha</th>
                        <th style={{ width: 450 }}>Cliente</th>
                        <th style={{ width: 100, textAlign: 'right' }}>Total</th>
                        <th>Cancelada</th>
                        <th>Timbrada</th>
                    </tr>
                </thead>
                <tbody>
                    {facturas.map((fact, i) => (
                        <FacturaRow key={i} factura={fact} imprimir={imprimir} enviarEmail={enviarEmail} cancelar={cancelar} nc={nc}/>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}
function FacturaRow({ factura,imprimir, enviarEmail,cancelar,nc }) {
    return (
        <tr>
            <td>{factura.Clave}</td>
            <td>{factura.Numero}</td>
            <td>{factura.Fecha}</td>
            <td>{factura.Nombre}</td>
            <td style={{width:100, textAlign:'right'}}>{factura.TotalStr}</td>
            <td className="text-center"><Input type="checkbox" checked={factura.Cancelada}/></td>
            <td className="text-center"><Input type="checkbox" checked={factura.Timbrada}/></td>
            <td><Button outline color="success" size="sm" onClick={() => imprimir(factura.Clave,factura.Numero)}>Imprimir</Button></td>
            <td><Button outline color="primary" size="sm" onClick={() => enviarEmail(factura.Clave,factura.Numero,factura.Email)}>Enviar</Button></td>
            <td><Button outline color="danger" disabled={factura.Cancelada} size="sm" onClick={() => cancelar(factura.Clave,factura.Numero)}>Cancelar</Button></td>
            {
            (factura.Clave === "FA" || factura.Clave === "FC")?
                <td><Button outline color="success" disabled={factura.Cancelada} size="sm" onClick={() => nc(factura)}>N.Credito</Button></td>
            :null
            }
        </tr>
    )
}

class FormPago extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        let fecIni = fec.yyyymmdd('-');
        this.state = {
            fecha:fecIni,
            cliente:0,
            importe:0,
            moneda:'MXN',
            tc:1,
            forma:'',
            facturasPagos:[]
        }
    }
    cargarFacturasPagos = async() =>{
        let cte  = this.state.cliente;
        if(cte === 0)
           cte = -1;
        const url = `${this.props.urlws}/factura/wsfacturasSaldo/${cte}?emp=${this.props.emp}`;
        const res = await fetch(url);
        const facturasPagos = await res.json();
        this.setState({facturasPagos, importe:0});
    }
    onChange = (e) =>{
        const campo = e.target.name;
        this.setState({
            [campo]: e.target.value
        },()=>{
            if(campo === "cliente")
                this.cargarFacturasPagos();
        });
    }
    modificaPago = (i,v) =>{
        let x  = this.state.facturasPagos;
        x[i].Pago = v;
        let importe = 0;
        for (let i = 0; i < this.state.facturasPagos.length; i++) {
            if (this.state.facturasPagos[i].Pago > 0) 
                importe += Number(this.state.facturasPagos[i].Pago);
        }
        this.setState({facturasPagos : x, importe});
    }
    guardar = () =>{
        if(this.state.forma === ''){
            Swal.fire('Atencion','Falta indicar la forma de pago','success');
            return;
        }
        let det = [];
        for (let i = 0; i < this.state.facturasPagos.length; i++) {
            if (this.state.facturasPagos[i].Pago > 0) {
                det.push({
                    cve: this.state.facturasPagos[i].Clave,
                    num: this.state.facturasPagos[i].Numero,
                    imp: this.state.facturasPagos[i].Pago
                });
            }
        }
        let json = JSON.stringify(det);
        let data = {
            emp : this.props.emp,
            fechaPagoStr:this.state.fecha,
            forma:this.state.forma,
            cte:this.state.cliente,
            moneda:this.state.moneda,
            tc:this.state.tc,
            s:json
        };
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar este pago ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.guardarB(data);
            }
        });
    }
    guardarB = async(data) => {
        const url = this.props.urlws + "/factura/wsguardarPago";
        //const url = "http://localhost:29674/factura/wsguardar";
        const req = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
          })
        const res = await req.text();
        if(res.substring(0,5) === "Error"){
            Swal.fire('Atencion',res,'error');
            return res;
        }
        this.props.onHide();
        Swal.fire('Atencion','Datos guardados.','success');
        let params = '/' + this.props.emp + '-' + res;
        const urlImp = this.props.urlws + '/factura/wsimprimir' + params;
        window.open(urlImp, '', '');
        this.setState({cliente:0});
        this.cargarFacturasPagos();
        return "ok";
    }
    render(){
        return(
            <Modal isOpen={this.props.show} toggle={this.props.onHide} size="lg">
                <ModalHeader closeButton className="body">
                    <span>Pago</span>
                </ModalHeader>
                <ModalBody className="body">
                    <div className="row">
                        <FormGroup as={Col} md="3">
                            <span>Fecha</span><br/>
                            <Input size="sm" type="date" name="fecha" value={this.state.fecha} onChange={this.onChange} />
                        </FormGroup>
                        <FormGroup as={Col}>
                        <span>Cliente</span><br/>
                            <Input size="sm" type="select" name="cliente" value={this.state.cliente} onChange={this.onChange}>
                                <option key="0" value="">Seleccionar</option>
                                {this.props.clientes.map((i, k) =>
                                    <option key={i.I} value={i.I}>{i.N}</option>
                                )}
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="row">
                        <div className="col-6"> 
                        <FormGroup>
                        <span>Forma de pago</span><br/>
                            <Input size="sm" type="select" name="forma" value={this.state.forma} onChange={this.onChange}>
                                <option key="0" value="">Seleccionar</option>
                                {this.props.formas.map((i, k) =>
                                    <option key={i.Id} value={i.Id}>{i.Dato1}</option>
                                )}
                            </Input>
                        </FormGroup>
                        </div>
                        <div className="col-3">
                        <FormGroup >
                            <span>Moneda</span><br />
                            <Input type="select" size="sm" name="moneda" value={this.state.moneda} onChange={this.onChange}>
                                <option key="0" value="?">Seleccione</option>
                                {this.props.monedas.map((i, k) =>
                                    <option key={i.Id} value={i.Id}>{i.Dato1}</option>
                                )}
                            </Input>
                        </FormGroup>
                        </div>
                        <div className="col-2">
                        <FormGroup>
                            <span>Tipo de cambio</span><br />
                            <Input type="texp" size="sm" name="tc" value={this.state.tc} onChange={this.onChange} />
                        </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div style={{ height: 300, overflow: 'auto' }}>
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th style={{ width: 55 }}>Serie</th>
                                        <th style={{ width: 75 }}>Numero</th>
                                        <th style={{ width: 90 }}>Importe</th>
                                        <th style={{ width: 90 }}>Saldo</th>
                                        <th style={{ width: 140 }}>Pago</th>
                                        <th>UUID</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.facturasPagos.map((fact, i) => (
                                        <FacturaPago dato={fact} indice={i} modificaPago={this.modificaPago} />
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className="body">
                    <FormGroup as={Col} md="2">
                    <span>Importe</span><br/>
                        <Input size="sm" type="text" value={this.state.importe} />
                    </FormGroup>
                    <Button color="success" size="sm" onClick={this.guardar}>Guardar</Button>
                    <Button color="danger" size="sm" onClick={this.props.onHide}>Cancelar</Button>
                </ModalFooter>
            </Modal>                
        );
    }
}
class FacturaPago extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            pago:0
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.dato.Numero !== prevProps.dato.Numero)
          this.setState({pago:0});
    }
    onChange = (e) =>{
        this.setState({pago:e.target.value});
        this.props.modificaPago(this.props.indice,e.target.value);
    }
    render(){
        return(
            <tr key={this.props.indice}>
                <td style={{width:55}}>{this.props.dato.Clave}</td>
                <td style={{width:75}}>{this.props.dato.Numero}</td>
                <td style={{width:90, textAlign:'right'}}>{this.props.dato.Total}</td>
                <td style={{width:90, textAlign:'right'}}>{this.props.dato.Saldo}</td>
                <td style={{width:140, textAlign:'right'}}>
                    <input size="sm" type="text" className="form-control" value={this.state.pago} onChange={this.onChange}/>
                </td>
                <td>{this.props.dato.UUID}</td>
            </tr>
        )
    }
} 
class Captura extends React.Component {
    estadoInicial = {
        show:false,
        titulo:'',
        renActual:0,
        editando:false,
        pedirDescrip:false,
        codigo: '',
        nombre: '',
        precio: 0,
        importe: 0,
        cantidad: 1,
        tc:1,
        moneda:'MXN',
        formaPago:'',
        metodoPago:'',
        usoCfdi:'',
        cliente: -1,
        ivaIncluido:false,
        detalle: []
    };
    constructor(props) {
        super(props);
        this.state = this.estadoInicial;
    }
    componentWillReceiveProps(newProps){
        this.setState({
            show:false,
            titulo:'',
            renActual:0,
            editando:false,
            pedirDescrip:false,
            codigo: '',
            nombre: '',
            precio: 0,
            importe: 0,
            cantidad: 1,
            tc:1,
            moneda:'MXN',
            formaPago:'',
            metodoPago:'',
            usoCfdi:'',
            cliente: -1,
            ivaIncluido:false,
            detalle: []    
        });
    }
    cargarArticulo = async (cod) => {
        const url = `${this.props.urlws}/articulo/wscargar/${cod}?emp=${this.props.emp}`;
        const res = await fetch(url);
        const arti = await res.json();
        if (arti.Empresa === 0) {
            return;
        }
        this.setState({ codigo: cod, nombre: arti.Nombre, ivaIncluido: arti.IvaIncluido, pedirDescrip:arti.PedirDescrip, precio: arti.Precio, importe: arti.Precio * this.state.cantidad });
    }
    codigoBuscador = (codigo) => {
        this.cargarArticulo(codigo);
    }
    handleClose = () => {
        this.setState({ show: false });
    }
    nuevo = () => {
        this.setState({ show: true, editando : false, titulo:'agregar concepto' });
    }
    onChange = (e) => {
        const campo = e.target.name;
        let imp = this.state.importe;
        let valor = e.target.value;
        let cargarArt = false;
        let actFormas = false;

        if (campo === "cantidad")
            imp = this.state.precio * Number(valor);
        if (campo === "precio")
            imp = this.state.cantidad * Number(valor);
        if (campo === "codigo"){
            cargarArt = valor !== this.state.codigo;
        }            
        if (campo === "moneda" || campo === "tc")
           this.setState({ [campo]: valor });
        if (campo === "cliente"){
            // actualizar forma,metodo,uso;
            for(let k = 0; k < this.props.clientes.length; k++)
               if(this.props.clientes[k].Id === valor){
                   actFormas = true;
                   const formaPago = this.props.clientes[k].Dato2;
                   const metodoPago = this.props.clientes[k].Dato3;
                   const usoCfdi = this.props.clientes[k].Dato4;
                   this.setState({ [campo]: valor, formaPago, metodoPago, usoCfdi });
                }
        }          
        if(!actFormas){
            this.setState({ [campo]: valor, importe: imp },()=>{
                if(cargarArt)
                    this.cargarArticulo(valor);
            });
        }
    }
    agregar = () => {
        const item = {
            show:false,
            codigo: this.state.codigo,
            nombre: this.state.nombre,
            cantidad: this.state.cantidad,
            precio: this.state.precio,
            importe: this.state.importe,
            pedirDescrip : this.state.pedirDescrip
        }
        let det = this.state.detalle;
        if(this.state.editando){
            det[this.state.renActual] = item;
        }
        else
            det.push(item);
        this.setState({ detalle: det, editando : false });
    }
    editar = (i) =>{
        const ren = this.state.detalle[i];
        this.setState({
            renActual:i,
            editando:true,
            pedirDescrip:ren.pedirDescrip,
            codigo: ren.codigo,
            nombre: ren.nombre,
            precio: ren.precio,
            importe: ren.importe,
            cantidad: ren.cantidad,
            show:true,
            titulo:'editando concepto'
        })
    }
    eliminar = (i) => {
        let det = this.state.detalle;
        det.splice(i, 1);
        this.setState({ detalle: det });
    }
    guardar = () =>{
        if(this.state.cliente === -1){
            Swal.fire('Atencion','Debe especificar el cliente','error');
            return;
        }
        if(this.state.formaPago === '' || this.state.metodoPago === '' || this.state.usoCfdi === '' || this.state.cliente === 0){
            Swal.fire('Atencion','Falta de especificar algunos datos','error');
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value)
                this.guardarB();
        });
    }
    guardarB = async() =>{
        let det = [];
        const rows = this.state.detalle;
        for(let i = 0; i < rows.length; i++) {
            det.push({
                cod: rows[i].codigo,
                can: rows[i].cantidad,
                pre: rows[i].precio,
                des: rows[i].nombre
            });
        }
        let json = JSON.stringify(det);
        let data = {
            empresa : this.props.emp,
            metodo: this.state.metodoPago,
            forma: this.state.formaPago,
            uso: this.state.usoCfdi,
            cte: this.state.cliente,
            moneda:this.state.moneda,
            tc:Number(this.state.tc),
            ivaIncluido: this.state.ivaIncluido?'S':'N',
            s: json
        }
        const res = await this.props.guardar(data);
        //if(res === "ok")
        //this.setState(this.estadoInicial);
    }
    cancelar = () =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea salir sin guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.setState(this.estadoInicial);
                this.props.cancelar();
            }
        });
    }
    urlBusq = `https://www.facturar.isaerpweb.com/articulo/wslista?emp=${this.props.emp}&filtro=`;
    popCod = (
        <Popover id="popover-basic" title="Buscar concepto" container={this.props.parent}>
            <Buscador onSelect={this.codigoBuscador} url={this.urlBusq} />
        </Popover>
    );
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <FormGroup as={Col} md="4">
                    <span>Cliente</span><br/>
                        <Input size="sm" type="select" name="cliente" value={this.state.cliente} onChange={this.onChange}>
                            <option key="0" value={-1}>Seleccione</option>
                            {this.props.clientes.map((i, k) =>
                                <option key={i.I} value={i.I}>{i.N}</option>
                            )}
                        </Input>
                    </FormGroup>
                    &nbsp;
                    <FormGroup as={Col} md="2">
                    <span>Forma de pago</span><br/>
                        <Input size="sm" type="select" name="formaPago" value={this.state.formaPago} onChange={this.onChange}>
                            <option key="0" value="">Seleccione</option>
                            {this.props.formas.map((i, k) =>
                                <option key={i.Id} value={i.Id}>{i.Dato1}</option>
                            )}
                        </Input>
                    </FormGroup>
                    &nbsp;
                    <FormGroup as={Col} md="2">
                    <span>Metodo de pago</span><br/>
                        <Input type="select" size="sm" name="metodoPago" value={this.state.metodoPago} onChange={this.onChange}>
                            <option key="0" value="">Seleccione</option>
                            {this.props.metodos.map((i, k) =>
                                <option key={i.Id} value={i.Id}>{i.Dato1}</option>
                            )}
                        </Input>
                    </FormGroup>
                    &nbsp;
                    <FormGroup as={Col} md="4">
                    <span>Uso del CFDi</span><br/>
                        <Input type="select" size="sm" name="usoCfdi" value={this.state.usoCfdi} onChange={this.onChange}>
                            <option key="0" value="">Seleccione</option>
                            {this.props.usos.map((i, k) =>
                                <option key={i.Id} value={i.Id}>{i.Dato1}</option>
                            )}
                        </Input>
                    </FormGroup>
                    &nbsp;&nbsp;
                    <FormGroup as={Col} md="2">
                    <span>Moneda</span><br/>
                        <Input type="select" size="sm" name="moneda" value={this.state.moneda} onChange={this.onChange}>
                            <option key="0" value="?">Seleccione</option>
                            {this.props.monedas.map((i, k) =>
                                <option key={i.Id} value={i.Id}>{i.Dato1}</option>
                            )}
                        </Input>
                    </FormGroup>
                    &nbsp;&nbsp;
                    {
                        this.state.moneda != "MXN" &&
                    <FormGroup as={Col} md="2">
                    <span>T.Cambio</span><br/>
                        <Input type="text" size="sm" name="tc" value={this.state.tc} onChange={this.onChange} />
                    </FormGroup>
                    }
                </div>

                <div className="row" style={{ height: 250, overflow: 'auto' }}>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th style={{ width: 100, textAlign: 'right' }}>Cantidad</th>
                                <th style={{ width: 75 }}>Codigo</th>
                                <th>Nombre</th>
                                <th style={{ width: 100, textAlign: 'right' }}>Precio</th>
                                <th style={{ width: 100, textAlign: 'right' }}>Importe</th>
                                <th style={{ width: 85 }}></th>
                                <th style={{ width: 85 }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.detalle.map((e, i) => (
                                <tr>
                                    <td>{e.cantidad}</td>
                                    <td>{e.codigo}</td>
                                    <td>{e.nombre}</td>
                                    <td style={{ width: 100, textAlign: 'right' }}>{e.precio}</td>
                                    <td style={{ width: 100, textAlign: 'right' }}>{e.importe}</td>
                                    <td>
                                    <img src="/imagenes/edit.png" onClick={() => this.editar(i)} alt="editar" title="editar"/>
                                    </td> 
                                    <td>
                                    <img src="/imagenes/quitar.png" onClick={() => this.eliminar(i)} alt="editar" title="editar"/>
                                    </td>
                                </tr>

                            ))}
                        </tbody>
                    </Table>
                </div>

                <div className="row">
                    <div className="col">
                        <Button size = "sm" color="success" onClick={this.nuevo}>+</Button> {'  '}
                        <Button size = "sm" color="success" onClick={this.guardar}>Guardar</Button> {'  '}
                        <Button size = "sm" color="danger" onClick={this.cancelar}>Cancelar</Button> {'  '}
                    </div>
                </div>

                <Modal id="dlg" name="dlg" isOpen={this.state.show} toggle={this.handleClose} size="lg" tabIndex={1}>
                    <ModalHeader closeButton>
                        <span>{this.state.titulo}</span>
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <FormGroup as={Col}>
                                <span>Concepto</span><br/>
                                <Input type="select" name="codigo" value={this.state.codigo} onChange={this.onChange}>
                                    <option key="0" value="">Seleccione</option>
                                {this.props.codigos.map((i, k) =>
                                    <option key={i.I} value={i.I}>{i.N}</option>
                                )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="row">
                            <div className="col-2">
                                <div className="form-group" size="sm">
                                    <label>Cantidad</label>
                                    <Input size="sm" type="text" name="cantidad" value={this.state.cantidad} onChange={this.onChange} />
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="form-group" size="sm">
                                    <label>Precio</label>
                                    <Input size="sm" type="text" name="precio" value={this.state.precio} onChange={this.onChange} />
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="form-group" size="sm">
                                    <label>Importe</label>
                                    <Input size="sm" type="text" name="importe" value={this.state.importe} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="form-group" size="sm">
                                    <label>Descripcion</label>
                                    <textarea class="form-control" rows="5" name="nombre" value={this.state.nombre} onChange={this.onChange} readOnly={this.state.pedirDescrip} ></textarea>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button size="sm" color="success" onClick={this.agregar}>Agregar</Button>
                        <Button size="sm" color="danger" onClick={this.handleClose}>Cancelar</Button>
                    </ModalFooter>
                </Modal>                
            </React.Fragment>
        )
    }
}