import React from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Buscador from '../Buscador';
import { async } from 'q';

export default class PagosCxP extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');
        this.state = {
            cargando:false,
            editando:false,
            Temporada:this.props.temp,
            almacen:1,
            fechaIni:fecIni,
            fechaFin:fecFin,
            clave:'PA',
            pagos:[],
            temporadas:[],
            cuentas:[],
            formas:[],
            pago:{
                TipoPago:'??',
                Importe:0,
                Cuenta:0,
                Prov:0,
                Abonos:[]
            }
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/agricola/temporadas`;
        let resp = await fetch(url);
        const temporadas = await resp.json();
        
        url = `${this.props.urlws}/empresa/formasPago`;
        resp = await fetch(url);
        const formas = await resp.json();
        
        url = `${this.props.urlws}/empresa/cuentas/${this.state.almacen}`;
        resp = await fetch(url);
        const cuentas = await resp.json();

        this.setState({cargando:false, temporadas, cuentas, formas});
    }

    cargarMovs = async () =>{
        if(this.state.almacen == 0){
            Swal.fire('Atencion','Especifique el almacen','error');
            return;
        }
        const alm = this.state.almacen;
        const fecIni = this.state.fechaIni;
        const fecFin = this.state.fechaFin;
        this.setState({cargando:true});
        let url = `${this.props.urlws}/prov/pagos?alm=${alm}&fecIni=${fecIni}&fecFin=${fecFin}`;
        let resp = await fetch(url);
        const pagos = await resp.json();
        this.setState({cargando:false, pagos });
    }
    cargarDetalle = (mov) =>{
        this.cargarMov(this.state.almacen,mov.Tip,mov.Num);
    }
    cargarMov = async (alm,cve,num) =>{
        this.setState({cargando:true});
        let url = `${this.props.urlws}/prov/cargaPago?alm=${alm}&tipoAbo=${cve}&refAbo=${num}`;
        let resp = await fetch(url);
        const pago = await resp.json();
        if(pago.Numero === 0)
            pago.Temporada = this.state.Temporada;
        this.setState({cargando:false, editando:true, pago});
    }
    nuevoMov = () =>{
        const alm = this.state.almacen;
        const cve = this.state.clave;
        if(cve === "??"){
            Swal.fire('Atencion','Especifique el tipo de movimiento','error');
            return;
        }
        if(alm === 0){
            Swal.fire('Atencion','Especifique el almacen','error');
            return;
        }
        this.cargarMov(alm,cve,"0");
    }
    guardar = async (obj) =>{
        const mov = {
            Almacen : this.state.almacen,
            TipoAbono : this.state.clave,
            RefAbono: '', //obj.RefAbono,
            Fecha : obj.fecha,
            TipoPago : obj.forma,
            Referencia :obj.cheque,
            Importe:obj.abonos,
            Cuenta:obj.cuenta,
            Prov:obj.prov,
            Concepto:'',
            S: JSON.stringify(obj.doctos.filter((o) => (o.Abo + o.AboDls) > 0))
        };
        const url = this.props.urlws +"/prov/wsguardarPago";
        this.setState({cargando:true});
        const json = JSON.stringify(mov);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.setState({editando:false});
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    imprimir = (mov) =>{
        let params = '?alm=' + mov.Almacen + '&cve=' + mov.Clave + '&num=' + mov.Numero;
        let url = this.props.urlws + '/compras/imprimir' + params;
        window.open(url, '', '');
    }
    imprimirPol = (mov) =>{
        let params = '?emp=' + this.state.almacen + '&ejer=2020&per=1&tipo=E' + '&pol=' + mov.Num;
        let url = this.props.urlws + '/conta/poliza' + params;
        window.open(url, '', '');
    }
    cancelar = () =>{
        this.setState({editando:false});
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor},() =>{
            //if(campo === "Temporada")
            //   this.cargarLotes();
        });
    }
    render () {
        const estilo1 = { display: this.state.editando ? 'inline' : 'none' };
        const estilo2 = { padding:2, display: this.state.editando ? 'none' : 'inline' };
        return(            
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo1}>
                    <Captura pago={this.state.pago} height={450} urlws={this.props.urlws} cancelar={this.cancelar} guardar={this.guardar} temp={this.state.Temporada} cuentas={this.state.cuentas} formas={this.state.formas} />
                </div>
                <div style={estilo2}>
                    <div className="row">
                        <div className="col-1">
                            <FormGroup>
                                <span>Temporada</span><br/>
                                <Input size="sm" type="select" name="Temporada" value={this.state.Temporada} onChange={this.onChange} style={{  fontSize:'12px'}}>
                                    <option key="0" value="">Especifique</option>
                                    {this.state.temporadas.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Del dia</span><br/>
                                <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>al dia</span><br/>
                                <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup as={Col}>
                                <span>&nbsp;</span><br/>
                                <Button color='success' size="sm" onClick={this.cargarMovs}>Cargar</Button>
                                {'  '}
                                <Button color='success' size="sm" onClick={this.nuevoMov}>+</Button>
                            </FormGroup>
                        </div>
                    </div>
                    <div style={{ height: 4 }}></div>
                    <MovsTable pagos={this.state.pagos} height={500} onRowClick={this.cargarDetalle} imprimir={this.imprimir} poliza={this.imprimirPol} />
                </div>                
            </Container>
        )
    }
}
class MovsTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { pagos } = this.props;
        return (
            <div style={{ height: this.props.height, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:70}}>Numero</th>
                        <th style={{width:80}}>Fecha</th>
                        <th style={{width:300}}>Proveedor</th>
                        <th >Forma de pago</th>
                        <th >Cuenta</th>
                        <th className="numero" style={{width:85}}>Importe</th>
                        <th style={{textAlign:'center'}}>Cancelado</th>
                        <th style={{width:30, textAlign:'center'}}></th>
                        <th style={{width:30, textAlign:'center'}}></th>
                        <th style={{width:30, textAlign:'center'}}></th>
                    </tr>
                </thead>
                <tbody>
                    {pagos.map((esc, i) => (
                        <MovRow key={i} mov={esc} onRowClick={this.onRowClick} imprimir={this.props.imprimir} poliza={this.props.poliza} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}
class MovRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.mov);
    }
    imprimir = (e) =>{
        this.props.imprimir(this.props.mov);
    }
    poliza = (e) =>{
        this.props.poliza(this.props.mov);
    }
    render(){
        let {mov} = this.props;
        return (
        <tr>
            <td>{mov.Tip}-{mov.Num}</td>
            <td>{mov.Fec}</td>
            <td>{mov.Nom}</td>
            <td>{mov.For}</td>
            <td>{mov.Cta}</td>
            <td className="numero">{mov.Imp.formato()}</td>
            <td style={{textAlign:'center'}}><input type="checkbox" checked={mov.C}/></td>
            <td>
                <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={this.onClick}/>
            </td>
            <td>
                <img src="/imagenes/printer.png" alt="imprimir" title="imprimir" onClick={this.imprimir}/>
            </td>
            <td>
                <img src="/imagenes/tabla.png" alt="poliza" title="poliza" onClick={this.poliza}/>
            </td>
        </tr>
        );
    }
}
class Captura extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        let fecIni = fec.yyyymmdd('-');
        this.state = {
            tc:0,
            cheque:0,
            prov:0,
            cuenta:0,
            forma:'??',
            nomProv:'',
            fecha:fecIni,
            cambio:false,
            popoverProvOpen:false,
            cargando:false,
            doctos:[],
            importe:0,
            pendiente:0,
            abonos:0,
            saldo:0,
            importeDls:0,
            pendienteDls:0,
            abonosDls:0,
            saldoDls:0,
            ...this.props.pago
        }
        this.cargarTipoCambio();
    }
    componentWillReceiveProps(newProps){
        this.setState({
            cambio:false,
            forma:newProps.pago.TipoPago,
            cuenta:newProps.pago.Cuenta,
            importe:newProps.pago.Importe,
            abonos:newProps.pago.Importe,
            prov:newProps.pago.Prov,
            cheque:newProps.pago.Referencia,
            nomProv:newProps.pago.Nombre,
            doctos:newProps.pago.Abonos,
            ...newProps.pago,
        },()=>{
            this.cargarTipoCambio();
        });
    }
    cargarDoctos = async () => {
        let importe = 0;
        let pendiente = 0;
        let abonos = 0;
        let saldo = 0;
        let importeDls = 0;
        let pendienteDls = 0;
        let abonosDls = 0;
        let saldoDls = 0;
        this.setState({cargando:true});
        let url = `${this.props.urlws}/prov/porPagar/${this.state.prov}`;
        let resp = await fetch(url);
        const doctos = await resp.json();
        for (let i = 0; i < doctos.length; i++) {
            importe += doctos[i].Imp;
            pendiente += doctos[i].Sdo;
            abonos += doctos[i].Abo;
            importeDls += doctos[i].ImpDls;
            pendienteDls += doctos[i].SdoDls;
            abonosDls += doctos[i].AboDls;
        }
        saldo = pendiente - abonos;
        saldoDls = pendienteDls - abonosDls;
        this.setState({cargando:false,doctos,importe,pendiente,abonos,saldo,importeDls,pendienteDls,abonosDls,saldoDls });
    }
    onChange = (e) => {
        const campo = e.target.name;
        let valor = e.target.value;
        this.setState({ [campo] : valor, cambio: true},()=>{
            if(campo === "fecha")
               this.cargarTipoCambio();
        });
    }
    cargarTipoCambio = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/home/precioDolar?fecha=${this.state.fecha}`;
        let resp = await fetch(url);
        const x = await resp.text();
        this.setState({tc:Number(x)});
    }

    capturarPago = async() =>{
        const { value: importe } = await Swal.fire({
            title: 'Importe del pago',
            titleText:`Saldo : ${this.state.pendiente.formato()}`,
            input: 'text',
            inputValue: this.state.abonos,
            showCancelButton: true,
            inputValidator: (value) => {
              if (!value) {
                return 'Especifica el importe del pago'
              }
            }
          });
          
          if (importe) {
              let valor = Number(importe);
              if(Number.isNaN(valor)){
                 Swal.fire('Atencion','Dato incorrecto','error');
                 return;
              }
              if(valor > this.state.pendiente){
                Swal.fire('Atencion','El importe es mayor al saldo de la factura','error');
                return;
             }
             this.aplicar(valor)
         }            
    }
    aplicar = (valor) =>{
        let doctos = this.state.doctos;
        let abonos = 0;
        for (let i = 0; i < doctos.length; i++) {
            if(valor > doctos[i].Sdo){
                abonos += doctos[i].Sdo
                doctos[i].Abo = doctos[i].Sdo;
                valor -= doctos[i].Sdo;
            }else {
                abonos += valor;
                doctos[i].Abo =valor;
                valor = 0;
            }
        }
        this.setState({doctos,abonos});
    }
    abonar = (valor, valorDls, indice) =>{
        let doctos = this.state.doctos;
        let abonos = 0;
        let abonosDls = 0;
        if(valorDls > 0)
           valor = valorDls * this.state.tc;
        doctos[indice].Abo = valor;
        doctos[indice].AboDls = valorDls;
        for (let i = 0; i < doctos.length; i++) {
            abonos += doctos[i].Abo;
            abonosDls += doctos[i].AboDls;
        }
        this.setState({doctos,abonos, abonosDls});
    }
    toggleProv = () => {
        this.setState({popoverProvOpen: !this.state.popoverProvOpen});
    }    
    provSel = (item) =>{
        this.setState({popoverProvOpen:false, prov: item.I, nomProv:item.N},() =>{
            this.cargarDoctos();
        });
    }
    cargarProv = (idProv) =>{
        
    }
    onKeyPressProv = (e) =>{
        if(e.which == 13)
           this.cargarProv(this.state.prov);
    }
    cancelar = () =>{
        if(!this.state.cambio){
            this.props.cancelar();
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea cancelar la captura ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.cancelar();
            }
          })        
    }
    guardar = () =>{
        console.log(this.state);
        if((this.state.abonos + this.state.abonosDls) < 0.10){
            return;
        }
        if(this.state.forma === "" || this.state.forma === "??"){
            Swal.fire('Atencion','Especifique la forma de pago','error');
            return;
        }
        if(this.state.cuenta == 0){
            Swal.fire('Atencion','Especifique la cuenta bancaria','error');
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar este movimiento ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.guardar(this.state);
            }
          })        
    }
    validaCheque = () =>{
        alert("v.ch");
    }
    render() {
        return(
            <Container>
                <div className="row">
                    <div className="col-2">
                        <span>Proveedor </span><br />
                        <InputGroup>
                            <Input size="sm" type="text" name="prov" value={this.state.prov} onChange={this.onChange} onKeyPress={this.onKeyPressProv} id="prov" autoFocus />
                            <InputGroupAddon addonType="append">
                                <Button size="sm" color="success" id="btnBuscarProv">
                                    <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                </Button>
                                <Popover trigger="legacy" placement="right" isOpen={this.state.popoverProvOpen} target="btnBuscarProv" toggle={this.toggleProv} >
                                    <PopoverBody>
                                        <Buscador tema='provs' onSelect={this.provSel} url={this.props.urlws + "/prov/lista?filtro="} />
                                    </PopoverBody>
                                </Popover>
                            </InputGroupAddon>
                        </InputGroup>
                    </div>
                    <div className="col">
                        <FormGroup>
                            <span>Nombre</span><br />
                            <Input size="sm" type="text" name="nomProv" value={this.state.nomProv} readOnly />
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-8">
                        <div className="row">
                            <div style={{ height: this.props.height, overflow: 'auto' }}>
                                <Table striped bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <th style={{ width: 50 }}>Tipo</th>
                                            <th style={{ width: 120 }}>Numero</th>
                                            <th style={{ width: 90 }}>Fecha</th>
                                            <th style={{ width: 90 }}>Vence</th>
                                            <th className="numero" style={{ width: 95 }}>Importe</th>
                                            <th className="numero" style={{ width: 95 }}>Saldo</th>
                                            <th className="numero" style={{ width: 95 }}>Pago</th>
                                            <th className="numero" style={{ width: 105 }}>Importe Dls</th>
                                            <th className="numero" style={{ width: 95 }}>Saldo Dls</th>
                                            <th className="numero" style={{ width: 95 }}>Pago Dls</th>
                                            <th style={{ width: 30, textAlign: 'center' }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.doctos.map((esc, i) => (
                                            <DoctoRow key={i} mov={esc} indice={i} onRowClick={this.onRowClick} abonar={this.abonar} tc={this.state.tc} />
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                            <div className="row">
                                <div >
                                    <Table striped bordered hover size="sm">
                                        <tbody>
                                            <tr>
                                                <th style={{ width: 50 }}>&nbsp;</th>
                                                <th style={{ width: 120 }}>&nbsp;</th>
                                                <th style={{ width: 90 }}>&nbsp;</th>
                                                <th style={{ width: 90 }}>&nbsp;</th>
                                                <th className="numero" style={{ width: 95 }}>{this.state.importe.formato()}</th>
                                                <th className="numero" style={{ width: 95 }}>{this.state.pendiente.formato()}</th>
                                                <th className="numero" style={{ width: 95 }}>{this.state.abonos.formato()}</th>
                                                <th className="numero" style={{ width: 95 }}>{this.state.importeDls.formato()}</th>
                                                <th className="numero" style={{ width: 95 }}>{this.state.pendienteDls.formato()}</th>
                                                <th className="numero" style={{ width: 95 }}>{this.state.abonosDls.formato()}</th>
                                                <th style={{ width: 30, textAlign: 'center' }}></th>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col" style={{ padding: '0px 0px 0px 30px' }}>
                        <div className="row">
                            <div className="col-6">
                                <FormGroup>
                                    <span>Fecha de pago</span><br />
                                    <Input size="sm" type="date" name="fecha" value={this.state.fecha} onChange={this.onChange} />
                                </FormGroup>
                            </div>
                            <div className="col-4">
                                <FormGroup>
                                    <span>Tipo de cambio</span><br />
                                    <Input size="sm" type="text" value={this.state.tc.formato()} />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-7">
                                <FormGroup>
                                    <span>Forma de pago</span><br />
                                    <Input size="sm" type="select" name="forma" value={this.state.forma} onChange={this.onChange}>
                                        <option key="0" value="??">Especifique</option>
                                        {this.props.formas.map((i, k) =>
                                            <option key={i.I} value={i.I}>{i.N}</option>
                                        )}
                                    </Input>
                                </FormGroup>
                            </div>
                            <div className="col-4">
                                {
                                this.state.forma === "02" &&
                                <FormGroup>
                                    <span>Cheque</span><br />
                                    <Input size="sm" type="text" name="cheque" value={this.state.cheque} onChange={this.onChange} onBlur={this.validaCheque} />
                                </FormGroup>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-7">
                                <FormGroup>
                                    <span>Cuenta</span><br />
                                    <Input size="sm" type="select" name="cuenta" value={this.state.cuenta} onChange={this.onChange}>
                                        <option key="0" value={0}>Especifique</option>
                                        {this.props.cuentas.map((i, k) =>
                                            <option key={i.I} value={i.I}>{i.N}</option>
                                        )}
                                    </Input>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <label>Importe del pago </label><br />
                                <InputGroup >
                                    <Input size="sm" type="text" value={this.state.abonos} readOnly />
                                    <InputGroupAddon addonType="append">
                                        <Button size="sm" color="success" onClick={this.capturarPago}>
                                            <img src="/imagenes/edit.png" alt="editar" title="editar" />
                                        </Button>
                                    </InputGroupAddon>
                                </InputGroup>
                            </div>
                            <div className="col-6">
                                <label>Importe en Dls. </label><br />
                                <InputGroup >
                                    <Input size="sm" type="text" value={this.state.abonosDls} readOnly />
                                </InputGroup>
                            </div>
                        </div>
                        <br/><br/>
                        <div className="row">
                            <div className="col-6">
                                <Button size="sm" color="success" onClick={this.guardar}>Guardar</Button>
                                <span>&nbsp;&nbsp;</span>
                                <Button size="sm" color="danger" onClick={this.cancelar}>Cancelar</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </Container>
        );
    }
}
class DoctoRow extends React.Component{
    onClick = () =>{
        if(this.props.mov.ImpDls > 0)
           this.capturaAbonoDls();
        else
           this.capturaAbono();
    }
    capturaAbonoDls = async() =>{
        if(this.props.tc === 0){
            await Swal.fire('Atencion','Factura en dolares, no hay tipo de cambio para la fecha','error');
            return;
        }
        else
           await Swal.fire('Atencion','Factura en dolares, favor de ingresar el abono en dolares','info');
      const { value: importe } = await Swal.fire({
        //title: 'Importe del pago en dolares',
        titleText:`${this.props.mov.Tip}-${this.props.mov.Ref}, Saldo en Dls. : ${this.props.mov.SdoDls.formato()}`,
        input: 'text',
        inputValue: this.props.mov.AboDls,
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return 'Especifica el importe del pago'
          }
        }
      })
      
      if (importe) {
          let valor = Number(importe);
          if(Number.isNaN(valor)){
             Swal.fire('Atencion','Dato incorrecto','error');
             return;
          }
          if(valor > this.props.mov.SdoDls){
            Swal.fire('Atencion','El importe es mayor al saldo de la factura','error');
            return;
         }
         this.props.abonar(valor,valor,this.props.indice)
     }        
    }    
    capturaAbono = async() =>{
        const { value: importe } = await Swal.fire({
          title: 'Importe del pago',
          titleText:`${this.props.mov.Tip}-${this.props.mov.Ref}, Saldo : ${this.props.mov.Sdo.formato()}`,
          input: 'text',
          inputValue: this.props.mov.Abo,
          showCancelButton: true,
          inputValidator: (value) => {
            if (!value) {
              return 'Especifica el importe del pago'
            }
          }
        })
        
        if (importe) {
            let valor = Number(importe);
            if(Number.isNaN(valor)){
               Swal.fire('Atencion','Dato incorrecto','error');
               return;
            }
            if(valor > this.props.mov.Sdo){
              Swal.fire('Atencion','El importe es mayor al saldo de la factura','error');
              return;
           }
           this.props.abonar(valor,0,this.props.indice)
       }        
      }    
      render(){
        let {mov} = this.props;
        return (
        <tr>
            <td>{mov.Tip}</td>
            <td>{mov.Ref}</td>
            <td>{mov.Fec}</td>
            <td>{mov.Ven}</td>
            <td className="numero">{mov.Imp.formato()}</td>
            <td className="numero">{mov.Sdo.formato()}</td>
            <td className="numero">{mov.Abo.formato()}</td>
            <td className="numero">{mov.ImpDls.formato()}</td>
            <td className="numero">{mov.SdoDls.formato()}</td>
            <td className="numero">{mov.AboDls.formato()}</td>
            <td>
                <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={this.onClick}/>
            </td>
        </tr>
        );
    }
}
