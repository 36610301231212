import React from 'react';
import { Nav,Navbar,InputGroup,Modal, ModalBody, InputGroupAddon, Collapse, Table, Form, FormGroup, Badge, Input, Button, Container, Label } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { async } from 'q';

export default class CultivosLote extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            idCult:0,
            nombre:'?',
            cultivo:0,
            variedad:0,
            parcela:0,
            has:0,
            hasSiembra:0,
            hasCosecha:0,
            mostrarModal:false,
            mesIni:0,
            mesFin:0,
            cargando:true,
            formVisible: false,
            Temporada:this.props.temp,
            lote:{},
            temporadas:[],
            lotes:[],
            cultivos:[],
            variedades:[],
            parcelas:[],
            cultsLote:[]
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/agricola/cultivos`;
        let resp = await fetch(url);
        const cultivos = await resp.json();
        url = `${this.props.urlws}/agricola/temporadas`;
        resp = await fetch(url);
        const temporadas = await resp.json();
        url = `${this.props.urlws}/agricola/parcelas`;
        resp = await fetch(url);
        const parcelas = await resp.json();
        this.setState({cargando:false, cultivos,temporadas,parcelas });
    }
    cargarLotes = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/agricola/lotes?id=${this.state.nombre}`;
        let resp = await fetch(url);
        const lotes = await resp.json();
        this.setState({cargando:false, lotes });
    }
    cargarVariedades = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/agricola/variedadesCultivo/${this.state.cultivo}`;
        let resp = await fetch(url);
        const variedades = await resp.json();
        this.setState({cargando:false, variedades,variedad:0 });
    }
    cargarCultivosLote = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/agricola/lotesCultivoParcelaTemp/${this.state.Temporada}?lote=${this.state.lote.I}`;
        let resp = await fetch(url);
        const cultsLote = await resp.json();
        url = `${this.props.urlws}/agricola/parcelasLote/${this.state.lote.I}`;
        resp = await fetch(url);
        const parcelas = await resp.json();
        this.setState({
            mostrarModal:false,
            cargando:false, 
            cultivo:0,
            variedad:0,
            parcela:0,
            parcelas,
            cultsLote
        });
    }
    handleClose = () =>{
        this.setState({mostrarModal:false});
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.cargarLotes();
    }
    onChange = (e) => {
        const campo = e.target.name;
        this.setState({ [campo] : e.target.value},()=>{
            if(campo === "cultivo")
               this.cargarVariedades();
        });
    }
    onBntBuscarClick = () =>{
        this.cargarLotes();
    }    
    cargarLote = (i) =>{
        const lote = this.state.lotes[i];
        this.setState({lote}, () => this.cargarCultivosLote());
    }
    editarCultivo = async(obj) =>{
        this.setState({cargando:true});
        let url = `${this.props.urlws}/agricola/variedadesCultivo/${obj.Cult}`;
        let resp = await fetch(url);
        const variedades = await resp.json();
        //this.setState({cargando:false, variedades,variedad:0 });

        this.setState({
            idCult:obj.Id,
            cargando:false,
            variedades,
            cultivo:obj.Cult,
            variedad:obj.Var,
            parcela:obj.Parcela,
            has:obj.Has,
            hasSiembra:obj.HasSiembra,
            hasCosecha:obj.HasCosecha,
            mostrarModal:true
        });
    }
    quitarCultivo = async(obj) =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Quitar " + obj.NomCult + '-' + obj.NomVar + " ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then(async(result) => {
            if(result.value){
                this.setState({cargando:true});
                let url = `${this.props.urlws}/agricola/wsBorrarCultivoLote/${obj.Id}`;
                let resp = await fetch(url);
                const res = await resp.text();
                this.setState({cargando:false});
                if(res === "ok")
                   this.cargarCultivosLote();
                else
                   Swal.fire('Atencion',res,'error');
            }
          })        
    }
    agregarCultivo = () =>{
        this.setState({mostrarModal:true, idCult:0, has:0, hasSiembra:0, hasCosecha:0, parcela:0, cultivo:0, variedad:0});
    }
    guardarCultivo = async() =>{
        if(this.state.cultivo === 0 || this.state.variedad === 0){
            Swal.fire('Atencion','faltan datos para guardar','error');
            return;
        }
        this.setState({cargando:true});
        const dato = {
            Id:this.state.idCult,
            Temp:this.state.Temporada,
            Lote:this.state.lote.I,
            Parcela:this.state.parcela,
            Cultivo:this.state.cultivo,
            Variedad:this.state.variedad,
            Has:Number(this.state.has),
            HasSiembra:Number(this.state.hasSiembra),
            HasCosecha:Number(this.state.hasCosecha)

        }
        let url = `${this.props.urlws}/agricola/wsGuardarCultivoLote`;
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        
        const res = await resp.text();
        this.setState({cargando:false});
        if(res !== "ok"){
           Swal.fire('Atencion',res,'error');
           return;
        }
        this.cargarCultivosLote();
    }
    render(){
        return(
            <Container>
                <div className="row">
                    <div className="col">
                        <h5>Lotes</h5>
                        <Navbar bg="f5e831" expand="lg">
                            <Collapse isOpen={true} id="basic-navbar-nav">
                                <Nav className="mr-auto" size="sm">
                                    <Form inline onSubmit={this.onSubmit} >
                                        <div className="row">
                                            <div className="col">
                                                <span>Temporada</span><br />
                                                <Input size="sm" type="select" name="Temporada" value={this.state.Temporada} onChange={this.onChange}>
                                                    <option key="0" value="">Especifique</option>
                                                    {this.state.temporadas.map((i, k) =>
                                                        <option key={i.I} value={i.I}>{i.N}</option>
                                                    )}
                                                </Input>
                                            </div>
                                            <div className="col-7">
                                                <span size="sm">Nombre</span>&nbsp;
                                                <InputGroup>
                                                    <Input size="sm" type="text" placeholder="Buscar" name="nombre" onChange={this.onChange} />
                                                    <InputGroupAddon addonType="append">
                                                        <Button size="sm" color="success" onClick={this.onBntBuscarClick}> Buscar <img src="/imagenes/zoom.png" /> </Button>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                            </div>
                                        </div>
                                    </Form>
                                </Nav>
                            </Collapse>
                        </Navbar>
                        <div style={{ height:440, overflow: 'auto' }}>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th style={{ width: 100 }}>Id</th>
                                    <th>Nombre</th>
                                    <th style={{ width: 30 }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.lotes.map((o, i) => (
                                    <tr key={o.I}>
                                        <td>{o.I}</td>
                                        <td>{o.N}</td>
                                        <td>
                                            <img src="/imagenes/edit.png" onClick={() => this.cargarLote(i)} alt="editar" title="editar" />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        </div>
                    </div>
                    <div className="col">
                        <h5>Cultivos del lote <Badge color="warning">{this.state.lote.N}</Badge> &nbsp;<Button size="sm" color="success" onClick={this.agregarCultivo}>+</Button></h5>
                        <label size="sm">&nbsp;</label>&nbsp;
                        <div style={{height:10}}></div>
                        <div className="row">
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>Cultivo</th>
                                    <th>Variedad</th>
                                    <th>Parcela</th>
                                    <th>Has</th>
                                    <th>Siembra</th>
                                    <th>Cosecha</th>
                                    <th style={{ width: 30 }}></th>
                                    <th style={{ width: 30 }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.cultsLote.map((o, i) => (
                                    <tr key={o.Id}>
                                        <td>{o.NomCult}</td>
                                        <td>{o.NomVar}</td>
                                        <td>{o.Prop}</td>
                                        <td>{o.Has}</td>
                                        <td>{o.HasSiembra}</td>
                                        <td>{o.HasCosecha}</td>
                                        <td>
                                            <img src="/imagenes/edit.png" onClick={() => this.editarCultivo(o)} alt="editar" title="editar" />
                                        </td>
                                        <td>
                                            <img src="/imagenes/trash.png" onClick={() => this.quitarCultivo(o)} alt="editar" title="editar" />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        </div>
                    </div>
                </div>
                <Modal id="dlg" name="dlg" isOpen={this.state.mostrarModal} toggle={this.handleClose}>
                    <ModalBody>
                        <h5>{this.state.lote.N}</h5>
                        <div className="col">
                        <div className="row">
                                <span>Cultivo</span><br/>
                                <Input size="sm" type="select" name="cultivo" id="cultivo" value={this.state.cultivo} onChange={this.onChange}  >
                                    <option key={-1} value={0}>Especifique</option>    
                                    {this.state.cultivos.map((i,k) =>(
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </div>
                            <div className="row">
                                <span>Variedad</span><br/>
                                <Input size="sm" type="select" name="variedad" id="variedad" value={this.state.variedad} onChange={this.onChange}  >
                                    <option key={-1} value={0}>Especifique</option>    
                                    {this.state.variedades.map((i,k) =>(
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </div>
                            <div className="row">
                                <span>Parcela</span><br/>
                                <Input size="sm" type="select" name="parcela" id="parcela" value={this.state.parcela} onChange={this.onChange}  >
                                    <option key={-1} value={0}>No definida</option>    
                                    {this.state.parcelas.map((i,k) =>(
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </div>
                            <div className="row">
                                <div className="col">
                                   <span>Hectareas</span><br/>
                                   <Input size="sm" type="text" name="has" id="has" value={this.state.has} onChange={this.onChange}  />
                                </div>
                                <div className="col">
                                   <span>Has.Siembra</span><br/>
                                   <Input size="sm" type="text" name="hasSiembra" id="hasSiembra" value={this.state.hasSiembra} onChange={this.onChange}  />
                                </div>
                                <div className="col">
                                   <span>Has.Cosecha</span><br/>
                                   <Input size="sm" type="text" name="hasCosecha" id="hasCosecha" value={this.state.hasCosecha} onChange={this.onChange}  />
                                </div>
                            </div>
                            <div className="col">
                                <span>&nbsp;</span><br/>
                                <Button color="success" size="sm" onClick={this.guardarCultivo}>Guardar</Button>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Button color="danger" size="sm" onClick={this.handleClose}>Cancelar</Button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </Container>
        )
    }
}
