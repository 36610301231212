import React from 'react';
import { Nav,Navbar,Spinner, InputGroupAddon, Collapse, Table, Form, FormGroup, Badge, Input, Button, Container, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class ActividadesCultivo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nombre:'?',
            cultivo:{},
            mesIni:0,
            mesFin:0,
            diaIni:0,
            diaFin:0,
            cargando:true,
            formVisible: false,
            modalVisible:false,
            titulo:'',
            actividad:0,
            cultivos:[],
            actividades: [],
            cultsAct: []
        };
        this.cargarActividades();
    }
    cargarActividades = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/nomina/actividades?id=`;
        let resp = await fetch(url);
        const actividades = await resp.json();
        url = `${this.props.urlws}/agricola/cultivos`;
        resp = await fetch(url);
        const cultivos = await resp.json();
        const seleccion = cultivos.map(() => false);
        this.setState({cargando:false, actividades, cultivos,seleccion });
    }
    cargarCultivos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/agricola/cultivosActividad/${this.state.actividad.I}`;
        let resp = await fetch(url);
        const cultsAct = await resp.json();
        this.setState({cargando:false, cultsAct});
    }
    cargarActsCultivo = async() =>{
        this.setState({cargando:true});
        let url = `${this.props.urlws}/agricola/actividadesCultivo/${this.state.cultivo.I}`;
        let resp = await fetch(url);
        const cultsAct = await resp.json();
        this.setState({cargando:false,modalVisible:false, cultsAct});
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.cargarActividades();
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    onBntBuscarClick = () =>{
        this.cargarActividades();
    }    
    cargarActividad = (i) =>{
        const cultivo = this.state.cultivos[i];
        this.setState({cultivo}, () => this.cargarActsCultivo());
    }
    quitarCultivo = (i) =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea quitar la actividad " + i.Nom +" ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.quitarCultivoB(i);
            }
          })        
    }
    quitarCultivoB = async(i) =>{
        this.setState({cargando:true});
        let url = `${this.props.urlws}/nomina/eliminarActCult?act=${i.Id}&cult=${this.state.cultivo.I}`;
        let resp = await fetch(url);
        const res = await resp.text();
        this.setState({cargando:false});
        if(res !== "ok"){
           Swal.fire('Atencion',res,'error');
           return;
        }
        this.cargarActsCultivo();
    }
    editarCultivo = (i) =>{
        this.setState({
            actividad:i.Id,
            mesIni : i.MesIni,
            mesFin : i.MesFin,
            diaIni : i.DiaIni,
            diaFin : i.DiaFin,
            modalVisible:true, 
            titulo:'Editar actividad'
        });
    }
    agregarCultivo = async() =>{
        const {actividad} = this.state;
        if(actividad == 0){
            Swal.fire('Atencion','Especifique la actividad','error');
            return;
        }
        this.setState({cargando:true});
        let url = `${this.props.urlws}/nomina/agregarActCult?act=${actividad}&cult=${this.state.cultivo.I}&mesIni=${this.state.mesIni}&mesFin=${this.state.mesFin}&diaIni=${this.state.diaIni}&diaFin=${this.state.diaFin}`;
        let resp = await fetch(url);
        const res = await resp.text();
        this.setState({cargando:false});
        if(res !== "ok"){
           Swal.fire('Atencion',res,'error');
           return;
        }
        this.cargarActsCultivo();
    }
    mostrarModal = () =>{
        this.setState({modalVisible:true, titulo:'Agregar actividad', actividad:0})
    }
    cerrarModal = () =>{
        this.setState({modalVisible:false})
    }
    render(){
        //const meses = ["ENERO","FEBRERO","MARZO","ABRIL","MAYO","JUNIO","JULIO","AGOSTO","SEPTIEMBRE","OCTUBRE","NOVIEMBRE","DICIEMBRE"];
        const meses = ["JULIO","AGOSTO","SEPTIEMBRE","OCTUBRE","NOVIEMBRE","DICIEMBRE","ENERO","FEBRERO","MARZO","ABRIL","MAYO","JUNIO"];
        const dias = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31];
        return(
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div className="row">
                    <div className="col">
                        <h5>Cultivos</h5>
                        <Navbar bg="f5e831" expand="lg">
                            <Collapse isOpen={true} id="basic-navbar-nav">
                                <Nav className="mr-auto" size="sm">
                                </Nav>
                            </Collapse>
                        </Navbar>
                        <div style={{ height:440, overflow: 'auto' }}>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th style={{ width: 100 }}>Id</th>
                                    <th>Nombre</th>
                                    <th style={{ width: 30 }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.cultivos.map((o, i) => (
                                    <tr key={o.I}>
                                        <td>{o.I}</td>
                                        <td>{o.N}</td>
                                        <td>
                                            <img src="/imagenes/edit.png" onClick={() => this.cargarActividad(i)} alt="editar" title="editar" />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        </div>
                    </div>
                    <div className="col">
                        <h5>Actividades del cultivo <Badge color="warning">{this.state.cultivo.N}</Badge> &nbsp;<Button size="sm" color="success" onClick={this.mostrarModal}>+</Button></h5>
                        <label size="sm">&nbsp;</label>&nbsp;
                        <div className="row">
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Del</th>
                                    <th>Mes</th>
                                    <th>Al </th>
                                    <th>Mes</th>
                                    <th style={{ width: 30 }}></th>
                                    <th style={{ width: 30 }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.cultsAct.map((o, i) => (
                                    <tr key={o.I}>
                                        <td>{o.Nom}</td>
                                        <td>{dias[o.DiaIni - 1]}</td>
                                        <td>{meses[o.MesIni - 1]}</td>
                                        <td>{dias[o.DiaFin - 1]}</td>
                                        <td>{meses[o.MesFin - 1]}</td>
                                        <td>
                                            <img src="/imagenes/edit.png" onClick={() => this.editarCultivo(o)} alt="editar" title="editar" />
                                        </td>
                                        <td>
                                            <img src="/imagenes/trash.png" onClick={() => this.quitarCultivo(o)} alt="quitar" title="quitar" />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        </div>
                    </div>
                </div>
                <Modal id="dlgActs" name="dlgActs" isOpen={this.state.modalVisible} toggle={this.cerrarModal}>
                    <ModalHeader>
                                <h6>{this.state.titulo}</h6>
                    </ModalHeader>
                    <ModalBody>
                    <Container>
                       <div className="row">
                            <div className="col">
                                <span>Actividad</span><br/>
                                <Input size="sm" type="select" name="actividad" id="actividad" value={this.state.actividad} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>    
                                    {this.state.actividades.map((i,k) =>(
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <span>Dia inicial</span><br/>
                                <Input size="sm" type="select" name="diaIni" id="diaIni" value={this.state.diaIni} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>    
                                    {dias.map((i,k) =>(
                                        <option key={k} value={k+1}>{i}</option>
                                    ))}
                                </Input>
                            </div>
                            <div className="col">
                                <span>Mes</span><br/>
                                <Input size="sm" type="select" name="mesIni" id="mesIni" value={this.state.mesIni} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>    
                                    {meses.map((i,k) =>(
                                        <option key={k} value={k+1}>{i}</option>
                                    ))}
                                </Input>
                            </div>
                            </div>
                            <div className="row">
                            <div className="col-3">
                                <span>Dia final</span><br/> 
                                <Input size="sm" type="select" name="diaFin" id="diaFin" value={this.state.diaFin} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>    
                                    {dias.map((i,k) =>(
                                        <option key={k} value={k+1}>{i}</option>
                                    ))}
                                </Input>
                            </div>
                            <div className="col">
                                <span>Mes</span><br/>
                                <Input size="sm" type="select" name="mesFin" id="mesFin" value={this.state.mesFin} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>    
                                    {meses.map((i,k) =>(
                                        <option key={k} value={k+1}>{i}</option>
                                    ))}
                                </Input>
                            </div>
                            <div className="col">
                                <br/>
                                <Button size="sm" color="success" onClick={this.agregarCultivo}>Aceptar</Button>
                            </div>
                            </div>
                            <div style={{height:10}}></div>
                        </Container>                         
                    </ModalBody>
                </Modal>
            </Container>
        )
    }
}
