import React from 'react';
import { Nav,Navbar,InputGroup, InputGroupAddon, Collapse, Table, Form, FormGroup, Col, Input, Button, Container, Spinner } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class Credenciales extends React.Component {
    constructor(props) {
        super(props);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');
        this.state = {
            cargando:true,
            formVisible: false,
            empresa:2,
            fechaIni:fecIni,
            fechaFin:fecFin,
            arandanos:false,
            emp1:'',
            emp2:'',
            emp3:'',
            emp4:'',
            emp5:'',
            emp6:'',
            emp7:'',
            emp8:'',
            empresas:[]
        };
        this.cargarObjetos();
    }
    onFormClose = () => {
        this.setState({
            formVisible: false
        });
    }
    cargarObjetos = async () => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/empresa/empresasNomina`;
        const resp = await fetch(url);
        const empresas = await resp.json();
        this.setState({cargando:false, empresas });
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    onChecked = (e) => {
        this.setState({ [e.target.name] : e.target.checked});
    }

    imprimir = () =>{
        const tipo = this.state.arandanos ? "A":"N";
        const url = `${this.props.urlws}/nomina/imprimirCredenciales?empresa=${this.state.empresa}&fecIni=${this.state.fechaIni}&fecFin=${this.state.fechaFin}&tipo=${tipo}`;
        window.open(url, '', '');
    }
    imprimirSerie = () =>{
        let serie = "";
        let sepa = "";
        if(this.state.emp1 !== ""){
            serie = serie + sepa + this.state.emp1;
            sepa = ",";
        }
        if(this.state.emp2 !== ""){
            serie = serie + sepa + this.state.emp2;
            sepa = ",";
        }
        if(this.state.emp3 !== ""){
            serie = serie + sepa + this.state.emp3;
            sepa = ",";
        }
        if(this.state.emp4 !== ""){
            serie = serie + sepa + this.state.emp4;
            sepa = ",";
        }
        if(this.state.emp5 !== ""){
            serie = serie + sepa + this.state.emp5;
            sepa = ",";
        }
        if(this.state.emp6 !== ""){
            serie = serie + sepa + this.state.emp6;
            sepa = ",";
        }
        if(this.state.emp7 !== ""){
            serie = serie + sepa + this.state.emp7;
            sepa = ",";
        }
        if(this.state.emp8 !== ""){
            serie = serie + sepa + this.state.emp8;
            sepa = ",";
        }
        const tipo = this.state.arandanos ? "A":"N";
        const url = `${this.props.urlws}/nomina/imprimirCredencialesSerie?empresa=${this.state.empresa}&serie=${serie}&tipo=${tipo}`;
        window.open(url, '', '');
    }
    render() {
        return (
            <Container >
                <div className="row">
                    <div className="col-4">
                        <FormGroup>
                            <span>Empresa</span><br />
                            <Input size="sm" type="select" name="empresa" value={this.state.empresa} onChange={this.onChange}>
                                <option key="0" value="">Especifique</option>
                                {this.state.empresas.map((i, k) =>
                                    <option key={i.I} value={i.I}>{i.N}</option>
                                )}
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup>
                            <span>Del dia</span><br />
                            <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup>
                            <span>al dia</span><br />
                            <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                        <div className="col-1">
                            <FormGroup >
                                <span>Arandano</span><br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input size="sm" type="checkbox" name="arandanos" checked={this.state.arandanos} onChange={this.onChecked} />
                            </FormGroup>
                        </div>
                    <div className="col">
                        <FormGroup>
                            <span>&nbsp;</span><br />
                            <Button size="sm" color="success" onClick={this.imprimir}>Imprimir</Button> 
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-1">
                        <FormGroup>
                            <span>No.Emp</span><br />
                            <Input size="sm" type="text" name="emp1" value={this.state.emp1} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col-1">
                        <FormGroup>
                            <span>No.Emp</span><br />
                            <Input size="sm" type="text" name="emp2" value={this.state.emp2} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col-1">
                        <FormGroup>
                            <span>No.Emp</span><br />
                            <Input size="sm" type="text" name="emp3" value={this.state.emp3} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col-1">
                        <FormGroup>
                            <span>No.Emp</span><br />
                            <Input size="sm" type="text" name="emp4" value={this.state.emp4} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col-1">
                        <FormGroup>
                            <span>No.Emp</span><br />
                            <Input size="sm" type="text" name="emp5" value={this.state.emp5} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col-1">
                        <FormGroup>
                            <span>No.Emp</span><br />
                            <Input size="sm" type="text" name="emp6" value={this.state.emp6} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col-1">
                        <FormGroup>
                            <span>No.Emp</span><br />
                            <Input size="sm" type="text" name="emp7" value={this.state.emp7} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col-1">
                        <FormGroup>
                            <span>No.Emp</span><br />
                            <Input size="sm" type="text" name="emp8" value={this.state.emp8} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col-1">
                            <FormGroup >
                                <span>Arandano</span><br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input size="sm" type="checkbox" name="arandanos" checked={this.state.arandanos} onChange={this.onChecked} />
                            </FormGroup>
                    </div>
                    <div className="col">
                        <FormGroup>
                            <span>&nbsp;</span><br />
                            <Button size="sm" color="success" onClick={this.imprimirSerie}>Imprimir</Button> 
                        </FormGroup>
                    </div>
                </div>
            </Container>
        );
    }
}