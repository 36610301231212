import React from 'react';
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class AgriReportes extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');
        this.state = {
            Temporada:this.props.temp,
            fechaIni:fecIni,
            fechaFin:fecFin,
            excel:false,
            anio:2020,
            mes:0,
            anios:[2019,2020,2021,2022,2023,2024],
            meses:['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']
        };
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    onChecked = (e) => {
        const campo = e.target.name;
        const valor = e.target.checked;
        this.setState({ [campo] : valor});
    }
    imprimir = async() =>{
        const excel = this.state.excel ? "S":"N";
        const m = "mes" + (Number(this.state.mes) + 101);
        const mm =this.state.anio + "" + m.substring(4,6)
        let prm = `1-${this.props.temp}-${mm}-${excel}`;
        const resp = await fetch(this.props.urlws +"/home/codifica?id=" + prm);
        const res = await resp.text();
        let params = `/agricolarpt/dieselMesPdf?id=${res}`;
        let url = this.props.urlws + params;
        window.open(url, '', '');        
    }
    render(){
        return(
            <Container>
                <br/>
                <div className="row">
                    <div className="col-2" style={{ textAlign: 'right' }}>
                        <span>Año</span>
                    </div>
                    <div className="col-4">
                        <FormGroup>
                            <Input size="sm" type="select" name="anio" value={this.state.anio} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                <option key="0" value="">Especifique</option>
                                {this.state.anios.map((i, k) =>
                                    <option key={k} value={i}>{i}</option>
                                )}
                            </Input>
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-2" style={{ textAlign: 'right' }}>
                        <span>Mes</span>
                    </div>
                    <div className="col-4">
                        <FormGroup>
                            <Input size="sm" type="select" name="mes" value={this.state.mes} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                <option key="0" value="">Especifique</option>
                                {this.state.meses.map((i, k) =>
                                    <option key={k} value={k}>{i}</option>
                                )}
                            </Input>
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                <div className="col-2">
                    
                </div>
                    <div className="col">
                             &nbsp;&nbsp;&nbsp;Exportar a Excel &nbsp; <input type="checkbox" name="excel" checked={this.state.excel} onChange={this.onChecked} />
                        <br />
                        <br />
                    </div>
                </div>
                <div className="row">
                    <div className="col-2">
                    </div>
                    <div className="col-2" style={{ textAlign: 'right' }}>
                        <Button color="success" onClick={this.imprimir}>
                            <img src="/imagenes/printer.png" alt="imprimir" title="imprimir" />
                            &nbsp;&nbsp;Imprimir
                            </Button>
                    </div>
                </div>
            </Container>
        )
    }
}