import React from 'react';
import { Nav,Navbar,NavbarBrand, Modal, ModalBody, Collapse, Table, Form, FormGroup, InputGroup, InputGroupAddon, Input, Button, Container, Spinner } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class TipoCambio extends React.Component {
    constructor(props) {
        super(props);
        let fec = new Date().yyyymmdd('-');
        this.state = {
            fechaIni:fec,
            cargando:true,
            formVisible: false,
            clave: 0,
            mostrarModal:false,
            fecha:'', 
            precio:0,
            nombre:'?',
            objeto:{},
            objetos: []
        };
        this.cargarCatalogos();
        this.cargarObjetos();
    }
    onFormClose = () => {
        this.setState({
            formVisible: false
        });
    }
    cargarCatalogos = async () => {
        /*
        this.setState({cargando:true});
        let url = `${this.props.urlws}/prov/tiposProv`;
        let resp = await fetch(url);
        const tiposProv = await resp.json();
        url = `${this.props.urlws}/prov/tiposOp`;
        resp = await fetch(url);
        const tiposOp = await resp.json();
        this.setState({cargando:false, tiposProv, tiposOp });
        */
    }
    cargarObjetos = async () => {
        this.setState({cargando:true, mostrarModal: false});
        const url = `${this.props.urlws}/home/listaPrecioDolar?fecha=${this.state.fechaIni}`;
        const resp = await fetch(url);
        const objetos = await resp.json();
        this.setState({cargando:false, objetos });
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.cargarObjetos();
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    onBntBuscarClick = () =>{
        this.cargarObjetos();
    }    
    cargarObjeto = async (d) => {
        this.setState({mostrarModal:true, fecha:d.N, precio:d.P});
    }
    guardar = async() =>{
        const url = `${this.props.urlws}/home/grabaPrecioDolar?fecha=${this.state.fecha}&precio=${this.state.precio}`;
        this.setState({cargando:true});
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.cargarObjetos();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    nuevo = () => {
        this.setState({mostrarModal:true, fecha:new Date().yyyymmdd('-'), precio:0});
    }
    handleClose = () =>{
        this.setState({mostrarModal:false});
    }
    render() {
        const estilo = { display: this.state.formVisible ? 'none' : 'inline' };
        return (
            <Container >
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo}>
                <Navbar bg="f5e831" expand="lg">
                    <NavbarBrand  size="sm" style={{color:'black'}}>Proveedores</NavbarBrand >
                        <Collapse isOpen={true} id="basic-navbar-nav">
                            <Nav className="mr-auto" size="sm">
                                <Button size="sm" color="success" outline onClick={this.nuevo}><img src="/imagenes/add.png" /></Button>
                                &nbsp;
                            <Form inline onSubmit={this.onSubmit} >
                                    <label size="sm">Nombre</label>&nbsp;
                            <InputGroup>
                                        <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                                        <InputGroupAddon addonType="append">
                                            <Button size="sm" color="success" onClick={this.onBntBuscarClick}> Buscar <img src="/imagenes/zoom.png" /> </Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                    &nbsp;
                                    &nbsp;
                            <Button size="sm" color="success" outline onClick={this.imprimir}><img src="/imagenes/printer.png" /></Button>
                                    &nbsp;
                            <Button size="sm" color="success" outline onClick={this.excel}><img src="/imagenes/excel.png" /></Button>
                                </Form>
                            </Nav>
                        </Collapse>
                </Navbar>
                <div style={{ height: 4 }}></div>
                    <ObjetosTable objetos={this.state.objetos} height={500} width={990} onRowClick={this.cargarObjeto} />
                </div>
                <Modal id="dlg" name="dlg" isOpen={this.state.mostrarModal} toggle={this.handleClose} >
                    <ModalBody>
                        <Container>
                            <div className="row">
                                <div className="col-5">
                                    <FormGroup  >
                                        <label>Fecha</label>
                                        <Input size="sm" type="date" name="fecha" value={this.state.fecha} onChange={this.onChange} />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-5">
                                    <FormGroup  >
                                        <label>Precio</label>
                                        <Input size="sm" type="text" name="precio" value={this.state.precio} onChange={this.onChange} />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row">
                                <Button size="sm" color="success" onClick={this.guardar}><img src="/imagenes/ok.png" />Guardar</Button>
                            </div>
                        </Container>
                    </ModalBody>
                </Modal>

            </Container>
        );
    }
}

class ObjetosTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { objetos } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:100}}>Fecha</th>
                        <th style={{width:100}} className="numero">Precio</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {objetos.map((o, i) => (
                        <ObjetoRow key={i} obj={o} onRowClick={this.onRowClick} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}

class ObjetoRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.obj);
    }
    render(){
        let {obj} = this.props;
        return (
        <tr>
            <td>{obj.N}</td>
            <td className="numero">{obj.P}</td>
            <td>
                <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={this.onClick}/>
            </td>
        </tr>
        );
    }
}