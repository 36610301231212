import React from 'react';
import { Nav,Navbar,NavbarBrand, Collapse, Table, Form, FormGroup, InputGroup, InputGroupAddon, Input, Button, Container, Spinner } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class Proveedores extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cargando:true,
            formVisible: false,
            clave: 0,
            nombre:'?',
            proveedor:{},
            proveedores: [],
            tiposProv: [],
            tiposOp: []
        };
        this.cargarCatalogos();
        this.cargarProveedores();
    }
    onFormClose = () => {
        this.setState({
            formVisible: false
        });
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/prov/tiposProv`;
        let resp = await fetch(url);
        const tiposProv = await resp.json();
        url = `${this.props.urlws}/prov/tiposOp`;
        resp = await fetch(url);
        const tiposOp = await resp.json();
        this.setState({cargando:false, tiposProv, tiposOp });
    }
    cargarProveedores = async () => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/prov/lista?filtro=${this.state.nombre}`;
        const resp = await fetch(url);
        const proveedores = await resp.json();
        this.setState({cargando:false, proveedores });
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.cargarProveedores();
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    onBntBuscarClick = () =>{
        this.cargarProveedores();
    }    
    cargarProv = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/prov/cargar/" + cve;
        const resp = await fetch(url);
        const proveedor = await resp.json();
        if(proveedor.Id === 0){
            proveedor.Sector = 1;
         }
         this.setState({
            cargando:false,
            formVisible: true,
            clave: cve,
            proveedor
        });
    }
    guardar = async(prov) =>{
        const url = this.props.urlws +"/prov/wsguardar";
        this.setState({cargando:true});
        const json = JSON.stringify(prov);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.onFormClose();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    nuevo = () => {
        this.cargarProv(-1);
    }
    render() {
        const estilo = { display: this.state.formVisible ? 'none' : 'inline' };
        return (
            <Container >
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo}>
                <Navbar bg="f5e831" expand="lg">
                    <NavbarBrand  size="sm" style={{color:'black'}}>Proveedores</NavbarBrand >
                        <Collapse isOpen={true} id="basic-navbar-nav">
                            <Nav className="mr-auto" size="sm">
                                <Button size="sm" color="success" outline onClick={this.nuevo}><img src="/imagenes/add.png" /></Button>
                                &nbsp;
                            <Form inline onSubmit={this.onSubmit} >
                                    <label size="sm">Nombre</label>&nbsp;
                            <InputGroup>
                                        <Input size="sm" type="text" placeholder="Buscar" name="nombre" onChange={this.onChange} style={{ width: 400 }} />
                                        <InputGroupAddon addonType="append">
                                            <Button size="sm" color="success" onClick={this.onBntBuscarClick}> Buscar <img src="/imagenes/zoom.png" /> </Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                    &nbsp;
                                    &nbsp;
                            <Button size="sm" color="success" outline onClick={this.imprimir}><img src="/imagenes/printer.png" /></Button>
                                    &nbsp;
                            <Button size="sm" color="success" outline onClick={this.excel}><img src="/imagenes/excel.png" /></Button>
                                </Form>
                            </Nav>
                        </Collapse>
                </Navbar>
                <div style={{ height: 4 }}></div>
                    <ProvTable provs={this.state.proveedores} height={500} width={990} onRowClick={this.cargarProv} />
                </div>
                <ProvForm visible={this.state.formVisible} prov={this.state.proveedor} tiposProv={this.state.tiposProv} tiposOp={this.state.tiposOp} onClose={this.onFormClose} clave={this.state.clave} guardar={this.guardar}/>
            </Container>
        );
    }
}

class ProvTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { provs } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:50}}>Id</th>
                        <th>Nombre</th>
                        <th style={{width:120}}>R.F.C.</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {provs.map((esc, i) => (
                        <ProvRow key={i} prov={esc} onRowClick={this.onRowClick} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}

class ProvRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.prov.I);
    }
    render(){
        let {prov} = this.props;
        return (
        <tr>
            <td className="numero">{prov.I}</td>
            <td>{prov.N}</td>
            <td>{prov.S}</td>
            <td>
                <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={this.onClick}/>
            </td>
        </tr>
        );
    }
}

class ProvForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            //...this.props.alumno,
            prov:this.props.prov,
        }
    }
    componentWillReceiveProps(newProps){
        this.setState({
            prov:newProps.prov
        });
    }
    onChange = (e) => {
        const esc = {
            ...this.state.prov,
            [e.target.name] : e.target.value
        };
        this.setState({prov:esc});
    }
    guardar = () =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.guardar(this.state.prov);
            }
          })        
    }
    render() {
        const estilo = { display: this.props.visible ? 'inline' : 'none'};
        if(this.props.prov === undefined)
            return null;
        if(Object.keys(this.props.prov).length === 0)
            return null;
        return (
            <Container style={estilo}>
                <Form>
                    <div class="row">
                        <div className="col-2">
                            <FormGroup >
                                <label>Id</label>
                                <Input size="sm" type="text" name="Clave" value={this.state.prov.Clave} />
                            </FormGroup>
                        </div>
                        <div className="col-10">
                            <FormGroup  >
                                <label>Nombre</label>
                                <Input size="sm" type="text" name="Nombre" value={this.state.prov.Nombre} onChange={this.onChange} style={{ width: 700 }} />
                            </FormGroup>
                        </div>
                    </div>
                    <div class="row">
                        <div className="col-2">
                            <FormGroup  >
                                <label>R.F.C.</label>
                                <Input size="sm" type="text" name="Rfc" value={this.state.prov.Rfc} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup  >
                                <label>Telefono</label>
                                <Input size="sm" type="text" name="Telefono" value={this.state.prov.Telefono} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-3">
                            <FormGroup  >
                                <label>Cuenta Contable</label>
                                <Input size="sm" type="text" name="CuentaProv" value={this.state.prov.CuentaProv} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-1">
                            <FormGroup  >
                                <label>Plazo</label>
                                <Input size="sm" type="text" name="Plazo" value={this.state.prov.Plazo} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                    </div>
                    <div class="row">
                        <div className="col-6">
                            <FormGroup  >
                                <label>Tipo</label>
                                <Input size="sm" type="select" name="Tipo" id="Tipo" value={this.state.prov.Tipo} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>    
                                    {this.props.tiposProv.map((i,k) =>(
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-6">
                            <FormGroup  >
                                <label>Tipo Operacion</label>
                                <Input size="sm" type="select" name="TipoOp" id="TipoOp" value={this.state.prov.TipoOp} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>    
                                    {this.props.tiposOp.map((i,k) =>(
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                    </div>
                </Form>
                <br />
                <Button color="primary" onClick={this.guardar}>Aceptar</Button>
                {'  '}
                <Button color="danger" onClick={this.props.onClose}>Cancelar</Button>
            </Container>
        );
    }
}