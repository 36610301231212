import React from 'react';
import { Nav,Navbar,InputGroup, InputGroupAddon, Collapse, Table, Form, FormGroup, Col, Input, Button, Container, Spinner } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class Actividades extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cargando:true,
            formVisible: false,
            clave: '',
            nombre:'?',
            objeto:{},
            objetos: []
        };
        this.cargarObjetos();
    }
    onFormClose = () => {
        this.setState({
            formVisible: false
        });
    }
    cargarObjetos = async () => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/agricola/actividades?id=${this.state.nombre}`;
        const resp = await fetch(url);
        const objetos = await resp.json();
        this.setState({cargando:false, objetos });
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.cargarObjetos();
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    onBntBuscarClick = () =>{
        this.cargarObjetos();
    }    
    cargar = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/agricola/cargarActividad/" + cve;
        const resp = await fetch(url);
        const objeto = await resp.json();
         this.setState({
            cargando:false,
            formVisible: true,
            clave: cve,
            objeto
        });
    }
    guardar = async(dato) =>{
        const url = this.props.urlws +"/agricola/wsGuardarActividad";
        this.setState({cargando:true});
        if(dato.Codigo === 0)
           dato.Codigo = '';
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.onFormClose();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    nuevo = () => {
        this.cargar(-1);
    }
    imprimir = () =>{
        const url = `${this.props.urlws}/articulo/agroquimicosPdf`;
        window.open(url, '', '');
    }
    excel = () =>{
    }
    render() {
        const estilo = { display: this.state.formVisible ? 'none' : 'inline' };
        return (
            <Container >
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo}>
                <Navbar bg="f5e831" expand="lg">
                    <Collapse isOpen={true} id="basic-navbar-nav">
                        <Nav className="mr-auto" size="sm">
                            <Button size="sm" color="success" outline onClick={this.nuevo}><img src="/imagenes/add.png"/></Button>
                            &nbsp;
                            <Form inline onSubmit={this.onSubmit} >
                            <label size="sm">Nombre</label>&nbsp;
                            <InputGroup>
                                <Input size="sm" type="text" placeholder="Buscar" name="nombre" onChange={this.onChange} style={{width:400}} />
                                <InputGroupAddon addonType="append">
                                    <Button size="sm" color="success" onClick={this.onBntBuscarClick}> Buscar <img src="/imagenes/zoom.png" /> </Button>
                                </InputGroupAddon>
                            </InputGroup>                            
                            &nbsp;
                            &nbsp;
                            <Button size="sm" color="success" outline onClick={this.imprimir}><img src="/imagenes/printer.png"/></Button>
                            &nbsp;
                            <Button size="sm" color="success" outline onClick={this.excel}><img src="/imagenes/excel.png"/></Button>
                        </Form>
                        </Nav>
                    </Collapse>
                </Navbar>
                <div style={{ height: 4 }}></div>
                    <ObjetoTable objetos={this.state.objetos} height={500} width={990} onRowClick={this.cargar} />
                </div>
                <ObjetoForm urlws={this.props.urlws} visible={this.state.formVisible} tipos={this.state.tipos} objeto={this.state.objeto} onClose={this.onFormClose} clave={this.state.clave} guardar={this.guardar}/>
            </Container>
        );
    }
}

class ObjetoTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { objetos } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:100}}>Id</th>
                        <th>Nombre</th>
                        <th style={{width:30}}></th>
                    </tr>
                </thead>
                <tbody>
                    {objetos.map((o, i) => (
                        <ObjetoRow key={i} objeto={o} onRowClick={this.onRowClick} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}

class ObjetoRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.objeto.I);
    }
    render(){
        let {objeto} = this.props;
        return (
        <tr>
            <td>{objeto.I}</td>
            <td>{objeto.N}</td>
            <td>
                <img src="/imagenes/edit.png" onClick={this.onClick} alt="editar" title="editar"/>
            </td>
        </tr>
        );
    }
}

class ObjetoForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            objeto:this.props.objeto,
            unidades:[{I:'H',N:'HECTAREA'}, {I:'K',N:'KILOMETRO'}]
        };
    }
    componentWillReceiveProps(newProps){
        this.setState({
            objeto:newProps.objeto,
        });
    }
    onChange = (e) => {
        const campo = e.target.name;
        const obj = {
            ...this.state.objeto,
            [campo] : e.target.value
        };
        this.setState({objeto:obj});
    }
    guardar = () =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.guardar(this.state.objeto);
            }
          })        
    }
    render() {
        const estilo = { display: this.props.visible ? 'inline' : 'none'};
        if(this.props.objeto === undefined)
            return null;
        if(Object.keys(this.props.objeto).length === 0)
            return null;
        return (
            <Container style={estilo}>
                <Form>
                    <div class="row">
                        <div className="col-2">
                            <FormGroup md="2" >
                                <label>Id</label>
                                <Input size="sm" type="text" name="Codigo" value={this.state.objeto.Id} />
                            </FormGroup>
                        </div>
                        {' '}
                        <div className="col-10">
                            <FormGroup md="10">
                                <label>Nombre</label>
                                <Input size="sm" type="text" name="Nombre" value={this.state.objeto.Nombre} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                    </div>
                    <div class="row">
                        <div className="col-5">
                            <FormGroup>
                                <label>Unidad de rendimiento de combustible</label>
                                <Input size="sm" type="select" name="Unidad" value={this.state.objeto.Unidad} onChange={this.onChange} >
                                    <option key="0" value="">Especifique</option>
                                    {this.state.unidades.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                    </div>
                </Form>
                <br />
                <Button color="primary" onClick={this.guardar}>Aceptar</Button>
                {'  '}
                <Button color="danger" onClick={this.props.onClose}>Cancelar</Button>
            </Container>
        );
    }
}