import React from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Buscador from '../Buscador';

export default class Muestreos extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');
        this.state = {
            cargando:false,
            editando:false,
            Temporada:this.props.temp,
            fechaIni:fecIni,
            fechaFin:fecFin,
            movimientos:[],
            temporadas:[],
            lotes:[],
            secciones:[],
            movimiento:{}
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/agricola/secciones`;
        let resp = await fetch(url);
        const secciones = await resp.json();
        url = `${this.props.urlws}/agricola/temporadas`;
        resp = await fetch(url);
        const temporadas = await resp.json();
        url = `${this.props.urlws}/agricola/lotesTemp/${this.state.Temporada}`;
        resp = await fetch(url);
        const lotes = await resp.json();
        this.setState({cargando:false, secciones, temporadas, lotes });
    }
    cargarLotes = async() =>{
        this.setState({cargando:true});
        const url = `${this.props.urlws}/agricola/lotesTemp/${this.state.Temporada}`;
        const resp = await fetch(url);
        const lotes = await resp.json();
        this.setState({cargando:false, lotes });
    }
    cargarMovs = async () =>{
        if(this.state.Temporada === ''){
            Swal.fire('Atencion','Especifique la temporada','error');
            return;
        }
        const temp = this.state.Temporada;
        const fecIni = this.state.fechaIni;
        const fecFin = this.state.fechaFin;
        this.setState({cargando:true});
        let url = `${this.props.urlws}/agricola/muestreos?fecIni=${fecIni}&fecFin=${fecFin}&temp=${temp}`;
        let resp = await fetch(url);
        const movimientos = await resp.json();
        this.setState({cargando:false, movimientos });
    }
    cargarDetalle = (mov) =>{
        this.cargarMov(mov.Num);
    }
    cargarMov = async (num) =>{
        this.setState({cargando:true});
        let url = `${this.props.urlws}/agricola/cargarMuestreo?temp=${this.state.Temporada}&num=${num}`;
        let resp = await fetch(url);
        const movimiento = await resp.json();
        this.setState({cargando:false, editando:true, movimiento});
    }
    nuevoMov = () =>{
        const alm = this.state.almacen;
        const cve = this.state.clave;
        if(cve === "??"){
            Swal.fire('Atencion','Especifique el tipo de movimiento','error');
            return;
        }
        if(alm === 0){
            Swal.fire('Atencion','Especifique el almacen','error');
            return;
        }
        this.cargarMov(0);
    }
    guardar = async (obj) =>{
        const mov = {
            Temporada:obj.Temporada,
            Numero : obj.Numero,
            Fecha : obj.Fecha,
            Observa : obj.Observa,
            Lote:obj.Lote,
            S: JSON.stringify(obj.Detalle)    
        }
        const url = this.props.urlws +"/agricola/wsguardarMuestreo";
        this.setState({cargando:true});
        const json = JSON.stringify(mov);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.setState({editando:false});
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    imprimir = (mov) =>{
        let params = '?alm=' + mov.Almacen + '&cve=' + mov.Clave + '&num=' + mov.Numero;
        let url = this.props.urlws + '/compras/imprimir' + params;
        window.open(url, '', '');
    }
    cancelar = () =>{
        this.setState({editando:false});
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor},() =>{
            if(campo === "Temporada")
               this.cargarLotes();
        });
    }

    render () {
        const estilo1 = { display: this.state.editando ? 'inline' : 'none' };
        const estilo2 = { padding:2, display: this.state.editando ? 'none' : 'inline' };
        return(            
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo1}>
                    <Captura movimiento={this.state.movimiento} height={450} urlws={this.props.urlws} cancelar={this.cancelar} guardar={this.guardar} temp={this.state.Temporada} lotes={this.state.lotes} secciones={this.state.secciones}/>
                </div>
                <div style={estilo2}>
                    <div className="row">
                        <div className="col-2">
                            <FormGroup>
                                <span>Temporada</span><br/>
                                <Input size="sm" type="select" name="Temporada" value={this.state.Temporada} onChange={this.onChange} style={{  fontSize:'12px'}}>
                                    <option key="0" value="">Especifique</option>
                                    {this.state.temporadas.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Del dia</span><br/>
                                <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>al dia</span><br/>
                                <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup as={Col}>
                                <span>&nbsp;</span><br/>
                                <Button color='success' size="sm" onClick={this.cargarMovs}>Cargar</Button>
                                {'  '}
                                <Button color='success' size="sm" onClick={this.nuevoMov}>+</Button>
                            </FormGroup>
                        </div>
                    </div>
                    <div style={{ height: 4 }}></div>
                    <MovsTable movimientos={this.state.movimientos} height={500} width="98%" onRowClick={this.cargarDetalle} imprimir={this.imprimir} />
                </div>                
            </Container>
        )
    }
}

class MovsTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { movimientos } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:80}}>Numero</th>
                        <th style={{width:80}}>Fecha</th>
                        <th>Lote</th>
                        <th>Observaciones</th>
                        <th style={{width:30, textAlign:'center'}}></th>
                        <th style={{width:30, textAlign:'center'}}></th>
                    </tr>
                </thead>
                <tbody>
                    {movimientos.map((esc, i) => (
                        <MovRow key={i} mov={esc} onRowClick={this.onRowClick} imprimir={this.props.imprimir} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}
class MovRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.mov);
    }
    imprimir = (e) =>{
        this.props.imprimir(this.props.mov);
    }
    render(){
        let {mov} = this.props;
        return (
        <tr>
            <td className="numero">{mov.Num}</td>
            <td>{mov.Fec}</td>
            <td>{mov.Lote}</td>
            <td>{mov.Obs}</td>
            <td>
                <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={this.onClick}/>
            </td>
            <td>
                <img src="/imagenes/printer.png" alt="imprimir" title="imprimir" onClick={this.imprimir}/>
            </td>
        </tr>
        );
    }
}
class Captura extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            agregando:false,
            cambio:false,
            mostrarModal:false,
            cargando:false,
            seccion:0,
            ph:0,
            ec:0,
            drenado:0,
            sodio:0,
            potasio:0,
            calcio:0,
            nitratos:0,
            Lote:0,
            renglon:0,
            renSig:1,
            indice:0,
            orden:'',
            Detalle:[],
            LoteAnt:0,
            Observa:'',
            ...this.props.movimiento
        }
    }
    cargarCultivos = async (lote) => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/agricola/lotesCultivoTemp/${this.props.temp}?lote=${lote}`;
        let resp = await fetch(url);
        const cultivos = await resp.json();
        this.setState({cargando:false, cultivos });
    }
    componentWillReceiveProps(newProps){
        this.setState({
            cambio:false,
            ...newProps.movimiento,
        },() => this.cargarCultivos(newProps.movimiento.Lote));
    }
    onChange = (e) => {
        const campo = e.target.name;
        let valor = e.target.value;
        this.setState({ [campo] : valor, cambio: true},()=>{
            if(campo === "Lote")
               this.cargarCultivos(valor);
        });
    }
    onKeyPress = (e) =>{
        if(e.which !== 13)
           return;
        const campo = e.target.name;
        let valor = e.target.value;
        if(campo === "ph" || campo === "ec" || campo === "drenado" || campo === "sodio" || campo === "potasio" || campo === "calcio" || campo === "nitratos"){
           valor = Number(valor);
           this.setState({ [campo] : valor},() => this.ponFoco(campo));
        } 
        else
            this.ponFoco(campo);
    }
    ponFoco = (campo) =>{
        if(campo === "seccion")
           this.enter("ph");
        if(campo === "ph")
           this.enter("ec");
        else if(campo === "ec")
           this.enter("drenado");
        else if(campo === "drenado")
           this.enter("sodio");
        else if(campo === "sodio")
           this.enter("potasio");
        else if(campo === "potasio")
           this.enter("calcio");
        else if(campo === "calcio")
           this.enter("nitratos");
        else if(campo === "nitratos"){
            this.enter("seccion");
            this.agregarRenglon();
        }
    }
    enter = (campo) =>{
        let foco = campo;
        document.getElementById(foco).focus();
    }
    handleClose = () =>{
        this.setState({mostrarModal:false});
    }
    agregarRenglon = () =>{
        var cb = document.getElementById("seccion");
        var nomSec = cb.options[cb.selectedIndex].text;
        if(this.state.renglon === 0){
            const det = {
                Renglon:this.state.renSig,
                Sec:this.state.seccion,
                Nom:nomSec,
                Ph:this.state.ph,
                Ec:this.state.ec,
                Dre:this.state.drenado,
                Sod:this.state.sodio,
                Pot:this.state.potasio,
                Cal:this.state.calcio,
                Nit:this.state.nitratos
            };
            let Detalle = this.state.Detalle;
            Detalle.push(det);
            this.setState({cambio:true, Detalle,renSig:this.state.renSig + 1});
        }
        else{
            let Detalle = this.state.Detalle;
            const indice = this.state.indice;
            Detalle[indice].Sec = this.state.seccion;
            Detalle[indice].Nom = nomSec;
            Detalle[indice].Ph = this.state.ph;
            Detalle[indice].Ec = this.state.ec;
            Detalle[indice].Dre = this.state.drenado;
            Detalle[indice].Sod = this.state.sodio;
            Detalle[indice].Pot = this.state.potasio;
            Detalle[indice].Cal = this.state.calcio;
            Detalle[indice].Nit = this.state.nitratos;
            this.setState({mostrarModal:false, cambio:true});
        }
    }
    agregarDetalle = () =>{
        this.setState({mostrarModal:true, renglon : 0, agregando:true});
    }
    quitarRenglon = (ren,i) =>{
        let Detalle = this.state.Detalle;
        Detalle.splice(i, 1);
        this.setState({cambio:true, Detalle});
    }
    editarRenglon = (ren,i) =>{
        this.setState({
            agregando:false,
            mostrarModal:true,
            renglon:ren.Renglon,
            seccion:ren.Sec,
            ph:ren.Ph,
            ec:ren.Ec,
            drenado:ren.Dre,
            sodio:ren.Sod,
            potasio:ren.Pot,
            calcio:ren.Cal,
            nitratos:ren.Nit,
            indice:i,
        });
    }
    cancelar = () =>{
        if(!this.state.cambio){
            this.props.cancelar();
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea cancelar la captura ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.cancelar();
            }
          })        
    }
    guardar = () =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar este movimiento ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.guardar(this.state);
            }
          })        
    }
    render(){
        return(
            <div className="container">
                <div className="row">
                    <div className="col-1">
                        <FormGroup>
                            <span>Numero</span><br />
                            <Input size="sm" type="text" name="Numero" value={this.state.Numero} readOnly />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup>
                            <span>Fecha</span><br />
                            <Input size="sm" type="date" name="Fecha" value={this.state.Fecha} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup>
                            <span>Lote</span><br />
                            <Input size="sm" type="select" name="Lote" value={this.state.Lote} onChange={this.onChange}>
                                <option key="0" value="">Especifique</option>
                                {this.props.lotes.map((i, k) =>
                                    <option key={i.I} value={i.I}>{i.N}</option>
                                )}
                            </Input>
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-1">
                        <span>Observaciones</span>
                    </div>
                    <div className="col-11">
                        <Input size="sm" type="text" name="Observa" value={this.state.Observa} onChange={this.onChange} />
                    </div>
                </div>
                <div style={{ height: 4 }}></div>
                <div style={{ height: this.props.height, overflow: 'auto' }}>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th style={{ width: 50 }}>Ph</th>
                                <th>Seccion</th>
                                <th style={{ width: 50 }}>Ph</th>
                                <th style={{ width: 50 }}>Ec</th>
                                <th style={{ width: 50 }}>Drenado</th>
                                <th style={{ width: 50 }}>Sodio</th>
                                <th style={{ width: 50 }}>Potasio</th>
                                <th style={{ width: 50 }}>Calcio</th>
                                <th style={{ width: 50 }}>Nitratos</th>
                                <th style={{width:30, textAlign:'center'}}></th>
                                <th style={{width:30, textAlign:'center'}}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.Detalle.map((ren, i) => (
                                <DetalleRow key={i} indice={i} renglon={ren} onRowClick={this.editarRenglon} onRowQuitar={this.quitarRenglon} />
                            ))}
                        </tbody>
                    </Table>
                </div>
                <div className="row">
                    <div className="col-6">
                        <Button size="sm" color="success" onClick={this.guardar}>Guardar</Button>
                        <span>&nbsp;&nbsp;</span>
                        <Button size="sm" color="danger" onClick={this.cancelar}>Cancelar</Button>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <Button size="sm" color="success" onClick={this.agregarDetalle}>+</Button>
                    </div>
                    <div className="col-6" style={{fontWeight:'bold', textAlign:'right'}}>
                    </div>
                </div>
                <Modal id="dlg" name="dlg" isOpen={this.state.mostrarModal} toggle={this.handleClose} size="lg" tabIndex={1}>
                    <ModalBody>
                        <div className="row">
                            <div className="col-3">
                                <FormGroup>
                                    <span>Seccion</span><br />
                                    <Input size="sm" type="select" autoFocus id="seccion" name="seccion" value={this.state.seccion} onChange={this.onChange} onKeyPress={this.onKeyPress}>
                                        <option key="0" value="">Especifique</option>
                                        {this.props.secciones.map((i, k) =>
                                            <option key={i.I} value={i.I}>{i.N}</option>
                                        )}
                                    </Input>
                                </FormGroup>
                            </div>
                            <div className="col-2">
                                <FormGroup>
                                    <span>PH</span><br />
                                    <Input size="sm" type="text" id="ph" name="ph" value={this.state.ph} onChange={this.onChange} onKeyPress={this.onKeyPress}/>
                                </FormGroup>
                            </div>
                            <div className="col-2">
                                <FormGroup>
                                    <span>EC</span><br />
                                    <Input size="sm" type="text" id="ec" name="ec" value={this.state.ec} onChange={this.onChange} onKeyPress={this.onKeyPress}/>
                                </FormGroup>
                            </div>
                            <div className="col-2">
                                <FormGroup>
                                    <span>Drenado</span><br />
                                    <Input size="sm" type="text" id="drenado" name="drenado" value={this.state.drenado} onChange={this.onChange} onKeyPress={this.onKeyPress}/>
                                </FormGroup>
                            </div>
                            <div className="col-2">
                                <FormGroup>
                                    <span>Sodio</span><br />
                                    <Input size="sm" type="text" id="sodio" name="sodio" value={this.state.sodio} onChange={this.onChange} onKeyPress={this.onKeyPress}/>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2">
                                <FormGroup>
                                    <span>Potasio</span><br />
                                    <Input size="sm" type="text" id="potasio" name="potasio" value={this.state.potasio} onChange={this.onChange} onKeyPress={this.onKeyPress}/>
                                </FormGroup>
                            </div>
                            <div className="col-2">
                                <FormGroup>
                                    <span>Calcio</span><br />
                                    <Input size="sm" type="text" id="calcio" name="calcio" value={this.state.calcio} onChange={this.onChange} onKeyPress={this.onKeyPress}/>
                                </FormGroup>
                            </div>
                            <div className="col-2">
                                <FormGroup>
                                    <span>Nitratos</span><br />
                                    <Input size="sm" type="text" id="nitratos" name="nitratos" value={this.state.nitratos} onChange={this.onChange} onKeyPress={this.onKeyPress}/>
                                </FormGroup>
                            </div>
                            <div className="col">
                                <FormGroup>
                                    <label></label><br/>
                                    <Button size="sm" color="success">Aceptar</Button>
                                </FormGroup>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}
class DetalleRow extends React.Component{
    onClick = (e) =>{
        console.log(this.props.renglon);
        this.props.onRowClick(this.props.renglon, this.props.indice);
    }
    onQuitar = (e) =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea eliminar este renlon (" + this.props.renglon.NomAct + ") ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.onRowQuitar(this.props.renglon, this.props.indice);
            }
        });
    }
    render(){
        let {renglon} = this.props;
        return (
        <tr>
            <td className="numero">{renglon.Sec}</td>
            <td>{renglon.Nom}</td>
            <td className="numero">{renglon.Ph}</td>
            <td className="numero">{renglon.Ec}</td>
            <td className="numero">{renglon.Dre}</td>
            <td className="numero">{renglon.Sod}</td>
            <td className="numero">{renglon.Pot}</td>
            <td className="numero">{renglon.Cal}</td>
            <td className="numero">{renglon.Nit}</td>
            <td>
                <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={this.onClick}/>
            </td>
            <td>
                <img src="/imagenes/quitar.png" alt="eliminar" title="eliminar" onClick={this.onQuitar}/>
            </td>
        </tr>
        );
    }
}