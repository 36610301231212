import React from 'react';
import { Form, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import Swal from 'sweetalert2'

export default class ReportesAgri extends React.Component{
    constructor(props){
        let fecha = new Date().yyyymmdd('-');
        super(props);
        this.state = {
            cargando:false,
            fechaIni:fecha,
            fechaFin:fecha,
            detallado:false,
            totalizado:false,
            porLinea:false,
            excel:false,
            cultivo:0,
            lote:0,
            maquina:0,
            temporada:'',
            temporadas:[],
            compradores:[],
            cultivos:[],
            maquinas:[],
            lotes:[],
            cliente:0,
            reporte:-1,
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/granos/compradoresLiq`;
        let resp = await fetch(url);
        const compradores = await resp.json();
        url = `${this.props.urlws}/agricola/cultivos`;
        resp = await fetch(url);
        const cultivos = await resp.json();
        url = `${this.props.urlws}/agricola/lotes`;
        resp = await fetch(url);
        const lotes = await resp.json();
        url = `${this.props.urlws}/agricola/temporadas`;
        resp = await fetch(url);
        const temporadas = await resp.json();
        url = `${this.props.urlws}/agricola/maquinaria`;
        resp = await fetch(url);
        const maquinas = await resp.json();
        this.setState({cargando:false,cultivos, lotes, compradores, temporadas, maquinas});
    }
    onChecked = (e) => {
        const campo = e.target.name;
        const valor = e.target.checked;
        this.setState({ [campo] : valor});
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor},() =>{
            if(campo === "deptoIni" || campo === "deptoFin"){
                if(valor > 0 && (this.state.deptoIni === this.state.deptoFin))
                   this.cargarLineas();
                else
                    this.setState({lineas:[]});
            }
            if(campo === "lineaIni" || campo === "lineaFin"){
                if(valor > 0 && (this.state.lineaIni === this.state.lineaFin))
                   this.cargarSublineas();
                else
                    this.setState({sublineas:[]});
            }
        });
    }
    imprimir = () =>{
        const rep = this.state.reporte;
        const alm = this.state.almacen;
        let {fechaIni} = this.state;
        let {fechaFin} = this.state;
        let {cliente} = this.state;
        let {cultivo} = this.state;
        let {lote} = this.state;
        let {detallado} = this.state;
        let {totalizado} = this.state;
        let {porLinea} = this.state;
        let {temporada} = this.state;
        let {maquina} = this.state;
        if(fechaIni > fechaFin){
            Swal.fire('Atencion','Rango de fechas incorrecto','error');
            return;
        }
        let url = this.props.urlws;
        if(this.props.rpt === 'rptPapaProd'){
            let url = `${this.props.urlws}/agricolarpt/papasProdAcum?fecha=${fechaIni}&lote=${lote}&temp=${temporada}`;
            window.open(url, '', '');
        }
        if(this.props.rpt === 'rptCosechaGranos'){
            let url = `${this.props.urlws}/granos/cosechaPdfws?fecIni=${fechaIni}&fecFin=${fechaFin}&lote=0&cultivo=${cultivo}`;
            window.open(url, '', '');
        }
        if(this.props.rpt === 'rptRemGranos'){
            let url = `${this.props.urlws}/granos/remisionesPdfws?fecIni=${fechaIni}&fecFin=${fechaFin}&lote=0&cultivo=${cultivo}`;
            window.open(url, '', '');
        }
        if(this.props.rpt === 'rptRemGranosLiq'){
            let url = `${this.props.urlws}/granos/remisionesLiqPdfws?fecIni=${fechaIni}&fecFin=${fechaFin}&cte=${cliente}`;
            window.open(url, '', '');
        }
        if(this.props.rpt === 'rptAgriAct'){
            const excel = this.state.excel ? "S":"N";
            let url = `${this.props.urlws}/agricolarpt/actMaqPdfDiesel?fecIni=${fechaIni}&fecFin=${fechaFin}&maq=${maquina}&excel=${excel}`;
            window.open(url, '', '');
        }
        if(this.props.rpt === 'rptCostoLoteCult'){
            let url = `${this.props.urlws}/agricolarpt/costoLoteCultivo?empresa=1&fecIni=${fechaIni}&fecFin=${fechaFin}&lote=${lote}&cult=${cultivo}&temp=${temporada}`;
            if(detallado)
               if(totalizado)
                  url = `${this.props.urlws}/agricolarpt/costoLoteCultivoDet?empresa=1&fecIni=${fechaIni}&fecFin=${fechaFin}&lote=${lote}&cult=${cultivo}&temp=${temporada}&tot=S`;
               else
                  url = `${this.props.urlws}/agricolarpt/costoLoteCultivoDet?empresa=1&fecIni=${fechaIni}&fecFin=${fechaFin}&lote=${lote}&cult=${cultivo}&temp=${temporada}&tot=N`;
            if(porLinea)
               url = `${this.props.urlws}/agricolarpt/costoLoteCultivoDet?empresa=1&fecIni=${fechaIni}&fecFin=${fechaFin}&lote=${lote}&cult=${cultivo}&temp=${temporada}&tot=L`;
            window.open(url, '', '');
        }
        if(this.props.rpt === 'rptAgriActLote'){
            let url = `${this.props.urlws}/agricolarpt/actMaqLote?empresa=1&fecIni=${fechaIni}&fecFin=${fechaFin}&lote=${lote}&cult=${cultivo}&temp=${temporada}`;
            window.open(url, '', '');
        }
    }
    render(){
        //if(this.state.deptoIni > 0 && (this.state.deptoIni === this.state.deptoFin))
        return (
            <container>
                <div className="row">
                    <div className="col-2"></div>
                    <div className="col-6">
                        <div className="row">
                            <div className="col-4">
                                <span size="sm">Del dia</span><br/>
                                <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                            </div>
                            {this.props.rpt !== 'rptPapaProd' ?
                            <div className="col-4">
                                <span size="sm">Al dia</span><br/>
                                <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                            </div>
                            :null
                            }
                            {this.props.rpt !== 'rptRemGranosLiq' && this.props.rpt !== 'rptAgriAct'  &&
                            <div className="col">
                            <FormGroup>
                                <span>Temporada</span><br />
                                <Input size="sm" type="select" name="temporada" value={this.state.temporada} onChange={this.onChange}>
                                    <option key="0" value={''}>Todas</option>
                                    {this.state.temporadas.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                            </div>
                        }
                        </div>
                        {this.props.rpt === 'rptRemGranosLiq' &&
                        <div className="row">
                            <div className="col">
                            <FormGroup>
                                <span>Bodega</span><br />
                                <Input size="sm" type="select" name="cliente" value={this.state.cliente} onChange={this.onChange}>
                                    <option key="0" value={0}>Todos</option>
                                    {this.state.compradores.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                            </div>
                        </div>
                        }
                        {this.props.rpt !== 'rptRemGranosLiq' && this.props.rpt !== 'rptAgriAct' &&
                        <div className="row">
                            <div className="col">
                            <FormGroup>
                                <span>Lote</span><br />
                                <Input size="sm" type="select" name="lote" value={this.state.lote} onChange={this.onChange}>
                                    <option key="0" value={0}>Todos</option>
                                    {this.state.lotes.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                            </div>
                        </div>
                        }
                        {this.props.rpt !== 'rptRemGranosLiq' && this.props.rpt !== 'rptAgriAct' && this.props.rpt !== 'rptPapaProd' &&
                        <div className="row">
                            <div className="col">
                            <FormGroup>
                                <span>Cultivo</span><br />
                                <Input size="sm" type="select" name="cultivo" value={this.state.cultivo} onChange={this.onChange}>
                                    <option key="0" value={0}>Todos</option>
                                    {this.state.cultivos.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                            </div>
                        </div>
                        }
                        {
                        this.props.rpt == 'rptAgriAct' &&
                        <div className="row">
                           <div className="col">
                               <br/>
                             &nbsp;&nbsp;&nbsp;Exportar a Excel &nbsp; <input type="checkbox" name="excel" checked={this.state.excel} onChange={this.onChecked}/>
                             <br/>
                            </div> 
                        </div>
                        }

                        {
                        this.props.rpt == 'rptCostoLoteCult' &&
                        <div className="row">
                           <div className="col">
                               <br/>
                             Detallado <input type="checkbox" name="detallado" checked={this.state.detallado} onChange={this.onChecked}/>
                             <br/>
                            </div> 
                            <div className="col">
                               <br/>
                             Por lineas <input type="checkbox" name="porLinea" checked={this.state.porLinea} onChange={this.onChecked}/>
                             <br/>
                            </div> 
                        </div>
                        }
                        {
                        this.state.detallado &&
                        <div className="row">
                           <div className="col">
                               <br/>
                             Totales por concepto <input type="checkbox" name="totalizado" checked={this.state.totalizado} onChange={this.onChecked}/>
                             <br/>
                            </div> 
                        </div>
                        }
                        {this.props.rpt == 'rptAgriAct' &&
                        <div className="row">
                            <div className="col">
                            <FormGroup>
                                <span>Maquina</span><br />
                                <Input size="sm" type="select" name="maquina" value={this.state.maquina} onChange={this.onChange}>
                                    <option key="0" value={0}>Todas</option>
                                    {this.state.maquinas.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                            </div>
                        </div>
                        }
                        <div className="col-6" style={{ padding: 10 }}>
                            <Button color="success" onClick={this.imprimir}>Imprimir</Button>
                        </div>
                    </div>
                </div>
            </container>
        )
    }
}
