import React from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Buscador from '../Buscador';

export default class Relacionar extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            prov:0,
            nomProv:'',
            popoverProvOpen:false,
            popoverCodOpen:false,
            cambio:false,
            mostrarModal:false,
            cargando:false,
            agregandoArti:false,
            codigo:'000009',
            codigoP:'',
            nombre:'',
            nombreP:'',
            articulo:{},
            renglon:0,
            renSig:1,
            indice:-1,
            nomProd:'',
            depto:0,
            linea:0,
            sublinea:0,
            unidad:'',
            detalle:[],
            deptos:[],
            lineas:[],
            sublineas:[],
            unidades:[]
        }
        this.cargarDeptos();
    }
    cargarDet = async () =>{
        if(this.state.prov == 0){
            Swal.fire('Atencion','Especifique el almacen','error');
            return;
        }
        this.setState({cargando:true});
        let url = `${this.props.urlws}/prov/relacionar/${this.state.prov}`;
        let resp = await fetch(url);
        const detalle = await resp.json();
        this.setState({cargando:false, detalle });
    }
    cargarDeptos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/articulo/deptos`;
        let resp = await fetch(url);
        const deptos = await resp.json();
        url = `${this.props.urlws}/articulo/unidades`;
        resp = await fetch(url);
        const unidades = await resp.json();
        this.setState({cargando:false, deptos,unidades });
    }
    cargarLineas = async (dep) => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/articulo/lineas/${this.state.depto}`;
        const resp = await fetch(url);
        const lineas = await resp.json();
        this.setState({cargando:false, lineas });
    }
    cargarSublineas = async (lin) => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/articulo/sublineas?depto=${this.state.depto}&linea=${lin}`;
        const resp = await fetch(url);
        const sublineas = await resp.json();
        this.setState({cargando:false, sublineas });
    }
    altaArticulo = async() =>{
        if(this.state.unidad === '' || this.state.depto == 0 || this.state.linea == 0 || this.state.sublinea == 0){
            Swal.fire('Atencion',"faltan datos del articulo",'error');
            this.setState({cargando:false});
            return;
        }
        var dato = {
            Codigo:'',
            Nombre: this.state.nomProd,
            Costo:0,
            TasaIva:0,
            Depto:this.state.depto,
            Linea:this.state.linea,
            Sublinea:this.state.sublinea,
            Descripcion:'',
            Unidad:this.state.unidad
        }
        const url = this.props.urlws +"/articulo/wsguardarCodigo";
        this.setState({cargando:true});
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res.substr(0, 5) != "Error"){
            Swal.fire('Atencion','Datos guardados','success');
            this.state.codigo = res;
            this.state.nombre = dato.Nombre;
            this.relacionar();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    relacionar =  async() =>{
        let url = `${this.props.urlws}/prov/relacionarCod/${this.state.prov}?cod=${this.state.codigo}&codP=${this.state.codigoP}`;
        let resp = await fetch(url);
        const res = await resp.text();
        if(res === "ok"){
            this.state.detalle[this.state.indice].Cod = this.state.codigo;
            this.state.detalle[this.state.indice].Nom = this.state.nombre;
            this.setState({codigoP:'',mostrarModal:false,agregandoArti:false});
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    editarRenglon = (ren,i) =>{
        this.setState({
            agregando:false,
            mostrarModal:true,
            codigo:ren.Cod,
            nombre:ren.Nom,
            codigoP:ren.CodP,
            nombreP:ren.NomP,
            nomProd:ren.NomP,
            indice:i,
        });
    }
    cargarArticulo = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/articulo/cargar/" + cve;
        const resp = await fetch(url);
        const articulo = await resp.json();
        if(articulo.Codigo === ''){
            Swal.fire('Atencion','Codigo no registrado','error');
            this.setState({cargando:false});
            return;
        }
        this.setState({
            cargando:false,
            codigo:articulo.Codigo,
            nombre:articulo.Nombre,
            articulo
        });
    }
    onChange = (e) => {
        const campo = e.target.name;
        let valor = e.target.value;
        this.setState({ [campo] : valor, cambio: true});
    }
    onChangeCb = (e) => {
        const campo = e.target.name;
        let valor = e.target.value;
        this.setState({[campo] : valor},() =>{
            if(campo === "depto")
               this.cargarLineas(valor);
            if(campo === "linea")
               this.cargarSublineas(valor);
        });
    }
    toggleProv = () => {
        this.setState({popoverProvOpen: !this.state.popoverProvOpen});
    }    
    toggleCod = () => {
        this.setState({popoverCodOpen: !this.state.popoverCodOpen});
    }    
    provSel = (item) =>{
        this.setState({popoverProvOpen:false, prov: item.I, nomProv:item.N},() =>{
            this.cargarDet();
        });
    }
    codigoIns = (item) =>{
        this.setState({popoverOpen:false});
        this.cargarArticulo(item.I);
    }
    handleClose = () =>{
        this.setState({mostrarModal:false,agregandoArti:false});
    }
    onKeyPressCodigo = (e) =>{
        if(e.which == 13)
           this.cargarArticulo(this.state.codigo);
    }
    render() {
        var st1 = {display: this.state.agregandoArti ? 'none':'block'};
        var st2 = {display: this.state.agregandoArti ? 'block':'none'};
        return(
            <div className="container">
                                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }

                <div className="row">
                <div className="col-2">
                        <span>Proveedor </span><br/>
                        <InputGroup>
                            <Input size="sm" type="text" name="prov" value={this.state.prov} onChange={this.onChange} onKeyPress={this.onKeyPressProv} id="prov" autoFocus />
                            <InputGroupAddon addonType="append">
                                <Button size="sm" color="success" id="btnBuscarProv">
                                    <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                </Button>
                                <Popover trigger="legacy" placement="right" isOpen={this.state.popoverProvOpen} target="btnBuscarProv" toggle={this.toggleProv} >
                                    <PopoverBody>
                                        <Buscador onSelect={this.provSel} url={this.props.urlws + "/prov/lista?filtro="} tema="provs" />
                                    </PopoverBody>
                                </Popover>
                            </InputGroupAddon>
                        </InputGroup>
                    </div>
                    <div className="col">
                        <span>&nbsp;</span><br/>
                        <Input size="sm" type="text" value={this.state.nomProv} readOnly/>
                    </div>
                </div>
                <div style={{ height: 4 }}></div>
                <div style={{ height: this.props.height, overflow: 'auto' }}>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th style={{ width: 100 }}>Cod.Prov.</th>
                                <th>Nombre Prov.</th>
                                <th style={{ width: 60 }}>Codigo</th>
                                <th>Nombre</th>
                                <th style={{width:30, textAlign:'center'}}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.detalle.map((ren, i) => (
                                <DetalleRow key={i} indice={i} renglon={ren} onRowClick={this.editarRenglon}  />
                            ))}
                        </tbody>
                    </Table>
                </div>                
                <Modal id="dlg" name="dlg" isOpen={this.state.mostrarModal} toggle={this.handleClose} size="lg" tabIndex={1}>
                    <ModalBody>
                        <div className="row">
                            <div className="col">
                                <InputGroup>
                                    <Input size="sm" type="text" value={this.state.nombreP} readOnly />
                                    <InputGroupAddon addonType="append">
                                        <Button size="sm" color="success" onClick={()=> this.setState({agregandoArti:true})}>
                                            <img src="/imagenes/add.png" alt="agregar" title="agregar" />
                                        </Button>
                                    </InputGroupAddon>
                                </InputGroup>
                            </div>
                        </div>
                        <div style={st1}>
                            <div className="row">
                                <div className="col">
                                    <FormGroup md="3" >
                                        <label>Codigo &nbsp;</label>
                                        <InputGroup>
                                            <Input size="sm" type="text" name="codigo" value={this.state.codigo} onChange={this.onChange} onKeyPress={this.onKeyPressCodigo} id="codigo" autoFocus />
                                            <InputGroupAddon addonType="append">
                                                <Button size="sm" color="success" id="btnBuscarCod">
                                                    <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                                </Button>
                                                <Popover trigger="legacy" placement="right" isOpen={this.state.popoverCodOpen} target="btnBuscarCod" toggle={this.toggleCod} >
                                                    <PopoverBody>
                                                        <Buscador onSelect={this.codigoIns} url={this.props.urlws + "/articulo/articulos?depto=0&filtro="} tema="articulos"/>
                                                    </PopoverBody>
                                                </Popover>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                </div>
                                <div className="col">
                                    <FormGroup md="4">
                                        <label>Nombre &nbsp;</label>
                                        <Input size="sm" type="text" value={this.state.nombre} readOnly style={{ width: 600 }} />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row">
                                &nbsp;&nbsp;&nbsp;
                            <Button color="success" size="sm" onClick={this.relacionar}>Relacionar</Button>
                            &nbsp;&nbsp;&nbsp;
                            <Button color="danger" size="sm" onClick={this.handleClose}>Cancelar</Button>
                            </div>
                        </div>
                        <div style={st2}>
                            <div class="row">
                                <div className="col">
                                    <FormGroup md="10">
                                        <label>Nombre</label>
                                        <Input size="sm" type="text" name="nomProd" value={this.state.nomProd} onChange={this.onChangeCb} />
                                    </FormGroup>
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-3">
                                    <FormGroup>
                                        <label>Departamento</label>
                                        <Input size="sm" type="select" name="depto" id="depto" value={this.state.depto} onChange={this.onChangeCb}  >
                                            <option key={-1} value={-1}>Especifique</option>
                                            {this.state.deptos.map((i, k) => (
                                                <option key={i.I} value={i.I}>{i.N}</option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </div>
                                <div className="col-3">
                                    <FormGroup>
                                        <label>Linea</label>
                                        <Input size="sm" type="select" name="linea" id="linea" value={this.state.linea} onChange={this.onChangeCb}  >
                                            <option key={-1} value={-1}>Especifique</option>
                                            {this.state.lineas.map((i, k) => (
                                                <option key={i.I} value={i.I}>{i.N}</option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </div>
                                <div className="col-3">
                                    <FormGroup  >
                                        <label>SubLinea</label>
                                        <Input size="sm" type="select" name="sublinea" id="sublinea" value={this.state.sublinea} onChange={this.onChangeCb}  >
                                            <option key={-1} value={-1}>Especifique</option>
                                            {this.state.sublineas.map((i, k) => (
                                                <option key={i.I} value={i.I}>{i.N}</option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </div>
                                <div className="col-3">
                                    <FormGroup  >
                                        <label>Unidad</label>
                                        <InputGroup>
                                            <Input size="sm " type="select" name="unidad" id="unidad" value={this.state.unidad} onChange={this.onChangeCb}  >
                                                <option key={-1} value="">Especifique</option>
                                                {this.state.unidades.map((i, k) => (
                                                    <option key={i.I} value={i.I}>{i.N}</option>
                                                ))}
                                            </Input>
                                        </InputGroup>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row">
                                &nbsp;&nbsp;&nbsp;
                            <Button color="success" size="sm" onClick={this.altaArticulo}>Guardar</Button>
                            &nbsp;&nbsp;&nbsp;
                            <Button color="danger" size="sm" onClick={this.handleClose}>Cancelar</Button>
                            </div>
                        </div>  
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}
class DetalleRow extends React.Component{
    onClick = (e) =>{
        console.log(this.props.renglon);
        this.props.onRowClick(this.props.renglon, this.props.indice);
    }
    render(){
        let {renglon} = this.props;
        return (
        <tr>
            <td>{renglon.CodP}</td>
            <td>{renglon.NomP}</td>
            <td>{renglon.Cod}</td>
            <td>{renglon.Nom}</td>
            <td>
                <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={this.onClick}/>
            </td>
        </tr>
        );
    }
}
