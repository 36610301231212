import React from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Row, Table, FormGroup, Col, Input, Button, Container, Spinner, Modal, ModalHeader, ModalBody} from 'reactstrap';
import Buscador from '../Buscador';

export default class ConsumoDiesel extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');
        this.state = {
            cargando:false,
            verDet:false,
            modalVisible:false,
            numero:0,
            actual:'',
            Temporada:this.props.temp,
            fechaIni:fecIni,
            fechaFin:fecFin,
            movimientos:[],
            tipos:[],
            temporadas:[],
            detalle:{
                Det:[],
                Act:[],
                Lote:[],
                Cult:[]
            },
            lotes:[],
            cultivos:[],
            maquina:{},
            movimiento:{}
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/invMovs/almacenes`;
        let resp = await fetch(url);
        const almacenes = await resp.json();
        url = `${this.props.urlws}/agricola/temporadas`;
        resp = await fetch(url);
        const temporadas = await resp.json();
        url = `${this.props.urlws}/agricola/lotes`;
        resp = await fetch(url);
        const lotes = await resp.json();
        url = `${this.props.urlws}/agricola/cultivos`;
        resp = await fetch(url);
        const cultivos = await resp.json();
        this.setState({cargando:false, almacenes, temporadas, lotes, cultivos});
    }
    cargarLotes = async() =>{
        this.setState({cargando:true});
        const url = `${this.props.urlws}/agricola/lotesTemp/${this.state.Temporada}`;
        const resp = await fetch(url);
        const lotes = await resp.json();
        this.setState({cargando:false, lotes });
    }
    cargarMovs = async () =>{
        const temp = this.state.Temporada;
        const fecIni = this.state.fechaIni;
        const fecFin = this.state.fechaFin;
        this.setState({cargando:true});
        let url = `${this.props.urlws}/agricola/consumoDiesel?fecIni=${fecIni}&fecFin=${fecFin}&temp=${temp}`;
        let resp = await fetch(url);
        const movimientos = await resp.json();
        this.setState({cargando:false, movimientos });
    }
    cargarDetalle = async(maq) =>{
        const temp = this.state.Temporada;
        const fecIni = this.state.fechaIni;
        const fecFin = this.state.fechaFin;
        this.setState({cargando:true,modalVisible:false});
        let url = `${this.props.urlws}/agricola/consumoDieselMaqAct?fecIni=${fecIni}&fecFin=${fecFin}&temp=${temp}&maq=${maq.IdMaq}`;
        let resp = await fetch(url);
        const detalle = await resp.json();
        this.setState({cargando:false, detalle, verDet:true, maquina:maq });        
    }
    imprimir = (mov) =>{
        let params = '?alm=' + mov.Almacen + '&cve=' + mov.Clave + '&num=' + mov.Numero;
        let url = this.props.urlws + '/compras/imprimir' + params;
        window.open(url, '', '');
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor});
    }
    cerrarModal = () =>{
        this.setState({modalVisible:false})
    }
    editarTemp = (i) =>{
        this.setState({modalVisible:true, temp : i.T, actual:i.T, numero:i.N, lote: i.Lt, cultivo: i.Cult, ren: i.Ren});
    }
    cambiarTemp = async () =>{
        const url = `${this.props.urlws}/agricola/cambiarTempDiesel?actual=${this.state.actual}&nueva=${this.state.temp}&num=${this.state.numero}&ren=${this.state.ren}&lote=${this.state.lote}&cult=${this.state.cultivo}`;
        this.setState({cargando:true});
        const resp = await fetch(url,{
            method: 'POST' // or 'PUT'
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','se modifico la temporada','success');
            this.cargarDetalle(this.state.maquina);
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    render () {
        const estilo1 = { display: this.state.verDet ? 'inline' : 'none' };
        const estilo2 = { padding:2, display: this.state.verDet ? 'none' : 'inline' };
        return(            
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo1}>
                    <br/>
                    <Button color="danger" size="sm" onClick={()=> this.setState({verDet:false})}>Regresar</Button>
                    <Detalle detalle={this.state.detalle} maquina={this.state.maquina} editarTemp={this.editarTemp}/>
                    <Button color="danger" size="sm" onClick={()=> this.setState({verDet:false})}>Regresar</Button>
                </div>
                <div style={estilo2}>
                    <div className="row">
                        <div className="col-2">
                            <FormGroup>
                                <span>Temporada</span><br/>
                                <Input size="sm" type="select" name="Temporada" value={this.state.Temporada} onChange={this.onChange} style={{  fontSize:'12px'}}>
                                    <option key="0" value="">Todas</option>
                                    {this.state.temporadas.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Del dia</span><br/>
                                <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>al dia</span><br/>
                                <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup as={Col}>
                                <span>&nbsp;</span><br/>
                                <Button color='success' size="sm" onClick={this.cargarMovs}>Cargar</Button>
                            </FormGroup>
                        </div>
                    </div>
                    <div style={{ height: 4 }}></div>
                    <MovsTable movimientos={this.state.movimientos} height={500} width="98%" onRowClick={this.cargarDetalle} imprimir={this.imprimir} />
                </div>     


                <Modal id="dlgActs" name="dlgActs" isOpen={this.state.modalVisible} toggle={this.cerrarModal}>
                    <ModalHeader>
                                <h6>Cambiar temporada</h6>
                    </ModalHeader>
                    <ModalBody>
                        <Container>
                            <div className="row">
                                <div className="col">
                                    <FormGroup>
                                        <span>Temporada</span><br />
                                        <Input size="sm" type="select" name="temp" value={this.state.temp} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                            <option key="0" value="">Especifique</option>
                                            {this.state.temporadas.map((i, k) =>
                                                <option key={i.I} value={i.I}>{i.N}</option>
                                            )}
                                        </Input>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <FormGroup>
                                        <span>Lote</span><br />
                                        <Input size="sm" type="select" name="lote" value={this.state.lote} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                            <option key="0" value="">Especifique</option>
                                            {this.state.lotes.map((i, k) =>
                                                <option key={i.I} value={i.I}>{i.N}</option>
                                            )}
                                        </Input>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <FormGroup>
                                        <span>Cultivo</span><br />
                                        <Input size="sm" type="select" name="cultivo" value={this.state.cultivo} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                            <option key="0" value="">Especifique</option>
                                            {this.state.cultivos.map((i, k) =>
                                                <option key={i.I} value={i.I}>{i.N}</option>
                                            )}
                                        </Input>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <br />
                                    <Button size="sm" color="success" onClick={this.cambiarTemp}>Aceptar</Button>
                                </div>
                            </div>
                        </Container>                         
                    </ModalBody>
                </Modal>
           
            </Container>
        )
    }
}

class MovsTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { movimientos } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:80}}>Id</th>
                        <th>Maquina</th>
                        <th className="numero">Litros</th>
                        <th style={{width:30}}></th>
                    </tr>
                </thead>
                <tbody>
                    {movimientos.map((esc, i) => (
                        <MovRow key={i} mov={esc} onRowClick={this.onRowClick} imprimir={this.props.imprimir} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}
class MovRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.mov);
    }
    render(){
        let {mov} = this.props;
        return (
        <tr>
            <td className="numero">{mov.IdMaq > 0 ? mov.IdMaq : ''}</td>
            <td>{mov.Maq}</td>
            <td className="numero">{mov.Lts.formato()}</td>
            <td>
                <img src="/imagenes/tabla.png" onClick={this.onClick} alt="ver detalle" title="ver detalle"/>
            </td>
       </tr>
        );
    }
}

class Detalle extends React.Component {
    editar = (d) =>{
        this.props.editarTemp(d);
    }
    render() {
        let det = this.props.detalle.Det;
        let act = this.props.detalle.Act;
        let cult = this.props.detalle.Cult;
        let lote = this.props.detalle.Lote;
        return (
            <div>
                <h5>{this.props.maquina.Maq}</h5>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th style={{ width: 50 }}>Temp</th>
                            <th style={{ width: 80 }}>Fecha</th>
                            <th style={{ width: 40 }}>Hora</th>
                            <th className="numero" style={{ width: 60 }}>Litros</th>
                            <th>Operador</th>
                            <th className="numero" style={{ width: 40 }}>Has</th>
                            <th>Lote</th>
                            <th>Cultivo</th>
                            <th>Actividad</th>
                            <th>Equipo</th>
                            <th style={{ width: 30 }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {det.map((d, i) => (
                            <tr>
                                <td>{d.T}</td>
                                <td>{d.F}</td>
                                <td>{d.H}</td>
                                <td className="numero">{d.D}</td>
                                <td>{d.O}</td>
                                <td className="numero">{d.Has.formato()}</td>
                                <td>{d.L}</td>
                                <td>{d.C}</td>
                                <td>{d.A}</td>
                                <td>{d.E}</td>
                                <td>
                                    <img src="/imagenes/edit.png" onClick={() => this.editar(d)} alt="editar" title="editar" />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <br/>
                <br/>
                <h5>{this.props.maquina.Maq}</h5>
                <Container>
                    <Row>
                        <Col>
                            <h6>Resumen por lote</h6>
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Lote</th>
                                        <th className="numero" style={{ width: 60 }}>Litros</th>
                                        <th className="numero" style={{ width: 40 }}>Has</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {lote.map((d, i) => (
                                        <tr>
                                            <td>{d.N}</td>
                                            <td className="numero">{d.A.formato()}</td>
                                            <td className="numero">{d.B.formato()}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                        <Col>
                            <h6>Resumen por Cultivo</h6>
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Cultivo</th>
                                        <th className="numero" style={{ width: 60 }}>Litros</th>
                                        <th className="numero" style={{ width: 40 }}>Has</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cult.map((d, i) => (
                                        <tr>
                                            <td>{d.N}</td>
                                            <td className="numero">{d.A.formato()}</td>
                                            <td className="numero">{d.B.formato()}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                        <Col>
                            <h6>Resumen por actividad</h6>
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Actividad</th>
                                        <th className="numero" style={{ width: 60 }}>Litros</th>
                                        <th className="numero" style={{ width: 40 }}>Has</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {act.map((d, i) => (
                                        <tr>
                                            <td>{d.N}</td>
                                            <td className="numero">{d.A.formato()}</td>
                                            <td className="numero">{d.B.formato()}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
