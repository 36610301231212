import React from 'react';
import { Col,Row,InputGroup, InputGroupAddon, Collapse, Table, Form, FormGroup, Popover, PopoverBody, Input, Button, Container, Spinner } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Buscador from '../Buscador';
import Cuenta from '../Cuenta';

export default class Analitica extends React.Component {
    constructor(p){
        super(p);
        this.state = {
            cargando:false,
            empresa:1,
            ejercicio:2019,
            perIni:1,
            perFin:1,
            cc:0,
            ejercicios:[],
            periodos:[],
            cta:'',
            nom:'',
            cuenta:{
                Nombre:''
            },
            analitica:{
                Inicial:0,
                Cargos:0,
                Abonos:0,
                Actual:0,
                Det:[]
            }
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        let url = `${this.props.urlws}/conta/ejercicios`;
        let resp = await fetch(url);
        const ejercicios = await resp.json();
        url = `${this.props.urlws}/conta/periodos`;
        resp = await fetch(url);
        const periodos = await resp.json();
        this.setState({ejercicios,periodos});
    }
    cargarMovs = async () => {
        const emp = this.state.empresa;
        const ejer = this.state.ejercicio;
        const perIni = this.state.perIni;
        const perFin = this.state.perFin;
        const cta = this.state.cuenta.Cuenta;
        const cc = this.state.cc;
        this.setState({cargando:true});
        let url = `${this.props.urlws}/conta/analiticaCuenta?emp=${emp}&ejer=${ejer}&perIni=${perIni}&perFin=${perFin}&cta=${cta}&cc=${cc}`;
        let resp = await fetch(url);
        const analitica = await resp.json();
        this.setState({cargando:false,analitica});
    }
    cargarCuenta = async (cta) => {
        const emp = this.state.empresa;
        this.setState({cargando:true});
        const url = `${this.props.urlws}/conta/cuenta?emp=${emp}&cta=${cta}`;
        const resp = await fetch(url);
        const cuenta = await resp.json();
        if(cuenta.Empresa == 0){
            Swal.fire('Atencion',"La cuenta " + cta + " no existe",'error');
            return;
        }
         this.setState({
            cargando:false,
            cuenta
        });
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    render(){
        return(
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <br/>
                <Row>
                    <Col-3>
                        <Cuenta prefijo="ana" fin={this.cargarCuenta} emp={this.state.empresa} urlws={this.props.urlws}/>
                    </Col-3>
                    <Col>
                        <Input size="sm" type="text" value={this.state.cuenta.Nombre} />
                    </Col>
                </Row>
                <div className="row">
                    <table>
                        <tr>
                            <td>Ejercicio&nbsp;&nbsp;</td>
                            <td>
                                <Input size="sm" type="select" name="ejercicio" value={this.state.ejercicio} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                    <option key="0" value="">Especifique</option>
                                    {this.state.ejercicios.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </td>
                            <td>&nbsp;&nbsp;Del periodo&nbsp;&nbsp;</td>
                            <td>
                                <Input size="sm" type="select" name="perIni" value={this.state.perIni} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                    <option key="0" value="">Especifique</option>
                                    {this.state.periodos.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </td>
                            <td>&nbsp;&nbsp;al periodo&nbsp;&nbsp;</td>
                            <td>
                                <Input size="sm" type="select" name="perFin" value={this.state.perFin} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                    <option key="0" value="">Especifique</option>
                                    {this.state.periodos.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </td>
                            <td>&nbsp;&nbsp;
                                <Button size="sm" color="success" onClick={this.cargarMovs}>
                                    <img src="/imagenes/ok.png" alt="aceptar" title="aceptar" />
                            &nbsp; Aceptar
                        </Button>
                            </td>
                        </tr>
                    </table>
                </div>
                <div style={{ height: 4 }}></div>
                <div className="wrapperX">
                    <div className="containerX" style={{ height: '400px' }}>
                        <table style={{ width: '100%' }} className="table-striped">
                            <thead>
                                <tr>
                                    <th>
                                        <div>
                                            <span>Cuenta</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div>
                                            <span>Nombre</span>
                                        </div>
                                    </th>
                                    <th className="numero" style={{width:100}}>
                                        <div>
                                            <span>Inicial</span>
                                        </div>
                                    </th>
                                    <th className="numero" style={{width:100}}>
                                        <div>
                                            <span>Cargos</span>
                                        </div>
                                    </th>
                                    <th className="numero" style={{width:100}}>
                                        <div>
                                            <span>Abonos</span>
                                        </div>
                                    </th>
                                    <th className="numero" style={{width:100}}>
                                        <div>
                                            <span>Actual</span>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                            {this.state.analitica.Det.map((mov, i) => (
                                <tr>
                                    <td>{mov.Cta}</td>
                                    <td>{mov.Nom}</td>
                                    <td className="numero">
                                        {mov.I.formato()}
                                    </td>
                                    <td className="numero">
                                        {mov.C.formato()}
                                    </td>
                                    <td className="numero">
                                        {mov.A.formato()}
                                    </td>
                                    <td className="numero">
                                        {mov.S.formato()}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>
                                        <div>
                                            <span></span>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <span></span>
                                        </div>
                                    </td>
                                    <td style={{ width: 100 }}>
                                        <div>
                                            <span>{this.state.analitica.Inicial.formato()}</span>
                                        </div>
                                    </td>
                                    <td style={{ width: 100 }}>
                                        <div>
                                            <span>{this.state.analitica.Cargos.formato()}</span>
                                        </div>
                                    </td>
                                    <td style={{ width: 100 }}>
                                        <div>
                                            <span>{this.state.analitica.Abonos.formato()}</span>
                                        </div>
                                    </td>
                                    <td style={{ width: 100}}>
                                        <div>
                                            <span>{this.state.analitica.Actual.formato()}</span>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </Container>
        );
    }
}
