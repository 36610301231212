import React from 'react';
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class RemisionesPapa extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');
        this.state = {
            cultivo:1,
            folio:0,
            cargando:false,
            editando:false,
            Temporada:this.props.temp,
            fechaIni:fecIni,
            fechaFin:fecFin,
            fecha:fecFin,
            clave:'?',
            ordenI:0,
            lote:0,
            vendedor:0,
            tiposOrden:[],
            temporadas:[],
            lotes:[],
            remisiones:[],
            ordenes:[],
            destinos:[], 
            vendedores:[],
            clientes:[],
            variedades:[],
            calidades:[],
            series:[],
            excel:false,
            remision:{},
            mov:{}
        };
        this.cargarCatalogos();
        this.cargarLotes();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/agricola/temporadas`;
        let resp = await fetch(url);
        const temporadas = await resp.json();
        url = `${this.props.urlws}/agricola/lotesCultivo/${this.state.cultivo}?temp=${this.state.Temporada}`;
        resp = await fetch(url);
        const lotes = await resp.json();
        url = `${this.props.urlws}/agricola/variedadesCultivo/${this.state.cultivo}`;
        resp = await fetch(url);
        const variedades = await resp.json();
        url = `${this.props.urlws}/agricola/calidadesCultivo/${this.state.cultivo}`;
        resp = await fetch(url);
        const calidades = await resp.json();
        url = `${this.props.urlws}/embarque/destinos/`;
        resp = await fetch(url);
        const destinos = await resp.json();
        url = `${this.props.urlws}/embarque/vendedores/`;
        resp = await fetch(url);
        const vendedores = await resp.json();
        url = `${this.props.urlws}/cliente/listaEmb?filtro=`;
        resp = await fetch(url);
        const clientes = await resp.json();
        url = `${this.props.urlws}/embarque/series/${this.state.cultivo}`;
        resp = await fetch(url);
        const series = await resp.json();
        url = `${this.props.urlws}/embarque/tiposOrden`;
        resp = await fetch(url);
        const tiposOrden = await resp.json();
        this.setState({cargando:false,series, vendedores, clientes, destinos, temporadas, lotes, variedades, calidades, tiposOrden});
    }
    cargarLotes = async() =>{
        this.setState({cargando:true});
        let url = `${this.props.urlws}/agricola/lotesCultivo/${this.state.cultivo}?temp=${this.state.Temporada}`;
        let resp = await fetch(url);
        const lotes = await resp.json();
        url = `${this.props.urlws}/embarque/ordenesAbiertas/${this.state.Temporada}`;
        resp = await fetch(url);
        const ordenes = await resp.json();
        this.setState({cargando:false, lotes, ordenes });
    }
    cargarMovs = async () =>{
        if(this.state.Temporada === ''){
            Swal.fire('Atencion','Especifique la temporada','error');
            return;
        }
        const temp = this.state.Temporada;
        const cve = this.state.clave; 
        const fecIni = this.state.fechaIni;
        const fecFin = this.state.fechaFin;
        this.setState({cargando:true});
        let url = `${this.props.urlws}/embarque/remisiones?lote=0&clave=${cve}&fecIni=${fecIni}&fecFin=${fecFin}&temp=${temp}`;
        let resp = await fetch(url);
        console.log(url);
        const remisiones = await resp.json();
        this.setState({cargando:false, remisiones });
    }
    cargarMov = async (temp,cve,num) =>{
        this.setState({cargando:true});
        let url = `${this.props.urlws}/embarque/cargarRemision?temp=${temp}&cve=${cve}&num=${num}`;
        let resp = await fetch(url);
        const remision = await resp.json();
        if(remision.Numero === 0){
           remision.Numero = num;
           if(this.state.ordenI >= 0){
               remision.NumeroOrden = this.state.ordenes[this.state.ordenI].I;
               remision.ClaveOrden = this.state.ordenes[this.state.ordenI].N;
           }
           remision.Lote = this.state.lote;
           remision.Vendedor = this.state.vendedor;
        }
        this.setState({cargando:false, editando:true, remision});
    }
    cargarDetalle = (mov) =>{
        this.cargarMov(this.state.Temporada,mov.Clave,mov.Numero);
    }
    cargarRemision = async(mov) =>{
        this.setState({cargando:true});
        let num = mov.NumRem;
        if(num === '')
           num = '0';
        let url = `${this.props.urlws}/embarque/cargarRemision?temp=${this.state.Temporada}&cve=${mov.CveRem}&num=${num}`;
        let resp = await fetch(url);
        const remision = await resp.json();
        this.setState({mov,remision,cargando:false, editandoRem:true});
    }
    ponNumero = () =>{
        for (let i = 0; i < this.state.series.length; i++) 
            if(this.state.series[i].N === this.state.clave)
               this.setState({folio : this.state.series[i].I});
    }

    imprimirRemCh = (mov) =>{
        let url = `${this.props.urlws}/embarque/imprimirRemisionCh?temp=${this.state.Temporada}&cve=${mov.Clave}&num=${mov.Numero}`;
        window.open(url, '', '');    
    }
    nuevoMovB = () =>{
        this.setState({mostrarModalPeso:false});
        this.cargarMov(this.state.Temporada,this.state.clave,this.state.folio);
    }
    nuevoMov = () =>{
        this.setState({mostrarModalPeso:true, ordenI:-1, lote:0});
    }
    cancelar = () =>{
        this.setState({editando:false});
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor},() =>{
            if(campo === "Temporada")
               this.cargarLotes();
            else if(campo === "clave"){
               this.cargarMovs();
               this.ponNumero();
            }
        });
    }    
    onChecked = (e) => {
        this.setState({ [e.target.name] : e.target.checked});
    }

    handleClose = () =>{
        this.setState({mostrarModalPeso:false});
    }
    cancelar = () =>{
        this.setState({editando:false});
    }
    guardar = async(s,cte)  =>{
        const url = this.props.urlws + "/embarque/grabarRemision"; //?temp=${this.state.Temporada}&cveOrd=${this.state.mov.Cve}&numOrd=${this.state.mov.Num}&cultivo=${this.state.cultivo}&s=${s}`;        
        const rem = {
            Temporada : this.state.Temporada,
            Clave:this.state.clave,
            Numero : this.state.remision.Numero,
            Fecha : this.state.fecha,
            ClaveOrden:this.state.remision.ClaveOrden,
            NumeroOrden:this.state.remision.NumeroOrden,
            Lote:this.state.lote,
            Cliente:cte,
            Vendedor:this.state.Vendedor,
            Cultivo:this.state.cultivo,
            S:s
        };
        this.setState({cargando:true});
        const json = JSON.stringify(rem);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json
        });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res.substring(0,1) !== "E"){
            Swal.fire('Atencion','Datos guardados','success');
            this.setState({cargando:false, editando:false});
            this.cargarMovs();
        }
        else{
            this.setState({cargando:false});
            Swal.fire('Atencion',res,'error');
        }
        return res;
    }
    render(){
        const estilo1 = { display: this.state.editando ? 'inline' : 'none' };
        const estilo2 = { padding:2, display: (this.state.editando || this.state.editandoRem) ? 'none' : 'inline' };
        const estilo3 = { display: this.state.editandoRem ? 'inline' : 'none' };
        return(
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo1}>
                    {this.state.editando?
                        <Remision height={200} urlws={this.props.urlws} temp={this.state.Temporada} cultivo={this.state.cultivo} remision={this.state.remision}  variedades={this.state.variedades} calidades={this.state.calidades} series={this.state.series} clientes={this.state.clientes} guardar={this.guardar} cancelar={this.cancelar} />
                        :
                        null
                    }
                </div>
                <div style={estilo2}>
                    <div className="row">
                        <div className="col-2">
                            <FormGroup>
                                <span>Temporada</span><br/>
                                <Input size="sm" type="select" name="Temporada" value={this.state.Temporada} onChange={this.onChange} style={{  fontSize:'12px'}}>
                                    <option key="0" value="">Especifique</option>
                                    {this.state.temporadas.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-1">
                            <FormGroup>
                                <span>Serie</span><br/>
                                <Input size="sm" type="select" name="clave" value={this.state.clave} onChange={this.onChange} style={{  fontSize:'12px'}}>
                                    <option key="0" value="?">Especifique</option>
                                    {this.state.series.map((i, k) =>
                                        <option key={i.I} value={i.N}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Del dia</span><br/>
                                <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>al dia</span><br/>
                                <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col">
                            <FormGroup as={Col}>
                                <span>&nbsp;</span><br/>
                                <Button color='success' outline size="sm" onClick={this.cargarMovs}><img src="/imagenes/ok.png"/></Button>
                                {'  '}
                                <Button color='success' outline size="sm" onClick={this.nuevoMov}><img src="/imagenes/add.png"/></Button>
                            </FormGroup>
                        </div>
                    </div>
                    <div style={{ height: 4 }}></div>
                    <MovsTable movs={this.state.remisiones} height={500} width={990} onRowClick={this.cargarDetalle} imprimirRemCh={this.imprimirRemCh}/>
                </div>          
                <Modal id="dlgPeso" name="dlgPeso" isOpen={this.state.mostrarModalPeso} toggle={this.handleClose} size="sm" tabIndex={1}>
                    <ModalHeader>
                        <span>Nueva orden</span>
                    </ModalHeader>
                    <ModalBody>
                    <div className="col-8">                            
                            <FormGroup>
                                <span>Fecha</span>
                                <Input size="sm" type="date" name="fecha" value={this.state.fecha} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-6">                            
                            <FormGroup>
                                <span>Folio</span>
                                <Input size="sm" type="text" name="folio" value={this.state.folio} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-8">
                            <FormGroup>
                            <span>Orden</span><br/>
                                <Input size="sm" type="select" name="ordenI" value={this.state.ordenI} onChange={this.onChange} style={{  fontSize:'12px'}}>
                                    <option key="0" value={-1}>Ninguna</option>
                                    {this.state.ordenes.map((i, k) =>
                                        <option key={k} value={k}>{i.N}-{i.I}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col">
                            <FormGroup>
                            <span>Lote</span><br/>
                                <Input size="sm" type="select" name="lote" value={this.state.lote} onChange={this.onChange} style={{  fontSize:'12px'}}>
                                    <option key="0" value={-1}>Especifique</option>
                                    {this.state.lotes.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col">
                            <FormGroup>
                            <span>Vendedor</span><br/>
                                <Input size="sm" type="select" name="vendedor" value={this.state.vendedor} onChange={this.onChange} style={{  fontSize:'12px'}}>
                                    <option key="0" value={-1}>Especifique</option>
                                    {this.state.vendedores.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col">
                            <FormGroup>
                                <Button onClick={this.nuevoMovB} color="success" size="sm">Continuar</Button>
                            </FormGroup>
                        </div>
                    </ModalBody>
                </Modal>      
            </Container>
        )
    }
}

class MovsTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { movs } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:70}}>Remision</th>
                        <th style={{width:80}}>Fecha</th>
                        <th style={{width:70}}>Orden</th>
                        <th>Cliente</th>
                        <th >Vendedor</th>
                        <th style={{width:170}}>Lote</th>
                        <th className="numero" style={{width:80}}>Arpillas</th>
                        <th style={{width:30}}></th>
                        <th style={{width:30}}></th>
                    </tr>
                </thead>
                <tbody>
                    {movs.map((o, i) => (
                        <MovsRow key={i} mov={o} onRowClick={this.onRowClick} imprimirRemCh={this.props.imprimirRemCh} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}
class MovsRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.mov);
    }
    onRemChClick = (e) =>{
        this.props.imprimirRemCh(this.props.mov);
    }
    render(){
        let {mov} = this.props;
        return (
        <tr>
            <td>{mov.Numero}</td>
            <td>{mov.Fecha}</td>
            <td>{mov.Orden}</td>
            <td>{mov.Cte}</td>
            <td>{mov.Vend}</td>
            <td>{mov.Lote}</td>
            <td className="numero">{mov.Cant}</td>
            <td>
                <img src="/imagenes/edit.png" onClick={this.onClick} alt="editar" title="editar"/>            
            </td>
            <td>
                <img src="/imagenes/printer.png" onClick={this.onRemChClick} alt="remision chofer" title="remision chofer"/>
            </td>
        </tr>
        );
    }
}

class Remision extends React.Component{
    constructor(props){
        super(props);
        let total = 0;
        let totalKgs = 0;
        const detalle = this.props.remision.Detalle;
        for (let i = 0; i < detalle.length; i++){
           total += detalle[i].Cant;
           totalKgs += detalle[i].Kilos;
        }
        this.state = {
            total : Number(total),
            totalKgs : Number(totalKgs),
            series:[],
            serie:this.props.remision.Clave,
            numero:this.props.remision.Numero,
            clientes:this.props.clientes,
            cliente:this.props.remision.Cliente,
            orden:this.props.remision.NumeroOrden,
            variedad:0,
            calidad:0,
            cantidad:0,
            precio:0,
            kilos:0,
            kgsDescto:0,
            indice : -1,
            mostrarModal:false,
            accionRenglon:'',
            detalle
        };
    }
    cargarClientes = async () => {
        const url = `${this.props.urlws}/cliente/lista?filtro=`;
        const resp = await fetch(url);
        const clientes = await resp.json();
        this.setState({clientes });
    }

    onChange = (e) => {
        if(this.props.remision.Cerrada)
           return;
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor},() =>{
            if(campo === "serie")
               this.ponNumero();
        });
    }
    agregarRenglon = () =>{
        if(this.state.variedad === 0 || this.state.calidad === 0)
           return;
        if(this.state.indice >= 0){
            this.actualizaRenglon();
            return;
        }
        let nomV = '';
        let nomC = '';
        for (let i = 0; i < this.props.variedades.length; i++) {
           if(this.props.variedades[i].I == this.state.variedad)
              nomV = this.props.variedades[i].N;
        }
        for (let i = 0; i < this.props.calidades.length; i++) {
            if(this.props.calidades[i].I == this.state.calidad)
                nomC = this.props.calidades[i].N;
            }
        const cant = parseInt(this.state.cantidad);
        const kgs = parseInt(this.state.kilos);
        const kgsD = Number(this.state.kgsDescto);
        const ren = {
            Var : this.state.variedad,
            Cal : this.state.calidad,
            Cant : cant,
            NomV: nomV,
            NomC : nomC,
            Kilos: kgs,
            KgsDescto:kgsD,
            Pre:this.state.precio
        };
        this.state.detalle.push(ren);
        this.state.total = Number(this.state.total) + cant;
        this.state.totalKgs = Number(this.state.totalKgs) + kgs;
        this.setState({calidad:0, cantidad:0, kilos:0, kgsDescto:0, indice : -1});
    }
    actualizaRenglon = () =>{
        let i = this.state.indice;
        let nomV = '';
        let nomC = '';
        for (let i = 0; i < this.props.variedades.length; i++) {
           if(this.props.variedades[i].I == this.state.variedad)
              nomV = this.props.variedades[i].N;
        }
        for (let i = 0; i < this.props.calidades.length; i++) {
            if(this.props.calidades[i].I == this.state.calidad)
                nomC = this.props.calidades[i].N;
            }
        const cant = parseInt(this.state.cantidad);
        const kgs = parseInt(this.state.kilos);
        const kgsD = Number(this.state.kgsDescto);

        this.state.total = this.state.total + cant - this.state.detalle[i].Cant;
        this.state.totalKgs = this.state.totalKgs + kgs - this.state.detalle[i].Kilos;

        this.state.detalle[i].Var = this.state.variedad;
        this.state.detalle[i].Cal = this.state.calidad;
        this.state.detalle[i].Pre = this.state.precio;
        this.state.detalle[i].Cant = cant;
        this.state.detalle[i].Kilos = kgs;
        this.state.detalle[i].KgsDescto = kgsD;
        this.state.detalle[i].NomV = nomV;
        this.state.detalle[i].NomC = nomC;
        this.setState({mostrarModal:false, calidad:0, cantidad:0, indice : -1});
    }
    quitarRenglon = (i) =>{
        let total = 0;
        let totalKgs = 0;
        this.state.detalle.splice(i, 1);
        for (let i = 0; i < this.state.detalle.length; i++) {
            total += this.state.detalle[i].Cant;
            totalKgs += this.state.detalle[i].Kilos;
         } 
        this.setState({total, totalKgs, indice : -1});
    }
    nuevoRenglon = () =>{
        this.state.variedad = 0;
        this.state.calidad = 0;
        this.state.precio = 0;
        this.state.cantidad = 0;
        this.state.kilos = 0;
        this.state.kgsDescto = 0;
        this.state.accionRenglon = "Nuevo renglon"
        this.setState({indice:-1,mostrarModal : true});
    }
    editarRenglon = (k) =>{
        let i = k.i;
        let det = this.state.detalle;
        this.state.variedad = det[i].Var;
        this.state.calidad = det[i].Cal;
        this.state.precio = det[i].Pre;
        this.state.cantidad = det[i].Cant;
        this.state.kilos = det[i].Kilos;
        this.state.kgsDescto = det[i].KgsDescto;
        this.state.accionRenglon = "Editando renglon"
        this.setState({indice:i,mostrarModal : true});
    }
    agregar = () =>{
        this.setState({mostrarModal : true});
    }
    handleClose = () =>{
        this.setState({mostrarModal:false});
    }
    guardar = () =>{
        let s = JSON.stringify(this.state.detalle);
        this.props.guardar(s,this.state.cliente);
    }
    render(){
       console.log(this.props.orden);
        return(
            <Container>
                <br/>
                <div className="row">
                    <div className="col-1">
                        <FormGroup>
                            <span>Orden</span>
                            <Input size="sm" type="text" value={this.state.orden}/>
                        </FormGroup>
                    </div>
                    <div className="col-1">
                        <FormGroup>
                            <span>Serie</span>
                            <Input size="sm" type="select" name="serie" value={this.state.serie}  >
                                    <option key="0" value="">?</option>
                                    {this.props.series.map((i, k) =>
                                        <option key={i.N} value={i.N}>{i.N}</option>
                                    )}
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup>
                            <span>Remision</span>
                            <Input  size="sm" type="text" name="numero" value={this.state.numero} />
                        </FormGroup>
                    </div>
                    <div className="col">
                        <FormGroup>
                            <span>Remisionado a</span>
                            <InputGroup>
                                <Input size="sm" type="select" name="cliente" id="cliente" value={this.state.cliente} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>
                                    {this.state.clientes.map((i, k) => (
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                                <InputGroupAddon addonType="prepend"><Button outline size="sm" color="success" onClick={this.cargarClientes}>+</Button></InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    </div>
                </div>
                <div style={{ height: this.props.height, width: this.props.width, overflow: 'auto' }}>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Variedad</th>
                                <th>Calidad</th>
                                <th className="numero" style={{ width: 80 }}>Arpillas</th>
                                <th className="numero" style={{ width: 80 }}>Kilos</th>
                                <th className="numero" style={{ width: 80 }}>Precio Kg.</th>
                                <th style={{ width: 30 }}></th>
                                <th style={{ width: 30 }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.detalle.map((o, i) => (
                                <tr key={i}>
                                    <td>{o.NomV}</td>
                                    <td>{o.NomC}</td>
                                    <th className="numero">{o.Cant}</th>
                                    <th className="numero">{o.Kilos}</th>
                                    <th className="numero">{o.Pre}</th>
                                    <td style={{ width: 30 }}>
                                        {
                                        !this.props.remision.Cerrada &&
                                        <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={()=>{ this.editarRenglon({i});}}/>                                            
                                        }
                                    </td>
                                    <td style={{ width: 30 }}>
                                        {
                                        !this.props.remision.Cerrada &&
                                        <img src="/imagenes/quitar.png" alt="eliminar" title="eliminar" onClick={()=>{ this.quitarRenglon({i});}}/>
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th className="numero" style={{ width: 80, color:'red' }}>{this.state.total}</th>
                            <th className="numero" style={{ width: 80, color:'red' }}>{this.state.totalKgs}</th>
                            <th className="numero" style={{ width: 80, color:'red' }}></th>
                            <th style={{ width: 60 }}>
                                { /* <Button onClick={this.agregar} color="success" size="sm">+</Button> */}
                            </th>
                        </tr>
                    </thead>
                </Table>
                {
                    !this.props.remision.Cerrada &&
                    <Button outline onClick={this.nuevoRenglon} color="success" size="sm"><img src="/imagenes/add.png"/></Button>
                }
                <span>&nbsp;&nbsp;&nbsp;</span>
                {
                    !this.props.remision.Cerrada &&
                    <Button onClick={this.guardar} color="success" size="sm">Guardar</Button>
                }
                <span>&nbsp;&nbsp;&nbsp;</span>
                <Button onClick={this.props.cancelar} color="danger" size="sm">Cancelar</Button>

                <Modal id="dlgCom" name="dlgCom" isOpen={this.state.mostrarModal} toggle={this.handleClose} tabIndex={1}>
                    <ModalHeader>
                        <h4>{this.state.accionRenglon}</h4>
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-6">
                                <FormGroup>
                                    <span>Variedad</span>
                                    <InputGroup>
                                        <Input size="sm" type="select" name="variedad" id="variedad" value={this.state.variedad} onChange={this.onChange}  >
                                            <option key={-1} value={-1}>Especifique</option>
                                            {this.props.variedades.map((i, k) => (
                                                <option key={i.I} value={i.I}>{i.N}</option>
                                            ))}
                                        </Input>
                                    </InputGroup>
                                </FormGroup>
                            </div>
                            <div className="col-6">
                                <FormGroup>
                                    <span>Calidad</span>
                                    <InputGroup>
                                        <Input size="sm" type="select" name="calidad" id="calidad" value={this.state.calidad} onChange={this.onChange}  >
                                            <option key={-1} value={-1}>Especifique</option>
                                            {this.props.calidades.map((i, k) => (
                                                <option key={i.I} value={i.I}>{i.N}</option>
                                            ))}
                                        </Input>
                                    </InputGroup>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <FormGroup>
                                    <span>Arpillas</span>
                                    <Input size="sm" type="text" name="cantidad" value={this.state.cantidad} onChange={this.onChange} />
                                </FormGroup>
                            </div>
                            <div className="col-3">
                                <FormGroup>
                                    <span>Kilos</span>
                                    <Input size="sm" type="text" name="kilos" value={this.state.kilos} onChange={this.onChange} />
                                </FormGroup>
                            </div>
                            <div className="col-3">
                                <FormGroup>
                                    <span>Descto.Kgs.</span>
                                    <Input size="sm" type="text" name="kgsDescto" value={this.state.kgsDescto} onChange={this.onChange} />
                                </FormGroup>
                            </div>
                            <div className="col-3">
                                <FormGroup>
                                    <span>Precio por Kg</span>
                                    <Input size="sm" type="text" name="precio" value={this.state.precio} onChange={this.onChange} />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <span>&nbsp;&nbsp;&nbsp;</span>
                            <Button size="sm" color="success" onClick={this.agregarRenglon}>Aceptar</Button>
                        </div>
                    </ModalBody>
                </Modal>
            </Container>      
        )
    }
}