import React from 'react';
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Buscador from '../Buscador';

export default class EdoCtaCxP extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');
        this.state = {
            cargando:false,
            popoverOpen:false,
            Temporada:this.props.temp,
            clave:'',
            prov:{
                Nombre:''
            },
            almacen:1,
            fechaIni:fecIni,
            fechaFin:fecFin,
            almacenes:[],
            movs:[],
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/invMovs/almacenes`;
        let resp = await fetch(url);
        const almacenes = await resp.json();
        this.setState({cargando:false, almacenes });
    }
    cargarMovs = async () => {
        const {almacen} = this.state;
        const {fechaIni} = this.state;
        const {fechaFin} = this.state;
        const {clave} = this.state;
        this.setState({cargando:true});
        console.log(this.state);
        let url = `${this.props.urlws}/prov/edoCta?alm=${almacen}&fecIni=${fechaIni}&fecFin=${fechaFin}&prov=${clave}`;
        let resp = await fetch(url);
        const movs = await resp.json();
        this.setState({cargando:false, movs });
    }
    toggle = () => {
        this.setState({popoverOpen: !this.state.popoverOpen});
    }    
    onChange = (e) => {
        const campo = e.target.name;
        let valor = e.target.value;
        this.setState({ [campo] : valor});
    }
    onKeyPressCodigo = (e) =>{
        if(e.which == 13)
           this.cargarProv(this.state.clave);
    }
    codigoIns = (item) =>{
        this.setState({popoverOpen:false});
        this.cargarProv(item.I);
    }
    cargarProv = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/prov/cargar/" + cve;
        const resp = await fetch(url);
        const prov = await resp.json();
        if(prov.Clave === 0){
            Swal.fire('Atencion','Proveedor no registrado','error');
            return;
        }
        this.setState({
            clave:prov.Clave,
            cargando:false,
            prov
        });
    }
    render(){
        return(
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div>
                    <div className="row">
                        <div className="col-2">
                            <FormGroup>
                                <span>Almacen</span><br/>
                                <Input size="sm" type="select" name="almacen" value={this.state.almacen} onChange={this.onChange}>
                                    <option key="0" value={0}>Especifique</option>
                                    {this.state.almacenes.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Del dia</span><br />
                                <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>al dia</span><br />
                                <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Proveedor &nbsp;</span>
                                <InputGroup>
                                    <Input size="sm" type="text" name="clave" value={this.state.clave} onChange={this.onChange} onKeyPress={this.onKeyPressCodigo} id="codigo" autoFocus />
                                    <InputGroupAddon addonType="append">
                                        <Button size="sm" color="success" id="btnBscrCxpEdoCta">
                                            <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                        </Button>
                                        <Popover trigger="legacy" placement="right" isOpen={this.state.popoverOpen} target="btnBscrCxpEdoCta" toggle={this.toggle} >
                                            <PopoverBody>
                                                <Buscador onSelect={this.codigoIns} url={this.props.urlws + "/prov/lista?filtro="} tema="provs" />
                                            </PopoverBody>
                                        </Popover>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </div>
                        <div className="col">
                            <span>&nbsp;</span><br />
                            <InputGroup>
                                <Input size="sm" type="text" value={this.state.prov.Nombre} readOnly />
                                <InputGroupAddon addonType="append">
                                <Button color='success' size="sm" onClick={this.cargarMovs}>
                                    <img src="/imagenes/check.png" alt="cargar" title="cargar" />
                                </Button>
                                    
                                    </InputGroupAddon>
                            </InputGroup>
                        </div>
                    </div>
                    <div style={{ height: 4 }}></div>
                    <MovsTable movs={this.state.movs} height={500}/>
                </div>                
            </Container>
        )
    }
}
class MovsTable extends React.Component {
    onRowClick = (cve) => {
        //this.props.onRowClick(cve);
    }
    render() {
        let { movs } = this.props;
        let ent = 0;
        let sal = 0;
        let ini = 0;
        let act = 0;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:80}}>Fecha</th>
                        <th style={{width:100}}>Ref.Cargo</th>
                        <th style={{width:100}}>Ref.Abono</th>
                        <th>Concepto</th>
                        <th className="numero" style={{width:80}}>Cargo</th>
                        <th className="numero" style={{width:80}}>Abono</th>
                        <th className="numero" style={{width:80}}>Saldo</th>
                    </tr>
                </thead>
                <tbody>
                    {movs.map((esc, i) => {
                     if(esc.T === "C"){
                        ent += esc.C;
                     }
                     else if(esc.T === "A"){
                        sal += esc.A;
                     }
                     else{
                        ini = esc.S;
                     }
                     act = esc.S;
                    return <MovRow key={i} mov={esc} onRowClick={this.onRowClick} imprimir={this.props.imprimir} cambiarStatus={this.props.cambiarStatus} />
                    })}
                    <tr>
                        <td></td>
                        <td></td>
                        <td>TOTALES</td>
                        <th className="numero" >Saldo Anterior : {ini.formato()}</th>
                        <th className="numero" style={{width:80}}>{ent.formato()}</th>
                        <th className="numero" style={{width:80}}>{sal.formato()}</th>
                        <th className="numero" style={{width:80}}>{act.formato()}</th>
                    </tr>
                </tbody>
                </Table>
            </div>
        );
    }
}
class MovRow extends React.Component{
    onClick = (e) =>{
        //this.props.onRowClick(this.props.mov);
    }
    imprimir = (e) =>{
        //this.props.imprimir(this.props.mov);
    }
    render(){
        let {mov} = this.props;
        return (
        <tr>
            <td>{mov.F}</td>
            <td>{mov.Rc}</td>
            <td>{mov.Ra}</td>
            <td>{mov.Co}</td>
            <th className="numero" style={{width:80}}>
                {mov.T === "C" ? mov.C.formato() : ""}
            </th>
            <th className="numero" style={{width:80}}>
                {mov.T === "A" ? mov.A.formato() : ""}
            </th>
            <th className="numero" style={{width:80}}>{mov.S.formato()}</th>
        </tr>
        );
    }
}
