import React from 'react';
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class OrdenEmbarque extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');
        this.state = {
            cultivo:1,
            cargando:false,
            editando:false,
            editandoRem:false,
            mostrarModalPeso:false,
            Temporada:this.props.temp,
            fechaIni:fecIni,
            fechaFin:fecFin,
            ptara:0,
            pbruto:0,
            pneto:0,
            pfolio:0,
            pton:0,
            flete:0,
            sello:'',
            clave:'?',
            temporadas:[],
            lotes:[],
            ordenes:[],
            destinos:[], 
            camiones:[], 
            cajas:[], 
            choferes:[], 
            vendedores:[],
            clientes:[],
            estados:[],
            transportistas:[],
            variedades:[],
            calidades:[],
            tiposOrden:[],
            excel:false,
            orden:{},
            remision:{},
            mov:{}
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/agricola/temporadas`;
        let resp = await fetch(url);
        const temporadas = await resp.json();
        url = `${this.props.urlws}/agricola/lotesCultivo/${this.state.cultivo}?temp=${this.state.Temporada}`;
        resp = await fetch(url);
        const lotes = await resp.json();
        url = `${this.props.urlws}/embarque/tiposOrden`;
        resp = await fetch(url);
        const tiposOrden = await resp.json();
        url = `${this.props.urlws}/nomina/catalogoSat/estados`;
        resp = await fetch(url);
        const estados = await resp.json();
        url = `${this.props.urlws}/agricola/variedadesCultivo/${this.state.cultivo}`;
        resp = await fetch(url);
        const variedades = await resp.json();
        url = `${this.props.urlws}/agricola/calidadesCultivo/${this.state.cultivo}`;
        resp = await fetch(url);
        const calidades = await resp.json();
        this.setState({cargando:false, temporadas, tiposOrden, lotes, estados, variedades, calidades},()=> this.cargarCatalogosEmb());
    }
    cargarCatalogosEmb = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/embarque/camiones/0`;
        let resp = await fetch(url);
        const camiones = await resp.json();
        url = `${this.props.urlws}/embarque/cajas/0`;
        resp = await fetch(url);
        const cajas = await resp.json();
        url = `${this.props.urlws}/embarque/destinos/`;
        resp = await fetch(url);
        const destinos = await resp.json();
        url = `${this.props.urlws}/embarque/transportistas/`;
        resp = await fetch(url);
        const transportistas = await resp.json();
        url = `${this.props.urlws}/embarque/choferes/`;
        resp = await fetch(url);
        const choferes = await resp.json();
        url = `${this.props.urlws}/embarque/vendedores/`;
        resp = await fetch(url);
        const vendedores = await resp.json();
        url = `${this.props.urlws}/cliente/listaEmb?filtro=`;
        resp = await fetch(url);
        const clientes = await resp.json();
        this.setState({cargando:false, destinos, camiones, cajas, choferes, transportistas,vendedores,clientes });
    }
    cargarLotes = async() =>{
        this.setState({cargando:true});
        const url = `${this.props.urlws}/agricola/lotesCultivo/${this.state.cultivo}?temp=${this.state.Temporada}`;
        const resp = await fetch(url);
        const lotes = await resp.json();
        this.setState({cargando:false, lotes });
    }
    cargarMovs = async () =>{
        if(this.state.Temporada === ''){
            Swal.fire('Atencion','Especifique la temporada','error');
            return;
        }
        const temp = this.state.Temporada;
        const cve = this.state.clave; 
        const fecIni = this.state.fechaIni;
        const fecFin = this.state.fechaFin;
        this.setState({cargando:true});
        let url = `${this.props.urlws}/embarque/ordenes?lote=0&clave=${cve}&fecIni=${fecIni}&fecFin=${fecFin}&temp=${temp}`;
        let resp = await fetch(url);
        console.log(url);
        const ordenes = await resp.json();
        this.setState({cargando:false, ordenes });
    }
    cargarMov = async (temp,cve,num) =>{
        this.setState({cargando:true});
        let url = `${this.props.urlws}/embarque/cargarOrden?temp=${temp}&cve=${cve}&num=${num}`;
        let resp = await fetch(url);
        const orden = await resp.json();
        if(orden.Numero === 0)
           orden.Numero = num;
        this.setState({cargando:false, editando:true, orden});
    }
    cargarDetalle = (mov) =>{
        this.cargarMov(this.state.Temporada,mov.Cve,mov.Num);
    }
    cargarRemision = async(mov) =>{
        this.setState({cargando:true});
        let num = mov.NumRem;
        if(num === '')
           num = '0';
        let url = `${this.props.urlws}/embarque/cargarRemision?temp=${this.state.Temporada}&cve=${mov.CveRem}&num=${num}`;
        let resp = await fetch(url);
        const remision = await resp.json();
        this.setState({mov,remision,cargando:false, editandoRem:true});
    }
    imprimirEmbSeg = () =>{
        let excel = this.state.excel ? "S": "N";
        let url = `${this.props.urlws}/embarque/imprimirEmbarquesSeg?fecIni=${this.state.fechaIni}&fecFin=${this.state.fechaFin}&clave=${this.state.clave}&excel=${excel}&lote=0`;
        window.open(url, '', '');    
    }
    imprimirEmbAcum = () =>{
        let url = `${this.props.urlws}/embarque/imprimirEmbarquesAcum?clave=${this.state.clave}&temp=${this.state.Temporada}&fecha=${this.state.fechaFin}&lote=0`;
        window.open(url, '', '');    
    }
    imprimirRemCh = (mov) =>{
        let num = mov.NumRem;
        if(num === '')
           num = '0';
        let url = `${this.props.urlws}/embarque/imprimirRemisionCh?temp=${this.state.Temporada}&cve=${mov.CveRem}&num=${num}`;
        window.open(url, '', '');    
    }
    imprimirRem = (mov) =>{
        let url = `${this.props.urlws}/embarque/imprimirRemisionTepa?temp=${this.state.Temporada}&cve=${mov.Cve}&num=${mov.Num}`;
        window.open(url, '', '');    
    }
    imprimirCert = (mov) =>{
        let url = `${this.props.urlws}/embarque/imprimirCertificadoOrigen?temp=${this.state.Temporada}&cve=${mov.Cve}&num=${mov.Num}`;
        window.open(url, '', '');    
    }
    imprimirOrdenCampo = (mov) =>{
        let url = `${this.props.urlws}/embarque/imprimirOrdenCampo?temp=${this.state.Temporada}&cve=${mov.Cve}&num=${mov.Num}`;
        window.open(url, '', '');    
    }
    imprimirCaades = (mov) =>{
        let url = `${this.props.urlws}/embarque/imprimirHojaCaades?temp=${this.state.Temporada}&cve=${mov.Cve}&num=${mov.Num}`;
        window.open(url, '', '');    
    }
    imprimirCarta = (mov) =>{
        let url = `${this.props.urlws}/embarque/imprimirOrden?temp=${this.state.Temporada}&cve=${mov.Cve}&num=${mov.Num}`;
        window.open(url, '', '');    
    }
    imprimirCartaCte = (mov) =>{
        let url = `${this.props.urlws}/embarque/imprimirCartaCliente?temp=${this.state.Temporada}&cve=${mov.Cve}&num=${mov.Num}`;
        window.open(url, '', '');    
    }
    nuevoMov = async() =>{
        const cve = this.state.clave;
        if(cve === "?"){
            Swal.fire('Atencion','Especifique el tipo de orden','error');
            return;
        }
        const { value: numero } = await Swal.fire({
            input: 'text',
            title: 'Numero de orden',
            inputValue:'',
            inputPlaceholder: 'ingrese el numero de orden'
          })          
          if (numero) {
              let num = parseInt(numero);
              if(num != NaN)
                this.cargarMov(this.state.Temporada,cve,num);
            }
    }
    cancelar = () =>{
        this.setState({editando:false});
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor},() =>{
            if(campo === "Temporada")
               this.cargarLotes();
            else if(campo === "clave")
               this.cargarMovs();
        });
    }    
    onChangePeso = (e) => {
        if(this.state.mov.Cerrada)
           return;
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor},() =>{
            if(campo === "Temporada")
               this.cargarLotes();
            else if(campo === "clave")
               this.cargarMovs();
        });
    }    
    onChecked = (e) => {
        this.setState({ [e.target.name] : e.target.checked});
    }

    handleClose = () =>{
        this.setState({mostrarModalPeso:false});
    }
    capturarPeso = (mov) =>{
        this.setState({
            mov,
            pbruto : mov.PBruto,
            pneto : mov.PNeto,
            ptara : mov.PTara,
            pfolio: mov.PFolio,
            pton : mov.PTon,
            flete : mov.Fle,
            sello : mov.Sello,
            mostrarModalPeso : true
        });
    }
    cerrar = (mov) =>{
        if(!mov.Cerrada){
            const MySwal = withReactContent(Swal)
            MySwal.fire({
                title: 'Confirme',
                text: "¿ Cerrar el embarque de la orden " + mov.Num + "?",
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'No',
                confirmButtonText: 'Si'
              }).then(async(result) => {
                if (result.value) {
                    const status = mov.Status + 1;
                    this.setState({cargando:true});
                    const url = `${this.props.urlws}/embarque/cierraOrden?temp=${this.state.Temporada}&cve=${mov.Cve}&num=${mov.Num}`;
                    const resp = await fetch(url);
                    const txt = await resp.text();
                    this.setState({cargando:false });
                    if(txt === "ok")
                        this.cargarMovs();
                    else
                        Swal.fire('Atencion',txt,'error');
                }
              })            
        }
    }
    guardarPesaje = async()  =>{
        let {mov} = this.state;
        let url = `${this.props.urlws}/embarque/grabarPesaje?temp=${this.state.Temporada}&cve=${mov.Cve}&num=${mov.Num}&pesot=${this.state.ptara}&peson=${this.state.pneto}&pesob=${this.state.pbruto}&folio=${this.state.pfolio}&precio=${this.state.pton}&sello=${this.state.sello}`;
        this.setState({cargando:true});
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.setState({cargando:false, editando:false, mostrarModalPeso:false});
            this.cargarMovs();
        }
        else{
            this.setState({cargando:false});
            Swal.fire('Atencion',res,'error');
        }
        return res;
    }
    guardar = async(d)  =>{
        const url = this.props.urlws +"/embarque/grabarOrden";
        this.setState({cargando:true});
        const dato = {
            Temporada : this.state.Temporada,
            Clave:this.state.clave,
            Numero : d.Numero,
            Fecha :d.Fecha,
            Lote:d.Lote,
            Vendedor:d.Vendedor,
            Comprador:d.Comprador,
            Destino:d.Destino,
            Condiciones:d.Condiciones,
            Arpillas:d.Arpillas,
            Cant1:d.Cant1,
            Cant2:d.Cant2,
            Cant3:d.Cant3,
            Cant4:d.Cant4,
            Transportista:d.Transportista,
            Chofer:d.Chofer,
            Camion:d.Camion,
            Caja:d.Caja,
            Guia:d.Guia,
            FolioRemision:d.FolioRemision,
            FolioCaades:d.FolioCaades,
            FolioCertificado:d.FolioCertificado,
            GuiaSanitaria:d.GuiaSanitaria,
            PlacasCamion:d.PlacasCamion,
            PlacasCaja:d.PlacasCaja,
            ColorCamion:d.ColorCamion,
            Observaciones:d.Observaciones,    
            MarcaCamion:d.MarcaCamion,
            ModeloCamion:d.ModeloCamion,
            PlacasCaja2:d.PlacasCaja2,
            Fletes:d.Fletes,
            Usuario : this.props.usuario.id
        };
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.setState({cargando:false, editando:false});
            this.cargarMovs();
        }
        else{
            this.setState({cargando:false});
            Swal.fire('Atencion',res,'error');
        }
        return res;
    }
    cancelarRem = () =>{
        this.setState({editandoRem:false});
    }
    guardarRem = async(s,cve,num,cte)  =>{
        const url = this.props.urlws + "/embarque/grabarRemision"; //?temp=${this.state.Temporada}&cveOrd=${this.state.mov.Cve}&numOrd=${this.state.mov.Num}&cultivo=${this.state.cultivo}&s=${s}`;        
        const rem = {
            Temporada : this.state.Temporada,
            ClaveOrden:this.state.mov.Cve,
            NumeroOrden:this.state.mov.Num,
            Clave:cve,
            Numero:num,
            Cliente:cte,
            Cultivo:this.state.cultivo,
            S:s
        };
        this.setState({cargando:true});
        const json = JSON.stringify(rem);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json
        });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res.substring(0,1) !== "E"){
            Swal.fire('Atencion','Datos guardados','success');
            this.setState({cargando:false, editando:false, editandoRem:false});
            this.cargarMovs();
        }
        else{
            this.setState({cargando:false});
            Swal.fire('Atencion',res,'error');
        }
        return res;
    }
    guardarDato = async(dato,metodo)  =>{
        const url = this.props.urlws +"/embarque/" + metodo;
        this.setState({cargando:true});
        dato.Usuario = this.props.usuario.id;
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.cargarCatalogosEmb();
        }
        else
            Swal.fire('Atencion',res,'error');
        return res;
    }
    render(){
        const estilo1 = { display: this.state.editando ? 'inline' : 'none' };
        const estilo2 = { padding:2, display: (this.state.editando || this.state.editandoRem) ? 'none' : 'inline' };
        const estilo3 = { display: this.state.editandoRem ? 'inline' : 'none' };
        return(
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo1}>
                    {this.state.editando?
                        <Captura urlws={this.props.urlws} orden={this.state.orden} height={400} urlws={this.props.urlws} cancelar={this.cancelar} guardar={this.guardar} temp={this.state.Temporada} lotes={this.state.lotes} choferes={this.state.choferes} camiones={this.state.camiones} cajas={this.state.cajas} transportistas={this.state.transportistas} destinos={this.state.destinos} guardarDato={this.guardarDato} estados={this.state.estados} vendedores={this.state.vendedores} />
                        :
                        null
                    }
                </div>

                <div style={estilo3}>
                    {this.state.editandoRem?
                        <Remision height={200} urlws={this.props.urlws} temp={this.state.Temporada} cultivo={this.state.cultivo} remision={this.state.remision} orden={this.state.mov} variedades={this.state.variedades} calidades={this.state.calidades} clientes={this.state.clientes} guardar={this.guardarRem} cancelar={this.cancelarRem} />
                        :
                        null
                    }
                </div>

                <div style={estilo2}>
                    <div className="row">
                        <div className="col-2">
                            <FormGroup>
                                <span>Temporada</span><br/>
                                <Input size="sm" type="select" name="Temporada" value={this.state.Temporada} onChange={this.onChange} style={{  fontSize:'12px'}}>
                                    <option key="0" value="">Especifique</option>
                                    {this.state.temporadas.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Tipo</span><br/>
                                <Input size="sm" type="select" name="clave" value={this.state.clave} onChange={this.onChange} style={{  fontSize:'12px'}}>
                                    <option key="0" value="?">Especifique</option>
                                    {this.state.tiposOrden.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Del dia</span><br/>
                                <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>al dia</span><br/>
                                <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col">
                            <FormGroup as={Col}>
                                <span>&nbsp;</span><br/>
                                <Button color='success' outline size="sm" onClick={this.cargarMovs}><img src="/imagenes/ok.png"/></Button>
                                {'  '}
                                <Button color='success' outline size="sm" onClick={this.nuevoMov}><img src="/imagenes/add.png"/></Button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button color='success' outline size="sm" onClick={this.imprimirEmbSeg}><img src="/imagenes/printer.png" alt="relacion para seguros" title="relacion de seguros"/></Button>
                                {'  '}
                                <Button color='success' outline size="sm" onClick={this.imprimirEmbAcum}><img src="/imagenes/printer.png" alt="acumulado de embarques" title="acumulado de embarques"/></Button>
                                &nbsp;&nbsp;Excel&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input size="sm" type="checkbox" name="excel" checked={this.state.excel} onChange={this.onChecked} />
                            </FormGroup>
                        </div>
                    </div>
                    <div style={{ height: 4 }}></div>
                    <MovsTable movs={this.state.ordenes} height={500} width={990} onRowClick={this.cargarDetalle} onRemClick={this.cargarRemision} imprimirRem={this.imprimirRem} imprimirRemCh={this.imprimirRemCh} imprimirCarta={this.imprimirCarta} imprimirCartaCte={this.imprimirCartaCte} imprimirCaades={this.imprimirCaades} imprimirOrdenCampo={this.imprimirOrdenCampo} imprimirCert={this.imprimirCert} enviar={this.enviar} capturarPeso={this.capturarPeso} cerrar={this.cerrar} />
                </div>                
                <Modal id="dlgPeso" name="dlgPeso" isOpen={this.state.mostrarModalPeso} toggle={this.handleClose} size="sm" tabIndex={1}>
                    <ModalHeader>
                        <span>Pesaje</span>
                    </ModalHeader>
                    <ModalBody>
                        <div className="col">                            
                            <FormGroup>
                                <span>Folio bascula</span>
                                <Input size="sm" type="text" name="pfolio" value={this.state.pfolio} onChange={this.onChangePeso} />
                            </FormGroup>
                        </div>
                        <div className="col">
                            <FormGroup>
                                <span>Peso Bruto</span>
                                <Input size="sm" type="text" name="pbruto" value={this.state.pbruto} onChange={this.onChangePeso} />
                            </FormGroup>
                        </div>
                        <div className="col">
                            <FormGroup>
                                <span>Peso Tara</span>
                                <Input size="sm" type="text" name="ptara" value={this.state.ptara} onChange={this.onChangePeso} />
                            </FormGroup>
                        </div>
                        <div className="col">
                            <FormGroup>
                                <span>Peso Neto</span>
                                <Input size="sm" type="text" name="pneto" value={this.state.pneto} onChange={this.onChangePeso} />
                            </FormGroup>
                        </div>
                        <div className="col">
                            <FormGroup>
                                <span>Precio x Tonelada</span>
                                <Input size="sm" type="text" name="pton" value={this.state.pton} onChange={this.onChangePeso} />
                            </FormGroup>
                        </div>
                        <div className="col">
                            <FormGroup>
                                <span>Sello</span>
                                <Input size="sm" type="text" name="sello" value={this.state.sello} onChange={this.onChangePeso} />
                            </FormGroup>
                        </div>
                        <div className="col">
                            <FormGroup>
                                {
                                !this.state.mov.Cerrada &&
                                <Button onClick={this.guardarPesaje} color="success" size="sm">Guardar</Button>
                                }
                            </FormGroup>
                        </div>
                    </ModalBody>
                </Modal>
            </Container>
        )
    }
}
class Captura extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            mostrarModalTra:false,
            mostrarModalCho:false,
            mostrarModalCam:false,
            mostrarModalCaj:false,
            mostrarModalDes:false,
            mostrarModalCom:false,
            ...this.props.orden,
            camiones:[],
            choferes:[],
            transportistas:[],
        };
        this.cargarTransportistas();
        this.cargarChoferes(this.props.orden.Transportista);
    }
    componentWillReceiveProps(newProps){
        this.setState({
            ...newProps.orden
        },()=> this.cargarChoferes(newProps.orden.Transportista));
    }
    cargarChoferes = async (trans) =>{
        let url = `${this.props.urlws}/embarque/choferes/${trans}`;
        let resp = await fetch(url);
        const choferes = await resp.json();
        this.setState({choferes});
    }
    cargarTransportistas = async (trans) =>{
        let url = `${this.props.urlws}/embarque/transportistas/`;
        let resp = await fetch(url);
        const transportistas = await resp.json();
        this.setState({transportistas});
    }
    cargarChofer = async () =>{
        let url = `${this.props.urlws}/embarque/cargarChofer/${this.state.Chofer}`;
        let resp = await fetch(url);
        let {PlacasCamion} = this.state;
        let {PlacasCaja} = this.state;
        let {PlacasCaja2} = this.state;
        let {MarcaCamion} = this.state;
        let {ModeloCamion} = this.state;
        let {ColorCamion} = this.state;
        const chofer = await resp.json();
        if(chofer.Id !== 0){
            if(chofer.PlacasCamion !== "")
               PlacasCamion = chofer.PlacasCamion;
            if(chofer.PlacasCaja !== "")
               PlacasCaja = chofer.PlacasCaja;
            if(chofer.PlacasCaja2 !== "")
               PlacasCaja2 = chofer.PlacasCaja2;
            if(chofer.ColorCamion !== "")
               ColorCamion = chofer.ColorCamion;
            if(chofer.MarcaCamion !== "")
               MarcaCamion = chofer.MarcaCamion;
            if(chofer.ModeloCamion !== "")
               ModeloCamion = chofer.ModeloCamion;
        }
        this.setState({ModeloCamion, MarcaCamion, PlacasCamion, PlacasCaja, PlacasCaja2, ColorCamion});
    }
    cargarCaja = async () =>{
        let url = `${this.props.urlws}/embarque/cargarCamion/${this.state.Caja}`;
        let resp = await fetch(url);
        let {PlacasCaja} = this.state;
        const camion = await resp.json();
        if(camion.Id !== 0){
           PlacasCaja = camion.Placas;
        }
        this.setState({PlacasCaja});
    }
    onChange = (e) => {
        if(this.props.orden.Cerrada)
           return;
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor},()=>{
            if(campo === "Transportista")
                this.cargarChoferes(valor);
            if(campo === "Chofer" && this.state.FolioRemision === 0)
                this.cargarChofer();
            if(campo === "Caja")
                this.cargarCaja();
        });
    }
    handleClose = () =>{
        this.setState({mostrarModalTra:false,mostrarModalCam:false, mostrarModalCaj:false, mostrarModalCho:false, mostrarModalDes:false, mostrarModalCom:false});
    }
    nuevoTransportista = () =>{
        this.cargarTransportistas();
        //this.setState({mostrarModalTra:true});
    }
    nuevoChofer = () =>{
        this.cargarChoferes();
        //this.setState({mostrarModalCho:true});
    }
    nuevoCamion = () =>{
        //this.setState({mostrarModalCam:true});
    }
    nuevaCaja = () =>{
        //this.setState({mostrarModalCaj:true});
    }
    nuevoDestino = () =>{
        //this.setState({mostrarModalDes:true});
    }
    guardarTransportista = async(obj) =>{
        let res = await this.props.guardarDato(obj,"grabarTransportista");
        if(res === "ok")
            this.setState({mostrarModalTra:false});
    }
    guardarChofer = async(obj) =>{
        let res = await this.props.guardarDato(obj,"grabarChofer");
        if(res === "ok")
            this.setState({mostrarModalCho:false});
    }
    guardarCamion = async(obj) =>{
        let res = await this.props.guardarDato(obj,"grabarCamion");
        if(res === "ok")
            this.setState({mostrarModalCam:false});
    }
    guardarCaja = async(obj) =>{
        let res = await this.props.guardarDato(obj,"grabarCamion");
        if(res === "ok")
            this.setState({mostrarModalCaj:false});
    }
    guardarDestino = async(obj) =>{
        let res = await this.props.guardarDato(obj,"grabarDestino");
        if(res === "ok")
            this.setState({mostrarModalDes:false});
    }
    guardar = () =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.guardar(this.state);
            }
          })        
    }
    render(){
        if(Object.keys(this.props.orden).length === 0)
            return null;
        return(
            <Container>
                <div className="row">
                    <div className="col-1">
                        <FormGroup>
                            <span>No.Orden</span>
                            <Input size="sm" type="text" id="Numero" name="Numero" value={this.state.Numero} />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup>
                            <span>Fecha</span>
                            <Input type="date" size="sm" id="Fecha" name="Fecha" value={this.state.Fecha} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <span>Lote</span>
                            <InputGroup>
                                <Input size="sm" type="select" name="Lote" id="Lote" value={this.state.Lote} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>
                                    {this.props.lotes.map((i, k) => (
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </InputGroup>
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup>
                            <span>Vendedor</span>
                                <Input size="sm" type="select" name="Vendedor" id="Vendedor" value={this.state.Vendedor} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>
                                    {this.props.vendedores.map((i, k) => (
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                        </FormGroup>
                    </div>
                    <div className="col">
                        <FormGroup>
                            <span>Destino</span>
                            <InputGroup>
                                <Input size="sm" type="select" name="Destino" id="Destino" value={this.state.Destino} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>
                                    {this.props.destinos.map((i, k) => (
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                                <InputGroupAddon addonType="prepend"><Button outline size="sm" color="success" onClick={this.nuevoDestino}>+</Button></InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <FormGroup>
                            <span>Transportista</span>
                            <InputGroup>
                                <Input size="sm" type="select" name="Transportista" id="Transportista" value={this.state.Transportista} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>
                                    {this.state.transportistas.map((i, k) => (
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                                <InputGroupAddon addonType="prepend"><Button outline size="sm" color="success" onClick={this.nuevoTransportista}>+</Button></InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup>
                            <span>Guia</span>
                            <Input type="text" size="sm" id="Guia" name="Guia" value={this.state.Guia} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col">
                        <FormGroup>
                            <span>Chofer</span>
                            <InputGroup>
                                <Input size="sm" type="select" name="Chofer" id="Chofer" value={this.state.Chofer} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>
                                    {this.state.choferes.map((i, k) => (
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                                <InputGroupAddon addonType="prepend"><Button outline size="sm" color="success" onClick={this.nuevoCamion}>+</Button></InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-2">
                        <FormGroup>
                            <span>Marca</span>
                            <Input size="sm" type="text" id="MarcaCamion" name="MarcaCamion" value={this.state.MarcaCamion} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup>
                            <span>Modelo</span>
                            <Input size="sm" type="text" id="ModeloCamion" name="ModeloCamion" value={this.state.ModeloCamion} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup>
                            <span>Color</span>
                            <Input size="sm" type="text" id="ColorCamion" name="ColorCamion" value={this.state.ColorCamion} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup>
                            <span>Placas</span>
                            <Input size="sm" type="text" id="PlacasCamion" name="PlacasCamion" value={this.state.PlacasCamion} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup>
                            <span>Placas Caja</span>
                            <Input size="sm" type="text" id="PlacasCaja" name="PlacasCaja" value={this.state.PlacasCaja} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup>
                            <span>Placas Caja 2</span>
                            <Input size="sm" type="text" id="PlacasCaja2" name="PlacasCaja2" value={this.state.PlacasCaja2} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-1">
                        <FormGroup>
                            <span>Arpillas</span>
                            <Input size="sm" type="text" id="Arpillas" name="Arpillas" value={this.state.Arpillas} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col">
                        <FormGroup>
                            <span>Condiciones</span>
                            <Input size="sm" type="text" id="Condiciones" name="Condiciones" value={this.state.Condiciones} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col-1">
                        <FormGroup>
                            <span>Folio Rem.</span>
                            <Input type="text" size="sm" id="FolioRemision" name="FolioRemision" value={this.state.FolioRemision} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup>
                            <span>Folio Caades</span>
                            <Input type="text" size="sm" id="FolioCaades" name="FolioCaades" value={this.state.FolioCaades} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col-1">
                        <FormGroup>
                            <span>Folio Cert.</span>
                            <Input type="text" size="sm" id="FolioCertificado" name="FolioCertificado" value={this.state.FolioCertificado} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup>
                            <span>Guia Sanitaria</span>
                            <Input type="text" size="sm" id="GuiaSanitaria" name="GuiaSanitaria" value={this.state.GuiaSanitaria} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <FormGroup>
                            <span>Observaciones</span>
                            <Input type="textarea" rows="8" size="sm" id="Observaciones" name="Observaciones" value={this.state.Observaciones} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    { 
                    !this.props.orden.Cerrada &&
                    <Button size="sm" color="success" onClick={this.guardar}>Guardar</Button>
                    }
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <Button size="sm" color="danger" onClick={this.props.cancelar}>Cancelar</Button>
                </div>

                <Modal id="dlgCom" name="dlgCom" isOpen={this.state.mostrarModalCom} toggle={this.handleClose} size="lg" tabIndex={1}>
                    <ModalHeader>
                        <span>Nuevo Comprador</span>
                    </ModalHeader>
                    <ModalBody>
                        <CapturaTransportista guardar={this.guardarComprador} cancelar={()=>{this.setState({mostrarModalCom:false});}} estados={this.props.estados}/>
                    </ModalBody>
                </Modal>
                <Modal id="dlgTra" name="dlgTra" isOpen={this.state.mostrarModalTra} toggle={this.handleClose} size="lg" tabIndex={1}>
                    <ModalHeader>
                        <span>Nuevo Transportista</span>
                    </ModalHeader>
                    <ModalBody>
                        <CapturaTransportista guardar={this.guardarTransportista} cancelar={()=>{this.setState({mostrarModalTra:false});}} estados={this.props.estados}/>
                    </ModalBody>
                </Modal>
                <Modal id="dlgCho" name="dlgCho" isOpen={this.state.mostrarModalCho} toggle={this.handleClose} size="lg" tabIndex={1}>
                    <ModalHeader>
                        <span>Nuevo Chofer</span>
                    </ModalHeader>
                    <ModalBody>
                        <CapturaTransportista guardar={this.guardarChofer} cancelar={()=>{this.setState({mostrarModalCho:false});}}  estados={this.props.estados}/>
                    </ModalBody>
                </Modal>
                <Modal id="dlgCam" name="dlgCam" isOpen={this.state.mostrarModalCam} toggle={this.handleClose} size="lg" tabIndex={1}>
                    <ModalHeader>
                        <span>Nuevo Camion</span>
                    </ModalHeader>
                    <ModalBody>
                        <CapturaCamion tipo='C' transportistas={this.props.transportistas} guardar={this.guardarCamion} cancelar={()=>{this.setState({mostrarModalCam:false});}} />
                    </ModalBody>
                </Modal>
                <Modal id="dlgCaj" name="dlgCaj" isOpen={this.state.mostrarModalCaj} toggle={this.handleClose} size="lg" tabIndex={1}>
                    <ModalHeader>
                        <span>Nueva Caja</span>
                    </ModalHeader>
                    <ModalBody>
                        <CapturaCamion tipo='J' transportistas={this.props.transportistas} guardar={this.guardarCaja} cancelar={()=>{this.setState({mostrarModalCaj:false});}} />
                    </ModalBody>
                </Modal>
                <Modal id="dlgDes" name="dlgDes" isOpen={this.state.mostrarModalDes} toggle={this.handleClose} size="lg" tabIndex={1}>
                    <ModalHeader>
                        <span>Nuevo Destino</span>
                    </ModalHeader>
                    <ModalBody>
                        <CapturaDestino estados={this.props.estados} guardar={this.guardarDestino} cancelar={()=>{this.setState({mostrarModalDes:false});}} />
                    </ModalBody>
                </Modal>
            </Container>
        )
    }
}
class CapturaTransportista extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            Nombre : '',
            Direccion : '',
            Ciudad : '',
            Estado : 'SIN',
            Telefono : '',
            Email : '',
            Clave:'',
        }
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor});
    }
    guardar = () =>{
        const obj = { ... this.state};
        this.props.guardar(obj);
    }
    cancelar = () =>{
        this.props.cancelar();
    }
    render(){
        return(
            <Container>
                <div className="row">
                    <div className="col">
                        <FormGroup>
                            <label>Nombre</label>
                            <Input size="sm" type="text" id="Nombre" name="Nombre" value={this.state.Nombre} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <FormGroup>
                            <label>Direccion</label>
                            <Input size="sm" type="text" name="Direccion" value={this.state.Direccion} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col">
                        <FormGroup>
                            <label>Ciudad</label>
                            <Input size="sm" type="text" name="Ciudad" value={this.state.Ciudad} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <label>Estado</label>
                            <InputGroup>
                                <Input size="sm" type="select" name="Estado" id="Estado" value={this.state.Estado} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>
                                    {this.props.estados.map((i, k) => (
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </InputGroup>
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <FormGroup>
                            <label>Telefono</label>
                            <Input size="sm" type="text" name="Telefono" value={this.state.Telefono} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col">
                        <FormGroup>
                            <label>Email</label>
                            <Input size="sm" type="text" name="Email" value={this.state.Email} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col">
                        <FormGroup>
                            <label>Clave</label>
                            <Input size="sm" type="text" name="Clave" value={this.state.Clave} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <Button size="sm" color="success" onClick={this.guardar}>Aceptar</Button>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <Button size="sm" color="danger" onClick={this.cancelar}>Cancelar</Button>
                </div>
            </Container>
        )
    }
}
class CapturaCamion extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            Tipo : this.props.tipo,
            Nombre : '',
            Placas : '',
            Color : '',
            Modelo : '',
            Transportista : 0
        }
    }
    componentWillReceiveProps(newProps){
        this.setState({
            Tipo : newProps.tipo,
            Nombre : '',
            Placas : '',
            Color : '',
            Modelo : 'SIN',
            Transportista : 0
        });
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor});
    }
    guardar = () =>{
        const obj = { ... this.state};
        this.props.guardar(obj);
    }
    cancelar = () =>{
        this.props.cancelar();
    }
    render(){
        return(
            <Container>
                <div className="row">
                    <div className="col">
                        <FormGroup>
                            <label>Nombre</label>
                            <Input size="sm" type="text" id="Nombre" name="Nombre" value={this.state.Nombre} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col">
                        <FormGroup>
                            <label>Placas</label>
                            <Input size="sm" type="text" name="Placas" value={this.state.Placas} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    </div>
                <div className="row">
                    <div className="col">
                        <FormGroup>
                            <label>Color</label>
                            <Input size="sm" type="text" name="Color" value={this.state.Color} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col">
                        <FormGroup>
                            <label>Modelo</label>
                            <Input size="sm" type="text" name="Modelo" value={this.state.Modelo} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <label>Transportista</label>
                            <InputGroup>
                                <Input size="sm" type="select" name="Transportista" id="Transportista" value={this.state.Transportista} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>
                                    {this.props.transportistas.map((i, k) => (
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                                <InputGroupAddon addonType="prepend"><Button outline size="sm" color="success" onClick={this.nuevoTransportista}>+</Button></InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <Button size="sm" color="success" onClick={this.guardar}>Aceptar</Button>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <Button size="sm" color="danger" onClick={this.cancelar}>Cancelar</Button>
                </div>
            </Container>
        )
    }
}
class CapturaDestino extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            Nombre : '',
            Ciudad:'',
            Estado : 'SIN'
        }
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor});
    }
    guardar = () =>{
        const obj = { ... this.state};
        this.props.guardar(obj);
    }
    cancelar = () =>{
        this.props.cancelar();
    }
    render(){
        return(
            <Container>
                <div className="row">
                    <div className="col">
                        <FormGroup>
                            <label>Direccion</label>
                            <Input size="sm" type="text" id="Nombre" name="Nombre" value={this.state.Nombre} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <FormGroup>
                            <label>Ciudad</label>
                            <Input size="sm" type="text" id="Ciudad" name="Ciudad" value={this.state.Ciudad} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <label>Estado</label>
                            <InputGroup>
                                <Input size="sm" type="select" name="Estado" id="Estado" value={this.state.Estado} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>
                                    {this.props.estados.map((i, k) => (
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </InputGroup>
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <Button size="sm" color="success" onClick={this.guardar}>Aceptar</Button>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <Button size="sm" color="danger" onClick={this.cancelar}>Cancelar</Button>
                </div>
            </Container>
        )
    }
}

class MovsTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    onRemClick = (cve) =>{
        this.props.onRemClick(cve);
    }
    onPesoClick = (cve) =>{
        this.props.capturarPeso(cve);
    }
    render() {
        let { movs } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:70}}>Orden</th>
                        <th style={{width:110}}>Fecha</th>
                        <th style={{width:70}}>Remision</th>
                        <th style={{width:170}}>Vendedor</th>
                        <th style={{width:170}}>Chofer</th>
                        <th>Lote</th>
                        <th className="numero" style={{width:80}}>Arpillas</th>
                        <th style={{width:30}}></th>
                        <th style={{width:30}}></th>
                        <th style={{width:30}}></th>
                        <th style={{width:30}}></th>
                        <th style={{width:30}}></th>
                        <th style={{width:30}}></th>
                        <th style={{width:30}}></th>
                        <th style={{width:30}}></th>
                        <th style={{width:30}}></th>
                    </tr>
                </thead>
                <tbody>
                    {movs.map((o, i) => (
                        <MovsRow key={i} mov={o} onRowClick={this.onRowClick} onRemClick={this.onRemClick} onPesoClick={this.onPesoClick} imprimirRem={this.props.imprimirRem} imprimirRemCh={this.props.imprimirRemCh} imprimirCaades={this.props.imprimirCaades} imprimirOrdenCampo={this.props.imprimirOrdenCampo} imprimirCert={this.props.imprimirCert} imprimirCarta={this.props.imprimirCarta} imprimirCartaCte={this.props.imprimirCartaCte} cerrar={this.props.cerrar}/>
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}
class MovsRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.mov);
    }
    onRemClick = (e) =>{
        this.props.onRemClick(this.props.mov);
    }
    onPesoClick = (e) =>{
        this.props.onPesoClick(this.props.mov);
    }
    onRemChClick = (e) =>{
        this.props.imprimirRemCh(this.props.mov);
    }
    onImpRemClick = (e) =>{
        this.props.imprimirRem(this.props.mov);
    }
    onCartaClick = (e) =>{
        this.props.imprimirCarta(this.props.mov);
    }
    onCartaCteClick = (e) =>{
        this.props.imprimirCartaCte(this.props.mov);
    }
    onCaadesClick = (e) =>{
        this.props.imprimirCaades(this.props.mov);
    }
    onOrdenCampoClick = (e) =>{
        this.props.imprimirOrdenCampo(this.props.mov);
    }
    onCertClick = (e) =>{
        this.props.imprimirCert(this.props.mov);
    }
    cerrar = (e) =>{
        this.props.cerrar(this.props.mov);
    }
    render(){
        let {mov} = this.props;
        if(mov.Cancelada)
           return(
               <tr>
                   <td>{mov.Num}</td>
                   <td>{mov.Fec}</td>
                   <td></td>
                   <td><span className="rojo">CANCELADA</span></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
               </tr>
           )
        return (
        <tr>
            <td>{mov.Num}</td>
            <td>{mov.Fec}</td>
            <td>{mov.NumRem}</td>
            <td>{mov.Com}</td>
            <td>{mov.Chofer}</td>
            <td>{mov.Lote}</td>
            <td className="numero">{mov.Cant}</td>
            <td>
                <img src="/imagenes/edit.png" onClick={this.onClick} alt="editar" title="editar"/>            
            </td>
            <td>
                <img src="/imagenes/printer.png" onClick={this.onOrdenCampoClick} alt="orden de campo" title="orden de campo"/>
            </td>
            <td>
                <img src="/imagenes/camion.png" onClick={this.onPesoClick} alt="pesaje" title="pesaje"/>
            </td>
            <td>
                <img src="/imagenes/printer.png" onClick={this.onCartaClick} alt="carta chofer" title="carta chofer"/>
            </td>
            <td>
                <img src="/imagenes/printer.png" onClick={this.onImpRemClick} alt="remision cliente" title="remision cliente"/>
            </td>
            <td>
                <img src="/imagenes/printer.png" onClick={this.onCaadesClick} alt="caades" title="caades"/>
            </td>
            <td>
                <img src="/imagenes/printer.png" onClick={this.onCertClick} alt="certificado" title="certificado"/>
            </td>
            <td>
                <img src="/imagenes/printer.png" onClick={this.onCartaCteClick} alt="carta cliente" title="carta cliente"/>
            </td>
            <td>
                {
                    mov.Cerrada ?
                    <span></span>
                    :
                    <img src="/imagenes/candado.png" alt="cerrar embarque" title="cerrar embarque" onClick={this.cerrar}/>
                }
            </td>
        </tr>
        );
    }
}

class Remision extends React.Component{
    constructor(props){
        super(props);
        let total = 0;
        let totalKgs = 0;
        const detalle = this.props.remision.Detalle;
        for (let i = 0; i < detalle.length; i++){
           total += detalle[i].Cant;
           totalKgs += detalle[i].Kilos;
        }
        this.state = {
            total : Number(total),
            totalKgs : Number(totalKgs),
            series:[],
            serie:this.props.orden.CveRem,
            numero:this.props.orden.NumRem,
            clientes:this.props.clientes,
            cliente:this.props.remision.Cliente,
            variedad:0,
            calidad:0,
            cantidad:0,
            precio:0,
            kilos:0,
            kgsDescto:0,
            indice : -1,
            mostrarModal:false,
            accionRenglon:'',
            detalle
        };
        this.cargarSeries();
    }
    cargarSeries = async () => {
        const url = `${this.props.urlws}/embarque/series/${this.props.cultivo}`;
        const resp = await fetch(url);
        const series = await resp.json();
        this.setState({series },() => this.ponNumero());
    }
    cargarClientes = async () => {
        const url = `${this.props.urlws}/cliente/lista?filtro=`;
        const resp = await fetch(url);
        const clientes = await resp.json();
        this.setState({clientes });
    }

    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor},() =>{
            if(campo === "serie")
               this.ponNumero();
        });
    }
    ponNumero = () =>{
        console.log(this.state.serie);
        console.log(this.state.numero);
        console.log(this.state.series);
        if(this.state.numero !== 0 && this.state.numero !== "")
           return;
        for (let i = 0; i < this.state.series.length; i++) 
            if(this.state.series[i].N === this.state.serie)
               this.setState({numero : this.state.series[i].I});
    }
    agregarRenglon = () =>{
        if(this.state.variedad === 0 || this.state.calidad === 0)
           return;
        if(this.state.indice >= 0){
            this.actualizaRenglon();
            return;
        }
        let nomV = '';
        let nomC = '';
        for (let i = 0; i < this.props.variedades.length; i++) {
           if(this.props.variedades[i].I == this.state.variedad)
              nomV = this.props.variedades[i].N;
        }
        for (let i = 0; i < this.props.calidades.length; i++) {
            if(this.props.calidades[i].I == this.state.calidad)
                nomC = this.props.calidades[i].N;
            }
        const cant = parseInt(this.state.cantidad);
        const kgs = parseInt(this.state.kilos);
        const kgsD = Number(this.state.kgsDescto);
        const ren = {
            Var : this.state.variedad,
            Cal : this.state.calidad,
            Cant : cant,
            NomV: nomV,
            NomC : nomC,
            Kilos: kgs,
            KgsDescto:kgsD,
            Pre:this.state.precio
        };
        this.state.detalle.push(ren);
        this.state.total = Number(this.state.total) + cant;
        this.state.totalKgs = Number(this.state.totalKgs) + kgs;
        this.setState({calidad:0, cantidad:0, kilos:0, kgsDescto:0, indice : -1});
    }
    actualizaRenglon = () =>{
        let i = this.state.indice;
        let nomV = '';
        let nomC = '';
        for (let i = 0; i < this.props.variedades.length; i++) {
           if(this.props.variedades[i].I == this.state.variedad)
              nomV = this.props.variedades[i].N;
        }
        for (let i = 0; i < this.props.calidades.length; i++) {
            if(this.props.calidades[i].I == this.state.calidad)
                nomC = this.props.calidades[i].N;
            }
        const cant = parseInt(this.state.cantidad);
        const kgs = parseInt(this.state.kilos);
        const kgsD = Number(this.state.kgsDescto);

        this.state.total = this.state.total + cant - this.state.detalle[i].Cant;
        this.state.totalKgs = this.state.totalKgs + kgs - this.state.detalle[i].Kilos;

        this.state.detalle[i].Var = this.state.variedad;
        this.state.detalle[i].Cal = this.state.calidad;
        this.state.detalle[i].Pre = this.state.precio;
        this.state.detalle[i].Cant = cant;
        this.state.detalle[i].Kilos = kgs;
        this.state.detalle[i].KgsDescto = kgsD;
        this.state.detalle[i].NomV = nomV;
        this.state.detalle[i].NomC = nomC;
        this.setState({mostrarModal:false, calidad:0, cantidad:0, indice : -1});
    }
    quitarRenglon = (i) =>{
        let total = 0;
        let totalKgs = 0;
        this.state.detalle.splice(i, 1);
        for (let i = 0; i < this.state.detalle.length; i++) {
            total += this.state.detalle[i].Cant;
            totalKgs += this.state.detalle[i].Kilos;
         } 
        this.setState({total, totalKgs, indice : -1});
    }
    nuevoRenglon = () =>{
        this.state.variedad = 0;
        this.state.calidad = 0;
        this.state.precio = 0;
        this.state.cantidad = 0;
        this.state.kilos = 0;
        this.state.kgsDescto = 0;
        this.state.accionRenglon = "Nuevo renglon"
        this.setState({indice:-1,mostrarModal : true});
    }
    editarRenglon = (k) =>{
        let i = k.i;
        let det = this.state.detalle;
        this.state.variedad = det[i].Var;
        this.state.calidad = det[i].Cal;
        this.state.precio = det[i].Pre;
        this.state.cantidad = det[i].Cant;
        this.state.kilos = det[i].Kilos;
        this.state.kgsDescto = det[i].KgsDescto;
        this.state.accionRenglon = "Editando renglon"
        this.setState({indice:i,mostrarModal : true});
    }
    agregar = () =>{
        this.setState({mostrarModal : true});
    }
    handleClose = () =>{
        this.setState({mostrarModal:false});
    }
    guardar = () =>{
        let s = JSON.stringify(this.state.detalle);
        this.props.guardar(s,this.state.serie,this.state.numero,this.state.cliente);
    }
    render(){
       console.log(this.props.orden);
        return(
            <Container>
                <br/>
                <div className="row">
                    <div className="col-1">
                        <FormGroup>
                            <span>Orden</span>
                            <Input size="sm" type="text" value={this.props.orden.Num} readOnly/>
                        </FormGroup>
                    </div>
                    <div className="col-1">
                        <FormGroup>
                            <span>Serie</span>
                            <Input size="sm" type="select" name="serie" value={this.state.serie} onChange={this.onChange} >
                                    <option key="0" value="">?</option>
                                    {this.state.series.map((i, k) =>
                                        <option key={i.N} value={i.N}>{i.N}</option>
                                    )}
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup>
                            <span>Remision</span>
                            <Input  size="sm" type="text" name="numero" value={this.state.numero} onChange={this.onChange}/>
                        </FormGroup>
                    </div>
                    <div className="col">
                        <FormGroup>
                            <span>Remisionado a</span>
                            <InputGroup>
                                <Input size="sm" type="select" name="cliente" id="cliente" value={this.state.cliente} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>
                                    {this.state.clientes.map((i, k) => (
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                                <InputGroupAddon addonType="prepend"><Button outline size="sm" color="success" onClick={this.cargarClientes}>+</Button></InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    </div>
                </div>
                <div style={{ height: this.props.height, width: this.props.width, overflow: 'auto' }}>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Variedad</th>
                                <th>Calidad</th>
                                <th className="numero" style={{ width: 80 }}>Arpillas</th>
                                <th className="numero" style={{ width: 80 }}>Kilos</th>
                                <th className="numero" style={{ width: 80 }}>Precio Kg.</th>
                                <th style={{ width: 30 }}></th>
                                <th style={{ width: 30 }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.detalle.map((o, i) => (
                                <tr key={i}>
                                    <td>{o.NomV}</td>
                                    <td>{o.NomC}</td>
                                    <th className="numero">{o.Cant}</th>
                                    <th className="numero">{o.Kilos}</th>
                                    <th className="numero">{o.Pre}</th>
                                    <td style={{ width: 30 }}>
                                        <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={()=>{ this.editarRenglon({i});}}/>
                                    </td>
                                    <td style={{ width: 30 }}>
                                        <img src="/imagenes/quitar.png" alt="eliminar" title="eliminar" onClick={()=>{ this.quitarRenglon({i});}}/>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th className="numero" style={{ width: 80, color:'red' }}>{this.state.total}</th>
                            <th className="numero" style={{ width: 80, color:'red' }}>{this.state.totalKgs}</th>
                            <th className="numero" style={{ width: 80, color:'red' }}></th>
                            <th style={{ width: 60 }}>
                                { /* <Button onClick={this.agregar} color="success" size="sm">+</Button> */}
                            </th>
                        </tr>
                    </thead>
                </Table>
                <Button outline onClick={this.nuevoRenglon} color="success" size="sm"><img src="/imagenes/add.png"/></Button>
                <span>&nbsp;&nbsp;&nbsp;</span>
                <Button onClick={this.guardar} color="success" size="sm">Guardar</Button>
                <span>&nbsp;&nbsp;&nbsp;</span>
                <Button onClick={this.props.cancelar} color="danger" size="sm">Cancelar</Button>

                <Modal id="dlgCom" name="dlgCom" isOpen={this.state.mostrarModal} toggle={this.handleClose} tabIndex={1}>
                    <ModalHeader>
                        <h4>{this.state.accionRenglon}</h4>
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-6">
                                <FormGroup>
                                    <label>Variedad</label>
                                    <InputGroup>
                                        <Input size="sm" type="select" name="variedad" id="variedad" value={this.state.variedad} onChange={this.onChange}  >
                                            <option key={-1} value={-1}>Especifique</option>
                                            {this.props.variedades.map((i, k) => (
                                                <option key={i.I} value={i.I}>{i.N}</option>
                                            ))}
                                        </Input>
                                    </InputGroup>
                                </FormGroup>
                            </div>
                            <div className="col-6">
                                <FormGroup>
                                    <label>Calidad</label>
                                    <InputGroup>
                                        <Input size="sm" type="select" name="calidad" id="calidad" value={this.state.calidad} onChange={this.onChange}  >
                                            <option key={-1} value={-1}>Especifique</option>
                                            {this.props.calidades.map((i, k) => (
                                                <option key={i.I} value={i.I}>{i.N}</option>
                                            ))}
                                        </Input>
                                    </InputGroup>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <FormGroup>
                                    <label>Arpillas</label>
                                    <Input size="sm" type="text" name="cantidad" value={this.state.cantidad} onChange={this.onChange} />
                                </FormGroup>
                            </div>
                            <div className="col-3">
                                <FormGroup>
                                    <label>Kilos</label>
                                    <Input size="sm" type="text" name="kilos" value={this.state.kilos} onChange={this.onChange} />
                                </FormGroup>
                            </div>
                            <div className="col-3">
                                <FormGroup>
                                    <label>Descto.Kgs.</label>
                                    <Input size="sm" type="text" name="kgsDescto" value={this.state.kgsDescto} onChange={this.onChange} />
                                </FormGroup>
                            </div>
                            <div className="col-3">
                                <FormGroup>
                                    <label>Precio por Kg</label>
                                    <Input size="sm" type="text" name="precio" value={this.state.precio} onChange={this.onChange} />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <span>&nbsp;&nbsp;&nbsp;</span>
                            <Button size="sm" color="success" onClick={this.agregarRenglon}>Aceptar</Button>
                        </div>
                    </ModalBody>
                </Modal>
            </Container>      
        )
    }
}