import React from 'react';
import { Form, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import Swal from 'sweetalert2'

export default class ReportesNom extends React.Component{
    constructor(props){
        let fecha = new Date().yyyymmdd('-');
        super(props);
        this.state = {
            empresa:2,
            cargando:false,
            fechaIni:fecha,
            fechaFin:fecha,
            empresas:[],
            reporte:-1,
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/empresa/empresasNomina`;
        let resp = await fetch(url);
        const empresas = await resp.json();
        this.setState({cargando:false, empresas });
    }
    onChecked = (e) => {
        const campo = e.target.name;
        const valor = e.target.checked;
        this.setState({ [campo] : valor});
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor});
    }
    imprimir = () =>{
        let {empresa} = this.state;
        let {fechaIni} = this.state;
        let {fechaFin} = this.state;
        let url = this.props.urlws;
        if(this.props.rpt === 'rptNomActFecDet'){
            let url = `${this.props.urlws}/nomina/detalleActDiariasFechaApp/${empresa}-${fechaIni}`;
            window.open(url, '', '');
        }
        if(this.props.rpt === 'rptNomActFecCon'){
            let url = `${this.props.urlws}/nomina/resumenActDiariasFechaApp/${empresa}-${fechaIni}`;
            window.open(url, '', '');
        }
    }
    render(){
        //if(this.state.deptoIni > 0 && (this.state.deptoIni === this.state.deptoFin))
        return (
            <container>
                <div className="row">
                    <div className="col-2"></div>
                    <div className="col-6">
                        <div className="row">
                            <div className="col-4">
                                <span size="sm">Del dia</span><br/>
                                <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                            </div>
                        </div>
                        <div className="col-6" style={{ padding: 10 }}>
                            <Button color="success" onClick={this.imprimir}>Imprimir</Button>
                        </div>
                    </div>
                </div>
            </container>
        )
    }
}
