import React from 'react';
import { Card,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader, Row } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class LiqGranos extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');
        this.state = {
            capturando:false,
            consulta:false,
            liquidacion:0,
            precio: 0,
            tipoCambio:0,
            precioContrato:0,
            total:0,
            cantidad:0,
            cargando:false,
            Temporada:this.props.temp,
            almacen:1,
            comprador:0,
            fechaIni:fecIni,
            fechaFin:fecFin,
            fechaLiq:fecFin,
            predial:0,
            peqProp:0,
            caades:0,
            proEduc:0,
            pieaffes:0,
            ligaEj:0,
            deducciones:0,
            anticipos:0,
            secado:0,
            coberturas:0,
            admon:0,
            bono:0,
            liquidar:0,
            contrato:0,
            liquidaciones:[],
            contratos:[],
            compradores:[],
            remisiones:[],
            temporadas:[]
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/agricola/temporadas`;
        let resp = await fetch(url);
        const temporadas = await resp.json();
        url = `${this.props.urlws}/granos/compradoresLiq`;
        resp = await fetch(url);
        const compradores = await resp.json();
        this.setState({cargando:false, compradores,temporadas});
    }
    cargarContratos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/granos/contratosCliente?temp=${this.state.Temporada}&cte=${this.state.comprador}`;
        let resp = await fetch(url);        
        const contratos = await resp.json();
        this.setState({cargando:false, contratos});
    }
    imprimir = (mov) =>{
        let params = '?cte=' + mov.Cte + '&temp=' + this.state.Temporada + '&num=' + mov.Num;
        let url = this.props.urlws + '/granos/imprimirLiquidacion' + params;
        window.open(url, '', '');
    }
    iniciar = (liq) => {
        let datos = {
            liquidacion:0,
            precio: 0,
            tipoCambio:0,
            precioContrato:0,
            total:0,
            cantidad:0,
            predial:0,
            peqProp:0,
            caades:0,
            proEduc:0,
            pieaffes:0,
            ligaEj:0,
            deducciones:0,
            anticipos:0,
            secado:0,
            coberturas:0,
            admon:0,
            bono:0,
            liquidar:0,
            contrato:0
        };
        if(liq.Numero > 0){
            datos = {
                cantidad:liq.Remisiones,
                precio: liq.Precio,
                precioContrato:liq.PrecioContrato,
                liquidacion:liq.Numero,
                contrato:liq.Contrato,
                fechaLiq:liq.Fecha,
                tipoCambio:liq.TipoCambio,
                total:liq.Aportaciones,
                predial:liq.Predial,
                peqProp:liq.PeqProp,
                caades:liq.Caades,
                proEduc:liq.ProEduc,
                pieaffes:liq.Pieaffes,
                ligaEj:liq.LigaEj,
                deducciones:liq.Deducciones,
                anticipos:liq.Anticipo,
                secado:liq.Secado,
                coberturas:liq.Coberturas,
                admon:liq.Admon,
                bono:liq.Bono,
                liquidar:liq.Total
            };    
        }
        this.setState({...datos});
    }
    cargarMovs = async () =>{
        if(this.state.Temporada === ''){
            Swal.fire('Atencion','Especifique la temporada','error');
            return;
        }
        if(this.state.almacen == 0){
            Swal.fire('Atencion','Especifique el almacen','error');
            return;
        }
        const temp = this.state.Temporada;
        const comprador = this.state.comprador;
        const fecIni = this.state.fechaIni;
        const fecFin = this.state.fechaFin;
        this.setState({cargando:true});
        let url = `${this.props.urlws}/granos/liquidaciones?fecIni=${fecIni}&fecFin=${fecFin}`;
        let resp = await fetch(url);
        const liquidaciones = await resp.json();
        this.setState({cargando:false,liquidaciones });
    }
    editar = (mov) =>{
        this.setState({comprador:mov.Cte}, () => this.nuevaLiqB(mov.Num));
    }
    nuevaLiq = async() =>{
        if(this.state.Temporada === ''){
            Swal.fire('Atencion','Especifique la temporada','error');
            return;
        }
        if(this.state.almacen == 0){
            Swal.fire('Atencion','Especifique el almacen','error');
            return;
        }
        if(this.state.comprador == 0){
            Swal.fire('Atencion','Especifique el comprador','error');
            return;
        }
        const { value: numLiq } = await Swal.fire({
          title: 'Nueva liquidacion',
          titleText:"Folio de la liquidacion",
          input: 'text',
          inputValue: '',
          showCancelButton: true,
          inputValidator: (value) => {
            if (!value) {
              return 'Especifica el folio de la liquidacion'
            }
          }
        })
        
        if (numLiq) {
            let valor = Number(numLiq);
            if(Number.isNaN(valor)){
               Swal.fire('Atencion','Dato incorrecto','error');
               return;
            }
            if(valor == 0){
                Swal.fire('Atencion','Dato incorrecto','error');
                return;
             }
            this.nuevaLiqB(valor);
       }        
    }    
  
    nuevaLiqB = async (num) =>{
        let numLiq = num;
        const temp = this.state.Temporada;
        const comprador = this.state.comprador;
        const fecIni = this.state.fechaIni;
        const fecFin = this.state.fechaFin;
        this.setState({cargando:true});
        let url = `${this.props.urlws}/granos/cargarLiquidacion?cte=${comprador}&temp=${temp}&num=${num}`;
        let resp = await fetch(url);
        const liq  = await resp.json();
        numLiq = liq.Numero; // si es nueva numLiq quedara en cero y cargara las rems pendientes de liq.
        const consulta = numLiq > 0;
        url = `${this.props.urlws}/granos/remisionesLiq/${comprador}?fecIni=${fecIni}&fecFin=${fecFin}&liq=${numLiq}`;
        resp = await fetch(url);
        const remisiones = await resp.json();
        this.iniciar(liq);
        this.setState({liquidacion:num,consulta, cargando:false,capturando:true, remisiones });
    }
    cancelar = ()=>{
        if(this.state.consulta){
            this.setState({capturando:false, consulta: false});
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea cancelar la captura ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.setState({capturando:false, consulta: false});
            }
          })        
    }
    eliminar = () =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea eliminar esta liquidacion ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.eliminarB();
            }
          })        
    }
    eliminarB = async() =>{
        const comprador = this.state.comprador;
        const temp = this.state.Temporada;
        const num = this.state.liquidacion;
        let url = `${this.props.urlws}/granos/eliminarLiquidacion?cte=${comprador}&temp=${temp}&num=${num}`;
        this.setState({cargando:true});
        const resp = await fetch(url,{
            method: 'POST',
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Se elimino la liquidacion ' + num,'success');
            this.setState({capturando:false, consulta: false});
        }
        else
            Swal.fire('Atencion',res,'error');
    }

    guardar = () =>{
        let n = 0;
        let rems = "";
        let sepa = "";
        for (let i = 0; i < this.state.remisiones.length; i++) {
            if(this.state.remisiones[i].Sel){
                rems += sepa + this.state.remisiones[i].Num;
                sepa = ",";
                n++;
            }            
        }
        if(this.state.liquidacion == 0){
            Swal.fire('Atencion','No se especifico el numero de liquidacion','error');
            return;
        }
        if(this.state.comprador == 0){
            Swal.fire('Atencion','No se especifico el comprador','error');
            return;
        }
        if(n == 0){
            Swal.fire('Atencion','No se selecciono ninguna boleta/remision','error');
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar esta liquidacion ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.guardarB(rems);
            }
          })        
    }
    guardarB = async (rems) => {
        const mov = {
            Temporada :this.state.Temporada,
            Cliente : this.state.comprador,
            Numero : this.state.liquidacion,
            Fecha :this.state.fechaLiq,
            Contrato :this.state.contrato,
            Predial:this.state.predial,
            PeqProp:this.state.peqProp,
            Caades:this.state.caades,
            ProEduc:this.state.proEduc,
            Pieaffes:this.state.pieaffes,
            LigaEj:this.state.ligaEj,
            Anticipo:this.state.anticipos,
            Secado:this.state.secado,
            Coberturas:this.state.coberturas,
            Admon:this.state.admon,
            Bono:this.state.bono,
            Deducciones:this.state.deducciones,
            Aportaciones:this.state.total,
            Total:this.state.liquidar,
            TipoCambio:this.state.tipoCambio,
            Usuario:this.props.usuario.id,
            PrecioContrato:this.state.precioContrato,
            Precio:this.state.precio,
            Remisiones:this.state.cantidad,
            serie:'M',
            rems
        };
        const url = this.props.urlws +"/granos/grabarLiquidacion";
        this.setState({cargando:true});
        const json = JSON.stringify(mov);
        const resp = await fetch(url,{
            method: 'POST',
            body:json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.setState({capturando:false, consulta: false});
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    checaTC = ()=>{
        let total = 0;
        let precioAnt = this.state.precio;
        let precio = Number(this.state.precioContrato * Number(this.state.tipoCambio)).roundToTwo();
        if(precioAnt != precio){
            for (let i = 0; i < this.state.remisiones.length; i++) {
                if(this.state.remisiones[i].Sel){
                    this.state.remisiones[i].Imp = Number(this.state.remisiones[i].P * precio / 1000).roundToTwo();
                    total += Number(this.state.remisiones[i].Imp);
                }            
            }    
            let liquidar = total - Number(this.state.deducciones) - Number(this.state.anticipos) - Number(this.state.secado) - Number(this.state.coberturas) - Number(this.state.admon) + Number(this.state.bono);
            this.setState({precio, total, liquidar});    
        }
    }
    checaPrecioContrato = () =>{
        let precioContrato = Number(this.state.precioContrato);
        for (let i = 0; i < this.state.contratos.length; i++) {
            if(this.state.contratos[i].Id == this.state.contrato){
                precioContrato = this.state.contratos[i].Precio;
                this.setState({precioContrato},()=>{
                    this.checaTC();
                })
            }
        }    
    }
    onChange = (e) => {
        if(this.state.consulta)
           return;
        const campo = e.target.name;
        const valor = e.target.value;
        console.log(campo);
        console.log(valor);
        this.setState({ [campo] : valor},()=>{
            if(campo === 'comprador')
               this.cargarContratos();
            if(campo === 'contrato')
               this.checaPrecioContrato();
            if(campo === 'precioContrato')
               this.checaTC();
        });
    }    
    onChangeDed = (e) => {
        if(this.state.consulta)
           return;
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor},()=>{
            let deducciones = Number(this.state.predial) + Number(this.state.peqProp) + Number(this.state.caades) + Number(this.state.proEduc) + Number(this.state.pieaffes);
            let liquidar = Number(this.state.total) - deducciones - Number(this.state.anticipos) - Number(this.state.secado) - Number(this.state.coberturas) - Number(this.state.admon) + Number(this.state.bono);
            this.setState({deducciones,liquidar});
        });
    }    
    onChangeLiq = (e) => {
        if(this.state.consulta)
           return;
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor},()=>{
            let liquidar = Number(this.state.total) - Number(this.state.deducciones) - Number(this.state.anticipos) - Number(this.state.secado) - Number(this.state.coberturas) - Number(this.state.admon) + Number(this.state.bono);
            this.setState({liquidar});
        });
    }    
    seleccionar = (i,v) =>{
        if(this.state.consulta)
           return;
        let {total} = this.state;
        let {cantidad} = this.state;
        total -= Number(this.state.remisiones[i].Imp);
        if(v)
            cantidad++;
        else
            cantidad--;
        this.state.remisiones[i].Sel = v;
        this.state.remisiones[i].Imp = v ? this.state.remisiones[i].P * this.state.precio / 1000 : 0;
        total += Number(this.state.remisiones[i].Imp);
        let liquidar = total - Number(this.state.deducciones) - Number(this.state.anticipos) - Number(this.state.secado) - Number(this.state.coberturas) - Number(this.state.admon) + Number(this.state.bono);
        this.setState({total,cantidad, liquidar});
    }
    render(){
        const estilo1 = { display: this.state.capturando ? 'inline' : 'none' };
        const estilo2 = { padding:2, display: this.state.capturando ? 'none' : 'inline' };
        return(
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo2}>
                    <div className="row">
                        <div className="col-1">
                            <FormGroup>
                                <span>Temporada</span><br />
                                <Input size="sm" type="select" name="Temporada" value={this.state.Temporada} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                    <option key="0" value="">Especifique</option>
                                    {this.state.temporadas.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Del dia</span><br />
                                <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>al dia</span><br />
                                <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-4">
                            <FormGroup>
                                <span>Bodega</span><br />
                                <Input size="sm" type="select" name="comprador" value={this.state.comprador} onChange={this.onChange}>
                                    <option key="0" value={0}>Todos</option>
                                    {this.state.compradores.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col">
                            <FormGroup as={Col}>
                                <span>&nbsp;</span><br />
                                <Button color='success' size="sm" onClick={this.cargarMovs}>Cargar</Button>
                                &nbsp;
                                <Button color='success' size="sm" onClick={this.nuevaLiq}>+</Button>
                            </FormGroup>
                        </div>
                    </div>
               </div>
               <div> 
                    <div style={estilo2}>
                        <MovsTable movs={this.state.liquidaciones} height={500} width={990} onRowClick={this.editar} imprimir={this.imprimir} />
                    </div> 
                    <Container style={estilo1}>
                        <Row>
                        <div className="col-2">
                                <FormGroup>
                                    <span>Liquidacion</span><br />
                                    <Input size="sm" name="liquidacion" value={this.state.liquidacion} readOnly />
                                </FormGroup>
                                <FormGroup>
                                    <span>Fecha</span><br />
                                    <Input size="sm" type="date" name="fechaLiq" value={this.state.fechaLiq} onChange={this.onChange} />
                                </FormGroup>
                                <FormGroup>
                                    <span>Contrato</span><br />
                                    <Input size="sm" type="select" name="contrato" value={this.state.contrato} onChange={this.onChange}>
                                        <option key="0" value="">Especifique</option>
                                        {this.state.contratos.map((i, k) =>
                                            <option key={i.Id} value={i.Id}>{i.Contrato}</option>
                                        )}
                                    </Input>
                                    <FormGroup>
                                        <span>Precio contrato</span><br />
                                        <Input size="sm" name="precioContrato" value={this.state.precioContrato} onChange={this.onChange} onBlur={this.checaTC}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <span>Tipo de cambio</span><br />
                                        <Input size="sm" name="tipoCambio" value={this.state.tipoCambio} onChange={this.onChange} onBlur={this.checaTC} />
                                    </FormGroup>
                                    <FormGroup>
                                        <span>Precio a liquidar</span><br />
                                        <Input size="sm" name="precio" value={this.state.precio.formato()} />
                                    </FormGroup>
                                    <br/>
                                    {this.state.consulta &&
                                    <Button color='danger' size="sm" onClick={this.eliminar}>Eliminar Liquidacion</Button>
                                    }
                                </FormGroup>
                            </div>
                            <div className="col-4">
                                <div style={{ height: 400, width: '100%', overflow: 'auto' }}>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                <th>Boleta</th>
                                                <th>Rem.</th>
                                                <th>Fecha</th>
                                                <th>Peso</th>
                                                <th className="numero" style={{ width: 80 }}>Importe</th>
                                                <th style={{ width: 30, textAlign: 'center' }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.remisiones.map((ren, i) => (
                                                <DetalleRow key={i} indice={i} renglon={ren} seleccionar={this.seleccionar} consulta={this.state.consulta} />
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                                <span>Remisiones : <b>{this.state.cantidad}</b></span>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;Importe : <b>{this.state.total.formato()}</b></span>
                            </div>
                            <div className="col-3">
                                <Card body outline color="danger" style={{ backgroundColor: 'transparent' }}>
                                    <h6 style={{ textAlign: 'center' }}>Deducciones</h6>
                                    <br />
                                    <table className="numero">
                                        <tr>
                                            <td><span>Predial rustico&nbsp;</span></td>
                                            <td style={{ width: 100 }}><Input size="sm" name="predial" value={this.state.predial} onChange={this.onChangeDed} /></td>
                                        </tr>
                                        <tr>
                                            <td><span>Pequeña Prop.&nbsp;</span></td>
                                            <td><Input size="sm" name="peqProp" value={this.state.peqProp} onChange={this.onChangeDed} /></td>
                                        </tr>
                                        <tr>
                                            <td><span>Cuota CAADES&nbsp;</span></td>
                                            <td><Input size="sm" name="caades" value={this.state.caades} onChange={this.onChangeDed} /></td>
                                        </tr>
                                        <tr>
                                            <td><span>Cuota Pro-Educ.&nbsp;</span></td>
                                            <td><Input size="sm" name="proEduc" value={this.state.proEduc} onChange={this.onChangeDed} /></td>
                                        </tr>
                                        <tr>
                                            <td><span>Cuota PIEAFFES&nbsp;</span></td>
                                            <td><Input size="sm" name="pieaffes" value={this.state.pieaffes} onChange={this.onChangeDed} /></td>
                                        </tr>
                                        <tr>
                                            <td><span>Deducciones&nbsp;</span></td>
                                            <td><Input size="sm" name="deducciones" value={this.state.deducciones.formato()} /></td>
                                        </tr>
                                    </table>
                                </Card>
                            </div>
                            <div className="col-3">
                                <Card body outline color="success" style={{ backgroundColor: 'transparent' }}>
                                    <h6 style={{ textAlign: 'center' }}>Total a liquidar</h6>
                                    <br />
                                    <table className="numero">
                                        <tr>
                                            <td><span>Aportaciones&nbsp;</span></td>
                                            <td style={{ width: 100 }}><Input size="sm" name="total" value={this.state.total.formato()}  /></td>
                                        </tr>
                                        <tr>
                                            <td><span>(-) Deducciones.&nbsp;</span></td>
                                            <td><Input size="sm" name="deducciones" value={this.state.deducciones.formato()} /></td>
                                        </tr>
                                        <tr>
                                            <td><span>(-) Anticipos&nbsp;</span></td>
                                            <td><Input size="sm" name="anticipos" value={this.state.anticipos} onChange={this.onChangeLiq} /></td>
                                        </tr>
                                        <tr>
                                            <td><span>(-) Secado&nbsp;</span></td>
                                            <td><Input size="sm" name="secado" value={this.state.secado} onChange={this.onChangeLiq} /></td>
                                        </tr>
                                        <tr>
                                            <td><span>(-) Coberturas&nbsp;</span></td>
                                            <td><Input size="sm" name="coberturas" value={this.state.coberturas} onChange={this.onChangeLiq} /></td>
                                        </tr>
                                        <tr>
                                            <td><span>(-) Cuota Admon.&nbsp;</span></td>
                                            <td><Input size="sm" name="admon" value={this.state.admon} onChange={this.onChangeLiq} /></td>
                                        </tr>
                                        <tr>
                                            <td><span>(+) Bono calidad&nbsp;</span></td>
                                            <td><Input size="sm" name="bono" value={this.state.bono} onChange={this.onChangeLiq} /></td>
                                        </tr>
                                        <tr>
                                            <td><span>Total liquidacion&nbsp;</span></td>
                                            <td><Input size="sm" name="liquidar" value={this.state.liquidar.formato()} /></td>
                                        </tr>
                                    </table>
                                </Card>
                                <br/>
                                { this.state.consulta ?
                                    <div>
                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        <Button color='warning' size="sm" onClick={this.cancelar}>Regresar</Button>
                                    </div>
                                    :
                                    <div>
                                        <Button color='success' size="sm" onClick={this.guardar}>Guardar</Button>
                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        <Button color='danger' size="sm" onClick={this.cancelar}>Cancelar</Button>
                                    </div>
                                }
                            </div>
                        </Row>
                    </Container>
                </div>
            </Container>
        )
    }
}
class MovsTable extends React.Component {
    render() {
        let { movs } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th className="numero" style={{width:70}}>Numero</th>
                        <th style={{width:80}}>Fecha</th>
                        <th>Comprador</th>
                        <th className="numero" style={{width:85}}>Importe</th>
                        <th style={{width:30, textAlign:'center'}}></th>
                        <th style={{width:30, textAlign:'center'}}></th>
                    </tr>
                </thead>
                <tbody>
                    {movs.map((esc, i) => (
                        <MovRow key={i} mov={esc} onRowClick={this.props.onRowClick} imprimir={this.props.imprimir} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}
class MovRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.mov);
    }
    imprimir = (e) =>{
        this.props.imprimir(this.props.mov);
    }
    render(){
        let {mov} = this.props;
        return (
        <tr>
            <td className="numero">{mov.Num}</td>
            <td>{mov.Fec}</td>
            <td>{mov.Nom}</td>
            <td className="numero">{mov.Imp.formato()}</td>
            <td>
                <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={this.onClick}/>
            </td>
            <td>
                <img src="/imagenes/printer.png" alt="imprimir" title="imprimir" onClick={this.imprimir}/>
            </td>
        </tr>
        );
    }
}
class DetalleRow extends React.Component{
    constructor(props){
        super(props);
        this.state = {Sel:this.props.renglon.Sel};
    }
    componentWillReceiveProps(newProps){
        this.setState({
            Sel:newProps.renglon.Sel
        });
    }
    onChange = (e) =>{
        if(this.props.consulta)
           return;
        this.setState({Sel:e.target.checked});
        this.props.seleccionar(this.props.indice,e.target.checked);
    }
    render(){
        let {renglon} = this.props;
        return (
        <tr>
            <td >{renglon.Bol}</td>
            <td >{renglon.Num}</td>
            <td>{renglon.Fec}</td>
            <td className="numero">{renglon.P.formato()}</td>
            <td className="numero">{renglon.Imp.formato()}</td>
            <td>
                <input type="checkbox" checked={this.state.Sel} onChange={this.onChange}/>
            </td>
        </tr>
        );
    }
}