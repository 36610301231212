import React from 'react';
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class ContaRepos extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            empresa:1,
            ejercicio:2020,
            perIni:1,
            perFin:1,
            empresas:[],
            ejercicios:[],
            periodos:[],
            mostrarModal:true
        };
        this.cargarCatalogos();
    }
    componentWillReceiveProps(newProps){
        this.setState({
            mostrarModal:true
        });
    }
    cargarCatalogos = async () => {
        let url = `${this.props.urlws}/conta/ejercicios`;
        let resp = await fetch(url);
        const ejercicios = await resp.json();
        url = `${this.props.urlws}/conta/periodos`;
        resp = await fetch(url);
        const periodos = await resp.json();
        url = `${this.props.urlws}/empresa/empresas`;
        resp = await fetch(url);
        const empresas = await resp.json();
        this.setState({ejercicios,periodos,empresas});
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    handleClose = () =>{
        this.setState({mostrarModal:false});
    }
    imprimir = async() =>{
        // rptBalanza' - rptAnalitica - rptAuxiliar - rptVerificador - rptPolizas - rptBalance - rptEstado
        let params = `${this.props.rpt}-${this.state.empresa}-${this.state.ejercicio}-0-${this.state.perIni}-${this.state.perFin}-0000000000000000-9999999999999999`;
        const resp = await fetch(this.props.urlws +"/home/codifica?id=" + params);
        const res = await resp.text();
        params = `/conta/reporte?id=${res}`;
        let url = this.props.urlws + params;
        window.open(url, '', '');
    }
    render(){
        return(
            <Container>
                <br/>
                <div>
                    <div className="row">
                        <div className="col-2" style={{textAlign:'right'}}>
                            <span>Empresa</span>
                        </div>
                        <div className="col-4">
                            <FormGroup>
                                <Input size="sm" type="select" name="empresa" value={this.state.empresa} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                    <option key="0" value="">Especifique</option>
                                    {this.state.empresas.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2" style={{textAlign:'right'}}>
                            <span>Ejercicio</span>
                        </div>
                        <div className="col-4">
                            <FormGroup>
                                <Input size="sm" type="select" name="ejercicio" value={this.state.ejercicio} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                    <option key="0" value="">Especifique</option>
                                    {this.state.ejercicios.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2" style={{textAlign:'right'}}>
                            <span>Del periodo</span>
                        </div>
                        <div className="col-4">
                            <FormGroup>
                                <Input size="sm" type="select" name="perIni" value={this.state.perIni} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                    <option key="0" value="">Especifique</option>
                                    {this.state.periodos.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2" style={{textAlign:'right'}}>
                            <span>Al periodo</span>
                        </div>
                        <div className="col-4">
                            <FormGroup>
                                <Input size="sm" type="select" name="perFin" value={this.state.perFin} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                    <option key="0" value="">Especifique</option>
                                    {this.state.periodos.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            
                        </div>
                        <div className="col-2" style={{textAlign:'right'}}>
                            <Button color="success" onClick={this.imprimir}>
                                <img src="/imagenes/printer.png" alt="imprimir" title="imprimir" />
                                &nbsp;&nbsp;Imprimir
                            </Button>
                        </div>
                    </div>
                </div>
            </Container>
        )
    }
}