import React from 'react';
import { Row, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class LiqPapa extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');
        this.state = {
            cultivo:1,
            cargando:false,
            editando:false,
            Temporada:this.props.temp,
            fechaIni:fecIni,
            fechaFin:fecFin,
            temporadas:[],
            embarques:[],
            embarque:{},
            orden:{},
            remision:{},
            mov:{}
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/agricola/temporadas`;
        let resp = await fetch(url);
        const temporadas = await resp.json();
        this.setState({cargando:false, temporadas});
    }
    cargarMovs = async () =>{
        if(this.state.Temporada === ''){
            Swal.fire('Atencion','Especifique la temporada','error');
            return;
        }
        const temp = this.state.Temporada;
        const cve = this.state.clave; 
        const fecIni = this.state.fechaIni;
        const fecFin = this.state.fechaFin;
        this.setState({cargando:true});
        let url = `${this.props.urlws}/embarque/embarquesLiq?lote=0&fecIni=${fecIni}&fecFin=${fecFin}&temp=${temp}`;
        let resp = await fetch(url);
        console.log(url);
        const embarques = await resp.json();
        this.setState({cargando:false, embarques });
    }
    cargarMov = async (temp,cve,num) =>{
        this.setState({cargando:true});
        let url = `${this.props.urlws}/embarque/cargarOrden?temp=${temp}&cve=${cve}&num=${num}`;
        let resp = await fetch(url);
        const orden = await resp.json();
        if(orden.Numero === 0)
           orden.Numero = num;
        this.setState({cargando:false, editando:true, orden});
    }
    cargarDetalle = (mov) =>{
        this.cargarMov(this.state.Temporada,mov.Cve,mov.Num);
    }
    editar = (mov) =>{
        this.setState({editando:true, embarque:mov});
    }
    cancelar = () =>{
        this.setState({editando:false});
    }
    guardar = async(fecLiq,fecLleg) =>{
        const json = JSON.stringify(this.state.embarque.Det);
        const url = this.props.urlws +"/embarque/guardarLiqPapa/" + this.state.embarque.Id + "?fecLiq="+ fecLiq + "&fecLleg="+ fecLleg + "&s=" + json;
        this.setState({cargando:true});
        const resp = await fetch(url,{
            method: 'POST' // or 'PUT'
          });
        const res = await resp.text();
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.setState({editando:false});
            this.cargarMovs();
        }else{
            this.setState({cargando:false});
            Swal.fire('Atencion',res,'error');           
        }
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor},() =>{
            if(campo === "Temporada")
               this.cargarLotes();
        });
    }    
    render(){
        const estilo1 = { display: this.state.editando ? 'inline' : 'none' };
        const estilo2 = { padding:2, display: (this.state.editando || this.state.editandoRem) ? 'none' : 'inline' };
        const estilo3 = { display: this.state.editandoRem ? 'inline' : 'none' };
        return(
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo1}>
                    {this.state.editando?
                        <Captura urlws={this.props.urlws} embarque={this.state.embarque} height={400} urlws={this.props.urlws} cancelar={this.cancelar} guardar={this.guardar} temp={this.state.Temporada}  />
                        :
                        null
                    }
                </div>
                <div style={estilo2}>
                    <div className="row">
                        <div className="col-2">
                            <FormGroup>
                                <span>Temporada</span><br/>
                                <Input size="sm" type="select" name="Temporada" value={this.state.Temporada} onChange={this.onChange} style={{  fontSize:'12px'}}>
                                    <option key="0" value="">Especifique</option>
                                    {this.state.temporadas.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Del dia</span><br/>
                                <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>al dia</span><br/>
                                <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup as={Col}>
                                <span>&nbsp;</span><br/>
                                <Button color='success' size="sm" onClick={this.cargarMovs}>Cargar</Button>
                            </FormGroup>
                        </div>
                    </div>
                    <div style={{ height: 4 }}></div>
                    <MovsTable movs={this.state.embarques} height={500} editar={this.editar} capturar={this.props.capturar}/>
                </div>                
            </Container>
        )
    }
}

class MovsTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    onRemClick = (cve) =>{
        this.props.onRemClick(cve);
    }
    onPesoClick = (cve) =>{
        this.props.capturarPeso(cve);
    }
    render() {
        let { movs } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:70}}>Remision</th>
                        <th style={{width:110}}>Fecha</th>
                        <th style={{width:70}}>Factura</th>
                        <th>Vendedor</th>
                        <th>Comprador</th>
                        <th>Destino</th>
                        <th>Lote</th>
                        {
                            this.props.capturar &&
                            <th></th>
                        }
                    </tr>
                </thead>
                <tbody>
                    {movs.map((o, i) => (
                        <MovsRow key={i} mov={o} editar={this.props.editar} capturar={this.props.capturar} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}
class MovsRow extends React.Component{
    render(){
        let {mov} = this.props;
        return (
            <React.Fragment>
                <tr>
                    <td className="numero rojo">{mov.Rems}</td>
                    <td><b>{mov.Fec}</b></td>
                    <td><b>{mov.Fac}</b></td>
                    <td><b>{mov.V}</b></td>
                    <td><b>{mov.C}</b></td>
                    <td><b>{mov.D}</b></td>
                    <td><b>{mov.L}</b></td>
                    {
                    this.props.capturar &&
                    <td>
                        <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={()=>{ this.props.editar(mov);}}/>
                    </td> 
                    }
                </tr>
                <tr>
                    <td colspan="8">
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <td ></td>
                                    <td ></td>
                                    <td colspan="2" className="centrado">Embarcado</td>
                                    <td colspan="2" className="centrado">Merma</td>
                                    <td colspan="2" className="centrado">Recibido</td>
                                    <td colspan="2" className="centrado">Descuentos</td>
                                    <td colspan="2" className="centrado">Liquidado</td>
                                    <td colspan="2" className="centrado">Precio</td>
                                    <td className="numero">Importe</td>
                                </tr>
                                <tr>
                                    <td >Variedad</td>
                                    <td >Calidad</td>
                                    <td className="numeroCeldaCh">Arp.</td>
                                    <td className="numeroCeldaCh">Kgs</td>
                                    <td className="numeroCeldaCh">%</td>
                                    <td className="numeroCeldaCh">Kgs</td>
                                    <td className="numeroCeldaCh">Arp.</td>
                                    <td className="numeroCeldaCh">Kgs</td>
                                    <td className="numeroCeldaCh">Kgs/Arp</td>
                                    <td className="numeroCeldaCh">Total</td>
                                    <td className="numeroCeldaCh">Arp.</td>
                                    <td className="numeroCeldaCh">Kgs</td>
                                    <td className="numeroCeldaCh">Emb.</td>
                                    <td className="numeroCeldaCh">Liq.</td>
                                    <td className="numeroCeldaCh">Total</td>
                                </tr>
                            </thead>
                            <tbody>
                                {mov.Det.map((o, i) => (
                                   <tr key={i}>
                                    <td >{o.V}</td>
                                    <td >{o.C}</td>
                                    <td className="numeroCeldaCh" >{o.A}</td>
                                    <td className="numeroCeldaCh" >{o.K.formato0()}</td>
                                    <td className="numeroCeldaCh" >{o.Mp}</td>
                                    <td className="numeroCeldaCh" >{o.Mk.formato()}</td>
                                    <td className="numeroCeldaCh" >{o.Ra}</td>
                                    <td className="numeroCeldaCh" >{o.Rk.formato0()}</td>
                                    <td className="numeroCeldaCh" >{o.Dk.formato()}</td>
                                    <td className="numeroCeldaCh" >{o.Dt.formato()}</td>
                                    <td className="numeroCeldaCh" >{o.La}</td>
                                    <td className="numeroCeldaCh" >{o.Lk.formato0()}</td>
                                    <td className="numeroCeldaCh" >{o.Pe.formato()}</td>
                                    <td className="numeroCeldaCh" >{o.Pl.formato()}</td>
                                    <td className="numeroCeldaCh" >{o.I.formato()}</td>
                                   </tr> 
                                ))}
                                {mov.Fliq !== "" &&
                                <tr >
                                    <td className="rojo">LIQUIDADA</td>
                                    <td>Fecha Liq. {mov.Fliq}</td>
                                    <td className="numeroCeldaCh" >{mov.A}</td>
                                    <td className="numeroCeldaCh" >{mov.K.formato0()}</td>
                                    <td className="numeroCeldaCh" ></td>
                                    <td className="numeroCeldaCh" >{mov.Mk}</td>
                                    <td className="numeroCeldaCh" >{mov.Ra}</td>
                                    <td className="numeroCeldaCh" >{mov.Rk.formato()}</td>
                                    <td className="numeroCeldaCh" ></td>
                                    <td className="numeroCeldaCh" >{mov.Dt.formato()}</td>
                                    <td className="numeroCeldaCh" >{mov.La}</td>
                                    <td className="numeroCeldaCh" >{mov.Lk.formato()}</td>
                                    <td className="numeroCeldaCh" ></td>
                                    <td className="numeroCeldaCh" ></td>
                                    <td className="numeroCeldaCh rojo" >{mov.I.formato()}</td>
                                </tr>
                                }
                            </tbody>
                        </Table>
                        <br/>
                    </td>
                </tr>
            </React.Fragment>
    );
    }
}
class Captura extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date().yyyymmdd('-');
        this.state = {
            modal:false,
            indice:0,
            Merma:this.props.embarque.Mk,
            Recibidos:this.props.embarque.Rk,
            A:0,
            K:0,
            Mp:0,
            Mk:0,
            Ra:0,
            Rk:0,
            Dk:0,
            Dt:0,
            La:0,
            Lk:0,
            Pe:0,
            Pl:0,
            I:0,
            fechaLiq:this.props.embarque.Fliq,
            fechaLleg:this.props.embarque.Flleg,
            embarque:this.props.embarque                        
        }
    }
    componentWillReceiveProps(newProps){
        this.setState({embarque:newProps.embarque, fechaLiq:newProps.embarque.Fliq, fechaLleg:newProps.embarque.Flleg, Merma:newProps.embarque.Mk,Recibidos:newProps.embarque.Rk});
    }

    handleClose = () =>{
        this.setState({modal:false});
    }
    editar = (i) =>{
        const d = this.state.embarque.Det[i];
        this.state.A = d.A;
        this.state.K = d.K;
        this.state.Mp = d.Mp;
        this.state.Mk = d.Mk;
        this.state.Ra = d.Ra === 0 ? d.A : d.Ra;
        this.state.Rk = d.Rk;
        this.state.Dk = d.Dk;
        this.state.Dt = d.Dt;
        this.state.La = d.La === 0 ? d.A : d.La;
        this.state.Lk = d.Lk;
        this.state.Pe = d.Pe;
        this.state.Pl = d.Pl;
        this.state.I = d.I;                 
        this.setState({modal:true, indice:i});
    }
    cancelar = () =>{
        this.setState({modal:false});
    }
    aceptar = () =>{
        let d = this.state.embarque.Det[this.state.indice];
        d.A = this.state.A;
        d.K = this.state.K;
        d.Mp = this.state.Mp;
        d.Mk = this.state.Mk;
        d.Ra = this.state.Ra;
        d.Rk = this.state.Rk;
        d.Dk = this.state.Dk;
        d.Dt = this.state.Dt;
        d.La = this.state.La;
        d.Lk = this.state.Lk;
        d.Pe = Number(this.state.Pe);
        d.Pl = this.state.Pl;
        d.I = this.state.I;                 

        this.state.embarque.I = 0;
        for (let k = 0; k < this.state.embarque.Det.length; k++) {
            this.state.embarque.I += this.state.embarque.Det[k].I;
        }

        this.setState({modal:false});
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor});
    }    
    onBlurRecibidos = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor},()=>{
            let Recibidos = Number(this.state.Recibidos);
            let Merma = this.state.embarque.K - Recibidos;
            let kgs = this.state.embarque.K;
            let sdo = kgs;
            this.state.embarque.I = 0;
            for (let k = 0; k < this.state.embarque.Det.length; k++) {
                this.state.embarque.Det[k].Mk = (Merma * (this.state.embarque.Det[k].K / kgs)).roundToTwo(); 
                sdo = sdo - this.state.embarque.Det[k].Mk;
                if(k == this.state.embarque.Det.length)  // es el ultimo renglon
                   this.state.embarque.Det[k].Mk += sdo;
                this.state.embarque.Det[k].Mp = (this.state.embarque.Det[k].Mk / this.state.embarque.Det[k].K * 100).roundToTwo();
                this.state.embarque.Det[k].Rk = this.state.embarque.Det[k].K - this.state.embarque.Det[k].Mk;
                this.state.embarque.Det[k].Dt = (this.state.embarque.Det[k].Ra * this.state.embarque.Det[k].Dk).roundToTwo();
                this.state.embarque.Det[k].Lk = this.state.embarque.Det[k].Rk - this.state.embarque.Det[k].Dt;
                this.state.embarque.Det[k].I = (this.state.embarque.Det[k].Lk * this.state.embarque.Det[k].Pl).roundToTwo();
                //this.state.embarque.Det[k].Pe = (this.state.embarque.Det[k].I / this.state.embarque.Det[k].K).roundToTwo();
                this.state.embarque.I += this.state.embarque.Det[k].I;
            }
            this.setState({Merma, Recibidos});
        });
    }
    onBlurMermaX = (e) => {
        return;
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor},()=>{
            let Mk = Number(this.state.Mk);
            let Mp = (Mk / this.state.K * 100).roundToTwo();
            let Rk = this.state.K - Mk;
            let Dt = (this.state.Ra * this.state.Dk).roundToTwo();
            let Lk = Rk - Dt;
            let I = Lk * this.state.Pl;
            //let Pe = (I / this.state.K).roundToTwo();
            this.setState({Mk, Mp, Rk, Dt, Lk, I});
        });
    }
    onBlurKgsRec = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor},()=>{
            let Rk = Number(this.state.Rk);
            let Mk = Number(this.state.K) - Rk;
            let Mp = (Mk / this.state.K * 100).roundToTwo();
            let Dt = (this.state.Ra * this.state.Dk).roundToTwo();
            let Lk = Rk - Dt;
            let I = Lk * this.state.Pl;
            //let Pe = (I / this.state.K).roundToTwo();
            this.setState({Mk, Mp, Rk, Dt, Lk, I});
        });
    }    
    onBlurDescto = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor},()=>{
            let Dk = Number(this.state.Dk);
            let Ra = Number(this.state.Ra);
            let Dt = (Ra * Dk).roundToTwo();
            let Lk = this.state.Rk - Dt;
            let I = Lk * this.state.Pl;
            //let Pe = (I / this.state.K).roundToTwo();
            this.setState({Dk, Dt, Lk, Ra, I });
        });
    }    
    onBlurDesctoTot = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor},()=>{
            let Dt = Number(this.state.Dt);
            let Ra = Number(this.state.Ra);
            let Dk = (Dt / Ra).roundToTwo();
            let Lk = this.state.Rk - Dt;
            let I = Lk * this.state.Pl;
            //let Pe = (I / this.state.K).roundToTwo();
            this.setState({Dk, Dt, Lk, Ra, I });
        });
    }
    onBlurKgsLiq = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor},()=>{
            let Lk = Number(this.state.Lk);
            let Ra = Number(this.state.Ra);
            let Dt = this.state.Rk - Lk;
            let Dk = (Dt / Ra).roundToTwo();
            let I = Lk * this.state.Pl;
            //let Pe = (I / this.state.K).roundToTwo();
            this.setState({Dk, Dt, Lk, Ra, I });
        });
    }   
    onBlurPrecio = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor},()=>{
            let Pl = Number(this.state.Pl);
            let I = this.state.Lk * Pl;
            //let Pe = (I / this.state.K).roundToTwo();
            this.setState({Pl, I });
        });
    }    
    guardar = () =>{
        this.props.guardar(this.state.fechaLiq,this.state.fechaLleg, this.state.Merma);
    }
    render(){
        const e = this.props.embarque;
        return (
            <React.Fragment>
                <br />
                <div className="row">
                    <div className="col">
                        <FormGroup>
                            <span>Fecha de Llegada</span>
                            <Input size="sm" type="date" name="fechaLleg" value={this.state.fechaLleg} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col">
                        <FormGroup>
                            <span>Fecha de Liquidacion</span>
                            <Input size="sm" type="date" name="fechaLiq" value={this.state.fechaLiq} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col">
                        <FormGroup>
                            <span>Kgs. Embarcados</span>
                            <Input size="sm" value={this.state.embarque.K.formato()} />
                        </FormGroup>
                    </div>
                    <div className="col">
                    <FormGroup>
                        <span>Kgs. Recibidos</span>
                        <Input size="sm" name="Recibidos" value={this.state.Recibidos} onChange={this.onChange} onBlur={this.onBlurRecibidos}/>
                    </FormGroup>
                    </div>
                    <div className="col-1">
                        <FormGroup>
                            <span>Merma</span>
                            <Input size="sm" value={this.state.Merma} />
                        </FormGroup>
                    </div>
                    <div className="col">
                        <FormGroup>
                            <span>Importe</span>
                            <Input size="sm" value={this.state.embarque.I.formato()} />
                        </FormGroup>
                    </div>
                </div>  
                <div style={{ height: this.props.height, width: this.props.width, overflow: 'auto' }}>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th ></th>
                                <th ></th>
                                <th colspan="2" className="centrado">Embarcado</th>
                                <th colspan="2" className="centrado">Merma</th>
                                <th colspan="2" className="centrado">Recibido</th>
                                <th colspan="2" className="centrado">Descuentos</th>
                                <th colspan="2" className="centrado">Liquidado</th>
                                <th colspan="2" className="centrado">Precio</th>
                                <th className="numero">Importe</th>
                            </tr>
                            <tr>
                                <th >Variedad</th>
                                <th >Calidad</th>
                                <th className="numeroCeldaCh">Arp.</th>
                                <th className="numeroCeldaCh">Kgs</th>
                                <th className="numeroCeldaCh">%</th>
                                <th className="numeroCeldaCh">Kgs</th>
                                <th className="numeroCeldaCh">Arp.</th>
                                <th className="numeroCeldaCh">Kgs</th>
                                <th className="numeroCeldaCh">Kgs/Arp</th>
                                <th className="numeroCeldaCh">Total</th>
                                <th className="numeroCeldaCh">Arp.</th>
                                <th className="numeroCeldaCh">Kgs</th>
                                <th className="numeroCeldaCh">Emb.</th>
                                <th className="numeroCeldaCh">Liq.</th>
                                <th className="numeroCeldaCh">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {e.Det.map((o, i) => (
                                <tr key={i}>
                                    <td >{o.V}</td>
                                    <td >{o.C}</td>
                                    <td className="numeroCeldaCh" >{o.A}</td>
                                    <td className="numeroCeldaCh" >{o.K.formato0()}</td>
                                    <td className="numeroCeldaCh" >{o.Mp}</td>
                                    <td className="numeroCeldaCh" >{o.Mk}</td>
                                    <td className="numeroCeldaCh" >{o.Ra}</td>
                                    <td className="numeroCeldaCh" >{o.Rk.formato()}</td>
                                    <td className="numeroCeldaCh" >{o.Dk}</td>
                                    <td className="numeroCeldaCh" >{o.Dt}</td>
                                    <td className="numeroCeldaCh" >{o.La}</td>
                                    <td className="numeroCeldaCh" >{o.Lk.formato()}</td>
                                    <td className="numeroCeldaCh" >{o.Pe}</td>
                                    <td className="numeroCeldaCh" >{o.Pl}</td>
                                    <td className="numeroCeldaCh" >{o.I.formato()}</td>
                                    <td>
                                        <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={() => this.editar(i)} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <Modal isOpen={this.state.modal} toggle={this.handleClose} size="sm">
                    <ModalBody>
                        <Container>
                            <span><b>Embarcado</b></span><br />
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <span>Arpillas</span>
                                        <Input type="text" size="sm" name="A" value={this.state.A} />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <span>Kilos</span>
                                        <Input type="text" size="sm" name="K" value={this.state.K.formato0()} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <span><b>Merma</b></span><br />
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <span>%</span>
                                        <Input type="text" size="sm" name="Mp" value={this.state.Mp.formato()} />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <span>Kilos</span>
                                        <Input type="text" size="sm" name="Mk" value={this.state.Mk.formato()}  />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <span><b>Recibido</b></span><br />
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <span>Arpillas</span>
                                        <Input type="text" size="sm" name="Ra" value={this.state.Ra} onChange={this.onChange} onBlur={this.onBlurDescto} />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <span>Kilos</span>
                                        <Input type="text" size="sm" name="Rk" value={this.state.Rk} onChange={this.onChange} onBlur={this.onBlurKgsRec}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <span><b>Descuentos</b></span><br />
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <span>Kgs x Arpilla</span>
                                        <Input type="text" size="sm" name="Dk" value={this.state.Dk} onChange={this.onChange} onBlur={this.onBlurDescto} />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <span>Total</span>
                                        <Input type="text" size="sm" name="Dt" value={this.state.Dt} onChange={this.onChange} onBlur={this.onBlurDesctoTot} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <span><b>Liquidado</b></span><br />
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <span>Arpillas</span>
                                        <Input type="text" size="sm" name="La" value={this.state.La} onChange={this.onChange} />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <span>Kilos</span>
                                        <Input type="text" size="sm" name="Lk" value={this.state.Lk} onChange={this.onChange} onBlur={this.onBlurKgsLiq}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <span><b>Precios</b></span><br />
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <span>Embarcado</span>
                                        <Input type="text" size="sm" name="Pe" value={this.state.Pe} onChange={this.onChange} />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <span>Liquidado</span>
                                        <Input type="text" size="sm" name="Pl" value={this.state.Pl} onChange={this.onChange} onBlur={this.onBlurPrecio} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <span>Importe</span>
                                        <Input type="text" size="sm" name="I" value={this.state.I.formato()} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Button size="sm" color="success" onClick={this.aceptar}>Aceptar</Button>
                            <span>&nbsp;&nbsp;&nbsp;</span>
                            <Button size="sm" color="danger" onClick={this.cancelar}>Cancelar</Button>
                        </Container>
                    </ModalBody>
                </Modal>
                <Button size="sm" color="success" onClick={this.guardar}>Guardar</Button>
                <span>&nbsp;&nbsp;&nbsp;</span>
                <span>&nbsp;&nbsp;&nbsp;</span>
                <Button size="sm" color="danger" onClick={this.props.cancelar}>Cancelar</Button>
            </React.Fragment>
       );
    }
}