import React from 'react';
import {Row, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Buscador from '../Buscador';

export default class NotaCredPend extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');
        this.state = {
            cargando:false,
            popoverOpen:false,
            mostrarModal:false,
            Temporada:this.props.temp,
            clave:0,
            porAplicar:0,
            aplicado:0,
            ncActual:{},
            prov:{
                Clave:0,
                Nombre:'TODOS'
            },
            almacen:1,
            fechaIni:fecIni,
            fechaFin:fecFin,
            almacenes:[],
            movs:[],
            doctos:[]
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/invMovs/almacenes`;
        let resp = await fetch(url);
        const almacenes = await resp.json();
        this.setState({cargando:false, almacenes });
    }
    cargarMovs = async () => {
        const {almacen} = this.state;
        const {fechaIni} = this.state;
        const {fechaFin} = this.state;
        const {clave} = this.state;
        this.setState({cargando:true});
        console.log(this.state);
        let url = `${this.props.urlws}/prov/ncpendientes?alm=${almacen}&fecIni=${fechaIni}&fecFin=${fechaFin}&prov=${clave}`;
        let resp = await fetch(url);
        const movs = await resp.json();
        this.setState({cargando:false, movs });
    }
    toggle = () => {
        this.setState({popoverOpen: !this.state.popoverOpen});
    }    
    onChange = (e) => {
        const campo = e.target.name;
        let valor = e.target.value;
        this.setState({ [campo] : valor});
    }
    onKeyPressCodigo = (e) =>{
        if(e.which == 13)
           this.cargarProv(this.state.clave);
    }
    codigoIns = (item) =>{
        this.setState({popoverOpen:false});
        this.cargarProv(item.I);
    }
    cargarProv = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/prov/cargar/" + cve;
        const resp = await fetch(url);
        const prov = await resp.json();
        if(prov.Clave === 0){
            prov.Nombre = "TODOS";
        }
        this.setState({
            clave:prov.Clave,
            cargando:false,
            prov
        });
    }
    aplicar = (nc) => {
        this.cargarDoctos(nc.Prov);
        this.state.ncActual = nc;
        this.state.porAplicar = nc.Sdo;
        this.state.aplicado = 0;
    }
    cargarDoctos = async (prov) => {
        let importe = 0;
        let pendiente = 0;
        let abonos = 0;
        let saldo = 0;
        this.setState({cargando:true});
        let url = `${this.props.urlws}/prov/porPagar/${prov}`;
        let resp = await fetch(url);
        const doctos = await resp.json();
        for (let i = 0; i < doctos.length; i++) {
            importe += doctos[i].Imp;
            pendiente += doctos[i].Sdo;
            abonos += doctos[i].Abo;
        }
        saldo = pendiente - abonos;
        this.setState({cargando:false,mostrarModal:true, clave:prov,doctos,importe,pendiente,abonos,saldo });
    }
    capturarPago = async(fac,i) =>{
        let pago = fac.Sdo;
        if(pago > this.state.porAplicar)
           pago = this.state.porAplicar;
        const { value: importe } = await Swal.fire({
            title: 'Importe del pago',
            titleText:`Saldo de la factura : ${fac.Sdo.formato()} // Saldo de la NC : ${this.state.porAplicar}`,
            input: 'text',
            inputValue: pago,
            showCancelButton: true,
            inputValidator: (value) => {
              if (!value) {
                return 'Especifica el importe del pago'
              }
            }
          });
          
          if (importe) {
              let valor = Number(importe).roundToTwo();
              if(Number.isNaN(valor)){
                 Swal.fire('Atencion','Dato incorrecto','error');
                 return;
              }
              if(valor > fac.Sdo){
                Swal.fire('Atencion','El importe es mayor al saldo de la factura','error');
                return;
             }
             if(valor > this.state.porAplicar){
                Swal.fire('Atencion','El importe es mayor al saldo de la NC','error');
                return;
             }
             this.state.aplicado = this.state.aplicado + valor; 
             this.state.doctos[i].Abo = valor;
             this.setState({porAplicar: (this.state.porAplicar - valor).roundToTwo()})
             //this.aplicar(valor)
         }            
    }
    guardar = () =>{
        if(this.state.aplicado == 0){
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar este movimiento ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.guardarB();
            }
          })        
    }
    guardarB = async() =>{
        let app = [];
        for (let i = 0; i < this.state.doctos.length; i++) {
           if(this.state.doctos[i].Abo > 0.10)
              app.push({
                  f:this.state.doctos[i].Ref,
                  i:this.state.doctos[i].Abo
              });
       }
        const mov = {
            alm: this.state.ncActual.Alm,
            num: this.state.ncActual.Num,
            prov:this.state.clave,
            s: JSON.stringify(app)
        }
        const url = this.props.urlws +"/prov/aplicarNC";
        this.setState({cargando:true});
        const json = JSON.stringify(mov);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.setState({clave:this.state.prov.Clave, mostrarModal:false});
            this.cargarMovs();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    handleClose = () =>{
        this.setState({mostrarModal:false});
    }
    render(){
        return(
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div>
                    <div className="row">
                        <div className="col-2">
                            <FormGroup>
                                <span>Almacen</span><br/>
                                <Input size="sm" type="select" name="almacen" value={this.state.almacen} onChange={this.onChange}>
                                    <option key="0" value={0}>Especifique</option>
                                    {this.state.almacenes.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Del dia</span><br />
                                <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>al dia</span><br />
                                <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Proveedor &nbsp;</span>
                                <InputGroup>
                                    <Input size="sm" type="text" name="clave" value={this.state.clave} onChange={this.onChange} onKeyPress={this.onKeyPressCodigo} id="codigo" autoFocus />
                                    <InputGroupAddon addonType="append">
                                        <Button size="sm" color="success" id="btnBuscar">
                                            <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                        </Button>
                                        <Popover trigger="legacy" placement="right" isOpen={this.state.popoverOpen} target="btnBuscar" toggle={this.toggle} >
                                            <PopoverBody>
                                                <Buscador onSelect={this.codigoIns} url={this.props.urlws + "/prov/lista?filtro="} tema="provs" />
                                            </PopoverBody>
                                        </Popover>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </div>
                        <div className="col">
                            <span>&nbsp;</span><br />
                            <InputGroup>
                                <Input size="sm" type="text" value={this.state.prov.Nombre} readOnly />
                                <InputGroupAddon addonType="append">
                                <Button color='success' size="sm" onClick={this.cargarMovs}>
                                    <img src="/imagenes/check.png" alt="cargar" title="cargar" />
                                </Button>
                                    
                                    </InputGroupAddon>
                            </InputGroup>
                        </div>
                    </div>
                    <Modal id="dlg" name="dlg" isOpen={this.state.mostrarModal} toggle={this.handleClose} size="lg" tabIndex={1}>
                        <ModalBody>
                            <Row>
                                <Col>
                                    <div style={{ height: 400, width: 500, overflow: 'auto' }}>
                                        <Table striped bordered hover size="sm">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: 80 }}>Fecha</th>
                                                    <th style={{ width: 100 }}>Numero</th>
                                                    <th className="numero" style={{ width: 80 }}>Importe</th>
                                                    <th className="numero" style={{ width: 80 }}>Saldo</th>
                                                    <th className="numero" style={{ width: 80 }}>Aplicar</th>
                                                    <th style={{ width: 30 }}>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.doctos.map((esc, i) =>
                                                    <tr>
                                                        <td>{esc.Fec}</td>
                                                        <td>{esc.Ref}</td>
                                                        <td>{esc.Imp.formato()}</td>
                                                        <td>{esc.Sdo.formato()}</td>
                                                        <td>{esc.Abo.formato()}</td>
                                                        <td>
                                                            <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={() => this.capturarPago(esc, i)} />
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                                <Col>
                                    <br />
                                    <span>Aplicando la NC : <b>{this.state.ncActual.Num}</b></span><br />
                                    <span>Aplicado  : <b>{this.state.aplicado.formato()}</b></span><br />
                                    <span>Por aplicar  : <b>{this.state.porAplicar.formato()}</b></span><br />
                                    <br /><br />
                                    <Button size="sm" color="success" onClick={this.guardar} >Guardar</Button>
                                    <br /><br />
                                    <Button size="sm" color="danger" onClick={this.handleClose}>Cancelar</Button>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                    <div style={{ height: 4 }}></div>
                    <MovsTable movs={this.state.movs} onRowClick={this.aplicar} height={500}/>
                </div>                
            </Container>
        )
    }
}
class MovsTable extends React.Component {
    render() {
        let { movs } = this.props;
        let ent = 0;
        let sal = 0;
        let ini = 0;
        let act = 0;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:80}}>Fecha</th>
                        <th style={{width:100}}>Numero</th>
                        <th>Proveedor</th>
                        <th className="numero" style={{width:80}}>Importe</th>
                        <th className="numero" style={{width:80}}>Saldo</th>
                        <th  style={{width:30}}>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {movs.map((esc, i) => {
                    ent += esc.Tot;
                    act = esc.Sdo;
                    return <MovRow key={i} mov={esc} onRowClick={this.props.onRowClick} imprimir={this.props.imprimir} cambiarStatus={this.props.cambiarStatus} />
                    })}
                    <tr>
                        <td></td>
                        <td></td>
                        <td>TOTALES</td>
                        <td className="numero" style={{width:80}}>{ent.formato()}</td>
                        <td className="numero" style={{width:80}}>{act.formato()}</td>
                        <td></td>
                    </tr>
                </tbody>
                </Table>
            </div>
        );
    }
}
class MovRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.mov);
    }
    imprimir = (e) =>{
        //this.props.imprimir(this.props.mov);
    }
    render(){
        let {mov} = this.props;
        return (
        <tr>
            <td>{mov.Fec}</td>
            <td>{mov.Num}</td>
            <td>{mov.Nom}</td>
            <td className="numero" style={{width:80}}>{mov.Tot.formato()}</td>
            <td className="numero" style={{width:80}}>{mov.Sdo.formato()}</td>
            <td>
            <img src="/imagenes/edit.png" alt="aplicar" title="aplicar" onClick={this.onClick}/>
            </td>
        </tr>
        );
    }
}
