import React from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Buscador from '../Buscador';

export default class Compras extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');
        this.state = {
            mostrarDialogo:false,
            cargando:false,
            editando:false,
            Temporada:this.props.temp,
            tipo:'E',
            almacen:1,
            fechaIni:fecIni,
            fechaFin:fecFin,
            clave:'CO',
            tipos:[],
            almacenes:[],
            compras:[],
            temporadas:[],
            compra:{
                Factura:'',
                Prov:0,
                Subtotal:0,
                Iva:0,
                Ieps:0,
                Total:0,
                Orden:''
            }
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/invMovs/almacenes`;
        let resp = await fetch(url);
        const almacenes = await resp.json();
        url = `${this.props.urlws}/compras/tipos`;
        resp = await fetch(url);
        const tipos = await resp.json();
        url = `${this.props.urlws}/agricola/temporadas`;
        resp = await fetch(url);
        const temporadas = await resp.json();
        url = `${this.props.urlws}/agricola/lotesTemp/${this.state.Temporada}`;
        resp = await fetch(url);
        const lotes = await resp.json();
        this.setState({cargando:false, almacenes, temporadas, tipos, lotes });
    }
    cargarMovs = async () =>{
        if(this.state.Temporada === ''){
            Swal.fire('Atencion','Especifique la temporada','error');
            return;
        }
        if(this.state.almacen == 0){
            Swal.fire('Atencion','Especifique el almacen','error');
            return;
        }
        const temp = this.state.Temporada;
        const alm = this.state.almacen;
        const cve = this.state.clave; 
        const fecIni = this.state.fechaIni;
        const fecFin = this.state.fechaFin;
        this.setState({cargando:true});
        let url = `${this.props.urlws}/compras/wscompras?alm=${alm}&cve=${cve}&fecIni=${fecIni}&fecFin=${fecFin}&temp=${temp}`;
        let resp = await fetch(url);
        const compras = await resp.json();
        this.setState({cargando:false, compras });
    }
    cargarDetalle = (mov) =>{
        this.cargarMov(mov.Almacen,mov.Clave,mov.Numero);
    }
    cargarMov = async (alm,cve,num) =>{
        this.setState({cargando:true});
        let url = `${this.props.urlws}/compras/cargar?alm=${alm}&cve=${cve}&num=${num}`;
        let resp = await fetch(url);
        const compra = await resp.json();
        if(compra.Numero === 0)
            compra.Temporada = this.state.Temporada;
        this.setState({cargando:false, editando:true, compra});
    }
    nuevoMov = () =>{
        const alm = this.state.almacen;
        const cve = this.state.clave;
        if(cve === "??"){
            Swal.fire('Atencion','Especifique el tipo de movimiento','error');
            return;
        }
        if(alm === 0){
            Swal.fire('Atencion','Especifique el almacen','error');
            return;
        }
        this.cargarMov(alm,cve,0);
    }
    costear = () =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea recostear el almacen ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.costearB();
            }
          })        
    }
    costearB = async () =>{
        const url = this.props.urlws +"/articulo/costear";
        this.setState({cargando:true});
        const resp = await fetch(url,{
            method: 'POST' // or 'PUT'
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','se costeo el inventario','success');
        }
        else
            Swal.fire('Atencion',res,'error');
    }

    guardar = async (obj) =>{
        const mov = {
            Almacen : this.state.almacen,
            Clave : this.state.clave,
            Numero : obj.Numero,
            Nombre : '',
            Fecha : obj.fechaFact == '' ? obj.Fecha : obj.fechaFact,
            Temporada:obj.Temporada,
            Orden:obj.orden,
            IdFact:obj.idFact,
            Subtotal:obj.subtotal,
            Iva:obj.iva,
            Ieps:obj.ieps,
            Total:obj.total,
            Descuento:0,
            Prov:obj.prov,
            Inventario:true,
            S: JSON.stringify(obj.Detalle)    
        }
        const url = this.props.urlws +"/compras/wsguardar";
        this.setState({cargando:true});
        const json = JSON.stringify(mov);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.setState({editando:false});
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    imprimir = (mov) =>{
        let params = '?alm=' + mov.Almacen + '&cve=' + mov.Clave + '&num=' + mov.Numero;
        let url = this.props.urlws + '/compras/imprimir' + params;
        window.open(url, '', '');
    }
    imprimirSerie = (mov) =>{
        if(this.state.almacen == 0){
            Swal.fire('Atencion','Especifique el almacen','error');
            return;
        }
        if(this.state.clave == "??"){
            Swal.fire('Atencion','Especifique el tipo de movimiento','error');
            return;
        }
        const inicio = this.state.inicio;
        const fin = this.state.fin;
        let params = '?alm=' + this.state.almacen + '&cve=' + this.state.clave + '&inicio=' + inicio + '&fin=' + fin;
        let url = this.props.urlws + '/compras/imprimirRango' + params;
        window.open(url, '', '');
        this.setState({mostrarDialogo:false});
    }
    cancelar = () =>{
        this.setState({editando:false});
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor});
    }
    handleClose = () =>{
        this.setState({mostrarDialogo:false});
    }

    render () {
        const estilo1 = { display: this.state.editando ? 'inline' : 'none' };
        const estilo2 = { padding:2, display: this.state.editando ? 'none' : 'inline' };
        return(            
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo1}>
                    <Captura compra={this.state.compra} height={450} urlws={this.props.urlws} cancelar={this.cancelar} guardar={this.guardar} temp={this.state.Temporada} lotes={this.state.lotes} />
                </div>
                <div style={estilo2}>
                    <div className="row">
                        <div className="col-1">
                            <FormGroup>
                                <span>Temporada</span><br/>
                                <Input size="sm" type="select" name="Temporada" value={this.state.Temporada} onChange={this.onChange} style={{  fontSize:'12px'}}>
                                    <option key="0" value="">Especifique</option>
                                    {this.state.temporadas.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Tipo</span><br/>
                                <Input size="sm" type="select" name="clave" value={this.state.clave} onChange={this.onChange}>
                                    <option key="0" value="??">Especifique</option>
                                    {this.state.tipos.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Almacen</span><br/>
                                <Input size="sm" type="select" name="almacen" value={this.state.almacen} onChange={this.onChange}>
                                    <option key="0" value={0}>Especifique</option>
                                    {this.state.almacenes.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Del dia</span><br/>
                                <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>al dia</span><br/>
                                <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-3">
                            <FormGroup as={Col}>
                                <span>&nbsp;</span><br/>
                                <Button color='success' size="sm" onClick={this.cargarMovs}>
                                <img src="/imagenes/ok.png" alt="cargar" title="cargar"/>
                                </Button>
                                {'  '}
                                <Button color='success' size="sm" onClick={this.nuevoMov}>+</Button>
                                {'  '}
                                <Button color='warning' size="sm" onClick={this.costear}>Costear</Button>
                                {'  '}
                                <Button color='warning' size="sm" onClick={() => this.setState({mostrarDialogo:true})}>
                                    <img src="/imagenes/printer.png" alt="imprimir" title="imprimir"/>
                                </Button>
                            </FormGroup>
                        </div>
                    </div>
                    <div style={{ height: 4 }}></div>
                    <MovsTable compras={this.state.compras} height={500} width={990} onRowClick={this.cargarDetalle} imprimir={this.imprimir} />
                </div>                
                <Modal id="dlgRango" name="dlgRango" isOpen={this.state.mostrarDialogo} toggle={this.handleClose}>
                    <ModalBody>
                        <div className="row">
                            <div className="col-4">
                                <FormGroup>
                                    <span>del numero</span><br />
                                    <Input size="sm" type="text" name="inicio" value={this.state.inicio} onChange={this.onChange} />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <FormGroup>
                                    <span>al numero</span><br />
                                    <Input size="sm" type="text" name="fin" value={this.state.fin} onChange={this.onChange} />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                        <div className="col">
                            <Button color='success' size="sm" onClick={this.imprimirSerie}>
                                    <img src="/imagenes/printer.png" alt="imprimir" title="imprimir"/>
                                    Imprimir
                            </Button>
                            </div> 
                        </div> 
                    </ModalBody>
                </Modal>              
            </Container>
        )
    }
}

class MovsTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { compras } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:40}}>Tipo</th>
                        <th className="numero" style={{width:70}}>Numero</th>
                        <th style={{width:80}}>Fecha</th>
                        <th style={{width:80}}>Factura</th>
                        <th style={{width:100}}>Orden</th>
                        <th>Proveedor</th>
                        <th className="numero" style={{width:85}}>Total</th>
                        <th style={{width:30, textAlign:'center'}}></th>
                        <th style={{width:30, textAlign:'center'}}></th>
                    </tr>
                </thead>
                <tbody>
                    {compras.map((esc, i) => (
                        <MovRow key={i} mov={esc} onRowClick={this.onRowClick} imprimir={this.props.imprimir} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}
class MovRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.mov);
    }
    imprimir = (e) =>{
        this.props.imprimir(this.props.mov);
    }
    render(){
        let {mov} = this.props;
        return (
        <tr>
            <td>{mov.Clave}</td>
            <td className="numero">{mov.Numero}</td>
            <td>{mov.Fecha}</td>
            <td>{mov.Factura}</td>
            <td>{mov.Orden}</td>
            <td>{mov.Nombre}</td>
            <td className="numero">{mov.Importe}</td>
            <td>
                <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={this.onClick}/>
            </td>
            <td>
                <img src="/imagenes/printer.png" alt="imprimir" title="imprimir" onClick={this.imprimir}/>
            </td>
        </tr>
        );
    }
}
class Captura extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            prov:0,
            nomProv:'',
            factura:'',
            idFact:this.props.compra.IdFact,
            popoverCodOpen:false,
            popoverProvOpen:false,
            popoverFactOpen:false,
            popoverOrdOpen:false,
            agregando:false,
            cambio:false,
            mostrarModal:false,
            cargando:false,
            codigo:'000009',
            nombre:'',
            articulo:{},
            cantidad:1,
            costo:0,
            importe:0,
            Lote:0,
            Cultivo:0,
            loteCult:0,
            renglon:0,
            renSig:1,
            indice:0,
            orden:'',
            Detalle:[],
            cultivos:[],
            facturas:[],
            ordenes:[],
            ordenesRel:[],
            fechaFact:'',
            fechaFact2:'',
            LoteAnt:0,
            subtotal:0,
            iva:0,
            ieps:0,
            total:0,
            ...this.props.compra
        }
    }
    cargarCultivos = async (lote) => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/agricola/lotesCultivoTemp/${this.props.temp}?lote=${lote}`;
        let resp = await fetch(url);
        const cultivos = await resp.json();
        this.setState({cargando:false, cultivos });
    }
    cargarFacturas = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/prov/xmlsPendientes/${this.state.prov}`;
        let resp = await fetch(url);
        const facturas = await resp.json();
        url = `${this.props.urlws}/prov/ordenesPendientes/${this.state.prov}?alm=${this.state.Almacen}`;
        resp = await fetch(url);
        const ordenes = await resp.json();
        this.setState({cargando:false, facturas, ordenes });
    }
    cargarDetalle = async () => {
        this.setState({cargando:true});
        let num = 0;
        let url = `${this.props.urlws}/prov/detalleXmlCompraId/${this.state.idFact}`;
        let resp = await fetch(url);
        const Detalle = await resp.json();
        url = `${this.props.urlws}/prov/ordenesPendientesFactura/${this.state.prov}?alm=${this.state.Almacen}&factura=${this.state.factura}`;
        resp = await fetch(url);
        const ordenesRel = await resp.json();

        let subtotal = 0;
        let iva = 0;
        let ieps = 0;
        let total = 0;
        for (let i = 0; i < Detalle.length; i++) {
            subtotal += Detalle[i].Importe;
            iva += Detalle[i].Iva;
            ieps += Detalle[i].Ieps;
        }
        total = subtotal + iva + ieps;

        for (let j = 0; j < this.state.ordenes.length; j++) 
            this.state.ordenes[j].Sel = false;
        for (let k = 0; k < ordenesRel.length; k++) {
            num = ordenesRel[k];
            for (let i = 0; i < this.state.ordenes.length; i++) {
                if(this.state.ordenes[i].Num === num)
                   this.state.ordenes[i].Sel = true;
            }
        }

        this.setState({cargando:false, Detalle, total,iva,ieps,subtotal,ordenesRel });
    }
    componentWillReceiveProps(newProps){
        this.setState({
            cambio:false,
            //facturas:[],
            //ordenes:[],
            idFact:newProps.compra.IdFact,
            factura:newProps.compra.Factura,
            prov:newProps.compra.Prov,
            nomProv:newProps.compra.NomProv,
            subtotal:newProps.compra.Subtotal,
            iva:newProps.compra.Iva,
            ieps:newProps.compra.Ieps,
            total:newProps.compra.Total,
            orden:newProps.compra.Orden,
            ...newProps.compra,
        },()=>this.cargarFacturas()
        );
    }
    onChange = (e) => {
        const campo = e.target.name;
        let valor = e.target.value;
        if(campo === "cantidad" || campo === "costo" || campo === "dosis")
           valor = Number(valor);
        this.setState({ [campo] : valor, cambio: true},()=>{
            if(campo === "Lote")
               this.cargarCultivos(valor);
        });
    }
    onKeyPressCodigo = (e) =>{
        if(e.which == 13)
           this.cargarArticulo(this.state.codigo);
    }
    toggleCod = () => {
        this.setState({popoverCodOpen: !this.state.popoverCodOpen});
    }    
    toggleProv = () => {
        this.setState({popoverProvOpen: !this.state.popoverProvOpen});
    }    
    toggleFact = () => {
        this.setState({popoverFactOpen: !this.state.popoverFactOpen});
    }    
    toggleOrd = () => {
        this.setState({popoverOrdOpen: !this.state.popoverOrdOpen});
    }    
    provSel = (item) =>{
        this.setState({popoverProvOpen:false, prov: item.I, nomProv:item.N},() =>{
            this.cargarFacturas();
        });
    }
    factSel = (item) =>{
        console.log(item);
        this.setState({popoverFactOpen:false, factura: item.Fol, idFact:item.Id,fechaFact:item.FecH, fechaFact2:item.Fec},()=>{
            this.cargarDetalle();
        });
    }
    ordSel = (item) =>{
        this.setState({popoverOrdOpen:false, orden: item.Num});
    }

    handleClose = () =>{
        this.setState({mostrarModal:false});
    }
    enter = (campo) =>{
        let foco = campo;
         document.getElementById(foco).focus();
    }
    agregarRenglon = () =>{
        let Detalle = this.state.Detalle;
        const indice = this.state.indice;
        Detalle[indice].Codigo = this.state.codigo;
        Detalle[indice].Nombre = this.state.nombre;
        this.setState({mostrarModal:false, cambio:true});
    }
    codigoIns = (item) =>{
        this.setState({popoverOpen:false});
        this.cargarArticulo(item.I);
    }
    cargarArticulo = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/articulo/cargar/" + cve;
        const resp = await fetch(url);
        const articulo = await resp.json();
        if(articulo.Codigo === '' || articulo.Depto === 3){
            Swal.fire('Atencion','Este codigo no corresponde a un insumo o subproducto','error');
            this.setState({cargando:false});
            return;
        }
        this.setState({
            cargando:false,
            codigo:articulo.Codigo,
            nombre:articulo.Nombre,
            articulo
        },() => {
            this.agregarRenglon();
        });
    }
    agregarDetalle = () =>{
        this.setState({mostrarModal:true, renglon : 0, agregando:true});
    }
    quitarRenglon = (ren,i) =>{
    }
    editarRenglon = (ren,i) =>{
        this.setState({
            agregando:false,
            mostrarModal:true,
            renglon:ren.Renglon,
            codigo:ren.Codigo,
            nombre:ren.Nombre,
            indice:i,
        });
    }
    cancelar = () =>{
        if(!this.state.cambio){
            this.props.cancelar();
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea cancelar la captura ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.cancelar();
            }
          })        
    }
    guardar = () =>{
        if(this.state.prov === 0){
            Swal.fire('Atencion','No se ha especificado el proveedor','error');
            this.setState({cargando:false});
            return;
        }
        if(this.state.factura === ''){
            Swal.fire('Atencion','No se ha especificado la factura del proveedor','error');
            this.setState({cargando:false});
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar este movimiento ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.guardar(this.state);
            }
          })        
    }
    onOrdenSelect = (i,v) =>{
        let orden = '';
        let sepa = '';
        let ordenes = this.state.ordenes;
        ordenes[i].Sel = v;
        for (i = 0; i < ordenes.length; i++) {
            if(ordenes[i].Sel){
                orden = orden + sepa + ordenes[i].Num;
                sepa = ',';
            }
        } 
        this.setState({ordenes,orden});
    }
    editarRecibido = (i,r) =>{
        this.state.Detalle[i].Recibido = r;
        this.setState({cambio:true});
    }
    render(){
        return(
            <div className="container">
                <div className="row">
                    <div className="col-1">
                        <FormGroup>
                            <span>Numero</span><br />
                            <Input size="sm" type="text" name="Numero" value={this.state.Numero} readOnly />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup>
                            <span>Fecha</span><br />
                            <Input size="sm" type="date" name="Fecha" value={this.state.Fecha} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <span>Proveedor </span><br/>
                        <InputGroup>
                            <Input size="sm" type="text" name="prov" value={this.state.prov} onChange={this.onChange} onKeyPress={this.onKeyPressProv} id="prov" autoFocus />
                            <InputGroupAddon addonType="append">
                                <Button size="sm" color="success" id="btnBuscarProv">
                                    <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                </Button>
                                <Popover trigger="legacy" placement="right" isOpen={this.state.popoverProvOpen} target="btnBuscarProv" toggle={this.toggleProv} >
                                    <PopoverBody>
                                        <Buscador onSelect={this.provSel} url={this.props.urlws + "/prov/lista?filtro="} tema="provs" />
                                    </PopoverBody>
                                </Popover>
                            </InputGroupAddon>
                        </InputGroup>
                    </div>
                    <div className="col-3">
                        <span>&nbsp;</span><br/>
                        <Input size="sm" type="text" value={this.state.nomProv} readOnly/>
                    </div>
                    <div className="col-2">
                        <span>Factura </span><br />
                        <InputGroup>
                            <Input size="sm" type="text" name="factura" value={this.state.factura} readOnly />
                            <InputGroupAddon addonType="append">
                                <Button size="sm" color="success" id="btnBuscarFact">
                                    <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                </Button>
                                <Popover trigger="legacy" placement="right" isOpen={this.state.popoverFactOpen} target="btnBuscarFact" toggle={this.toggleFact} >
                                    <PopoverBody>
                                        <Facturas onSelect={this.factSel} facturas={this.state.facturas} />
                                    </PopoverBody>
                                </Popover>
                            </InputGroupAddon>
                        </InputGroup>
                    </div>
                    <div className="col-2">
                        <span>Ordenes </span><br />
                        <InputGroup>
                            <Input size="sm" type="text" name="orden" value={this.state.orden} readOnly />
                            <InputGroupAddon addonType="append">
                                <Button size="sm" color="success" id="btnBuscarOrd">
                                    <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                </Button>
                                <Popover trigger="legacy" placement="right" isOpen={this.state.popoverOrdOpen} target="btnBuscarOrd" toggle={this.toggleOrd} >
                                    <PopoverBody>
                                        <Ordenes onSelect={this.ordSel} ordenes={this.state.ordenes} onSelect={this.onOrdenSelect}/>
                                    </PopoverBody>
                                </Popover>
                            </InputGroupAddon>
                        </InputGroup>
                    </div>
                </div>
                <div style={{ height: 4 }}></div>
                <div style={{ height: this.props.height, overflow: 'auto' }}>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th style={{ width: 100 }}>Codigo</th>
                                <th>Nombre</th>
                                <th style={{ width: 70 }} >Unidad</th>
                                <th style={{ width: 70 }} className="numero">Cantidad</th>
                                <th style={{ width: 80 }} className="numero">Recibido</th>
                                <th style={{ width: 70 }} >Unidad</th>
                                <th style={{ width: 100 }} className="numero">Precio</th>
                                <th style={{ width: 100 }} className="numero">Importe</th>
                                <th style={{width:30, textAlign:'center'}}></th>
                                <th style={{width:30, textAlign:'center'}}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.Detalle.map((ren, i) => (
                                <DetalleRow key={i} indice={i} renglon={ren} onRowClick={this.editarRenglon} onRowQuitar={this.quitarRenglon} editarRecibido={this.editarRecibido} />
                            ))}
                        </tbody>
                    </Table>
                </div>
                <div className="row">
                    <div className="col-6">
                        <Button size="sm" color="success" onClick={this.guardar}>Guardar</Button>
                        <span>&nbsp;&nbsp;</span>
                        <Button size="sm" color="danger" onClick={this.cancelar}>Cancelar</Button>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <Button size="sm" color="success" onClick={this.agregarDetalle}>+</Button>
                        <span>&nbsp;&nbsp;</span>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span>Fecha de la factura <span className="total">{this.state.fechaFact2}</span></span>
                    </div>
                    <div className="col-6" style={{fontWeight:'bold', textAlign:'right'}}>
                        <span>&nbsp;&nbsp;</span>
                        <span >Subtotal <span className="total">{this.state.subtotal.formato()}</span></span>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span >I.V.A. <span className="total">{this.state.iva.formato()}</span></span>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span >I.E.P.S. <span className="total">{this.state.ieps.formato()}</span></span>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span >Total <span className="total">{this.state.total.formato()}</span></span>
                    </div>
                </div>
                <Modal id="dlg" name="dlg" isOpen={this.state.mostrarModal} toggle={this.handleClose} size="lg" tabIndex={1}>
                    <ModalBody>
                        <div className="row">
                            <div className="col">
                                <FormGroup md="3" >
                                    <label>Codigo &nbsp;</label>
                                    <InputGroup>
                                        <Input size="sm" type="text" name="codigo" value={this.state.codigo} onChange={this.onChange} onKeyPress={this.onKeyPressCodigo} id="codigo" autoFocus />
                                        <InputGroupAddon addonType="append">
                                            <Button size="sm" color="success" id="btnBuscarCod">
                                                <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                            </Button>
                                            <Popover trigger="legacy" placement="right" isOpen={this.state.popoverCodOpen} target="btnBuscarCod" toggle={this.toggleCod} >
                                                <PopoverBody>
                                                    <Buscador onSelect={this.codigoIns} url={this.props.urlws + "/articulo/articulos?depto=0&filtro="} tema="articulos" />
                                                </PopoverBody>
                                            </Popover>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                            </div>
                            <div className="col">
                                <FormGroup md="4">
                                    <label>Nombre &nbsp;</label>
                                    <Input size="sm" type="text" value={this.state.nombre} readOnly style={{ width: 600 }} />
                                </FormGroup>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}
class DetalleRow extends React.Component{
    onClick = (e) =>{
        console.log(this.props.renglon);
        this.props.onRowClick(this.props.renglon, this.props.indice);
    }
    onChange = (e) =>{
        if(e.target.name == "recibido"){
           this.props.editarRecibido(this.props.indice,e.target.value);
        }
    }
    onQuitar = (e) =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea eliminar este renlon (" + this.props.renglon.Nombre + ") ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.onRowQuitar(this.props.renglon, this.props.indice);
            }
        });
    }
    render(){
        let {renglon} = this.props;
        return (
        <tr>
            <td style={{verticalAlign:'middle'}}>{renglon.Codigo}</td>
            <td style={{verticalAlign:'middle'}}>{renglon.Nombre}</td>
            <td style={{verticalAlign:'middle'}}>{renglon.Unidad}</td>
            <td style={{verticalAlign:'middle'}} className="numero">{renglon.Cantidad.formato4()}</td>
            <td className="numero">
                <Input size="sm" name="recibido" value={renglon.Recibido} onChange={this.onChange} />
            </td>
            <td style={{verticalAlign:'middle'}}>{renglon.UnidadInv}</td>
            <td style={{verticalAlign:'middle'}} className="numero">{renglon.Precio.formato()}</td>
            <td style={{verticalAlign:'middle'}} className="numero">{renglon.Importe.formato()}</td>
            <td>
                <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={this.onClick}/>
            </td>
            <td>
                <img src="/imagenes/quitar.png" alt="eliminar" title="eliminar" onClick={this.onQuitar}/>
            </td>
        </tr>
        );
    }
}
class Facturas extends React.Component{
    onRowClick = (item) => {
        this.props.onSelect(item);
        document.body.click();
    }
    render(){
        return (
            <div style={{padding:5}} className="body">
                <div className="row">
                <div className="col-11" style={{ height: 300, overflow: 'auto' }}>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th style={{width:140}}>Folio</th>
                                <th style={{width:85}}>Fecha</th>
                                <th  style={{width:100}} className="numero">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.facturas.map((item, index) => {
                                return (
                                    <tr key={index} onClick={() => this.onRowClick(item)}>
                                        <td>{item.Fol}</td>
                                        <td>{item.Fec}</td>
                                        <td className="numero">{item.Tot}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
                </div>
            </div>
        );
    }
}
class Ordenes extends React.Component{
    render(){
        return (
            <div style={{padding:5}} className="body">
                <div className="row">
                <div className="col-11" style={{ height: 300, overflow: 'auto' }}>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Tipo</th>
                                <th  style={{width:100}} className="numero">Folio</th>
                                <th style={{width:85}}>Fecha</th>
                                <th style={{width:40}}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.ordenes.map((item, index) => {
                                return (
                                    <tr key={index} >
                                        <td>OC</td>
                                        <td className="numero">{item.Num}</td>
                                        <td>{item.Fec}</td>
                                        <td><input type="checkbox" checked={item.Sel} onChange={(e) => this.props.onSelect(index,e.target.checked) }/></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
                </div>
            </div>
        );
    }
}