import React from 'react';
import { Nav,Navbar,InputGroup, InputGroupAddon, Collapse, Table, Form, FormGroup, Col, Input, Button, Container, Spinner } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class Empleados extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cargando:true,
            empresa:1,
            formVisible: false,
            clave: '',
            nombre:'A',
            apePat:'',
            apeMat:'',
            objeto:{},
            empresas:[],
            objetos: [],
            estados: [],
            regimenes:[],
            metodos:[],
            bancos:[],
            tiposContrato:[],
            periodicidades:[],
            riesgos:[],
            tiposJornada:[],
            deptos:[],
            opcionesInfo:[],
            sucursales:[]
        };
        this.cargarCatalogos();
        this.cargarCatalogosEmpresa();
        this.cargarObjetos();
    }
    onFormClose = () => {
        this.setState({
            formVisible: false
        });
    }
    cargarObjetos = async () => {
        this.setState({cargando:true});
        //const url = `${this.props.urlws}/nomina/empleados?empresa=${this.state.empresa}&filtro=${this.state.nombre}`;
        const url = `${this.props.urlws}/nomina/empleadosBuscar?empresa=${this.state.empresa}&nom=${this.state.nombre}&apePat=${this.state.apePat}&apeMat=${this.state.apeMat}`;
        const resp = await fetch(url);
        const objetos = await resp.json();
        this.setState({cargando:false, objetos });
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/nomina/catalogoSat/estados`;
        let resp = await fetch(url);
        const estados = await resp.json();
        url = `${this.props.urlws}/empresa/empresasNomina`;
        resp = await fetch(url);
        const empresas = await resp.json();
        url = `${this.props.urlws}/nomina/catalogoSat/formas`;
        resp = await fetch(url);
        const metodos = await resp.json();
        url = `${this.props.urlws}/nomina/catalogoSat/bancos`;
        resp = await fetch(url);
        const bancos = await resp.json();
        url = `${this.props.urlws}/nomina/catalogoSat/regimen`;
        resp = await fetch(url);
        const regimenes = await resp.json();
        url = `${this.props.urlws}/nomina/catalogoSat/contratos`;
        resp = await fetch(url);
        const tiposContrato = await resp.json();
        url = `${this.props.urlws}/nomina/catalogoSat/jornadas`;
        resp = await fetch(url);
        const tiposJornada = await resp.json();
        url = `${this.props.urlws}/nomina/catalogoSat/riesgos`;
        resp = await fetch(url);
        const riesgos = await resp.json();
        url = `${this.props.urlws}/nomina/catalogoSat/periodicidad`;
        resp = await fetch(url);
        const periodicidades = await resp.json();
        url = `${this.props.urlws}/nomina/catalogoSat/infonavit`;
        resp = await fetch(url);
        const opcionesInfo = await resp.json();
        this.setState({cargando:false,empresas,estados,metodos,bancos,regimenes,tiposContrato, riesgos, tiposJornada,periodicidades,opcionesInfo });
    }
    cargarCatalogosEmpresa = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/nomina/departamentos/${this.state.empresa}`;
        let resp = await fetch(url);
        const deptos = await resp.json();
        url = `${this.props.urlws}/nomina/sucursales/${this.state.empresa}`;
        resp = await fetch(url);
        const sucursales = await resp.json();
        this.setState({cargando:false,deptos,sucursales});
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.cargarObjetos();
    }
    onChange = (e) => {
        const campo = e.target.name;
        this.setState({ [campo] : e.target.value},()=>{
            if(campo === "empresa"){
                this.cargarCatalogosEmpresa();
                this.cargarObjetos();
            }
        });
    }
    onBntBuscarClick = () =>{
        this.cargarObjetos();
    }    
    cargar = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/nomina/cargarEmpleado/" + cve + "?empresa=" + this.state.empresa;
        const resp = await fetch(url);
        const objeto = await resp.json();
         this.setState({
            cargando:false,
            formVisible: true,
            clave: cve,
            objeto
        });
    }
    guardar = async(dato) =>{
        const url = this.props.urlws +"/nomina/wsguardarEmpleado";
        this.setState({cargando:true});
        if(dato.Codigo === 0)
           dato.Codigo = '';
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.onFormClose();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    nuevo = () => {
        this.cargar(0);
    }
    imprimirCredencial = (emp) =>{
        const url = `${this.props.urlws}/nomina/imprimirCredencial?empresa=${this.state.empresa}&empleado=${emp}`;
        window.open(url, '', '');
    }
    excel = () =>{
    }
    render() {
        const estilo = { display: this.state.formVisible ? 'none' : 'inline' };
        return (
            <Container >
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo}>
                <Navbar bg="f5e831" expand="lg">
                    <Collapse isOpen={true} id="basic-navbar-nav">
                        <Nav className="mr-auto" size="sm">
                        <Form inline onSubmit={this.onSubmit} >
                                <span>Empresa</span>
                                <Input size="sm" type="select" name="empresa" value={this.state.empresa} onChange={this.onChange} style={{ fontSize: '12px', width:'300px' }}>
                                    <option key="0" value={0}>Especifique</option>
                                    {this.state.empresas.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                                <span>&nbsp;&nbsp;</span>
                            <Button size="sm" color="success" outline onClick={this.nuevo}><img src="/imagenes/add.png"/></Button>
                            &nbsp;
                            <span size="sm">Nombre</span>&nbsp;
                            <Input size="sm" type="text" placeholder="Buscar" name="nombre" value={this.state.nombre} onChange={this.onChange} style={{width:120}} />
                            &nbsp;<span size="sm">Ape.Pat.</span>&nbsp;
                            <Input size="sm" type="text" placeholder="Buscar" name="apePat" value={this.state.apePat} onChange={this.onChange} style={{width:110}} />
                            &nbsp;<span size="sm">Ape.Mat.</span>&nbsp;
                            <Input size="sm" type="text" placeholder="Buscar" name="apeMat" value={this.state.apeMat} onChange={this.onChange} style={{width:110}} />
                            &nbsp;<Button size="sm" color="success" onClick={this.onBntBuscarClick}><img src="/imagenes/zoom.png" /> </Button>
                            &nbsp;
                            <Button size="sm" color="success" outline onClick={this.imprimir}><img src="/imagenes/printer.png"/></Button>
                            &nbsp;
                            <Button size="sm" color="success" outline onClick={this.excel}><img src="/imagenes/excel.png"/></Button>
                        </Form>
                        </Nav>
                    </Collapse>
                </Navbar>
                <div style={{ height: 4 }}></div>
                    <ObjetoTable objetos={this.state.objetos} height={500} width={990} onRowClick={this.cargar} imprimir={this.imprimirCredencial} />
                </div>
                <ObjetoForm urlws={this.props.urlws} visible={this.state.formVisible} tipos={this.state.tipos} objeto={this.state.objeto} onClose={this.onFormClose} clave={this.state.clave} guardar={this.guardar} estados={this.state.estados} sucursales={this.state.sucursales}
                            metodos={this.state.metodos} bancos={this.state.bancos} regimenes={this.state.regimenes} periodicidades={this.state.periodicidades} tiposContrato={this.state.tiposContrato} riesgos={this.state.riesgos} tiposJornada={this.state.tiposJornada} deptos={this.state.deptos} opcionesInfo={this.state.opcionesInfo}/>
            </Container>
        );
    }
}

class ObjetoTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { objetos } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:100}}>Id</th>
                        <th>Nombre</th>
                        <th style={{width:150}}>Puesto</th>
                        <th style={{width:30}}></th>
                        <th style={{width:30}}></th>
                    </tr>
                </thead>
                <tbody>
                    {objetos.map((o, i) => (
                        <ObjetoRow key={i} objeto={o} onRowClick={this.onRowClick} imprimir={this.props.imprimir} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}

class ObjetoRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.objeto.Id);
    }
    imprimir = (e) =>{
        this.props.imprimir(this.props.objeto.Id);
    }
    render(){
        let {objeto} = this.props;
        return (
        <tr>
            <td>{objeto.Id}</td>
            <td>{objeto.Nombre}</td>
            <td>{objeto.Puesto}</td>
            <td>
                <img src="/imagenes/edit.png" onClick={this.onClick} alt="editar" title="editar"/>
            </td>
            <td>
                <img src="/imagenes/printer.png" onClick={this.imprimir} alt="Imprimir credencial" title="Imprimir credencial"/>
            </td>
        </tr>
        );
    }
}

class ObjetoForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            objeto:this.props.objeto
        };
    }
    componentWillReceiveProps(newProps){
        this.setState({
            objeto:newProps.objeto,
        });
    }
    onChange = (e) => {
        const campo = e.target.name;
        const obj = {
            ...this.state.objeto,
            [campo] : e.target.value
        };
        this.setState({objeto:obj});
    }
    onChecked = (e) => {
        const obj = {
            ...this.state.objeto,
            [e.target.name] : e.target.checked
        };
        this.setState({objeto:obj});
    }

    guardar = () =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.guardar(this.state.objeto);
            }
          })        
    }
    render() {
        const estilo = { display: this.props.visible ? 'inline' : 'none'};
        if(this.props.objeto === undefined)
            return null;
        if(Object.keys(this.props.objeto).length === 0)
            return null;
        const calcInfo = parseInt(this.state.objeto.CalculoInfonavit);
        const estiloInfoSM = { display: calcInfo > 0 ? 'inline' : 'none'};
        return (
            <Container style={estilo}>
                <Form>
                    <div class="row">
                        <div className="col-1">
                            <FormGroup >
                                <span>Id</span>
                                <Input size="sm" type="text" name="Codigo" value={this.state.objeto.Id} />
                            </FormGroup>
                        </div>
                        {' '}
                        <div className="col-2">
                            <FormGroup >
                                <span>Apellido paterno</span>
                                <Input size="sm" type="text" name="ApePat" value={this.state.objeto.ApePat} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup >
                                <span>Apellido materno</span>
                                <Input size="sm" type="text" name="ApeMat" value={this.state.objeto.ApeMat} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup >
                                <span>Nombre</span>
                                <Input size="sm" type="text" name="Nombre" value={this.state.objeto.Nombre} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup >
                                <span>R.F.C.</span>
                                <Input size="sm" type="text" name="Rfc" value={this.state.objeto.Rfc} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-3">
                            <FormGroup >
                                <span>CURP</span>
                                <Input size="sm" type="text" name="Curp" value={this.state.objeto.Curp} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                    </div>

                    <div class="row">
                        <div className="col-3">
                            <FormGroup >
                                <span>Reg. IMSS</span>
                                <Input size="sm" type="text" name="RegImss" value={this.state.objeto.RegImss} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup >
                                <span>Fecha ingreso</span>
                                <Input size="sm" type="date" name="FechaIngreso" value={this.state.objeto.FechaIngreso} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup >
                                <span>Sueldo</span>
                                <Input size="sm" type="text" name="Sueldo" value={this.state.objeto.Sueldo} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup >
                                <span>Sueldo Int.</span>
                                <Input size="sm" type="text" name="SueldoInt" value={this.state.objeto.SueldoInt} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-3">
                            <FormGroup >
                                <span>Puesto</span>
                                <Input size="sm" type="text" name="Puesto" value={this.state.objeto.Puesto} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                    </div>
                    <div class="row">
                        <div className="col-2">
                            <FormGroup>
                                <span>Sucursal</span>
                                <Input size="sm" type="select" name="Sucursal" id="Sucursal" value={this.state.objeto.Sucursal} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>    
                                    {this.props.sucursales.map((i,k) =>(
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-3">
                            <FormGroup>
                                <span>Departamento</span>
                                <Input size="sm" type="select" name="Depto" id="Depto" value={this.state.objeto.Depto} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>    
                                    {this.props.deptos.map((i,k) =>(
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Riesgo</span>
                                <Input size="sm" type="select" name="Riesgo" id="Riesgo" value={this.state.objeto.Riesgo} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>    
                                    {this.props.riesgos.map((i,k) =>(
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-3">
                            <FormGroup >
                                <span>Email</span>
                                <Input size="sm" type="text" name="Email" value={this.state.objeto.Email} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Estado</span>
                                <Input size="sm" type="select" name="Estado" id="Estado" value={this.state.objeto.Estado} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>    
                                    {this.props.estados.map((i,k) =>(
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                    </div>

                    <div class="row">
                        <div className="col-3">
                            <FormGroup >
                                <span>Calle</span>
                                <Input size="sm" type="text" name="Calle" value={this.state.objeto.Calle} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-1">
                            <FormGroup >
                                <span>No.Ext.</span>
                                <Input size="sm" type="text" name="NumeroExt" value={this.state.objeto.NumeroExt} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-1">
                            <FormGroup >
                                <span>C.P.</span>
                                <Input size="sm" type="text" name="CP" value={this.state.objeto.CP} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-3">
                            <FormGroup >
                                <span>Colonia</span>
                                <Input size="sm" type="text" name="Colonia" value={this.state.objeto.Colonia} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup >
                                <span>Ciudad</span>
                                <Input size="sm" type="text" name="Ciudad" value={this.state.objeto.Ciudad} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup >
                                <span>Municipio</span>
                                <Input size="sm" type="text" name="Municipio" value={this.state.objeto.Municipio} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                    </div>

                    <div class="row">
                        <div className="col-2">
                            <FormGroup>
                                <span>Forma de pago</span>
                                <Input size="sm" type="select" name="MetodoPago" id="MetodoPago" value={this.state.objeto.MetodoPago} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>    
                                    {this.props.metodos.map((i,k) =>(
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Banco</span>
                                <Input size="sm" type="select" name="Banco" id="Banco" value={this.state.objeto.Banco} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>    
                                    {this.props.bancos.map((i,k) =>(
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup >
                                <span>CLABE</span>
                                <Input size="sm" type="text" name="Clabe" value={this.state.objeto.Clabe} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Regimen</span>
                                <Input size="sm" type="select" name="Regimen" id="Regimen" value={this.state.objeto.Regimen} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>    
                                    {this.props.regimenes.map((i,k) =>(
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Jornada</span>
                                <Input size="sm" type="select" name="TipoJornada" id="TipoJornada" value={this.state.objeto.TipoJornada} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>    
                                    {this.props.tiposJornada.map((i,k) =>(
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Contrato</span>
                                <Input size="sm" type="select" name="TipoContrato" id="TipoContrato" value={this.state.objeto.TipoContrato} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>    
                                    {this.props.tiposContrato.map((i,k) =>(
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <FormGroup >
                                <span>Horario</span>
                                <Input size="sm" type="text" name="Horario" value={this.state.objeto.Horario} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-1">
                            <FormGroup >
                                <span>Sindicalizado</span><br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input size="sm" type="checkbox" name="Sindicalizado" checked={this.state.objeto.Sindicalizado} onChange={this.onChecked} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Infonavit</span>
                                <Input size="sm" type="select" name="CalculoInfonavit" id="CalculoInfonavit" value={this.state.objeto.CalculoInfonavit} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>    
                                    {this.props.opcionesInfo.map((i,k) =>(
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-1" style={estiloInfoSM}>
                            <FormGroup >
                                <span>SMDF</span>
                                <Input size="sm" type="text" name="SMDFInfo" value={this.state.objeto.SMDFInfo} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-1">
                            <FormGroup >
                                <span>Cuota</span>
                                <Input size="sm" type="text" name="CuotaInfo" value={this.state.objeto.CuotaInfo} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-1">
                            <FormGroup >
                                <span>Porcentaje</span>
                                <Input size="sm" type="text" name="PtjeInfo" value={this.state.objeto.PtjeInfo} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-1" style={estiloInfoSM}>
                            <FormGroup >
                                <span>Seg.Bim.</span>
                                <Input size="sm" type="text" name="SeguroInfo" value={this.state.objeto.SeguroInfo} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Periodicidad</span>
                                <Input size="sm" type="select" name="Periodicidad" id="Periodicidad" value={this.state.objeto.Periodicidad} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>    
                                    {this.props.periodicidades.map((i,k) =>(
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                    </div>
                </Form>
                <br />
                <Button color="primary" onClick={this.guardar}>Aceptar</Button>
                {'  '}
                <Button color="danger" onClick={this.props.onClose}>Cancelar</Button>
            </Container>
        );
    }
}