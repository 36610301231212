import React from 'react';
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class OrdenesCompra extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');
        this.state = {
            cargando:false,
            editando:false,
            verReq:false,
            Temporada:this.props.temp,
            almacen:1,
            prov:0,
            fechaIni:fecIni,
            fechaFin:fecFin,
            almacenes:[],
            ordenes:[],
            temporadas:[],
            proves:[],
            requisicion:{
                Clave:'',
                Numero:0
            }
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/invMovs/almacenes`;
        let resp = await fetch(url);
        const almacenes = await resp.json();
        url = `${this.props.urlws}/agricola/temporadas`;
        resp = await fetch(url);
        const temporadas = await resp.json();
        url = `${this.props.urlws}/prov/lista?filtro=`;
        resp = await fetch(url);
        const proves = await resp.json();
        this.setState({cargando:false, almacenes,temporadas, proves});
    }
    cargarMovs = async () =>{
        if(this.state.Temporada === ''){
            Swal.fire('Atencion','Especifique la temporada','error');
            return;
        }
        if(this.state.almacen == 0){
            Swal.fire('Atencion','Especifique el almacen','error');
            return;
        }
        const temp = this.state.Temporada;
        const alm = this.state.almacen;
        const cve = this.state.clave; 
        const fecIni = this.state.fechaIni;
        const fecFin = this.state.fechaFin;
        const prov = this.state.prov;
        this.setState({cargando:true});
        let url = `${this.props.urlws}/invMovs/ordenes?alm=${alm}&fecIni=${fecIni}&fecFin=${fecFin}&temp=${temp}&prov=${prov}`;
        let resp = await fetch(url);
        const ordenes = await resp.json();
        this.setState({cargando:false, ordenes });
    }
    imprimirOrden = (num) =>{
        let params = '?alm=' + this.state.almacen + '&cve=OC&num=' + num;
        let url = this.props.urlws + '/invmovs/imprimirOrdenCompra' + params;
        window.open(url, '', '');
    }
    asignarFactura = async(indice,oc,factura) =>{
        const alm = oc.Almacen;
        const cve = oc.Clave;
        const num = oc.Numero;
        this.setState({cargando:true});
        const url = `${this.props.urlws}/invmovs/asignaOCFactura?alm=${alm}&cve=${cve}&num=${num}&factura=${factura}`;
        const resp = await fetch(url);
        const res = await resp.text();
        let {ordenes} = this.state;
        if(res === "ok"){
            ordenes[indice].Factura = factura;
            this.setState({cargando:false,ordenes});
            Swal.fire('Atencion','Se asigno la factura a la orden de compra','success');
        }
        else{
            this.setState({cargando:false});
            Swal.fire('Atencion',res,'error');
        }
    }
    enviarOrdenEmail = async(oc,email) =>{
        const alm = oc.Almacen;
        const cve = oc.Clave;
        const num = oc.Numero;
        this.setState({cargando:true});
        const url = `${this.props.urlws}/invmovs/enviarOrdenCompra?alm=${alm}&cve=${cve}&num=${num}&email=${email}`;
        const resp = await fetch(url);
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok")
            Swal.fire('Atencion','Se envio la orden','success');
        else
            Swal.fire('Atencion',res,'error');
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor});
    }    
    verReq = async(cve,num) =>{
        this.setState({cargando:true});
        const alm = this.state.almacen;
        let url = `${this.props.urlws}/invMovs/cargarReq?alm=${alm}&cve=${cve}&num=${num}`;
        let resp = await fetch(url);
        const requisicion = await resp.json();
        this.setState({cargando:false, verReq:true, requisicion });
        console.log(requisicion);
    }
    imprimirReq = async() =>{
        const mov = this.state.requisicion;
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Incluir todas las cotizaciones ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            let todo = result.value?"S":"N";
            let params = '?alm=' + mov.Almacen + '&cve=' + mov.Clave + '&num=' + mov.Numero + '&todo=' + todo;
            let url = this.props.urlws + '/invmovs/imprimirReq' + params;
            window.open(url, '', '');    
          })        
    }
    handleClose = () =>{
        this.setState({verReq:false});
    }
    render(){
        return(
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div>
                    <div className="row">
                        <div className="col-1">
                            <FormGroup>
                                <span>Temporada</span><br />
                                <Input size="sm" type="select" name="Temporada" value={this.state.Temporada} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                    <option key="0" value="">Especifique</option>
                                    {this.state.temporadas.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Almacen</span><br />
                                <Input size="sm" type="select" name="almacen" value={this.state.almacen} onChange={this.onChange}>
                                    <option key="0" value={0}>Especifique</option>
                                    {this.state.almacenes.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Del dia</span><br />
                                <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>al dia</span><br />
                                <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-4">
                            <FormGroup>
                                <span>Proveedor</span><br />
                                <Input size="sm" type="select" name="prov" value={this.state.prov} onChange={this.onChange}>
                                    <option key="0" value={0}>Todos</option>
                                    {this.state.proves.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col">
                            <FormGroup as={Col}>
                                <span>&nbsp;</span><br />
                                <Button color='success' size="sm" onClick={this.cargarMovs}>Cargar</Button>
                            </FormGroup>
                        </div>
                    </div>
                    <div style={{ height: 4 }}></div>
                    <div>
                    {this.state.ordenes.map((ren, i) => (
                        <OrdenRow key={i} indice={i} renglon={ren} verReq={this.verReq} imprimirOrden={this.imprimirOrden} enviarOrden={this.enviarOrdenEmail} asignarFactura={this.asignarFactura}/>
                    ))}
                    </div>
                </div>
                <Modal id="dlg" name="dlg" isOpen={this.state.verReq} toggle={this.handleClose} size="lg">
                    <ModalHeader>
                    <span>Requisicion <b>{this.state.requisicion.Clave}-{this.state.requisicion.Numero}</b></span> 
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;Fecha <b>{this.state.requisicion.Fecha}</b></span> 
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <Button size="sm" outline color="success" onClick={this.imprimirReq}>
                        <img src="/imagenes/printer.png" alt="imprimir" title="imprimir"/>
                    </Button>
                    </ModalHeader>
                    <ModalBody>
                        <Requisicion req={this.state.requisicion}/>
                    </ModalBody>
                </Modal>
            </Container>
        )
    }
}
class OrdenRow extends React.Component{
    imprimir = () =>{
        this.props.imprimirOrden(this.props.renglon.Numero)
    }
    email = async() =>{
        const { value: email } = await Swal.fire({
            input: 'email',
            title: 'Enviar orden por email',
            inputValue:this.props.renglon.Email,
            inputPlaceholder: 'ingrese la direccion de email'
          })
          
          if (email) {
            this.props.enviarOrden(this.props.renglon,email)
          }
    }
    asignar = async() =>{
        const { value: factura } = await Swal.fire({
            input: 'text',
            title: 'Numero de factura del proveedor',
            inputValue:this.props.renglon.Factura,
            inputPlaceholder: 'No. factura'
          })
          if (factura || this.props.renglon.Factura) {
            this.props.asignarFactura(this.props.indice,this.props.renglon,factura)
          }
    }
    render(){
        let {renglon} = this.props;
        let tam = renglon.Detalle.length;
        return (
            <div className="container">
                <div className="row">
                    <div className="col-1"><b>{renglon.Numero}</b></div>
                    <div className="col-1"><b>{renglon.Fecha}</b></div>
                    <div className="col-6"><b>{renglon.Prov}</b></div>
                    {
                        renglon.Status == 99 ?
                            <React.Fragment>
                                <div className="col-4">
                                    <img src="/imagenes/printer.png" alt="imprimir" title="imprimir" onClick={this.imprimir} />
                                    <br/>
                                </div>
                            </React.Fragment>
                        :
                            <React.Fragment>
                                <div className="col-1">
                                    <img src="/imagenes/printer.png" alt="imprimir" title="imprimir" onClick={this.imprimir} />
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <img src="/imagenes/email.png" alt="enviar por email" title="enviar por email" onClick={this.email} />
                                </div>
                                <div className="col-2">
                                    Factura : <b className="rojo">{renglon.Factura}</b>&nbsp;&nbsp;&nbsp;
                        <img src="/imagenes/edit.png" alt="asignar factura del proveedor" title="asignar factura del proveedor" onClick={this.asignar} />
                                </div>
                                <div className="col">
                                    <Button color="warning" size="sm" onClick={() => this.props.verReq(renglon.CveR, renglon.NumR)}>{renglon.CveR}-{renglon.NumR}</Button>
                                </div>
                            </React.Fragment>
                    }
                </div>
                <div className="row">
                    {
                        renglon.Status == 99 &&
                        <span className="rojo">CANCELADA</span>
                    }
                    {tam > 0 ?
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th style={{ width: 100 }}>Codigo</th>
                                <th>Nombre</th>
                                <th className="numero" style={{width:100}}>Cantidad</th>
                                <th className="numero" style={{width:100}}>Precio</th>
                                <th className="numero" style={{width:100}}>Importe</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renglon.Detalle.map((ren, i) => (
                                <tr>
                                    <td>{ren.Codigo}</td>
                                    <td>{ren.Nombre}</td>
                                    <td className="numero">{ren.Cantidad.formato()}</td>
                                    <td className="numero">{ren.Precio.formato()}</td>
                                    <td className="numero">{ren.Importe.formato()}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    : null
                    }
                </div>
                <br/>
            </div>
        );
    }
}
class Requisicion extends React.Component{
    render(){
        const {req} = this.props;
        return(
            <React.Fragment>
                <span>{req.Concepto}</span><br />
                <br />
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th style={{ width: 100 }}>Cant</th>
                            <th>Nombre</th>
                            <th>Destino</th>
                        </tr>
                    </thead>
                    <tbody>
                        {req.Detalle.map((ren, i) => (
                            <tr>
                                <td>{ren.Cantidad}</td>
                                <td>{ren.Nombre}</td>
                                <td>{ren.Dest}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }
}