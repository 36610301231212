import React from 'react';
import { Nav,Navbar,NavbarBrand, Collapse, Table, Form, FormGroup, InputGroup, InputGroupAddon, Input, Button, Container, Spinner } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class Destinos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cargando:true,
            formVisible: false,
            clave: 0,
            nombre:'?',
            objeto:{},
            objetos: []
        };
        this.cargarObjetos();
    }
    onFormClose = () => {
        this.setState({
            formVisible: false
        });
    }
    cargarObjetos = async () => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/embarque/destinos`;
        const resp = await fetch(url);
        const objetos = await resp.json();
        this.setState({cargando:false, objetos });
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.cargarObjetos();
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    onBntBuscarClick = () =>{
        this.cargarObjetos();
    }    
    cargarObjeto = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/embarque/cargarDestino/" + cve;
        const resp = await fetch(url);
        const objeto = await resp.json();
        if(objeto.Id === 0){
         }
         this.setState({
            cargando:false,
            formVisible: true,
            clave: cve,
            objeto
        });
    }
    guardar = async(cte) =>{
        const url = this.props.urlws +"/embarque/grabarDestino";
        this.setState({cargando:true});
        const json = JSON.stringify(cte);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.onFormClose();
            this.cargarObjetos();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    nuevo = () => {
        this.cargarObjeto(-1);
    }
    render() {
        const estilo = { display: this.state.formVisible ? 'none' : 'inline' };
        return (
            <Container >
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo}>
                <Navbar bg="f5e831" expand="lg">
                    <NavbarBrand  size="sm" style={{color:'black'}}>Destinos</NavbarBrand >
                        <Collapse isOpen={true} id="basic-navbar-nav">
                            <Nav className="mr-auto" size="sm">
                                <Button size="sm" color="success" outline onClick={this.nuevo}><img src="/imagenes/add.png" /></Button>
                                &nbsp;
                            <Form inline onSubmit={this.onSubmit} >
                                    <label size="sm">Nombre</label>&nbsp;
                            <InputGroup>
                                        <Input size="sm" type="text" placeholder="Buscar" name="nombre" onChange={this.onChange} style={{ width: 400 }} />
                                        <InputGroupAddon addonType="append">
                                            <Button size="sm" color="success" onClick={this.onBntBuscarClick}> Buscar <img src="/imagenes/zoom.png" /> </Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                    &nbsp;
                                    &nbsp;
                            <Button size="sm" color="success" outline onClick={this.imprimir}><img src="/imagenes/printer.png" /></Button>
                                    &nbsp;
                            <Button size="sm" color="success" outline onClick={this.excel}><img src="/imagenes/excel.png" /></Button>
                                </Form>
                            </Nav>
                        </Collapse>
                </Navbar>
                <div style={{ height: 4 }}></div>
                    <ObjTable objetos={this.state.objetos} height={500} width={990} onRowClick={this.cargarObjeto} />
                </div>
                <ObjForm visible={this.state.formVisible} objeto={this.state.objeto} onClose={this.onFormClose} clave={this.state.clave} guardar={this.guardar}/>
            </Container>
        );
    }
}

class ObjTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { objetos } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:50}}>Id</th>
                        <th>Nombre</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {objetos.map((o, i) => (
                        <ObjRow key={i} objeto={o} onRowClick={this.onRowClick} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}

class ObjRow extends React.Component{
    onClick = (e) =>{
        if(this.props.objeto.I !== 0)
           this.props.onRowClick(this.props.objeto.I);
    }
    render(){
        let {objeto} = this.props;
        return (
        <tr>
            <td className="numero">{objeto.I}</td>
            <td>{objeto.N}</td>
            <td>
                {
                    this.props.objeto.I !== 0 ?
                    <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={this.onClick}/>
                    :
                    null
                }
            </td>
        </tr>
        );
    }
}

class ObjForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            //...this.props.alumno,
            objeto:this.props.objeto,
        }
    }
    componentWillReceiveProps(newProps){
        this.setState({
            objeto:newProps.objeto
        });
    }
    onChange = (e) => {
        const o = {
            ...this.state.objeto,
            [e.target.name] : e.target.value
        };
        this.setState({objeto:o});
    }
    guardar = () =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.guardar(this.state.objeto);
            }
          })        
    }
    render() {
        const estilo = { display: this.props.visible ? 'inline' : 'none'};
        if(this.props.objeto === undefined)
            return null;
        if(Object.keys(this.props.objeto).length === 0)
            return null;
        return (
            <Container style={estilo}>
                <Form>
                    <div class="row">
                        <div className="col-2">
                            <FormGroup >
                                <label>Id</label>
                                <Input size="sm" type="text" name="Clave" value={this.state.objeto.Id} />
                            </FormGroup>
                        </div>
                        <div className="col-10">
                            <FormGroup  >
                                <label>Nombre</label>
                                <Input size="sm" type="text" name="Nombre" value={this.state.objeto.Nombre} onChange={this.onChange} style={{ width: 700 }} />
                            </FormGroup>
                        </div>
                    </div>
                </Form>
                <br />
                <Button color="primary" onClick={this.guardar}>Aceptar</Button>
                {'  '}
                <Button color="danger" onClick={this.props.onClose}>Cancelar</Button>
            </Container>
        );
    }
}