import React from 'react';
import { Nav,Navbar,NavbarBrand, Collapse, Table, Form, FormGroup, InputGroup, InputGroupAddon, Input, Button, Container, Spinner } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class Transportistas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cargando:true,
            formVisible: false,
            clave: 0,
            nombre:'?',
            chofer:{},
            estados:[],
            choferes: []
        };
        this.cargarChoferes();
        this.cargarCatalogos();
    }
    onFormClose = () => {
        this.setState({
            formVisible: false
        });
    }
    cargarChoferes = async () => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/embarque/transportistas?id=${this.state.nombre}`;
        const resp = await fetch(url);
        const choferes = await resp.json();
        this.setState({cargando:false, choferes });
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/nomina/catalogoSat/estados`;
        const resp = await fetch(url);
        const estados = await resp.json();
        this.setState({cargando:false, estados });
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.cargarChoferes();
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    onBntBuscarClick = () =>{
        this.cargarChoferes();
    }    
    cargarChofer = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/embarque/cargarTransportista/" + cve;
        const resp = await fetch(url);
        const chofer = await resp.json();
        if(chofer.Id === 0){
        }
        this.setState({
            cargando:false,
            formVisible: true,
            clave: cve,
            chofer
        });
    }
    guardar = async(cte) =>{
        const url = this.props.urlws +"/embarque/grabarTransportista";
        this.setState({cargando:true});
        const json = JSON.stringify(cte);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.onFormClose();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    nuevo = () => {
        this.cargarChofer(-1);
    }
    render() {
        const estilo = { display: this.state.formVisible ? 'none' : 'inline' };
        return (
            <Container >
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo}>
                <Navbar bg="f5e831" expand="lg">
                    <NavbarBrand  size="sm" style={{color:'black'}}>Transportistas</NavbarBrand >
                        <Collapse isOpen={true} id="basic-navbar-nav">
                            <Nav className="mr-auto" size="sm">
                                <Button size="sm" color="success" outline onClick={this.nuevo}><img src="/imagenes/add.png" /></Button>
                                &nbsp;
                            <Form inline onSubmit={this.onSubmit} >
                                    <label size="sm">Nombre</label>&nbsp;
                            <InputGroup>
                                        <Input size="sm" type="text" placeholder="Buscar" name="nombre" onChange={this.onChange} style={{ width: 400 }} />
                                        <InputGroupAddon addonType="append">
                                            <Button size="sm" color="success" onClick={this.onBntBuscarClick}> Buscar <img src="/imagenes/zoom.png" /> </Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                    &nbsp;
                                    &nbsp;
                            <Button size="sm" color="success" outline onClick={this.imprimir}><img src="/imagenes/printer.png" /></Button>
                                    &nbsp;
                            <Button size="sm" color="success" outline onClick={this.excel}><img src="/imagenes/excel.png" /></Button>
                                </Form>
                            </Nav>
                        </Collapse>
                </Navbar>
                <div style={{ height: 4 }}></div>
                    <ChoferTable choferes={this.state.choferes} height={500} width={990} onRowClick={this.cargarChofer} />
                </div>
                <TransForm visible={this.state.formVisible} chofer={this.state.chofer} lineas={this.state.lineas} estados={this.state.estados} onClose={this.onFormClose} clave={this.state.clave} guardar={this.guardar}/>
            </Container>
        );
    }
}

class ChoferTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { choferes } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:50}}>Id</th>
                        <th>Nombre</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {choferes.map((esc, i) => (
                        <ChoferRow key={i} chofer={esc} onRowClick={this.onRowClick} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}

class ChoferRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.chofer.I);
    }
    render(){
        let {chofer} = this.props;
        return (
        <tr>
            <td className="numero">{chofer.I}</td>
            <td>{chofer.N}</td>
            <td>
                <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={this.onClick}/>
            </td>
        </tr>
        );
    }
}

class TransForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            //...this.props.alumno,
            chofer:this.props.chofer,
        }
    }
    componentWillReceiveProps(newProps){
        this.setState({
            chofer:newProps.chofer
        });
    }
    onChange = (e) => {
        const esc = {
            ...this.state.chofer,
            [e.target.name] : e.target.value
        };
        this.setState({chofer:esc});
    }
    guardar = () =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.guardar(this.state.chofer);
            }
          })        
    }
    render() {
        const estilo = { display: this.props.visible ? 'inline' : 'none'};
        if(this.props.chofer === undefined)
            return null;
        if(Object.keys(this.props.chofer).length === 0)
            return null;
        return (
            <Container style={estilo}>
                <Form>
                    <div class="row">
                        <div className="col-2">
                            <FormGroup >
                                <label>Id</label>
                                <Input size="sm" type="text" name="Clave" value={this.state.chofer.Id} />
                            </FormGroup>
                        </div>
                        <div className="col-10">
                            <FormGroup  >
                                <label>Nombre</label>
                                <Input size="sm" type="text" name="Nombre" value={this.state.chofer.Nombre} onChange={this.onChange} style={{ width: 700 }} />
                            </FormGroup>
                        </div>
                    </div>
                    <div class="row">
                        <div className="col-6">
                            <FormGroup  >
                                <label>Direccion</label>
                                <Input size="sm" type="text" name="Direccion" value={this.state.chofer.Direccion} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-3">
                            <FormGroup  >
                                <label>Ciudad</label>
                                <Input size="sm" type="text" name="Ciudad" value={this.state.chofer.Ciudad} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-3">
                            <FormGroup  >
                                <label>Estado</label>
                                <Input size="sm" type="select" name="Estado" id="Estado" value={this.state.chofer.Estado} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>    
                                    {this.props.estados.map((i,k) =>(
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                    </div>
                    <div class="row">
                        <div className="col-3">
                            <FormGroup  >
                                <label>Telefono</label>
                                <Input size="sm" type="text" name="Telefono" value={this.state.chofer.Telefono} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-3">
                            <FormGroup  >
                                <label>Email</label>
                                <Input size="sm" type="text" name="Email" value={this.state.chofer.Email} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-3">
                            <FormGroup  >
                                <label>Celular del propietario</label>
                                <Input size="sm" type="text" name="Celular" value={this.state.chofer.Celular} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                    </div>
                </Form>
                <br />
                <Button color="success" size="sm" onClick={this.guardar}>Aceptar</Button>
                {'  '}
                <Button color="danger" size="sm" onClick={this.props.onClose}>Cancelar</Button>
            </Container>
        );
    }
}