import React from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Buscador from '../Buscador';

export default class ActDiariasMaq extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');
        this.state = {
            cargando:false,
            editando:false,
            Temporada:this.props.temp,
            fechaIni:fecIni,
            fechaFin:fecFin,
            movimientos:[],
            tipos:[],
            temporadas:[],
            lotes:[],
            movimiento:{}
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/invMovs/almacenes`;
        let resp = await fetch(url);
        const almacenes = await resp.json();
        url = `${this.props.urlws}/agricola/temporadas`;
        resp = await fetch(url);
        const temporadas = await resp.json();
        url = `${this.props.urlws}/agricola/lotesTemp/${this.state.Temporada}`;
        resp = await fetch(url);
        const lotes = await resp.json();
        this.setState({cargando:false, almacenes, temporadas, lotes });
    }
    cargarLotes = async() =>{
        this.setState({cargando:true});
        const url = `${this.props.urlws}/agricola/lotesTemp/${this.state.Temporada}`;
        const resp = await fetch(url);
        const lotes = await resp.json();
        this.setState({cargando:false, lotes });
    }
    cargarMovs = async () =>{
        if(this.state.Temporada === ''){
            Swal.fire('Atencion','Especifique la temporada','error');
            return;
        }
        const temp = this.state.Temporada;
        const fecIni = this.state.fechaIni;
        const fecFin = this.state.fechaFin;
        this.setState({cargando:true});
        let url = `${this.props.urlws}/agricola/actividadesMaq?fecIni=${fecIni}&fecFin=${fecFin}&temp=${temp}`;
        let resp = await fetch(url);
        const movimientos = await resp.json();
        this.setState({cargando:false, movimientos });
    }
    cargarDetalle = (mov) =>{
        this.cargarMov(mov.Num);
    }
    cargarMov = async (num) =>{
        this.setState({cargando:true});
        let url = `${this.props.urlws}/agricola/cargarActividadMaq?temp=${this.state.Temporada}&num=${num}`;
        let resp = await fetch(url);
        const movimiento = await resp.json();
        this.setState({cargando:false, editando:true, movimiento});
    }
    nuevoMov = () =>{
        const alm = this.state.almacen;
        const cve = this.state.clave;
        if(cve === "??"){
            Swal.fire('Atencion','Especifique el tipo de movimiento','error');
            return;
        }
        if(alm === 0){
            Swal.fire('Atencion','Especifique el almacen','error');
            return;
        }
        this.cargarMov(0);
    }
    guardar = async (obj) =>{
        const mov = {
            Temporada:obj.Temporada,
            Numero : obj.Numero,
            Fecha : obj.Fecha,
            Observa : obj.Observa,
            Lote:obj.Lote,
            Cultivo:obj.Cultivo,
            S: JSON.stringify(obj.Detalle)    
        }
        const url = this.props.urlws +"/agricola/wsguardarActividadMaq";
        this.setState({cargando:true});
        const json = JSON.stringify(mov);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.setState({editando:false});
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    imprimir = (mov) =>{
        let params = '?alm=' + mov.Almacen + '&cve=' + mov.Clave + '&num=' + mov.Numero;
        let url = this.props.urlws + '/compras/imprimir' + params;
        window.open(url, '', '');
    }
    cancelar = () =>{
        this.setState({editando:false});
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor},() =>{
            if(campo === "Temporada")
               this.cargarLotes();
        });
    }

    render () {
        const estilo1 = { display: this.state.editando ? 'inline' : 'none' };
        const estilo2 = { padding:2, display: this.state.editando ? 'none' : 'inline' };
        return(            
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo1}>
                    <Captura movimiento={this.state.movimiento} height={400} urlws={this.props.urlws} cancelar={this.cancelar} guardar={this.guardar} temp={this.state.Temporada} lotes={this.state.lotes} />
                </div>
                <div style={estilo2}>
                    <div className="row">
                        <div className="col-2">
                            <FormGroup>
                                <span>Temporada</span><br/>
                                <Input size="sm" type="select" name="Temporada" value={this.state.Temporada} onChange={this.onChange} style={{  fontSize:'12px'}}>
                                    <option key="0" value="">Especifique</option>
                                    {this.state.temporadas.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Del dia</span><br/>
                                <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>al dia</span><br/>
                                <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup as={Col}>
                                <span>&nbsp;</span><br/>
                                <Button color='success' size="sm" onClick={this.cargarMovs}>Cargar</Button>
                                {'  '}
                                <Button color='success' size="sm" onClick={this.nuevoMov}>+</Button>
                            </FormGroup>
                        </div>
                    </div>
                    <div style={{ height: 4 }}></div>
                    <MovsTable movimientos={this.state.movimientos} height={500} width="98%" onRowClick={this.cargarDetalle} imprimir={this.imprimir} />
                </div>                
            </Container>
        )
    }
}

class MovsTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { movimientos } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:80}}>Numero</th>
                        <th style={{width:80}}>Fecha</th>
                        <th>Maquina</th>
                        <th>Operador</th>
                        <th className="numero">Diesel</th>
                        <th>Observaciones</th>
                        <th style={{width:30, textAlign:'center'}}></th>
                        <th style={{width:30, textAlign:'center'}}></th>
                    </tr>
                </thead>
                <tbody>
                    {movimientos.map((esc, i) => (
                        <MovRow key={i} mov={esc} onRowClick={this.onRowClick} imprimir={this.props.imprimir} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}
class MovRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.mov);
    }
    imprimir = (e) =>{
        this.props.imprimir(this.props.mov);
    }
    render(){
        let {mov} = this.props;
        return (
        <tr>
            <td className="numero">{mov.Num}</td>
            <td>{mov.Fec}</td>
            <td>{mov.Maq}</td>
            <td>{mov.Op}</td>
            <td className="numero">{mov.Lts}</td>
            <td>{mov.Obs}</td>
            <td>
                <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={this.onClick}/>
            </td>
            <td>
                <img src="/imagenes/printer.png" alt="imprimir" title="imprimir" onClick={this.imprimir}/>
            </td>
        </tr>
        );
    }
}
class Captura extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            popoverActOpen:false,
            popoverMaqOpen:false,
            popoverEquiOpen:false,
            popoverOpeOpen:false,
            agregando:false,
            cambio:false,
            mostrarModal:false,
            cargando:false,
            actividad:0,
            maquina:0,
            equipo:0,
            operador:0,
            nomAct:'',
            nomMaq:'',
            nomEqui:'',
            nomOpe:'',
            has:0,
            Lote:0,
            Cultivo:0,
            renglon:0,
            renSig:1,
            indice:0,
            orden:'',
            Detalle:[],
            cultivos:[],
            LoteAnt:0,
            Observa:'',
            ...this.props.movimiento
        }
    }
    cargarCultivos = async (lote) => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/agricola/lotesCultivoTemp/${this.props.temp}?lote=${lote}`;
        let resp = await fetch(url);
        const cultivos = await resp.json();
        this.setState({cargando:false, cultivos });
    }
    componentWillReceiveProps(newProps){
        this.setState({
            cambio:false,
            ...newProps.movimiento,
        },() => this.cargarCultivos(newProps.movimiento.Lote));
    }
    onChange = (e) => {
        const campo = e.target.name;
        let valor = e.target.value;
        this.setState({ [campo] : valor, cambio: true},()=>{
            if(campo === "Lote")
               this.cargarCultivos(valor);
        });
    }
    onKeyPress = (e) =>{
        if(e.which !== 13)
           return;
        const campo = e.target.name;
        let valor = e.target.value;
        if(campo === "has" || campo === "diesel" || campo === "horoIni" || campo === "horoFin"){
           valor = Number(valor);
           this.setState({ [campo] : valor});
        }
        if(campo === "hodoIni")
           this.enter("hodoFin");
        else if(campo === "hodoFin")
           this.enter("has");
        else if(campo === "has")
           this.enter("diesel");
        else if(campo === "diesel"){
            this.enter("actividad");
            this.agregarRenglon();
        }
    }
    onKeyPressAct = (e) =>{
        if(e.which == 13)
           this.cargarActividad(this.state.actividad);
    }
    onKeyPressMaq = (e) =>{
        if(e.which == 13)
           this.cargarMaquina(this.state.maquina);
    }
    onKeyPressEqui = (e) =>{
        if(e.which == 13)
           this.cargarEquipo(this.state.equipo);
    }
    onKeyPressOpe = (e) =>{
        if(e.which == 13)
           this.cargarOperador(this.state.operador);
    }
    toggleAct = () => {
        this.setState({popoverActOpen: !this.state.popoverActOpen});
    }    
    toggleMaq = () => {
        this.setState({popoverMaqOpen: !this.state.popoverMaqOpen});
    }    
    toggleEqui = () => {
        this.setState({popoverEquiOpen: !this.state.popoverEquiOpen});
    }    
    toggleOpe = () => {
        this.setState({popoverOpeOpen: !this.state.popoverOpeOpen});
    }    
    handleClose = () =>{
        this.setState({mostrarModal:false});
    }
    enter = (campo) =>{
        let foco = campo;
        document.getElementById(foco).focus();
    }
    agregarRenglon = () =>{
        if(this.state.renglon === 0){
            const det = {
                Renglon:this.state.renSig,
                Act:this.state.actividad,
                NomAct:this.state.nomAct,
                Maq:this.state.maquina,
                NomMaq:this.state.nomMaq,
                Equi:this.state.equipo,
                NomEqui:this.state.nomEqui,
                Ope:this.state.operador,
                NomOpe:this.state.nomOpe,
                Has:this.state.has,
                Diesel:this.state.diesel,
                HoroIni:this.state.hodoIni,
                HoroFin:this.state.hodoFin
            };
            let Detalle = this.state.Detalle;
            Detalle.push(det);
            this.setState({cambio:true, Detalle,renSig:this.state.renSig + 1});
        }
        else{
            let Detalle = this.state.Detalle;
            const indice = this.state.indice;
            Detalle[indice].Act = this.state.actividad;
            Detalle[indice].NomAct = this.state.nomAct;
            Detalle[indice].Maq = this.state.maquina;
            Detalle[indice].NomMaq = this.state.nomMaq;
            Detalle[indice].Equi = this.state.equipo;
            Detalle[indice].NomEqui = this.state.nomEqui;
            Detalle[indice].Ope = this.state.operador;
            Detalle[indice].NomOpe = this.state.nomOpe;
            Detalle[indice].Has = this.state.has;
            Detalle[indice].Diesel = this.state.diesel;
            Detalle[indice].HoroIni = this.state.hodoIni;
            Detalle[indice].HoroFin = this.state.hodoFin;
            this.setState({mostrarModal:false, cambio:true});
        }
    }
    codigoAct = (item) =>{
        this.setState({popoverActOpen:false});
        this.cargarActividad(item.I);
    }
    codigoMaq = (item) =>{
        this.setState({popoverMaqOpen:false});
        this.cargarMaquina(item.I);
    }
    codigoEqui = (item) =>{
        this.setState({popoverEquiOpen:false});
        this.cargarEquipo(item.I);
    }
    codigoOpe = (item) =>{
        this.setState({popoverOpeOpen:false});
        this.cargarOperador(item.I);
    }
    cargarActividad = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/agricola/cargarActividad/" + cve;
        const resp = await fetch(url);
        const objAct = await resp.json();
        if(objAct.Id === 0){
            Swal.fire('Atencion','Actividad no registrada','error');
            this.setState({cargando:false});
            return;
        }
        this.setState({
            cargando:false,
            actividad:objAct.Id,
            nomAct:objAct.Nombre
        });
        this.enter("maquina");
    }
    cargarMaquina = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/agricola/cargarDestino/" + cve;
        const resp = await fetch(url);
        const objMaq = await resp.json();
        if(objMaq.Id === 0 || (objMaq.Tipo !== 2 && objMaq.Tipo !== 4)){
            Swal.fire('Atencion','Maquina no registrada','error');
            this.setState({cargando:false});
            return;
        }
        this.setState({
            cargando:false,
            maquina:objMaq.Id,
            nomMaq:objMaq.Nombre
        });
        this.enter("equipo");
    }
    cargarEquipo = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/agricola/cargarDestino/" + cve;
        const resp = await fetch(url);
        const objEqui = await resp.json();
        if(objEqui.Id === 0 || objEqui.Tipo !== 3){
            Swal.fire('Atencion','Equipo no registrado','error');
            this.setState({cargando:false});
            return;
        }
        this.setState({
            cargando:false,
            equipo:objEqui.Id,
            nomEqui:objEqui.Nombre
        });
        this.enter("operador");
    }
    cargarOperador = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/agricola/cargarOperador/" + cve;
        const resp = await fetch(url);
        const obj = await resp.json();
        if(obj.Id === 0){
            Swal.fire('Atencion','Operador no registrado','error');
            this.setState({cargando:false});
            return;
        }
        this.setState({
            cargando:false,
            operador:obj.Id,
            nomOpe:obj.Nombre
        });
        this.enter("hodoIni");
    }
    agregarDetalle = () =>{
        this.setState({mostrarModal:true, renglon : 0, agregando:true});
    }
    quitarRenglon = (ren,i) =>{
        let Detalle = this.state.Detalle;
        Detalle.splice(i, 1);
        this.setState({cambio:true, Detalle});
    }
    editarRenglon = (ren,i) =>{
        this.setState({
            agregando:false,
            mostrarModal:true,
            renglon:ren.Renglon,
            actividad:ren.Act,
            nomAct:ren.NomAct,
            maquina:ren.Maq,
            nomMaq:ren.NomMaq,
            equipo:ren.Equi,
            nomEqui:ren.NomEqui,
            operador:ren.Ope,
            nomOpe:ren.NomOpe,
            has:ren.Has,
            diesel:ren.Diesel,
            hodoIni:ren.HoroIni,
            hodoFin:ren.HoroFin,
            indice:i,
        });
    }
    cancelar = () =>{
        if(!this.state.cambio){
            this.props.cancelar();
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea cancelar la captura ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.cancelar();
            }
          })        
    }
    guardar = () =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar este movimiento ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.guardar(this.state);
            }
          })        
    }
    onOrdenSelect = (i,v) =>{
        let orden = '';
        let sepa = '';
        let ordenes = this.state.ordenes;
        ordenes[i].Sel = v;
        for (i = 0; i < ordenes.length; i++) {
            if(ordenes[i].Sel){
                orden = orden + sepa + ordenes[i].Num;
                sepa = ',';
            }
        } 
        this.setState({ordenes,orden});
    }
    render(){
        return(
            <div className="container">
                <div className="row">
                    <div className="col-1">
                        <FormGroup>
                            <span>Numero</span><br />
                            <Input size="sm" type="text" name="Numero" value={this.state.Numero} readOnly />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup>
                            <span>Fecha</span><br />
                            <Input size="sm" type="date" name="Fecha" value={this.state.Fecha} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <span>Hora</span><br />
                        <Input size="sm" type="text" name="Hora" value={this.state.Hora} onChange={this.onChange} />
                    </div>
                    <div className="col-2">
                        <span>Diesel</span><br />
                        <Input size="sm" type="text" name="Litros" value={this.state.Litros} onChange={this.onChange} />
                    </div>
                    <div className="col-2">
                        <span>Horometro</span><br />
                        <Input size="sm" type="text" name="Horometro" value={this.state.Horometro} onChange={this.onChange} />
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <FormGroup>
                            <span>Maquina</span><br />
                            <Input size="sm" type="text" name="NomMaq" value={this.state.NomMaq} readOnly />
                        </FormGroup>
                    </div>
                    <div className="col">
                        <FormGroup>
                            <span>Operador</span><br />
                            <Input size="sm" type="text" name="NomOp" value={this.state.NomOp} readOnly />
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-1">
                        <span>Observaciones</span>
                    </div>
                    <div className="col-11">
                        <Input size="sm" type="text" name="Observa" value={this.state.Observa} onChange={this.onChange} />
                    </div>
                </div>
                <div style={{ height: 4 }}></div>
                <div style={{ height: this.props.height, overflow: 'auto' }}>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th style={{ width: 40 }}></th>
                                <th>Actividad</th>
                                <th>Equipo</th>
                                <th>Lote</th>
                                <th>Cultivo</th>
                                <th style={{ width: 50 }} className="numero">Has.</th>
                                <th style={{width:30, textAlign:'center'}}></th>
                                <th style={{width:30, textAlign:'center'}}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.Detalle.map((ren, i) => (
                                <DetalleRow key={i} indice={i} renglon={ren} onRowClick={this.editarRenglon} onRowQuitar={this.quitarRenglon} />
                            ))}
                        </tbody>
                    </Table>
                </div>
                <div className="row">
                    <div className="col-6">
                        <Button size="sm" disabled color="success" onClick={this.guardar}>Guardar</Button>
                        <span>&nbsp;&nbsp;</span>
                        <Button size="sm" color="danger" onClick={this.cancelar}>Cancelar</Button>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <Button size="sm" disabled color="success" onClick={this.agregarDetalle}>+</Button>
                    </div>
                    <div className="col-6" style={{fontWeight:'bold', textAlign:'right'}}>
                    </div>
                </div>
                <Modal id="dlg" name="dlg" isOpen={this.state.mostrarModal} toggle={this.handleClose} size="lg" tabIndex={1}>
                    <ModalBody>
                        <div className="row">
                            <div className="col">
                                <FormGroup md="3" >
                                    <label>Actividad &nbsp;</label>
                                    <InputGroup>
                                        <Input size="sm" type="text" name="actividad" value={this.state.actividad} onChange={this.onChange} onKeyPress={this.onKeyPressAct} id="actividad" autoFocus />
                                        <InputGroupAddon addonType="append">
                                            <Button size="sm" color="success" id="btnBuscarAct">
                                                <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                            </Button>
                                            <Popover trigger="legacy" placement="right" isOpen={this.state.popoverActOpen} target="btnBuscarAct" toggle={this.toggleAct} >
                                                <PopoverBody>
                                                    <Buscador onSelect={this.codigoAct} url={this.props.urlws + "/agricola/actividades?id="} />
                                                </PopoverBody>
                                            </Popover>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                            </div>
                            <div className="col">
                                <FormGroup md="4">
                                    <label>Nombre &nbsp;</label>
                                    <Input size="sm" type="text" value={this.state.nomAct} readOnly style={{ width: 600 }} />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <FormGroup md="3" >
                                    <label>Equipo &nbsp;</label>
                                    <InputGroup>
                                        <Input size="sm" type="text" name="equipo" value={this.state.equipo} onChange={this.onChange} onKeyPress={this.onKeyPressEqui} id="equipo" autoFocus />
                                        <InputGroupAddon addonType="append">
                                            <Button size="sm" color="success" id="btnBuscarEqui">
                                                <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                            </Button>
                                            <Popover trigger="legacy" placement="right" isOpen={this.state.popoverEquiOpen} target="btnBuscarEqui" toggle={this.toggleEqui} >
                                                <PopoverBody>
                                                    <Buscador onSelect={this.codigoEqui} url={this.props.urlws + "/agricola/equipo?filtro="} />
                                                </PopoverBody>
                                            </Popover>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                            </div>
                            <div className="col">
                                <FormGroup md="4">
                                    <label>Nombre &nbsp;</label>
                                    <Input size="sm" type="text" value={this.state.nomEqui} readOnly style={{ width: 600 }} />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <FormGroup>
                                    <span>Lote</span><br />
                                    <Input size="sm" type="select" name="Lote" value={this.state.Lote} onChange={this.onChange}>
                                        <option key="0" value="">Especifique</option>
                                        {this.props.lotes.map((i, k) =>
                                            <option key={i.I} value={i.I}>{i.N}</option>
                                        )}
                                    </Input>
                                </FormGroup>
                            </div>
                            <div className="col">
                                <FormGroup>
                                    <span>Cultivo</span><br />
                                    <Input size="sm" type="select" name="Cultivo" value={this.state.Cultivo} onChange={this.onChange}>
                                        <option key="0" value="">Especifique</option>
                                        {this.state.cultivos.map((i, k) =>
                                            <option key={i.I} value={i.I}>{i.N}</option>
                                        )}
                                    </Input>
                                </FormGroup>
                            </div>
                            <div className="col-2">
                                <FormGroup>
                                    <span>Has &nbsp;</span><br/>
                                    <Input size="sm" type="text" id="has" name="has" value={this.state.has} onChange={this.onChange} onKeyPress={this.onKeyPress}/>
                                </FormGroup>
                            </div>
                            <div className="col-1">
                                <FormGroup>
                                    <span>&nbsp;</span><br/>
                                    <Button size="sm" color="success">Aceptar</Button>
                                </FormGroup>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}
class DetalleRow extends React.Component{
    onClick = (e) =>{
        console.log(this.props.renglon);
        this.props.onRowClick(this.props.renglon, this.props.indice);
    }
    onQuitar = (e) =>{

    }
    onQuitarX = (e) =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea eliminar este renlon (" + this.props.renglon.NomAct + ") ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.onRowQuitar(this.props.renglon, this.props.indice);
            }
        });
    }
    render(){
        let {renglon} = this.props;
        return (
        <tr>
            <td>{renglon.Act}</td>
            <td>{renglon.NomAct}</td>
            <td>{renglon.NomEqui}</td>
            <td>{renglon.NomLote}</td>
            <td>{renglon.NomCult}</td>
            <td className="numero">{renglon.Has}</td>
            <td>
                <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={this.onClick}/>
            </td>
            <td>
                <img src="/imagenes/quitar.png" alt="eliminar" title="eliminar" onClick={this.onQuitar}/>
            </td>
        </tr>
        );
    }
}