import React from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader, Row } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Cuenta from '../Cuenta';

export default class Polizas extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date().yyyymmdd('-');
        this.state = {
            cargando:false,
            editando:false,
            mostrarAsiento:false,
            empresa:1,
            ejercicio:2019,
            periodo:1,
            tipoPol:'D',
            pol:0,
            fecha:fec,
            poliza:{
                Concepto:'',
                Fecha:fec,
                Cargos:0,
                Abonos:0,
                Detalle:[]
            },
            cuenta:{}, 
            cta:'',            
            nombre:'',
            concepto:'',
            cargo:0,
            abono:0,
            tipoA:'C', 
            indice:0,
            polizas:[],
            empresas:[],
            ejercicios:[],
            periodos:[],
            tiposPol:[],
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/empresa/empresas`;
        let resp = await fetch(url);
        const empresas = await resp.json();

        url = `${this.props.urlws}/conta/ejercicios`;
        resp = await fetch(url);
        const ejercicios = await resp.json();

        url = `${this.props.urlws}/conta/periodos`;
        resp = await fetch(url);
        const periodos = await resp.json();

        url = `${this.props.urlws}/conta/tiposPol`;
        resp = await fetch(url);
        const tiposPol = await resp.json();

        this.setState({cargando:false, empresas, ejercicios, periodos, tiposPol});
    }
    cargarMovs = async () =>{
        const emp = this.state.empresa;
        const ejer = this.state.ejercicio;
        const per = this.state.periodo;
        const tipo = this.state.tipoPol;
        if(emp == 0){
            Swal.fire('Atencion','Especifique la empresa','error');
            return;
        }
        if(ejer == 0){
            Swal.fire('Atencion','Especifique el ejercicio','error');
            return;
        }
        if(per == 0){
            Swal.fire('Atencion','Especifique el periodo','error');
            return;
        }
        if(tipo == "?"){
            Swal.fire('Atencion','Especifique el tipo de poliza','error');
            return;
        }
        this.setState({cargando:true});
        let url = `${this.props.urlws}/conta/listadoPolizas?emp=${emp}&ejer=${ejer}&per=${per}&tipo=${tipo}&polIni=0&polFin=9999999&desc=N`;
        let resp = await fetch(url);
        const polizas = await resp.json();
        this.setState({cargando:false, polizas });
    }
    cargarDetalle = async (mov) =>{
        const emp = this.state.empresa;
        const ejer = this.state.ejercicio;
        const per = this.state.periodo;
        const tipo = this.state.tipoPol;
        const pol = mov.P;
        let url = `${this.props.urlws}/conta/cargarPoliza?emp=${emp}&ejer=${ejer}&per=${per}&tipo=${tipo}&pol=${mov.P}`;
        if(emp == 0){
            Swal.fire('Atencion','Especifique la empresa','error');
            return;
        }
        if(ejer == 0){
            Swal.fire('Atencion','Especifique el ejercicio','error');
            return;
        }
        if(per == 0){
            Swal.fire('Atencion','Especifique el periodo','error');
            return;
        }
        if(tipo == "?"){
            Swal.fire('Atencion','Especifique el tipo de poliza','error');
            return;
        }
        this.setState({cargando:true});
        let resp = await fetch(url);
        const poliza = await resp.json();
        this.setState({cargando:false, poliza, editando:true });
    }
    cargarCuenta = async (cta) => {
        const emp = this.state.empresa;
        this.setState({cargando:true});
        const url = `${this.props.urlws}/conta/cuenta?emp=${emp}&cta=${cta}`;
        const resp = await fetch(url);
        const cuenta = await resp.json();
        if(cuenta.Empresa == 0){
            Swal.fire('Atencion',"La cuenta " + cta + " no existe",'error');
            return;
        }
        if(!cuenta.Permite){
            Swal.fire('Atencion',"La cuenta " + cta + " no permite movimientos",'error');
            return;
        }
         this.setState({
            cargando:false,
            cta:cuenta.Cuenta,
            nombre:cuenta.Nombre,
            cuenta
        });
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor},()=>{
            if(campo === "empresa")
                this.cargarCuentas();
        });
    }
    handleCloseAsiento = () =>{
        this.setState({mostrarAsiento:false});
    }
    agregarAsiento = () =>{
        this.setState({
            mostrarAsiento:true,
            cta:'', 
            nombre:'',
            concepto:'',
            cargo:0,
            abono:0,
            tipoA:'C', 
            indice:-1
        });
    }
    editarAsiento = (asiento,indice) =>{
        this.state.cargo = 0;
        this.state.abono = 0;
        if(asiento.Tipo === 'C')
           this.state.cargo = asiento.Impo;
        else
           this.state.abono = asiento.Impo;
        this.setState({
            mostrarAsiento:true,
            cta:asiento.Cta, 
            nombre:asiento.Nom,
            concepto:asiento.Con,
            tipoA:asiento.Tipo, 
            indice
        });
    }
    guardarAsiento = () =>{
        let i = this.state.indice;
        let tipo = 'C';
        let impo = Number(this.state.cargo);
        if(impo === NaN || impo == 0){
            tipo = 'A';
            impo = Number(this.state.abono);
        }
        if(i >= 0){
           if(this.state.poliza.Detalle[i].Tipo === 'C')
              this.state.poliza.Cargos -= this.state.poliza.Detalle[i].Impo;
           else
              this.state.poliza.Abonos -= this.state.poliza.Detalle[i].Impo;

           this.state.poliza.Detalle[i].Cta = this.state.cta;
           this.state.poliza.Detalle[i].Nom = this.state.nombre;
           this.state.poliza.Detalle[i].Con = this.state.concepto;
           this.state.poliza.Detalle[i].Tipo = tipo;
           this.state.poliza.Detalle[i].Impo = impo;
        }else{
            i = this.state.poliza.Detalle.length;
            const asiento = {
                Ren : i,
                Cta : this.state.cta,
                Nom : this.state.nombre,
                Con : this.state.concepto,
                Tipo : tipo,
                Impo : impo,
                CC : 0,
                U:'',
            };
            this.state.poliza.Detalle.push(asiento);
        }
        if(this.state.poliza.Detalle[i].Tipo === 'C')
           this.state.poliza.Cargos += this.state.poliza.Detalle[i].Impo;
        else
           this.state.poliza.Abonos += this.state.poliza.Detalle[i].Impo;

        this.setState({mostrarAsiento:false});
    }
    imprimir = (mov) =>{
        const emp = this.state.empresa;
        const ejer = this.state.ejercicio;
        const per = this.state.periodo;
        const tipo = this.state.tipoPol;
        let url = `${this.props.urlws}/conta/polizas?emp=${emp}&ejer=${ejer}&per=${per}&tipo=${tipo}&polIni=${mov.P}&polFin=${mov.P}&desc=N`;
        window.open(url, '', '');
    }
    regresar = () =>{
        this.setState({editando:false});
    }
    guardar = async (fec,con) =>{
        const pol = {
            Emp : this.state.empresa,
            Ejer : this.state.ejercicio,
            Per : this.state.periodo,
            Tipo : this.state.tipoPol,
            Pol : this.state.poliza.Pol,
            Fecha : fec,
            Concepto:con,
            Formulo:'',
            Reviso:'',
            Autorizo:'',
            S: JSON.stringify(this.state.poliza.Detalle)    
        }
        const url = this.props.urlws +"/conta/guardarPoliza";
        this.setState({cargando:true});
        const json = JSON.stringify(pol);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res.substr(0,1) !== "E"){
            Swal.fire('Atencion','Se guardo la poliza ' + res,'success');
            this.setState({editando:false});
        }
        else
            Swal.fire('Atencion',res,'error');
    }

    //polizas(int emp, int ejer, string tipo, int per, int polIni, int polFin, string desc)
    render () {
        const estilo1 = { display: this.state.editando ? 'inline' : 'none' };
        const estilo2 = { padding:2, display: this.state.editando ? 'none' : 'inline' };
        return(            
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo1}>
                    <Poliza poliza={this.state.poliza} guardar={this.guardar} regresar={this.regresar} editar={this.editarAsiento} agregar={this.agregarAsiento} height={400}/>
                </div>
                <div style={estilo2}>
                    <div className="row">
                        <div className="col-4">
                            <FormGroup>
                                <span>Empresa</span><br/>
                                <Input size="sm" type="select" name="empresa" value={this.state.empresa} onChange={this.onChange}>
                                    <option key="0" value={0}>Especifique</option>
                                    {this.state.empresas.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Ejercicio</span><br/>
                                <Input size="sm" type="select" name="ejercicio" value={this.state.ejercicio} onChange={this.onChange}>
                                    <option key="0" value={0}>Especifique</option>
                                    {this.state.ejercicios.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Periodo</span><br/>
                                <Input size="sm" type="select" name="periodo" value={this.state.periodo} onChange={this.onChange}>
                                    <option key="0" value={0}>Especifique</option>
                                    {this.state.periodos.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Tipo</span><br/>
                                <Input size="sm" type="select" name="tipoPol" value={this.state.tipoPol} onChange={this.onChange}>
                                    <option key="0" value='?'>Especifique</option>
                                    {this.state.tiposPol.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N} ({i.I})</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup as={Col}>
                                <span>&nbsp;</span><br/>
                                <Button color='success' size="sm" onClick={this.cargarMovs}>Cargar</Button>
                                {'  '}
                                <Button color='success' size="sm" onClick={this.nuevoMov}>+</Button>
                            </FormGroup>
                        </div>
                    </div>
                    <div style={{ height: 4 }}></div>
                    <MovsTable polizas={this.state.polizas} height={500} width={990} cargarDetalle={this.cargarDetalle} imprimir={this.imprimir} />
                </div>         
                <Modal size="lg" id="dlgAsiento" name="dlgAsiento" isOpen={this.state.mostrarAsiento} toggle={this.handleCloseAsiento} tabIndex={1}>
                    <ModalBody>
                        <h6>Asiento</h6>
                        <Container>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <span>Cuenta</span><br />
                                    <Cuenta prefijo="capPol" cuenta={this.state.cta} fin={this.cargarCuenta} emp={this.state.empresa} urlws={this.props.urlws} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <span>Nombre</span><br />
                                    <Input size="sm" type="text" name="nombre" value={this.state.nombre} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <span>Concepto</span><br />
                                    <Input size="sm" type="text" name="concepto" value={this.state.concepto} onChange={this.onChange} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col-1>
                                <FormGroup>
                                    <span>Cargo</span><br />
                                    <Input size="sm" type="text" name="cargo" value={this.state.cargo} onChange={this.onChange} />
                                </FormGroup>
                            </Col-1>
                            <Col-2>
                            <span>&nbsp;&nbsp;&nbsp;</span>
                            </Col-2> 
                            <Col-1>
                                <FormGroup>
                                    <span>Abono</span><br />
                                    <Input size="sm" type="text" name="abono" value={this.state.abono} onChange={this.onChange} />
                                </FormGroup>
                            </Col-1>
                        </Row>
                        <Button size="sm" color="success" onClick={this.guardarAsiento}>Aceptar</Button>
                        <span>&nbsp;&nbsp;&nbsp;</span>
                        <Button size="sm" color="danger" onClick={()=> this.setState({mostrarAsiento:false})}>Cancelar</Button>
                        </Container>
                    </ModalBody>
                </Modal>       
            </Container>
        )
    }
}
class MovsTable extends React.Component {
    cargarDetalle = (cve) => {
        this.props.cargarDetalle(cve);
    }
    render() {
        let { polizas } = this.props;
        return (
            <div style={{ height: this.props.height, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th className="numero" style={{width:70}}>Poliza</th>
                        <th style={{width:80}}>Fecha</th>
                        <th>Concepto</th>
                        <th style={{width:30, textAlign:'center'}}></th>
                        <th style={{width:30, textAlign:'center'}}></th>
                    </tr>
                </thead>
                <tbody>
                    {polizas.map((esc, i) => (
                        <MovRow key={i} mov={esc} cargarDetalle={this.cargarDetalle} imprimir={this.props.imprimir} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}
class MovRow extends React.Component{
    cargarDetalle = (e) =>{
        this.props.cargarDetalle(this.props.mov);
    }
    imprimir = (e) =>{
        this.props.imprimir(this.props.mov);
    }
    render(){
        let {mov} = this.props;
        return (
        <tr>
            <td className="numero">{mov.P}</td>
            <td>{mov.F}</td>
            <td>{mov.C}</td>
            <td>
                <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={this.cargarDetalle}/>
            </td>
            <td>
                <img src="/imagenes/printer.png" alt="imprimir" title="imprimir" onClick={this.imprimir}/>
            </td>
        </tr>
        );
    }
}
class Poliza extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            concepto:this.props.poliza.Concepto,
            fecha:this.props.poliza.Fecha,
            cargos:0,
            abonos:0
        }
    }
    componentWillReceiveProps(p){
        this.setState({
            concepto:p.poliza.Concepto,
            fecha:p.poliza.Fecha,
            cargos:p.poliza.Cargos,
            abonos:p.poliza.Abonos
        });
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor},()=>{
            if(campo === "empresa");
        });
    }
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    guardar = () =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar la poliza ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.guardar(this.state.fecha,this.state.concepto);
            }
          })        
    }
    render() {
        let asientos = this.props.poliza.Detalle;
        return (
            <React.Fragment>
                <Row>
                    <div className="col">
                        <FormGroup>
                            <span>Concepto</span><br />
                            <Input size="sm" type="text" name="concepto" value={this.state.concepto} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup>
                            <span>Fecha</span><br />
                            <Input size="sm" type="date" name="fecha" value={this.state.fecha} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                </Row>  
                <br/>
                <div style={{ height: this.props.height, overflow: 'auto' }}>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th style={{ width: 140 }}>Cuenta</th>
                                <th style={{ width: 220 }}>Nombre</th>
                                <th>Concepto</th>
                                <th style={{ width: 100 }}>Cargos</th>
                                <th style={{ width: 100 }}>Abonos</th>
                                <th style={{ width: 30, textAlign: 'center' }}></th>
                                <th style={{ width: 30, textAlign: 'center' }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {asientos.map((esc, i) => (
                                <Asiento key={i} mov={esc} indice={i} editar={this.props.editar} />
                            ))}
                        </tbody>
                    </Table>
                </div>
                <Table>
                    <thead>
                        <tr>
                            <th style={{ width: 140 }}>
                                <Button size="sm" color="success" onClick={this.props.agregar}>+</Button>
                            </th>
                            <th style={{ width: 220 }}>
                                <Button size="sm" color="success" onClick={this.guardar}>Guardar</Button>
                            </th>
                            <th className="numero">
                                <Button size="sm" color="danger" onClick={this.props.regresar}>Regresar</Button>
                            </th>
                            <th className="numero" style={{ width: 100 }}>{this.state.cargos.formato()}</th>
                            <th className="numero" style={{ width: 100 }}>{this.state.abonos.formato()}</th>
                            <th style={{ width: 30, textAlign: 'center' }}></th>
                            <th style={{ width: 30, textAlign: 'center' }}></th>
                        </tr>
                    </thead>
                </Table>
            </React.Fragment>
        );
    }
}
class Asiento extends React.Component{
    editar = (e) =>{
        this.props.editar(this.props.mov,this.props.indice);
    }
    render(){
        let {mov} = this.props;
        return (
        <tr>
            <td>{mov.Cta}</td>
            <td>{mov.Nom}</td>
            <td>{mov.Con}</td>
            <td className="numero">
                {mov.Tipo === 'C' ?
                <span>{mov.Impo.formato()}</span>
                : 
                null
                }
            </td>
            <td className="numero">
                {mov.Tipo !== 'C' ?
                <span>{mov.Impo.formato()}</span>
                : 
                null
                }
            </td>
            <td>
                <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={this.editar}/>
            </td>
            <td>
                <img src="/imagenes/quitar.png" alt="eliminar" title="eliminar" onClick={this.imprimir}/>
            </td>
        </tr>
        );
    }
}
