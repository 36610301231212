import React from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';

export default class Dividendos extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');
        this.state = {
            cargando:false,
            editando:false,
            almacen:1,
            fechaIni:fecIni,
            fechaFin:fecFin,
            dividendos:[],
            almacenes:[],
            dividendo:{}
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/invMovs/almacenes`;
        let resp = await fetch(url);
        const almacenes = await resp.json();
        this.setState({cargando:false, almacenes});
    }

    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor});
    }
    imprimir = (dato) =>{
        let url = `${this.props.urlws}/conta/dividendoPDF?emp=${this.state.almacen}&cve=${dato.Clave}&num=${dato.Numero}`;
        window.open(url, '', '');    
    }
    cargarMovs = async () =>{
        if(this.state.almacen == 0){
            Swal.fire('Atencion','Especifique el almacen','error');
            return;
        }
        const alm = this.state.almacen;
        const fecIni = this.state.fechaIni;
        const fecFin = this.state.fechaFin;
        this.setState({cargando:true});
        let url = `${this.props.urlws}/conta/dividendos?alm=${alm}&fecIni=${fecIni}&fecFin=${fecFin}`;
        let resp = await fetch(url);
        const dividendos = await resp.json();
        this.setState({cargando:false, dividendos });
    }
    nuevoMov = () =>{
        const alm = this.state.almacen;
        if(alm === 0){
            Swal.fire('Atencion','Especifique el almacen','error');
            return;
        }
        const fec = new Date().yyyymmdd('-');
        const dividendo = {
            Nombre : '',
            Rfc:'',
            MontoExento:0,
            MontoGravado:0,
            MontoRetenido:0,
            MontDivAcumNal:0,
            FechaIni:fec,
            FechaFin:fec,
            MontoIsrAcredRetMex:0,
            MontoIsrAcredRetExt:0,
            ClaveTipo:''
        };
        this.setState({dividendo, editando:true});
    }
    cancelar = () =>{
        this.setState({editando:false});
    }
    guardar = async (obj) =>{
        const mov = {
            almacen : this.state.almacen,
            NomDenRazSocR : obj.Nombre,
            RFCRecep : obj.Rfc,
            montoTotExent : obj.MontoExento,
            montoTotGrav : obj.MontoGravado,
            montoTotRet: obj.MontoRetenido,
            montDivAcumNal: obj.MontDivAcumNal,
            FechaIni : obj.FechaIni,
            FechaFin : obj.FechaFin,
            montISRAcredRetMexico : obj.MontoIsrAcredRetMex,
            montISRAcredRetExtranjero: obj.MontoIsrAcredRetExt,
            CveTipDivOUtil: obj.ClaveTipo
        }
        const url = this.props.urlws +"/conta/timbrarRetDiv";
        this.setState({cargando:true});
        const json = JSON.stringify(mov);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});

        if(res.substr(0,5) !== "Error"){
            Swal.fire('Atencion','Datos guardados','success');
            this.setState({editando:false});
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    render () {
        const estilo1 = { display: this.state.editando ? 'inline' : 'none' };
        const estilo2 = { padding:2, display: this.state.editando ? 'none' : 'inline' };
        return(            
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo1}>
                    <Captura dividendo={this.state.dividendo} height={450} urlws={this.props.urlws} cancelar={this.cancelar} guardar={this.guardar}/>
                </div>
                <div style={estilo2}>
                    <div className="row">
                        <div className="col-2">
                            <FormGroup>
                                <span>Almacen</span><br/>
                                <Input size="sm" type="select" name="almacen" value={this.state.almacen} onChange={this.onChange}>
                                    <option key="0" value={0}>Especifique</option>
                                    {this.state.almacenes.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Del dia</span><br/>
                                <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>al dia</span><br/>
                                <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup as={Col}>
                                <span>&nbsp;</span><br/>
                                <Button color='success' size="sm" onClick={this.cargarMovs}>Cargar</Button>
                                {'  '}
                                <Button color='success' size="sm" onClick={this.nuevoMov}>+</Button>
                            </FormGroup>
                        </div>
                    </div>
                    <div style={{ height: 4 }}></div>
                    <MovsTable dividendos={this.state.dividendos} height={500} width={990} onRowClick={this.cargarDetalle} imprimir={this.imprimir} />
                </div>                
            </Container>
        )
    }
}
class MovsTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { dividendos } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th className="numero" style={{width:70}}>Numero</th>
                        <th style={{width:80}}>Fecha</th>
                        <th>Nombre</th>
                        <th>UUID</th>
                        <th>Fecha timbrado</th>
                        <th className="numero" style={{width:85}}>Importe</th>
                        <th style={{width:30, textAlign:'center'}}></th>
                        <th style={{width:30, textAlign:'center'}}></th>
                    </tr>
                </thead>
                <tbody>
                    {dividendos.map((esc, i) => (
                        <MovRow key={i} mov={esc} onRowClick={this.onRowClick} imprimir={this.props.imprimir} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}
class MovRow extends React.Component{
    onClick = (e) =>{
        //this.props.onRowClick(this.props.mov);
    }
    imprimir = (e) =>{
        this.props.imprimir(this.props.mov);
    }
    render(){
        let {mov} = this.props;
        return (
        <tr>
            <td className="numero">{mov.Numero}</td>
            <td>{mov.Fecha}</td>
            <td>{mov.Nombre}</td>
            <td>{mov.U}</td>
            <td>{mov.F}</td>
            <td className="numero">{mov.Importe}</td>
            <td>
                <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={this.onClick}/>
            </td>
            <td>
                <img src="/imagenes/printer.png" alt="imprimir" title="imprimir" onClick={this.imprimir}/>
            </td>
        </tr>
        );
    }
}

class Captura extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            dividendo:this.props.dividendo,
        }
    }
    componentWillReceiveProps(newProps){
        this.setState({
            dividendo:newProps.dividendo
        });
    }
    onChange = (e) => {
        const esc = {
            ...this.state.dividendo,
            [e.target.name] : e.target.value
        };
        this.setState({dividendo:esc});
    }
    guardar = () =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.guardar(this.state.dividendo);
            }
          })        
    }
    render() {
        if(this.props.dividendo === undefined)
            return null;
        if(Object.keys(this.props.dividendo).length === 0)
            return null;
        return (
            <Container>
                <Form>
                    <h6>PERSONA FISICA</h6>
                    <div class="row">
                        <div className="col-7">
                            <FormGroup  >
                                <label>Nombre</label>
                                <Input size="sm" type="text" name="Nombre" value={this.state.dividendo.Nombre} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup  >
                                <label>R.F.C.</label>
                                <Input size="sm" type="text" name="Rfc" value={this.state.dividendo.Rfc} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                    </div>
                    <h6>RETENCION</h6>
                    <div class="row">
                        <div className="col-2">
                            <FormGroup  >
                                <label>Monto Exento</label>
                                <Input size="sm" type="text" name="MontoExento" value={this.state.dividendo.MontoExento} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup  >
                                <label>Monto Gravado</label>
                                <Input size="sm" type="text" name="MontoGravado" value={this.state.dividendo.MontoGravado} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup  >
                                <label>Monto Retenido</label>
                                <Input size="sm" type="text" name="MontoRetenido" value={this.state.dividendo.MontoRetenido} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <label>Del dia</label>
                                <Input size="sm" type="date" name="FechaIni" value={this.state.dividendo.FechaIni} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <label>Al dia</label>
                                <Input size="sm" type="date" name="FechaFin" value={this.state.dividendo.FechaFin} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                    </div>
                    <h6>DIVIDENDOS</h6>
                    <div class="row">
                        <div className="col-2">
                            <FormGroup  >
                                <label>Monto Acum. Nacional</label>
                                <Input size="sm" type="text" name="MontDivAcumNal" value={this.state.dividendo.MontDivAcumNal} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup  >
                                <label>ISR Acred.Ret.Nacional</label>
                                <Input size="sm" type="text" name="MontoIsrAcredRetMex" value={this.state.dividendo.MontoIsrAcredRetMex} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup  >
                                <label>ISR Acred.Ret.Extranjero</label>
                                <Input size="sm" type="text" name="MontoIsrAcredRetExt" value={this.state.dividendo.MontoIsrAcredRetExt} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup  >
                                <label>Clave de tipo</label>
                                <Input size="sm" type="text" name="ClaveTipo" value={this.state.dividendo.ClaveTipo} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                    </div>
                </Form>
                <br />
                <Button color="primary" onClick={this.guardar}>Aceptar</Button>
                {'  '}
                <Button color="danger" onClick={this.props.cancelar}>Cancelar</Button>
            </Container>
        );
    }
}