import React from 'react';
import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import ContaRepos from './conta/ContaRepos';

export default class Menu extends React.Component {
    render() {
        let catInv = [{id:'agroquimicos',nombre:'Agroquimicos'},{id:'productos',nombre:'Refacciones'}];
        let movInv = [{id:'entAgro',nombre:'Entradas de agroquimicos'},{id:'entRef',nombre:'Entradas de refacciones'},{id:'salAgro',nombre:'Salidas de agroquimicos'},{id:'salRef',nombre:'Salidas de refacciones'},{id:'traspaso',nombre:'Traspasos'},{id:'compras',nombre:'Compras'}];
        let conInv = [{id:'kardex',nombre:'kardex por almacen'},{id:'kardexGen',nombre:'Kardex general'}];
        let repInv = [{id:'rptLstEnt',nombre:'Listado de entradas'},{id:'rptLstSal',nombre:'Listado de salidas'},{id:'rptSdosAlm',nombre:'Saldos por almacen'},{id:'rptExis',nombre:'Existencia'},{id:'rptExisAct',nombre:'Existencia actual'},{id:'rptKardex',nombre:'Kardex'}];

        let catCompras = [{id:'relArtisProv',nombre:'Relacionar codigos de productos'}];
        let movCompras = [{id:'requisicion',nombre:'Requisiciones'},{id:'cotiza',nombre:'Cotizaciones'},{id:'ordencompra',nombre:'Ordenes de compra'},{id:'compras',nombre:'Compras'}];

        let catAgri = [{id:'cultivos',nombre:'cultivos'},{id:'variedades',nombre:'variedades'},{id:'lotes',nombre:'lotes'},{id:'parcelas',nombre:'parcelas'},{id:'actividades',nombre:'actividades'},{id:'temporadas',nombre:'temporadas'},{id:'destinos',nombre:'destinos'}]
        let movAgri = [{id:'loteCult',nombre:'cultivos por lote'},{id:'aplicacion',nombre:'Aplicacion de insumos'},{id:'diarioMaq',nombre:'actividades maquinaria'},{id:'muestreos',nombre:'Muestreo'},{id:'movCorteAran',nombre:'Corte de arandano'},{id:'movEmpaqueAran',nombre:'Empaque de arandano'}];
        let conAgri = [{id:'conDiesel',nombre:'Consumo de diesel'},{id:'conActMaq',nombre:'Actividades por maquina'}];
        let repAgri = [{id:'rptPapaProd',nombre:'Cosecha de papa'},{id:'rptAgriCorte',nombre:'Corte diario de arandano'},{id:'rptAgriCorteSem',nombre:'Corte semanal de arandano'},{id:'rptAgriEmpaque',nombre:'Empaque diario de arandano'},{id:'rptAgriEmpaqueSem',nombre:'Empaque semanal de arandano'},{id:'rptAgriCorteEmpSem',nombre:'Concentrado semanal de arandano'},{id:'rptCosechaGranos',nombre:'Cosecha de granos'},{id:'rptCostoLoteCult',nombre:'Costos por Lote y cultivo'},{id:'rptAgriActLote',nombre:'Act. de Maquinas por Lote y cultivo'},{id:'rptAgriAct',nombre:'Bitacora de consumo de diesel en actividades agricolas'},{id:'rptDieselMes',nombre:'Concentrado de diesel mensual'}];

        let catEmb = [{id:'vendedores',nombre:'Vendedores'},{id:'clientes',nombre:'Clientes'},{id:'destinosEmb',nombre:'Destinos'},{id:'transportistas',nombre:'Transportistas'},{id:'choferes',nombre:'Choferes'}]
        let movEmb = [{id:'movEmbPapa',nombre:'Embarques de papa'},{id:'movRemPapa',nombre:'Remisiones de papa'},{id:'movRemAra',nombre:'Remisiones de arandano'},{id:'movLiqPapa',nombre:'Liquidacion de papa'},{id:'movLiqGranos',nombre:'Liquidaciones de granos'}];
        let conEmb = [{id:'conEmbPapa',nombre:'Embarques de papa'},{id:'conLiqPapa',nombre:'Liquidacion de papa'}];
        let repEmb = [{id:'rptEmbPapa',nombre:'Embarques de papa'},{id:'rptLiqPapa',nombre:'Liquidacion de papa'},{id:'rptRemGranos',nombre:'Remisiones de granos'},{id:'rptRemGranosLiq',nombre:'Remisiones y liquidaciones de granos'}];

        let catCxc = [{id:'artiFact',nombre:'Conceptos de facturacion'},{id:'cxcSec',nombre:'Sectores'},{id:'clientes',nombre:'Clientes'}];
        let movCxc = [{id:'facturas',nombre:'Facturas y pagos'}];

        let catCxp = [{id:'proveedores',nombre:'Proveedores'}];
        let movCxp = [{id:'xmls',nombre:'CFDI Recibidos'},{id:'cxpPagos',nombre:'Pago a proveedores'},{id:'cxpNcPend',nombre:'Notas de Credito por aplicar'},{id:'tipoCambio',nombre:'Tipo de cambio'}];
        let conCxp = [{id:'edoCtaCxp',nombre:'Estado de cuenta'}];

        let catNom = [{id:'nomDeptos',nombre:'Departamentos'},{id:'sucursales',nombre:'Sucursales'}, {id:'empleados',nombre:'Empleados'}, {id:'actividadesNom',nombre:'Actividades'},{id:'actsCultivos',nombre:'Actividades por cultivo'},{id:'cred',nombre:'Credenciales'}];
        let movNom = [{id:'nominas',nombre:'Nominas'},{id:'capnom',nombre:'Nomina por empleado'},{id:'capNomAg',nombre:'Actividades diarias'}];
        let conNom = [{id:'connom',nombre:'Nomina por empleado'}];
        let repNom = [{id:'rptNomActFecCon',nombre:'Concentrado de actividades diarias'},{id:'rptNomActFecDet',nombre:'Detalle de actividades diarias'}];
        
        let catConta = [{id:'contaCuenta',nombre:'Cuentas'},{id:'contaCostos',nombre:'Centros de costos'}];
        let movConta = [{id:'polizas',nombre:'Polizas'},{id:'cheques',nombre:'Cheques'},{id:'dividendos',nombre:'Retenciones de Dividendos'}];
        let conConta = [{id:'conAuxi',nombre:'Auxiliar por cuenta'},{id:'conAna',nombre:'Analitica de saldos por cuenta'}];
        let repConta = [{id:'rptBalanza',nombre:'Balanza de comprobacion'},{id:'rptAnalitica',nombre:'Analitica de saldos'},{id:'rptAuxiliar',nombre:'Auxiliar'},{id:'rptVerificador',nombre:'Verificador de polizas'},{id:'rptPolizas',nombre:'Impresion de polizas'},{id:'rptBalance',nombre:'Balance General'},{id:'rptResultados',nombre:'Estado de resultados'}];

        let modulos = [
                       {id:"inv", nombre:"Inventario", catalogos:catInv, movimientos:movInv, consultas:conInv, reportes:repInv},
                       {id:"compras", nombre:"Compras", catalogos:catCompras, movimientos:movCompras, consultas:[], reportes:[]},
                       {id:"prod", nombre:"Agricola", catalogos:catAgri, movimientos:movAgri, consultas:conAgri, reportes:repAgri},
                       {id:"embarques", nombre:"Embarques", catalogos:catEmb, movimientos:movEmb, consultas:conEmb, reportes:repEmb},
                       {id:"cxc", nombre:"Clientes", catalogos:catCxc, movimientos:movCxc, consultas:[], reportes:[]},
                       {id:"cxp", nombre:"Proveedores", catalogos:catCxp, movimientos:movCxp, consultas:conCxp, reportes:[]},
                       {id:"nom", nombre:"Nomina", catalogos:catNom, movimientos:movNom, consultas:conNom, reportes:repNom},
                       {id:"conta", nombre:"Contabilidad", catalogos:catConta, movimientos:movConta, consultas:conConta, reportes:repConta}
                    ];
                    
        let config = {
          id:"conf", 
          nombre:"Configuracion", 
          opciones: [
            {id:'confUsuarios',nombre:'Usuarios'}
          ]
        };
    
        return (
            <ul className="nav">
                {modulos.map((e,i) =>{
                    return(
                        <li className="nav-item">
                            <Modulo modulo={e} opcionSeleccionada={this.props.opcionSeleccionada}/>
                        </li>    
                    )
                })}
                <ModuloSimple modulo={config} opcionSeleccionada={this.props.opcionSeleccionada}/>
            </ul>)
    }
}

class Modulo extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false
    };
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }
  onClick = (e) =>{
      this.toggle();
      //alert(e.target.dataset.opc);
      this.props.opcionSeleccionada(e.target.dataset.opc,e.target.innerText);
  }
  render() {
    const {modulo} = this.props;
    const titulo = modulo.nombre;
    const idMod = modulo.id;
    const catalogos = modulo.catalogos;
    const movimientos = modulo.movimientos;
    const consultas = modulo.consultas;
    const reportes = modulo.reportes;
    return (
      <div>
          <a  id={idMod} className="nav-link opcion" href="#">
          {titulo}
        </a>
        <Popover placement="bottom" isOpen={this.state.popoverOpen} target={idMod} toggle={this.toggle} trigger="legacy" style={{width:400}}>
          <PopoverBody >
              <div className="container" style={{padding:1}}>
                <div className="body" style={{padding:5}}>
                  <div className="row">
                      <div className="col">
                        <span><b>catalogos</b></span>
                        <ul>
                            {catalogos.map((e,i) => <li className="opcionMenu" data-opc={e.id} onClick={this.onClick}>{e.nombre}</li>)}
                        </ul>
                        <span><b>Movimientos</b></span>
                        <ul>
                            {movimientos.map((e,i) => <li className="opcionMenu" data-opc={e.id} onClick={this.onClick}>{e.nombre}</li>)}
                        </ul>
                        <span><b>Consultas</b></span>
                        <ul>
                            {consultas.map((e,i) => <li className="opcionMenu" data-opc={e.id} onClick={this.onClick}>{e.nombre}</li>)}
                        </ul>
                       </div>
                      <div className="col">
                        <span><b>Informes</b></span>
                        <ul>
                            {reportes.map((e,i) => <li className="opcionMenu" data-opc={e.id} onClick={this.onClick}>{e.nombre}</li>)}
                        </ul>
                      </div>
                  </div>
                  </div>
              </div>
          </PopoverBody>
        </Popover>
      </div>
    );
  }
}

class ModuloSimple extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false
    };
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }
  onClick = (e) =>{
      this.toggle();
      //alert(e.target.dataset.opc);
      this.props.opcionSeleccionada(e.target.dataset.opc,e.target.innerText);
  }
  render() {
    const {modulo} = this.props;
    const titulo = modulo.nombre;
    const idMod = modulo.id;
    const opciones = modulo.opciones;
    return (
      <div>
          <a  id={idMod} className="nav-link opcion" href="#">
          {titulo}
        </a>
        <Popover placement="bottom" isOpen={this.state.popoverOpen} target={idMod} toggle={this.toggle} trigger="legacy" style={{width:400}}>
          <PopoverBody >
              <div className="container" style={{padding:1}}>
                <div className="body" style={{padding:5}}>
                  <div className="row">
                      <div className="col">
                        <ul>
                            {opciones.map((e,i) => <li data-opc={e.id} onClick={this.onClick}>{e.nombre}</li>)}
                        </ul>
                      </div>
                  </div>
                  </div>
              </div>
          </PopoverBody>
        </Popover>
      </div>
    );
  }
}