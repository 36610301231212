import React from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Buscador from '../Buscador';

export default class EmpaqueDiario extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        let fecIni = fec.yyyymmdd('-');
        this.state = {
            cargando:false,
            popoverProvOpen:false,
            Temporada:this.props.temp,
            empresa:2,
            lote:0,
            sectorI:-1,
            sector:0,
            tabla:0,
            linea:0,
            punto:0,
            empId:0,
            tipo:0,
            cant:0,
            fecha:fecIni,
            lotes:[],
            sectores:[],
            tiposCubeta:[],
            empleado:{},
            nomEmp:'',
            detalle:[]
        };
        this.cargarLotes();
    }
    cargarEmpleado = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/nomina/cargarEmpleado/${this.state.empId}?empresa=${this.state.empresa}`;
        let resp = await fetch(url);
        const empleado = await resp.json();
        if(empleado.Id === 0){
            Swal.fire('Atencion',"Empleado no registrado",'error');
            this.setState({cargando:false, empId:0, nomEmp:''});
            return;
        }
        const nomEmp = empleado.Nombre + ' ' + empleado.ApePat + ' ' + empleado.ApeMat;
        this.setState({cargando:false, empleado, nomEmp});
        document.getElementById("cant").focus();
    }
    empSel = (item) =>{
        this.setState({popoverProvOpen:false, empId: item.I, nomEmp:item.N},() =>{
            this.cargarEmpleado();
        });
    }
    onKeyPressEmp = (e) =>{
        if(e.which == 13)
           this.cargarEmpleado();
    }
    onKeyPressCant = (e) =>{
        if(e.which == 13){
            let cant = parseInt(this.state.cant);
            if(isNaN(this.state.cant) || isNaN(cant) || cant === 0){
                Swal.fire('Atencion','Cantidad de cubetas incorrecta','error');
                return;
            }
            this.agregar(cant);
        }
    }
    toggleEmp = () => {
        this.setState({popoverProvOpen: !this.state.popoverProvOpen});
    }    
    cargarSectores = async() =>{
        this.setState({cargando:true});
        let url = `${this.props.urlws}/agricola/sectoresLoteProg?fecha=${this.state.fecha}&lote=${this.state.lote}`;
        let resp = await fetch(url);
        let sectores = await resp.json();
        //if(sectores.length === 0){
            url = `${this.props.urlws}/agricola/sectoresLote?temp=${this.state.Temporada}&lote=${this.state.lote}`;
            resp = await fetch(url);
            sectores = await resp.json();
        //}
        console.log(sectores);
        this.setState({cargando:false, sectorI:-1, sectores });
    }
    cargarLotes = async() =>{
        this.setState({cargando:true});
        let url = `${this.props.urlws}/agricola/lotesCorteTemp/${this.state.Temporada}`;
        let resp = await fetch(url);
        const lotes = await resp.json();
        url = `${this.props.urlws}/agricola/cajasCultivo/3`;
        resp = await fetch(url);
        const tiposCubeta = await resp.json();
        this.setState({cargando:false, lotes, tiposCubeta });
    }
    cargarDetalle = async() =>{
        this.setState({cargando:true});
        const url = `${this.props.urlws}/nomina/empleadosEmpaqueDiaLote?empresa=${this.state.empresa}&fecha=${this.state.fecha}&lote=${this.state.lote}`;
        const resp = await fetch(url);
        const detalle = await resp.json();
        this.setState({cargando:false, detalle });
    }
    imprimir = () =>{
        let url = `${this.props.urlws}/agricolarpt/empaqueDiario?empresa=${this.state.empresa}&fecha=${this.state.fecha}`;
        window.open(url, '', '');    
    }
    onChange = (e) => {
        const campo = e.target.name;
        let valor = e.target.value;
        this.setState({ [campo] : valor},()=>{
            if(campo === "lote"){
                this.cargarSectores();
                this.cargarDetalle();
            }
        });
    }
    detalleClick = (emp) =>{
        this.setState({empId : emp.Id, nomEmp : emp.Nombre});
        document.getElementById("emp").focus();
    }
    agregar = async(cant) =>{
        if(this.state.empId === 0){
            Swal.fire('Atencion',"No se ha indicado el empleado",'error');
            return;
        }
        if(this.state.tipo === 0){
            Swal.fire('Atencion',"No se ha indicado el tipo de caja",'error');
            return;
        }
        let i = this.state.sectorI;
        if(i < 0){
            Swal.fire('Atencion',"No se ha indicado el sector",'error');
            return;
        }
        const sector = this.state.sectores[i].Sector;
        const tabla = this.state.sectores[i].Tabla;
        const cultivo = this.state.sectores[i].Cultivo;
        const variedad = this.state.sectores[i].Variedad;
        const fec = this.state.fecha.replace(/-/g,'');
        //alert("sector : " + sector + "  tabla : " + tabla + " variedad " + variedad + " fecha " + fec);
        const url = this.props.urlws +"/arandano/guardarEmpaqueArandano?usuario=" + this.props.usuario.id;
        this.setState({cargando:true});
        const dato = {
            Id:0,
            Temp : this.state.Temporada,
            Fec:fec,
            Emp:this.state.empId,
            Lote:this.state.lote,
            Sector:sector,
            Tabla:tabla,
            Cult:cultivo,
            Var:variedad,
            Cant:this.state.cant,
            Caja:this.state.tipo,
            Empresa:this.state.empresa,
            Punto:this.state.Punto,
            Hora:''
        };
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res !== "ok"){
            this.setState({cargando:false});
            Swal.fire('Atencion',res,'error');
            return;
        }
        //Swal.fire('Atencion','Datos guardados','success');
        //this.setState({cargando:false, editando:false});
        //this.cargarMovs();


        for (let i = 0; i < this.state.detalle.length; i++) {
            if(this.state.detalle[i].Id == this.state.empId){
                this.state.detalle[i].Cant += cant;
                this.setState({cargando:false});
                return;
            }
        }
        this.state.detalle.push({
            Id : this.state.empId,
            Nombre : this.state.nomEmp,
            Cant : cant
        });
        this.setState({cargando:false, empId:0,nomEmp:''});
    }
    render () {
        return(
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div className="row">
                    <div className="col-2">
                        <FormGroup>
                            <span>Del dia</span><br />
                            <Input size="sm" type="date" name="fecha" value={this.state.fecha} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col">
                        <FormGroup>
                            <span>Lote</span><br />
                            <Input size="sm" type="select" name="lote" value={this.state.lote} onChange={this.onChange}>
                                <option key="0" value="">Especifique</option>
                                {this.state.lotes.map((i, k) =>
                                    <option key={i.I} value={i.I}>{i.N}</option>
                                )}
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col-2">
                            <FormGroup as={Col}>
                                <span>&nbsp;</span><br/>
                                <Button color='success' size="sm" onClick={this.cargarDetalle}>Cargar</Button>
                                <span>&nbsp;&nbsp;</span>
                                <Button outline color='success' size="sm" onClick={this.imprimir}>
                                    <img src="/imagenes/printer.png" alt="imprimir" title="imprimir"/>
                                </Button>
                            </FormGroup>
                        </div>
                </div>
                <div className="row">
                    <div className="col-3">
                        <FormGroup>
                            <span>Sector</span><br />
                            <Input size="sm" type="select" name="sectorI" value={this.state.sectorI} onChange={this.onChange}>
                                <option key="X" value={-1}>Especifique</option>
                                {this.state.sectores.map((i, k) =>
                                    <option key={k} value={k}>Sector {i.Sector} // Tabla {i.Tabla} // {i.Nom}</option>
                                )}
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <span>Empleado</span>
                        <InputGroup>
                            <Input size="sm" type="text" name="empId" value={this.state.empId} onChange={this.onChange} onKeyPress={this.onKeyPressEmp} id="emp" autoFocus />
                            <InputGroupAddon addonType="append">
                                <Button size="sm" color="success" id="btnBuscarEmp">
                                    <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                </Button>
                                <Popover trigger="legacy" placement="right" isOpen={this.state.popoverProvOpen} target="btnBuscarEmp" toggle={this.toggleEmp} >
                                    <PopoverBody>
                                        <Buscador onSelect={this.empSel} url={this.props.urlws + "/nomina/empleadosLista?empresa=" + this.state.empresa + "&filtro="} />
                                    </PopoverBody>
                                </Popover>
                            </InputGroupAddon>
                        </InputGroup>
                    </div>
                    <div className="col-4">
                        <FormGroup>
                            <span>&nbsp;</span><br />
                            <Input size="sm" type="text" name="nomEmp" value={this.state.nomEmp} readOnly />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup>
                            <span>Tipo</span><br />
                            <Input size="sm" type="select" name="tipo" value={this.state.tipo} onChange={this.onChange}>
                                <option key="0" value={0}>Especifique</option>
                                {this.state.tiposCubeta.map((i, k) =>
                                    <option key={i.I} value={i.I}>{i.N}</option>
                                )}
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col-1">
                        <FormGroup>
                            <span>Cajas</span><br />
                            <Input size="sm" type="text" id="cant" name="cant" value={this.state.cant} onChange={this.onChange} onKeyPress={this.onKeyPressCant} />
                        </FormGroup>
                    </div>
                </div>
                <div style={{ height: 4 }}></div>
                <div>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th style={{ width: 60 }}>No.</th>
                                <th>Nombre</th>
                                <th style={{ width: 60 }}>Cubetas</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.detalle.map((ren, i) => (
                                <DetalleRow key={i} indice={i} renglon={ren} onClick={this.detalleClick} />
                            ))}
                        </tbody>
                    </Table>
                </div>                
            </Container>
        )
    }
}
class DetalleRow extends React.Component{
    onClick = () =>{
        this.props.onClick(this.props.renglon);
    }
    render(){
        let {renglon} = this.props;
        return (
        <tr onClick={this.onClick}>
            <td className="numero">{renglon.Id}</td>
            <td>{renglon.Nombre}</td>
            <td className="numero rojo" >{renglon.Cant}</td>
        </tr>
        );
    }
}