import React from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';

export default class Empaque extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        let fecIni = fec.yyyymmdd('-');
        this.state = {
            cargando:false,
            Temporada:this.props.temp,
            empresa:2,
            lote:0,
            fecha:fecIni,
            lotes:[],
            detalle:[],
            puntos:[],
            punto:0
        };
        this.cargarLotes();
        this.cargarPuntos();
    }
    cargarLotes = async() =>{
        this.setState({cargando:true});
        const url = `${this.props.urlws}/agricola/lotesCorteTemp/${this.state.Temporada}`;
        const resp = await fetch(url);
        const lotes = await resp.json();
        this.setState({cargando:false, lotes });
    }
    cargarPuntos = async() =>{
        this.setState({cargando:true});
        const url = `${this.props.urlws}/arandano/corteDiarioPuntos?fecha=${this.state.fecha}`;
        const resp = await fetch(url);
        const puntos = await resp.json();
        this.setState({cargando:false, puntos });
    }
    cargarDetalle = async() =>{
        this.setState({cargando:true});
        const url = `${this.props.urlws}/arandano/empaqueDiario?empresa=${this.state.empresa}&fecha=${this.state.fecha}&punto=${this.state.punto}`;
        const resp = await fetch(url);
        const detalle = await resp.json();
        this.setState({cargando:false, detalle });
    }
    imprimir = () =>{
        let url = `${this.props.urlws}/agricolarpt/empaqueDiario?empresa=${this.state.empresa}&fecha=${this.state.fecha}&punto=${this.state.punto}`;
        window.open(url, '', '');    
    }
    onChange = (e) => {
        const campo = e.target.name;
        let valor = e.target.value;
        this.setState({ [campo] : valor}, ()=>{
            if(campo === "fecha")
                this.cargarPuntos();
        });

    }
    render () {
        return(
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div className="row">
                    <div className="col-2">
                        <FormGroup>
                            <span>Del dia</span><br />
                            <Input size="sm" type="date" name="fecha" value={this.state.fecha} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col-4">
                        <FormGroup>
                            <span>Lote</span><br />
                            <Input size="sm" type="select" name="Lote" value={this.state.Lote} onChange={this.onChange}>
                                <option key="0" value="">Especifique</option>
                                {this.state.lotes.map((i, k) =>
                                    <option key={i.I} value={i.I}>{i.N}</option>
                                )}
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <span>Punto</span><br />
                            <Input size="sm" type="select" name="punto" value={this.state.punto} onChange={this.onChange}>
                                <option key="0" value={0}>Todos</option>
                                {this.state.puntos.map((i, k) =>
                                    <option key={i.I} value={i.I}>{i.N}</option>
                                )}
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col-2">
                            <FormGroup as={Col}>
                                <span>&nbsp;</span><br/>
                                <Button color='success' size="sm" onClick={this.cargarDetalle}>Cargar</Button>
                                <span>&nbsp;&nbsp;</span>
                                <Button outline color='success' size="sm" onClick={this.imprimir}>
                                    <img src="/imagenes/printer.png" alt="imprimir" title="imprimir"/>
                                </Button>
                            </FormGroup>
                        </div>
                </div>
                <div style={{ height: 4 }}></div>
                <div>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th style={{ width: 60 }}>No.</th>
                                <th>Nombre</th>
                                <th style={{ width: 60 }}>Estandar 4.4 OZ</th>
                                <th style={{ width: 60 }}>Estandar 6.0 OZ</th>
                                <th style={{ width: 60 }}>Estandar 8x18 OZ</th>
                                <th style={{ width: 60 }}>Estandar 12x18OZ</th>
                                <th style={{ width: 60 }}>Jumbo 4.4 OZ</th>
                                <th style={{ width: 60 }}>Jumbo 6.0 OZ</th>
                                <th style={{ width: 60 }}>Jumbo 9.8 OZ.</th>
                                <th style={{ width: 60 }}>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.detalle.map((ren, i) => (
                                <DetalleRow key={i} indice={i} renglon={ren} />
                            ))}
                        </tbody>
                    </Table>
                </div>                
            </Container>
        )
    }
}
class DetalleRow extends React.Component{
    render(){
        let {renglon} = this.props;
        return (
        <tr>
            <td>{renglon.Id}</td>
            <td>{renglon.Nom}</td>
            <td className="numero" >{renglon.C1}</td>
            <td className="numero" >{renglon.C2}</td>
            <td className="numero" >{renglon.C6}</td>
            <td className="numero" >{renglon.C7}</td>
            <td className="numero" >{renglon.C3}</td>
            <td className="numero" >{renglon.C4}</td>
            <td className="numero" >{renglon.C5}</td>
            <td className="numero rojo" >{renglon.Total}</td>
        </tr>
        );
    }
}