import React from 'react';
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';

export default class EmpaqueSem extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        fec.addDays(7);
        let fecFin = fec.yyyymmdd('-');
        this.state = {
            cargando:false,
            Temporada:this.props.temp,
            fecha:fecFin,
            empresa:2,
            lote:0,
            semana:0,
            semanas:[],
            lotes:[],
            detalle:[]
        };
        this.cargarLotes();
    }
    cargarLotes = async() =>{
        this.setState({cargando:true});
        let url = `${this.props.urlws}/agricola/lotesCorteTemp/${this.state.Temporada}`;
        let resp = await fetch(url);
        const lotes = await resp.json();
        url = `${this.props.urlws}/nomina/semanas?temp=${this.state.Temporada}&fecFin=${this.state.fecha}`;
        resp = await fetch(url);
        const semanas = await resp.json();
        this.setState({cargando:false,lotes, semanas });
    }
    cargarDetalle = async() =>{
        this.setState({cargando:true});
        const url = `${this.props.urlws}/nomina/empaqueSemana?empresa=${this.state.empresa}&temp=${this.state.Temporada}&semana=${this.state.semana}&lote=${this.state.lote}`;
        const resp = await fetch(url);
        const detalle = await resp.json();
        this.setState({cargando:false, detalle });
    }
    imprimir = () =>{
        //let url = `${this.props.urlws}/agricolarpt/corteDiario?empresa=${this.state.empresa}&fecha=${this.state.fecha}`;
        //window.open(url, '', '');    
    }
    onChange = (e) => {
        const campo = e.target.name;
        let valor = e.target.value;
        this.setState({ [campo] : valor});
    }
    render () {
        return(
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div className="row">
                    <div className="col-4">
                        <FormGroup>
                            <span>Semana</span><br />
                            <Input size="sm" type="select" name="semana" value={this.state.semana} onChange={this.onChange}>
                                <option key="0" value="0">Especifique</option>
                                {this.state.semanas.map((i, k) =>
                                    <option key={i.I} value={i.I}>{i.N}</option>
                                )}
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col">
                        <FormGroup>
                            <span>Lote</span><br />
                            <Input size="sm" type="select" name="lote" value={this.state.lote} onChange={this.onChange}>
                                <option key="0" value="0">Todos</option>
                                {this.state.lotes.map((i, k) =>
                                    <option key={i.I} value={i.I}>{i.N}</option>
                                )}
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col-2">
                            <FormGroup as={Col}>
                                <span>&nbsp;</span><br/>
                                <Button color='success' size="sm" onClick={this.cargarDetalle}>Cargar</Button>
                                <span>&nbsp;&nbsp;</span>
                                <Button outline color='success' size="sm" onClick={this.imprimir}>
                                    <img src="/imagenes/printer.png" alt="imprimir" title="imprimir"/>
                                </Button>
                            </FormGroup>
                        </div>
                </div>
                <div style={{ height: 4 }}></div>
                <div>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th colSpan="2"></th>
                                <th colsPan="8" style={{textAlign:'center'}}>Cajas</th>
                                <th colsPan="8" style={{textAlign:'center'}}>Pago</th>
                            </tr>
                            <tr>
                                <th style={{ width: 60 }}>No.</th>
                                <th>Nombre</th>
                                <th style={{ width: 40 }}>Dia1</th>
                                <th style={{ width: 40 }}>Dia2</th>
                                <th style={{ width: 40 }}>Dia3</th>
                                <th style={{ width: 40 }}>Dia4</th>
                                <th style={{ width: 40 }}>Dia5</th>
                                <th style={{ width: 40 }}>Dia6</th>
                                <th style={{ width: 40 }}>Dia7</th>
                                <th style={{ width: 60 }}>Total</th>
                                <th style={{ width: 40 }}>1</th>
                                <th style={{ width: 40 }}>2</th>
                                <th style={{ width: 40 }}>3</th>
                                <th style={{ width: 40 }}>4</th>
                                <th style={{ width: 40 }}>5</th>
                                <th style={{ width: 40 }}>6</th>
                                <th style={{ width: 40 }}>7</th>
                                <th style={{ width: 60 }}>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.detalle.map((ren, i) => (
                                <DetalleRow key={i} indice={i} renglon={ren} />
                            ))}
                        </tbody>
                    </Table>
                </div>                
            </Container>
        )
    }
}
class DetalleRow extends React.Component{
    render(){
        let {renglon} = this.props;
        return (
        <tr>
            <td>{renglon.Id}</td>
            <td>{renglon.Nom}</td>
            <td className="numero" >{renglon.C1}</td>
            <td className="numero" >{renglon.C2}</td>
            <td className="numero" >{renglon.C3}</td>
            <td className="numero" >{renglon.C4}</td>
            <td className="numero" >{renglon.C5}</td>
            <td className="numero" >{renglon.C6}</td>
            <td className="numero" >{renglon.C7}</td>
            <td className="numero" >{renglon.CT}</td>
            <td className="numero" >{renglon.I1}</td>
            <td className="numero" >{renglon.I2}</td>
            <td className="numero" >{renglon.I3}</td>
            <td className="numero" >{renglon.I4}</td>
            <td className="numero" >{renglon.I5}</td>
            <td className="numero" >{renglon.I6}</td>
            <td className="numero" >{renglon.I7}</td>
            <td className="numero" >{renglon.IT}</td>
        </tr>
        );
    }
}