import React from 'react';
import { Badge } from 'reactstrap';

export default class  Notifica extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          dato:-1,
        }
        this.actualiza();
    }    
    actualiza = async () => {
        let milisegundos = this.props.milisegundos;
        this.setState({cargando:true});
        const url = `${this.state.urlws}/invMovs/nuevasReq/1`;
        const resp = await fetch(this.props.url);
        const dato = await resp.text();
        this.setState({ dato });
        setTimeout(() => { this.actualiza(); }, milisegundos);
    }
    render(){
        return (
            <span>{this.props.texto} <Badge color="danger">{this.state.dato}</Badge></span>
        )
    }
}