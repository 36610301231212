import React from 'react';
import { Form, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Card, Button, CardTitle,CardBody } from 'reactstrap';
import Swal from 'sweetalert2'

export default class Login extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            usu:'',
            pwd:''
        };
        if(this.props.urlws === 'http://localhost:7115'){
            this.state.usu = 'Admin';
            this.state.pwd = 'abc123';
        }
    }
    onChange = (e) =>{
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    loguear  = async() => {
        const usu = this.state.usu;
        const pwd = this.state.pwd;
        const url = `${this.props.urlws}/usuario/loguear?usu=${usu}&pwd=${pwd}`;
        const req = await fetch(url);
        const res = await req.json();
        if(res.Id === 0){
            Swal.fire('Atencion','Datos incorrectos','error');
            return;
        }
        this.props.iniciar(res);
    }

    render(){
        return(
            <div className="container" >
                <br/><br/><br/><br/><br/>
                <div className="row">
                <div className="col-3">
                    &nbsp;
                </div>
                <div className="col-6" style={{fontWeight:'bolder', color:'black'}}>
                <Card color="warning" style={{backgroundColor:'transparent',padding:15}}>
                    <CardTitle><h3>{this.props.nombre}</h3></CardTitle>
                    <CardBody>
                    <form >
                            <div className="row">
                                <div className="col">
                                    <div className="form-group" size="sm">
                                        <h5>Usuario</h5>
                                        <input className="form-control form-control-sm" type="text" name="usu" value={this.state.usu} onChange={this.onChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group" size="sm">
                                        <h5>Contraseña</h5>
                                        <Input size="sm" type="password" name="pwd" value={this.state.pwd} onChange={this.onChange} />
                                    </div>
                                </div>
                            </div>
                            <Button outline color="success" onClick={this.loguear}>Iniciar sesion</Button>
                        </form>                        
                    </CardBody>
                </Card>
                </div>
                </div>
            </div>
        )
    }
}